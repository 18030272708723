import React from "react";
import { withRouter } from "react-router";
import Auth from "../services/auth";
import { Button } from "primereact/button";

class page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      failedLogin: false,
    };
  }

  componentDidMount() {
    const queryURL = new URLSearchParams(window.location.search);
    const token = queryURL.get("token");

    if (!token) {
      this.setState({ failedLogin: true });
    }

    window.setTimeout(() => {
      Auth.verifyMagicLink(token, (response) => {
        if (response.magic_token_verified) {
          localStorage.setItem("adminLoggedIn", true);
          this.props.history.replace("/adminPortal");
        } else {
          this.setState({ failedLogin: true });
        }
      });
    }, 1000);
  }

  render() {
    return (
      <div className="admin-login-page">
        <div className="banner-centered">
          <h1 className="p-m-0 center">Checking your magic token..</h1>
          {this.state.failedLogin && (
            <><div>
              Uh-oh, your link is no longer valid. Try getting a new one.
            </div>
            <Button className="p-button-raised center-img p-button-outlined p-button-plain p-mt-3" onClick={(e) => this.props.history.replace("/adminPortal/login")} label="go to login" />
            </>)}
        </div>
      </div>
    );
  }
}

var AdminMagicToken = withRouter(page);

export default AdminMagicToken;
