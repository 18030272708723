import React from "react";
import { withRouter } from "react-router";
import "primeflex/primeflex.css";
import { TieredMenu } from "primereact/tieredmenu";

import adminDashboardStyles from "../style/admin_dashboard.module.css";

class page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCity: null,
      selectedCountries: null,
      menuSize: this.menuSize()
    };

    this.items = [
      {
        label: "Users",
        icon: "pi pi-fw pi-users",
        items: [
          {
            label: "Search",
            icon: "pi pi-fw pi-search",
            url: "/adminPortal/home",
          },
          {
            label: "Credit Checks",
            icon: "pi pi-fw pi-check-circle",
            url: "/adminPortal/creditCheck",
          },
          {
            label: "Disable Payments",
            icon: "pi pi-fw pi-cog",
            url: "/adminPortal/disablePayments",
          },
          {
            label: "Failed Payments",
            icon: "pi pi-fw pi-cog",
            url: "/adminPortal/failedPayments",
          },
          {
            label: "PayGate",
            icon: "pi pi-fw pi-check-circle",
            url: "/adminPortal/paygate",
          },
          {
            label: "Meta Management",
            icon: "pi pi-fw pi-ticket",
            url: "/adminPortal/metas",
          },
        ],
      },
      {
        label: "Circles",
        icon: "pi pi-fw pi-circle-off",
        items: [
          {
            label: "Manage Circles",
            icon: "pi pi-fw pi-pencil",
            url: "/adminPortal/circles",
          },
          {
            label: "Draw Day Generator",
            icon: "pi pi-fw pi-flag",
            url: "/adminPortal/drawDayGenerator",
          },
          {
            label: "Draw Day Info",
            icon: "pi pi-fw pi-briefcase",
            url: "/adminPortal/drawDayInfo",
          },
          {
            label: "Chats",
            icon: "pi pi-fw pi-comments",
            url: "/adminPortal/chats",
          },
        ],
      },
      {
        label: "Payment Ops",
        icon: "pi pi-fw pi-money-bill",
        items: [
          {
            label: "Monthly Cycle",
            icon: "pi pi-fw pi-user",
            url: "/adminPortal/paymentGenerator",
          },
          {
            label: "Transfers",
            icon: "pi pi-fw pi-sort-alt rotate-90",
            url: "/adminPortal/transfers",
          },
          {
            label: "Transfers to payins",
            icon: "pi pi-fw pi-angle-double-right",
            url: "/adminPortal/TransfersToPayins",
          },
        ],
      },

      {
        label: "Partners",
        icon: "pi pi-fw pi-users",

        items: [
          {
            label: "Manage Offers",
            icon: "pi pi-fw pi-pencil",
            url: "/adminPortal/articles",
          },
          {
            label: "Analytics",
            icon: "pi pi-fw pi-percentage",
            url: "/adminPortal/partnerAnalytics",
          },
          {
            label: "Ambassadors",
            icon: "pi pi-fw pi-thumbs-up",
            url: "/adminPortal/ambassadors",
          },
          {
            label: "Vouchers",
            icon: "pi pi-fw pi-tags",
            url: "/adminPortal/vouchers",
          },
          {
            label: "Lifecycle Changes",
            icon: "pi pi-fw pi-circle-on",
            url: "/adminPortal/lifecycleChanges",
          },
        ],
      },
      {
        label: "Management Info",
        icon: "pi pi-fw pi-chart-bar",
        items: [
          {
            label: "Top Sheet",
            icon: "pi pi-fw pi-file",
            url: "/adminPortal/topsheet",
          },
          {
            label: "Circle Tracker",
            icon: "pi pi-fw pi-book",
            url: "/adminPortal/tracker",
          },
          {
            label: "Fee Information",
            icon: "pi pi-money-bill",
            url: "/adminPortal/fees",
          },
          {
            label: "Risk Summary",
            icon: "pi pi-fw pi-chart-bar",
            url: "/adminPortal/riskPolicy",
          },
          {
            label: "Signup Count",
            icon: "pi pi-fw pi-file",
            url: "/adminPortal/signups",
          },
          {
            label: "Conversion Funnel",
            icon: "pi pi-fw pi-filter",
            url: "/adminPortal/conversions",
          },
          
        ],
      },
      {
        label: "Settings",
        icon: "pi pi-fw pi-cog",
        items: [
          {
            label: "Manage Access",
            icon: "pi pi-fw pi-pencil",
            url: "/adminPortal/manageAccess",
          },
        ],
      },
      {
        separator: true,
      },
      {
        label: this.collapse,
        icon: "pi pi-fw pi-bars",
        command: (event) => {
          if(event.item && event.item.label === this.collapse) {
            this.minimiseMenu()
          }
        }
      },
      {
        separator: true,
      },
      {
        label: "Logout",
        icon: "pi pi-fw pi-power-off",
        url: "/logout",
      },
    ];
    this.minimiseMenu = this.minimiseMenu.bind(this);
    this.menuSize = this.menuSize.bind(this);
  }

  collapse = "Collapse menu";


  minimiseMenu() {
    localStorage.setItem("minimiseMenu", !this.state.menuSize);
    this.setState({menuSize: !this.state.menuSize});
  }

  menuSize () {
    return localStorage.getItem("minimiseMenu") === "true";
  }

  isActive(items) {
    const currentPath = window.location.pathname;

    return items.some((i) => {
      if (i.items) {
        return this.isActive(i.items);
      }

      if (i.url && i.url.indexOf(currentPath) > -1) {
        return true;
      }

      return false;
    });
  }

  isLoggedIn() {
    return localStorage.getItem("adminLoggedIn") === "true";
  }

  showActive(items){
    return items.map(i => {
      if(!i.items){
        return i;
      }
      
      return {...i, className: this.isActive(i.items) ? "active-menu-item" : "" }
    })
  }

  render() {
    if(!this.isLoggedIn()) {
      return (<React.Fragment></React.Fragment>);
    }
    return (
      <div
        className={`leftNav p-col-fixed ${adminDashboardStyles.adminLeftNav} ${this.state.menuSize ? "mini-menu" : ""}`}
       
      >
        <div className="logo-wrapper">
          <img
            className="admin-logo"
            alt="logo"
            src={this.state.menuSize ? "images/stepLadderLogo.png" : "images/stepLadderLogo2.png"}
          ></img>
        </div>
        <TieredMenu
          className={"tieredmenu " + adminDashboardStyles.adminLeftNavMenu}
          model={this.showActive(this.items)}
        />
        {this.menuSize()}
      </div>
    );
  }
}

var LeftNav = withRouter(page);

export default LeftNav;
