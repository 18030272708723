import { useEffect } from "react";
import { getQueryParams } from "../../../utils/generic";

function PreFill(){
    useEffect(() => {
        loadIt();
    }, []);

    const loadIt = () => {
        const params = getQueryParams();

        const hasLoggedInBefore = !!localStorage.getItem("user_logged_in_before");

        if(params){
            for(var key in params){
                if(key === "smallCircle"){
                    localStorage.setItem(key, params[key]);
                    sessionStorage.setItem("smallCircle", true);
                } else {
                    sessionStorage.setItem(key, params[key]);
                }
            }
        }

        window.location.href = !hasLoggedInBefore ? "/getStartedApply" : "/circleInvite";
    }

    return <div></div> 
}

export default PreFill;