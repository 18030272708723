import React, {useEffect} from "react";
import { useHistory } from "react-router";
import { useUI } from "../../../contexts/UIContext";
import { useUser } from "../../../contexts/UserContext";
import { getNextRoute } from "../../../utils/userRoutes";
import Form from "../../generic/forms/Form";
import { refferalsToAmbassadors } from "../../../../common/services/userHelpers";

function PersonalGoals({ setPage, userInfo, setTitle, setSubTitle }) {
  const UI = useUI();
  const userContext = useUser();
  const history = useHistory();
  const isRefferalToAmbassador = userInfo.ambassador_id && Object.entries(refferalsToAmbassadors).some(([key, value]) => value === userInfo.ambassador_id);
  useEffect(() => {
    setTitle("What is your financial goal?");
    setSubTitle("This information helps our team understand how we can help you better.");
    
    if(isRefferalToAmbassador && userInfo.ambassador_id !== "WomensCircle"){
      submitValues({reason_for_saving: userInfo.ambassador_id})
    }
  }, [setTitle, setSubTitle]);

  const submitValues = async (values) => {
    userInfo.reason_for_saving = values.reason_for_saving;

    if(values.reason_for_saving_other){
      userInfo.reason_for_saving_other = values.reason_for_saving_other;
    }

    UI.showLoading();

    const { status, data } = await userContext.createUser(userInfo);
    UI.hideLoading();

    if (status !== 200) {
      handleError(data, userInfo.email);
      return;
    }
    history.push(getNextRoute(userContext.user));
  };
  const handleError = (errorObject, emailSubmitted) => {
    const error = errorObject.error;

    if (error) {
      if (error === "email_exists") {
        UI.showToast("Your email exists, try logging in!");
        history.push("/welcomeBack?email=" + emailSubmitted);
        return;
      }

      if (error === "user_exists") {
        UI.showToast(
          "Your account exists with a different email, try logging in!"
        );
        history.push(
          "/welcomeBack?email=" + errorObject.email + "&partial=true"
        );
        return;
      }
    }

    UI.showToast("Sorry! We ran into an error, please contact us to fix it");
  };

  if(isRefferalToAmbassador) {
    return <></>;
  }

  return (
    <>
      {userInfo.joint_application && (
        <div className="blueBox">
          Please enter your own details for now, and at the end of the application
          form we'll ask you for your joint applicant details.
        </div>
      )}

      <Form
        inputs={[
          { key: "reason_for_saving", value: "" },
          {
            key: "reason_for_saving_other",
            value: "",
            showCondition: function (allValues) {
              return (
                allValues.find((itm) => itm.key === "reason_for_saving").value ===
                "Something else"
              );
            },
          },
        ]}
        onSubmit={(values) => submitValues(values)}
        onCancel={() => setPage(1)}
      />
    </>
  );
}

export default PersonalGoals;
