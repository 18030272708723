import React, { useContext, createContext, useState, useEffect } from "react";
import {
  createUser,
  getUser,
  nextStage,
  updateUserDetails,
} from "../services/users";
import LoadingOverlay from "../components/generic/LoadingOverlay";
import { requestCircle } from "../services/circles";
import { getSpecificParticipant, isReservedPeriod } from "../utils/circles";
import moment from "moment";
import AdminConfig from "./helpers/adminConfig";
import { isHouseRelated } from "../utils/users";

const UserContext = createContext();

export function UserContextProvider({ children }) {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [adminConfig, setAdminConfig] = useState({showDrawday: false, fsDrawn: false, hcDrawn: false});

  const isAuthenticated = !!localStorage.getItem("user_logged_in_before");
  const isImpersonating = !!localStorage.getItem("impersonation");

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function initialize() {
    try {
      if (isAuthenticated) {
        setIsLoading(true);
        const {data, status} = await getUser();

        if (status === 200) {
          initUserState(data)
        }
      }
    } finally {
      setIsLoading(false);
    }
  }

  function initUserState(intitUser) {
    if(isImpersonating && adminConfig.showDrawday){
      setUser({...intitUser, drawDayReady: adminConfig.showDrawday});
      return;
    } else {
      setUser(intitUser);
    }
  }

  function updateUserState(updates) {
    const newUser = user;
    for (var key in updates) {
      newUser[key] = updates[key];
    }
    setUser(newUser);

    // setUser(prevState => {
    //   const newUser = {...prevState.user, ...updates};               
    //   return { user: newUser };
    // });
  }

  async function updateUser(updates, dontCallServer = false) {
    if (dontCallServer) {
      updateUserState(updates);
      return true;
    }

    if (updates.isCircleAutoRenew) {
      await updateUserDetails({ auto_renewed_from_platform: "Yes" });
    }

    if (updates.nextStage) {
      const { status, data } = await nextStage();

      if (status === 200) {
        updateUserState({ stage: data.newStage });
      }

      return { status, data };
    }

    if (updates.requestCircle) {
      const { status, data } = await requestCircle(updates.requestCircle._id);

      if (status === 200) {
        const circleData = user.circles || { requested: null, approved: [] };
        circleData.requested = updates.requestCircle;
        console.log({ circles: circleData });
        updateUserState({ circles: circleData });
      }

      return { status, data };
    }

    const response = await updateUserDetails(updates);
    if (response.status === 200) {
      updateUserState(updates);
    }
    return response;
  }

  async function fetchUser(stage) {
    let latestUser = user;
    try {
      if (isAuthenticated) {
        setIsLoading(true);
        const {status, data: updatedUser} = await getUser(false);

        if (status === 200) {
          if(stage) {
            user.stage = stage;
          }
          latestUser = updatedUser;
          setUser(updatedUser);
        }
      }
    } finally {
      setIsLoading(false);
    }

    return latestUser;
  }

  //better to move to server
  function drawnSpots() {
    if(isImpersonating && adminConfig.showDrawday){
      const spots = [];
      const fsDrawn = {
        awarded: 1000,
        circle: user.circles.approved[0],
        walletId: user.circles.approved[0].participants[0].walletID,
      };
      const hcDrawn = {
        awarded: 2500,
        circle: {
          monthly_investment: 250,
          period: 10
        },
        walletId: '130949300'
      }
      if(adminConfig.fsDrawn) {
        spots.push(fsDrawn)
      }

      if(adminConfig.hcDrawn) {
        spots.push(hcDrawn)
      }
      return spots;
    }
  
    return user.circles.approved
    .filter((circle) => {
      const parts = getSpecificParticipant(user, circle);
        const wonSpots = parts.filter((part) =>
          part.drawn_date && moment(part.drawn_date).isSame(moment(), "month")
        );
        return wonSpots.length > 0;
    })
      .map((circle) => {
        const awarded = circle.period * circle.monthly_investment;
        const parts = getSpecificParticipant(user, circle);
        const wonSpots = parts.filter((part) =>
          moment(part.drawn_date).isSame(moment(), "month")
        );
        return wonSpots.map((part) => ({
          awarded: awarded,
          circle,
          walletId: part.walletID,
        }));
      })
      .flat();
  }

  function notDrawnSpots() {
    return user.circles.approved
    .filter((circle) => {
      const parts = getSpecificParticipant(user, circle);
        const notWonSpots = parts.filter((part) =>
          !part.drawn_date
        );
        return notWonSpots.length > 0;
    })
      .map((circle) => {
        const parts = getSpecificParticipant(user, circle);
        const notWonSpots = parts.filter((part) =>
          !part.drawn_date
        );
        return notWonSpots.map((part) => ({
          circle,
          walletId: part.walletID,
        }));
      })
      .flat();
  }


  function isHouseRelatedReason() {
    return isHouseRelated(user.reason_for_saving);
  }

  function isAnyHomeCircle() {
    return user.circles.approved
      .some((circle) => circle.monthly_investment > 100 && !circle.ambassador)
  }

  function isReservedOrAlumni() {
    const drawns = drawnSpots();
    const notDrawns = drawnSpots();
    const isReserved = !!drawns.length && drawns.every(ds => isReservedPeriod(ds));
    return !notDrawns.length && isReserved;
}

  async function createAccount(userObject) {
    const { status, data } = await createUser(userObject);

    if (status === 200) {
      localStorage.setItem("user_logged_in_before", true);
      setUser(data.user);
    }

    return { status, data };
  }

  function isHideABTest() {
    return user && (!user.timeline || !user.timeline.length || joinedBeforTestStart(user))
  }

  const joinedBeforTestStart = (user) => {
    const joined = user.timeline.find(tm => tm.eventName === "joined");
    if(!joined || moment(joined.event_date).isBefore(moment("07-10-2022", "DD-MM-YYYY")) ) {
      return true;
    }
    return false;
  }


  return (
    <UserContext.Provider
      value={{
        user: user,
        updateUser,
        createUser: createAccount,
        drawnSpots,
        notDrawnSpots,
        fetchUser,
        isReservedOrAlumni,
        isAnyHomeCircle,
        isHouseRelatedReason,
        isHideABTest
      }}
    >
      {isLoading ? (
        <LoadingOverlay></LoadingOverlay>
      ) : (
        <React.Fragment>
          {isImpersonating && user && (
           <AdminConfig
            adminConfig={adminConfig}
            setAdminConfig={setAdminConfig}
            initialize={initialize}
            user={user}
           />
            
          )}
          {children}
        </React.Fragment>
      )}
    </UserContext.Provider>
  );
}

export function useUser() {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}
