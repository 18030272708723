import API from "./api";

const path = "/chats";

function sendChat(chat){
  return API.call2(`${path}/admin-chat`, {
    method: "POST",
    body: chat,
  });
}

function updateChat(chat){
    return API.call2(`${path}/admin-chat`, {
        method: "PUT",
        body: chat,
      });
}

function deleteChat(chatId){
  return API.call2(`${path}/admin-chat/${chatId}`, {
      method: "DELETE",
    });
}

function getAdminChats(){
  return API.call2(`${path}/admin-chats`, {
      method: "GET",
    });
}

export {getAdminChats, sendChat, updateChat, deleteChat};