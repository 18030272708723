import React from "react";
import { isHouseRelated, isRefferalsToAmbassadors } from "../../../../utils/users";
import { useUser } from "../../../../contexts/UserContext";

function HomeCircleWarning(props) {
  const userContext = useUser();

  const circle = props.circle;
  const hiddenCircles = [244, 245, 629, 627, 625, 623];
  
  if (circle.monthly_investment > 100 &&
    hiddenCircles.indexOf(circle.excelID) === -1 &&
    isHouseRelated(userContext.user?.reason_for_saving) &&
    !isRefferalsToAmbassadors(userContext.user.ambassador_id)) {
    return (
      <div className="homeCircleWarning p-grid p-mr-0 p-ml-0 p-shadow-1">
        <i className="fas fa-info-circle p-col-fixed center-el fa-2x" style={{verticalAlign:"middle"}}></i> 
        <div className="p-col">
          This is a Home Circle, which means that
          during the term of your Circle we can only release the funds to a
          solicitor for the purpose of buying a home.
        </div>
      </div>
    );
  }

  return <React.Fragment></React.Fragment>;
}

export default HomeCircleWarning;
