import React, { useState, useEffect } from "react";
import QuizPageLayout from "./templates/quizPage";
import quizQuestions from "../../logic/quizQuestions";
import QuizTitle from "./templates/quizTitle";
import { Checkbox } from "primereact/checkbox";

const quizQuestionsOrder = [
  [0,1,2],
  [1,2,0],
  [2,0,1]
];

function QuizQuestions({ onSubmit, outcome, setTitle, setIsFull }) {
  const [questionIndex, setQuestionIndex] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [answers, setAnswers] = useState({});

  const currentQuestionOrder = quizQuestionsOrder[outcome === "highFail" ? 1 : outcome === "lowFail" ? 2 : 0];

  useEffect(() => {
    setIsFull(false)
    setTitle(quizQuestions[currentQuestionOrder[questionIndex]].question); //quizQuestions[questionIndex].question
  }, [questionIndex])

  const question = quizQuestions[currentQuestionOrder[questionIndex]];

  const onAnswer = (answer) => {
    let tempAnswers = answers;
    tempAnswers[question.key] = answer;
    setAnswers(tempAnswers);
    setSelectedAnswer(answer);
  };

  const nextSubmit = () => {
   
    if (questionIndex < quizQuestions.length - 1) {
      setQuestionIndex(questionIndex + 1);
      setSelectedAnswer(null);
      return;
    }

    onSubmit(answers);
  };

  return (
    <QuizPageLayout buttonDisabled={!!!selectedAnswer} onSubmit={nextSubmit} className="p-pt-5">
      {/* <QuizTitle>{question.question}</QuizTitle> */}

      <AnswerSelection
        onSelect={onAnswer}
        selectedAnswer={selectedAnswer}
        answers={question.answers}
      />

      {!!selectedAnswer && (
        <AnswerInfo selectedAnswer={selectedAnswer} question={question} />
      )}
    </QuizPageLayout>
  );
}

function AnswerSelection({ answers, onSelect, selectedAnswer }) {
  if (selectedAnswer) {
    return <IndividualAnswer answers={answers} selected={selectedAnswer} answer={selectedAnswer} />;
  }

  return answers.map((itm, key) => {
    return <IndividualAnswer onClick={() => onSelect(itm)} answer={itm} key={key} />;
  });
}

function IndividualAnswer({ onClick, selected, answer }) {

  let selectedClass;

  if(selected){
    selectedClass = selected.score ?  "correct" : "incorrect";
  }

  return (
    <div
    className={"quizAnswer " + selectedClass}
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}
    >
      <Checkbox checked={!!selected} />
      {answer.label}
    </div>
  );
}

function AnswerInfo({ selectedAnswer, question }) {
  const correctAnswer = question.answers.find((itm) => itm.score);

  return (
    <React.Fragment>
      <hr style={{marginBottom:"25px"}} />
      <div className={"answerinfo " + (selectedAnswer.score ? "correct": "incorrect")}>
        That's{" "}
        {selectedAnswer.score ? <span className="correct">correct</span> : <span className="incorrect">incorrect</span>}!{" "}
        {!selectedAnswer.score && (
          <React.Fragment>The answer was <span className="correct">{correctAnswer.label}</span>.</React.Fragment>
        )}
        <p>{question.answerInfo}</p>
      </div>
    </React.Fragment>
  );
}

export default QuizQuestions;
