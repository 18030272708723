import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { submitMagicToken } from "../../../services/users";
import LoadingOverlay from "../../generic/LoadingOverlay";

function MagicToken() {
  const location = useLocation();
  const [view, setView] = useState("loading");
  const history = useHistory();

  useEffect(() => {
    verifyMagicToken();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyMagicToken = async () => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    console.log(params);
    if (!token) {
      setView("error");
      return;
    }

    const { status, data } = await submitMagicToken(token);

    if (status !== 200 || !data.magic_token_verified) {
      setView("error");
      return;
    }

    localStorage.setItem("user_logged_in_before", true);

    window.location = "/";
  };

  if (view === "loading") {
    return <LoadingOverlay></LoadingOverlay>;
  }

  if (view === "error") {
    return (
      <React.Fragment>
        <h2>Uh-oh, lets try again!</h2>
        <div
          className="blueBox error"
          onClick={() => history.push("/welcomeBack")}
        >
          Uh-oh, we couldn't verify your link. This is usually because it has
          expired, or has already been used. Try another one by{" "}
          <b>clicking here</b>.
        </div>
      </React.Fragment>
    );
  }
}

export default MagicToken;
