import React, {useEffect} from "react";
import API from "../services/api";
import { useHistory } from "react-router";


function AdminPageWrapper({title, subTitle, children, showToast}) {
    const history = useHistory();
    const pathname = history.location.pathname;

    useEffect(() => {
        const unAuthPaths = ["/adminPortal/login", "/adminPortal/magicToken"];
        if(unAuthPaths.indexOf(pathname) < 0) {
            isAuth();
        }
        async function isAuth() {
            try {
                await API.call2("/users/isAuthorizedAdmin", { method: "GET" });
            } catch (error) {
                showToast("Not Authorized", "error")
            }
        }
      }, [showToast, pathname]);
    
    return (
        <div className="admin-page-wrapper p-col animate__animated animate__fadeIn animate__fast">
            {title && <h1 className="page-title">{title}</h1>}
            {subTitle && <h4 className="subTitle">{subTitle}</h4>}
            {children}
        </div>
    );

}

export default AdminPageWrapper;
