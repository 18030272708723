import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React, { useState, useEffect } from "react";

import creditCheckUtils from "./creditCheckUtils";
import { SelectButton } from "primereact/selectbutton";
import { CopyToClipboard } from "react-copy-to-clipboard";
import API from "../../services/api";

function FirstCreditCheck(props) {
  const [equifaxScore, setEquifaxScore] = useState("");
  const [AMLIdentity, setAMLIdentity] = useState("");
  const [AMLAddress, setAMLAddress] = useState("");
  const [AMLRisk, setAMLRisk] = useState("");
  const [localIVA, setLocalIVA] = useState("");

  useEffect(() => {
    setEquifaxScore("");
    setAMLAddress("");
    setAMLIdentity("");
    setAMLRisk("");
    setLocalIVA(
      props.selectedUser
        .f_have_you_ever_experienced_iva_ccj_repossession_or_bankruptcy_
    );
  }, [props.selectedUser]);

  const hasPassed = () => {
    return (
      creditCheckUtils.validEquifaxScore(equifaxScore) &&
      creditCheckUtils.validAML(AMLAddress, AMLIdentity, AMLRisk) &&
      creditCheckUtils.validIVA(
        localIVA
      )
    );
  };
  
  const copySuccessful = () => {
    props.showToast("Copied!", "success");
  };

  const submitCreditCheck = async () => {
    const checkValues = [equifaxScore, AMLIdentity, AMLRisk, AMLAddress];

    for (var item of checkValues) {
      //caters for 0 being one of the answers
      if (item === undefined || item === null) {
        return props.showToast("Please enter all fields", "error");
      }
    }

    const hasCreditCheckPassed = hasPassed();

    var updateObject = {
      creditCheckScore: {
        equifax: equifaxScore,
        AMLAddress: AMLAddress,
        AMLIdentity: AMLIdentity,
        AMLRisk: AMLRisk,
      },
      creditCheckScorePassed: hasCreditCheckPassed,
    };

    if (
      localIVA !==
      props.selectedUser
        .f_have_you_ever_experienced_iva_ccj_repossession_or_bankruptcy_
    ) {
      updateObject.f_have_you_ever_experienced_iva_ccj_repossession_or_bankruptcy_ = localIVA;
    }

    props.showLoading();

    var response = await API.call({
      url: "/users/adminUserUpdate",
      method: "PUT",
      body: {
        userID: props.selectedUser._id,
        updateObject: updateObject,
      },
    });

    props.hideLoading();

    if (response.detailsUpdated) {
      props.showToast("Submitted Credit Check", "success");
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      props.showToast("Failed to submit", "error");
    }
  };

  return (
    <Dialog
      header={
        props.selectedUser.firstname +
        " " +
        props.selectedUser.lastname +
        " (" +
        props.selectedUser.email +
        ")"
      }
      visible={props.visibility}
      style={{ width: "70vw" }}
      onHide={() => {
        props.closeDialog();
      }}
    >
      <div>
        <div className="adminPortalFirstCreditCheckQuickFacts">
          <CopyToClipboard
            text={props.selectedUser.address_line_1}
            onCopy={() => copySuccessful()}
          >
            <div>
              <i className="pi pi-copy"></i> <b>First line</b>:{" "}
              {props.selectedUser.address_line_1}
            </div>
          </CopyToClipboard>
          <CopyToClipboard text={props.selectedUser.zip} onCopy={() => copySuccessful()}>
            <div>
              <i className="pi pi-copy"></i> <b>Postcode</b>:{" "}
              {props.selectedUser.zip}
            </div>
          </CopyToClipboard>
          <CopyToClipboard text={props.selectedUser.dob} onCopy={() => copySuccessful()}>
            <div>
              <i className="pi pi-copy"></i> <b>Date of Birth</b>:{" "}
              {props.selectedUser.dob}
            </div>
          </CopyToClipboard>
        </div>

        <div>
          <div>
            <div>
              <b>Equifax Score</b>
            </div>
            <InputText
              type="number"
              value={equifaxScore}
              onChange={(e) => {
                setEquifaxScore(parseInt(e.target.value));
              }}
              style={{ width: "5em" }}
            ></InputText>
          </div>

          <div style={{ marginTop: "2em" }}>
            <div>
              <b>AML Score</b>
            </div>

            <div style={{ display: "inline-block" }}>
              Address:{" "}
              <InputText
                value={AMLAddress}
                onChange={(e) => {
                  setAMLAddress(parseInt(e.target.value));
                }}
                type="number"
                style={{ width: "5em", marginRight: "1em" }}
              ></InputText>
            </div>
            <div style={{ display: "inline-block", marginTop: "0.5em" }}>
              Identity:{" "}
              <InputText
                value={AMLIdentity}
                onChange={(e) => {
                  setAMLIdentity(parseInt(e.target.value));
                }}
                type="number"
                style={{ width: "5em", marginRight: "1em" }}
              ></InputText>
            </div>
            <div style={{ display: "inline-block", marginTop: "0.5em" }}>
              Risk:{" "}
              <InputText
                value={AMLRisk}
                onChange={(e) => {
                  setAMLRisk(parseInt(e.target.value));
                }}
                type="number"
                style={{ width: "5em", marginRight: "1em" }}
              ></InputText>
            </div>
          </div>

          <div style={{ marginTop: "2em" }}>
            <div>
              <b>IVA/CCJ?</b>
            </div>
            <SelectButton
              value={localIVA}
              options={["No", "Yes"]}
              onChange={(e) => setLocalIVA(e.value)}
            ></SelectButton>
          </div>

          <div
            style={{
              display: "inline-block",
              marginTop: "2em",
              padding: "0.5em",
              fontSize: "0.85em",
              borderRadius: "0.5em",
              backgroundColor: hasPassed() ? "#dbffdb" : "#ffdbdb",
            }}
          >
            <i className="pi pi-info-circle"></i>{" "}
            <span>
              This member will <b>{hasPassed() ? "PASS" : "FAIL"}</b> their
              credit check.
            </span>
          </div>
          <div>
            <Button
              onClick={(e) => {
                submitCreditCheck();
              }}
              label="Submit"
              style={{ marginTop: "2em" }}
            ></Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default FirstCreditCheck;
