import React from "react";
import PlatformContentContainer from "../_generic/platformContentContainer";
import { options } from "./config";
import { Button } from "primereact/button";
import { useHistory } from "react-router";
import { addMeta } from "../../../services/meta";
import { useUser } from "../../../contexts/UserContext";

function IncreaseSavings({ hasWon, onlyFirstStepWinner }) {
  const history = useHistory();
  const userContext = useUser();
  const option = options[hasWon ? "drawn" : "notdrawn"];
  const title = hasWon ?
    "Did you enjoy being drawn? Do you want to join an additional £50 per month Circle to get drawn again?" :
    "Would you like to enroll in an additional £50 per month Circle and increase your chances of being drawn?";

  function goTo(isConfirmed) {
    let path = "";
    addMeta({ drawDayInterestedNewSpot: isConfirmed })
    if (isConfirmed) {
      path = "/dashboard/drawday/continueBuilding";
    } else {
      path = userContext.isAnyHomeCircle() ? "/dashboard/drawday/partnersViewFairfax" : "/dashboard/drawday/partnersViewHargreaves";
    }

    history.push(path);
  }

  return (
    <div
      className={"drawDayStoryContainer scrollable drawday"}
      style={{
        backgroundImage: "url(" + option.bg + ")",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: "55"
      }} >
      <PlatformContentContainer className="platform_small p-mt-lg-4 p-pt-6 p-pb-6 p-pr-5 p-pl-5">
        <div className="p-pt-6">
          <h1 className="center p-pt-5 p-mt-6 p-text-center txt-rem-l">{title}</h1>
          <div>
            <Button
              label="Yes"
              className="p-mt-5 p-button-raised p-button-info width-100 button-purple"
              onClick={() => goTo(true)} />

            <Button
              label="No"
              className="p-mt-5 p-button-raised p-button-info width-100 button-purple"
              onClick={() => goTo(false)} />
          </div>
        </div>
      </PlatformContentContainer>
    </div>
  );
}

export default IncreaseSavings;
