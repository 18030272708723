function LoadingOverlay() {
  return (
    <div className="loadingOverlay animate__animated animate__fadeIn animate__faster">
      <div>
        <img src="./images/stepLadderLogo.png" alt="logo" />
        <i className="pi pi-spin pi-spinner" style={{ fontSize: "2em" }}></i>
      </div>
    </div>
  );
}

export default LoadingOverlay;
