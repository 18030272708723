import { InputSwitch } from "primereact/inputswitch";
import React, {useEffect} from "react";
import { useState } from "react";
import { useUI } from "../../../../../contexts/UIContext";
import PaymentsTestimonial from "../../payments/PaymentsTestimonial";
import agreementsConfig from "./agreementConfig";
import StickyButtons from "../../../../generic/stickyButtons";
import { useUser } from "../../../../../contexts/UserContext";
import { useHistory } from "react-router";


function AgreementSwitches(props) {
  const userContext = useUser();
  const UI = useUI();
  const history = useHistory();

  const [agreements, setAgreements] = useState(agreementsConfig.filter(a => !props.compressed || a.compressed));
  const [allTicked, setAllTicked] = useState(false);

  useEffect(() => {
    setAllTicked(areAllEnabled(agreements));
  }, [setAllTicked, agreements])

  const setAgreementsValue = (key, value) => {
    const objIndex = agreements.findIndex((obj) => obj.key === key);
    const newAgreements = agreements;
    newAgreements[objIndex].agreedTo = value;

    setAgreements([...newAgreements]);

    if(props.onChange){
      props.onChange(areAllEnabled(newAgreements));
    }
    setAllTicked(areAllEnabled(agreements))
  };

  const areAllEnabled = (agreementList) => {
      return !agreementList.filter(it => !it.notMandatory).filter((itm) => {
          return !itm.agreedTo;
      }).length;
  };

  const submit = () => {
    
    if(!areAllEnabled(agreements)){
      UI.showToast("Please agree to all the terms");
      return;
    }

    props.onSubmit(false);
  };

  const back = () => history.push("/circleInvite");

  return (
    <React.Fragment>
      <div className="agreements">
        {agreements.map((itm) => {
          return (
            <div key={itm.key} className="flex">
              <div>
                <InputSwitch
                  checked={itm.agreedTo}
                  onChange={(e) => setAgreementsValue(itm.key, e.value)}
                />
              </div>
              <div> 
                
              {itm.key === "circleAutoRenewAgreement" ? 
              <label>{itm.title}</label> :
                <label>
                  I have read and agree to the{" "}
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={itm.url}
                  >
                    {itm.title}
                  </a>
                </label>
                }
              </div>
            </div>
          );
        })}
      </div>

      <PaymentsTestimonial />
      
      {!props.hideSubmit &&
        <StickyButtons
          isDisabled={!allTicked}
          nextText={"I Agree"}
          nextClassName={"rollgoals"}
          cancelClassName={"width-100"}
          nextClick={submit}
          onCancel={userContext.user.stage === "Dashboard" ? back : null}
        />
       }
    </React.Fragment>
  );
}

export default AgreementSwitches;
