import { InputTextarea } from "primereact/inputtextarea";
import { useState } from "react";
import { useUI } from "../../../../../contexts/UIContext";
import { postChat } from "../../../../../services/chats";

function PostMessageBox(props) {
  const UI = useUI();
  const [chatMessage, setChatMessage] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const circle = props.circle;

  const sendMessage = async () => {
    const messageTrimmed = chatMessage.trim();

    if (messageTrimmed === "") {
      UI.showToast("Please enter a message");
      return;
    }

    setIsLoading(true);
    const { status, data } = await postChat(circle._id, messageTrimmed);
    setIsLoading(false);
    
    if (status !== 200) {
      if (data && data.error === "swearing_detected") {
        UI.showToast("Please make sure your message doesn't contain profanity");
      } else {
        UI.showToast(
          "Oops! We didn't send the message, try again in a few seconds"
        );
      }
      return;
    }

    props.onNewMessage(data);
    setChatMessage("");
  };

  return (
    <div className="chatBox">
      <div>
        <InputTextarea
          autoResize={true}
          rows={1}
          value={chatMessage}
          placeholder="Share something.."
          onChange={(e) => setChatMessage(e.target.value)}
        />
      </div>
      <div>
        {isLoading && <i className="spin fas fa-circle-notch"></i>}
        {!isLoading && (
          <i onClick={() => sendMessage()} className="fas fa-paper-plane"></i>
        )}
      </div>
    </div>
  );
}

export default PostMessageBox;
