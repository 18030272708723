import { AgGridColumn, AgGridReact } from "ag-grid-react";
import React, { useMemo } from "react";

import RiskPopup from "./RiskyPopup";
import moment from "moment";
import { currencyFormat } from "../../../../users/utils/generic";
import ColumnHeader from "./columnHeader";

function RiskPolicyTable({ data, config, noSelect, sortable=true }) {
  const [selectedRow, setSelectedRow] = React.useState({});
  const [popupVisible, setPopupVisible] = React.useState(false);
  const [tooltip, setTooltip] = React.useState();

  const onSelectionChanged = (value) => {
    if(noSelect){
      return;
    }
    
    const selected = value.api.getSelectedRows()[0];
    setSelectedRow({ ...selected });
    setPopupVisible(true);
  };

  const renderers = {
    percentage: percentageRenderer,
    currency: currencyRenderer,
    generic: genericRenderer,
    date: dateRenderer,
  };


  return (
    <React.Fragment>
      {popupVisible && (
        <RiskPopup value={selectedRow} onHide={() => setPopupVisible(false)} />
      )}
      {tooltip && (
        <div
          style={{
            position: "absolute",
            fontSize: "11px",
            border: "1px solid black",
            backgroundColor: "white",
            padding: "5px",
            width: "120px",
            zIndex: 500,
            top: "10px",
            left: "10px",
          }}
        >
          <i
            className="pi pi-times"
            onClick={() => setTooltip(null)}
            style={{
              cursor: "pointer",
              position: "absolute",
              top: -10,
              right: -10,
              backgroundColor: "black",
              borderRadius: "50%",
              color: "white",
              padding: "5px",
            }}
          />
          <span dangerouslySetInnerHTML={{ __html: tooltip }}></span>
        </div>
      )}
      <div
        className="ag-theme-alpine"
        style={{ height: "65vh", width: "100%" }}
      >
        <AgGridReact
          rowSelection={"single"}
          onSelectionChanged={onSelectionChanged}
          rowData={data}
          frameworkComponents={{agColumnHeader: ColumnHeader}}
        >
          {Object.keys(config).map((key, i) => {
            const column = config[key];
            const renderer = renderers[column.type] || renderers.generic;

            return (
              <AgGridColumn
                key={i}
                resizable={true}
                filter={column.filter || false}
                cellRendererFramework={renderer}
                pinned={column.pinned}
                headerName={column.string}
                field={key}
                headerComponent={"agColumnHeader"}
                sortable={sortable}
                tooltip={column.tooltip}
              />
            );
          })}
        </AgGridReact>
      </div>
    </React.Fragment>
  );
}

function genericRenderer(props) {
  return props.value;
}

function percentageRenderer(props) {
  return Math.round(props.value * 100) + "%";
}

function currencyRenderer(props) {
  return currencyFormat(Math.round(props.value));
}

function dateRenderer(props) {
  return moment.utc(props.value).format("MMM YY");
}

export default RiskPolicyTable;
