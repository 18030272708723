import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useState } from "react";
import { currencyFormat, isMobile } from "../../../utils/generic";
import { Dialog } from "primereact/dialog";

function AmbassadorSelectedPopup(props) {
  const [filter, setFilter] = useState("Active");
  const circle = props.data;
  const participants = circle.participants;
  const approvedParts = participants.filter(
    (part) => part.membership_status === "approved"
  );
  const requestedParts = participants.filter(
    (part) => part.membership_status === "requested"
  );

  const filters = [
    {
      name: "Active",
      data: approvedParts.filter((part) => !part.dropout_date),
    },
    {
      name: "Dropouts",
      data: approvedParts.filter((part) => part.dropout_date),
    },
    { name: "Not Completed", data: requestedParts },
  ];

  const current = filters.find((fi) => fi.name === filter);

  return (
    <Dialog
      header={"Circle " + circle.excelID}
      visible={true}
      style={{ width: isMobile ? "90vw" : "50vw" }}
      onHide={() => props.onHide()}
    >
      <div style={{"display":"flex", marginBottom:"15px"}}>
        {filters.map((itm) => (
          <div style={{padding:"5px", borderRadius:"5px", marginRight:"10px", cursor:"pointer", border:"1px solid #bebebe", backgroundColor: (current.name === itm.name ? "#dedede": "white") }} onClick={() => setFilter(itm.name)}>{itm.name}</div>
        ))}
      </div>

      <DataTable value={current.data}>
        <Column body={(part) => part.user.name} header="Name" />
        <Column body={(part) => part.user.email} header="Email" />
        <Column body={(part) => part.user.mobilephone} header="Phone Number" />
        <Column
          body={(part) => currencyFormat(part.user.minimumTest)}
          header="Affordability Calculation"
        />
        {filter === "activeMembers" && (
          <React.Fragment>
            <Column
              body={(part) =>
                !!part.drawn_date
                  ? moment.utc(part.drawn_date).format("MMM YYYY")
                  : ""
              }
              header="Drawn Date"
            />
          </React.Fragment>
        )}
      </DataTable>
    </Dialog>
  );
}

export default AmbassadorSelectedPopup;
