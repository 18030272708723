import React from "react";
import "primeflex/primeflex.css";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import API from "../../services/api";
import CircleService from "../../services/circleService";
// import utils from "../../utils/utils";
import ParticipantRow from "./participantRow";

class UpdateCircleMembership extends React.Component {
  constructor(props) {
    super(props);
    const dropoutReasons = [
      "Wanted money back",
      "Didn't understand the product",
      "Change of mind",
      "Signed up by accident",
      "Chase email offended them",
      "Ghost Workflow",
      "Ghost Workflow Dropped out",
      "Ghost Workflow – Moved to new Circle",
      "Moved to New Circle",
      "Unable to make further payments",
      "End of circle period - missed payments",

      "Missed x2 -Drop Out",
      "Thinks it's a scam",
      "Mistaken for immediate loan",
      "Ghost WF - no response",
      "Missed x2 - no response",
      "Pending credit check -no response",
      "system",
      "Other",]

    this.state = {
      circleObject: {},
      userIDMembershipData: [],
      visible: false,
      editArray: [],
      removeSpotConfirmationDisplay: false,
      spotSelectedToRemove: 0,
      dropoutReasons
    };
  }

  showUpdateCircleMembership(userID, circleObject) {
    //filter out the user IDs participation in the circle
    var membershipdata = circleObject.participants.filter(function (itm) {
      return itm.user && itm.user._id === userID;
    });

    //clone so we dont update the underlying values, in case they cancel the screen
    membershipdata = JSON.parse(JSON.stringify(membershipdata));

    this.setState({
      visible: true,
      userID,
      circleObject: circleObject,
      userIDMembershipData: membershipdata,
      //editarray is to log changes
      editArray: membershipdata.map((spot) => {
        return { spotID: spot._id, changes: {} };
      }),
    });
  }

  updateChangesLocallyForSpot(spotIndex, key, value) {
    //log the changes
    var editArray = this.state.editArray;
    editArray[spotIndex].changes[key] = value;

    //update the object we're listening to on the UI
    var userIDMembershipData = this.state.userIDMembershipData;
    userIDMembershipData[spotIndex][key] = value;

    //SET
    this.setState({
      editArray: editArray,
      userIDMembershipData: userIDMembershipData,
    });
  }

  async saveChangesForSpot(spotIndex) {
    const changeObject = this.state.editArray[spotIndex].changes;
    const spotID = this.state.editArray[spotIndex].spotID;

    if (Object.keys(changeObject).length === 0) {
      return this.props.showToast("No changes detected", "warn");
    }

    if(changeObject.dropout_date && !changeObject.dropoutReason) {
      return this.props.showToast("Please select dropout reason", "warn");
    }

    if(changeObject.dropout_date === null) {
      changeObject.dropoutReason = null;
      changeObject.notes = null;
    }

    const response = await API.call({
      url: "/circles/updateCircleParticipantInDBandHubspot",
      method: "PUT",
      body: {
        params: {
          spotID: spotID,
          spotChanges: changeObject,
        },
      },
    });

    if (response.circleUpdated) {
      this.props.showToast("Change successful!", "success");
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      this.props.showToast(
        "Change fail: " + response.error ? response.error : "generic error"
      );
    }
  }

  askRemoveSpot(spotIndex) {
    const spot = this.state.userIDMembershipData[spotIndex];
    this.setState({
      spotSelectedToRemove: spot,
      removeSpotConfirmationDisplay: true,
    });
  }

  async removeSpot() {
    const spot = this.state.spotSelectedToRemove;

    this.props.showLoading();

    const response = await API.call({
      url: "/circles/removeCircleParticipant",
      method: "PUT",
      body: {
        params: {
          participant: spot,
        },
      },
    });

    this.props.hideLoading();

    if (response.participantRemoved) {
      this.props.showToast("Spot removed!", "success");
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      this.props.showToast(
        "Failed to remove spot: " + response.error
          ? response.error
          : "generic error",
        "error"
      );
    }
  }

  async sendTermsToUser() {
    const confirmation = window.confirm(
      "Are you sure you want to send this via email to the user?"
    );

    if (!confirmation) {
      return;
    }

    const response = await CircleService.emailTermsToUser(
      this.state.userID,
      this.state.circleObject._id
    );

    if (response.sendStatus) {
      this.props.showToast(
        "Terms sent! You can see its sent in Hubspot.",
        "success"
      );
    } else {
      this.props.showToast("Terms failed to send", "error");
    }
  }

  render() {
    var rows = [];

    for (let i = 0; i < this.state.userIDMembershipData.length; i++) {
      let participantRow = this.state.userIDMembershipData[i];
      rows.push(
        <ParticipantRow
          dropoutReasons={this.state.dropoutReasons}
          key={i}
          spot={i+1}
          participantRow={participantRow}
          updateChangesLocallyForSpot={(key, value)=> this.updateChangesLocallyForSpot(i, key, value)}
          userIDMembershipData={this.state.userIDMembershipData[i]}
          askRemoveSpot={() => this.askRemoveSpot(i)}
          saveChangesForSpot={() => this.saveChangesForSpot(i)}
          isLast={this.state.userIDMembershipData.length -1 !== i}>
        </ParticipantRow>
      );
    }

    return (
      <React.Fragment>
        <Dialog
          visible={this.state.removeSpotConfirmationDisplay}
          onHide={() => this.setState({ removeSpotConfirmationDisplay: false })}
          header={
            "Remove single spot from Circle  " +
            this.state.circleObject.excelID +
            "?"
          }
          style={{ width: "50vw" }}
        >
          <Button
            label="Yes"
            onClick={(e) => {
              this.removeSpot();
            }}
          ></Button>
          <Button
            label="No"
            style={{ marginLeft: "1.5em" }}
            onClick={(e) => {
              this.setState({ removeSpotConfirmationDisplay: false });
            }}
            className="p-button-outlined"
          ></Button>
        </Dialog>
        <Dialog
          visible={this.state.visible}
          onHide={() => this.setState({ visible: false })}
          header={"Circle  " + this.state.circleObject.excelID}
          style={{ width: "50vw" }}
        >
          <Button
            onClick={() => this.sendTermsToUser()}
            label="Send Terms Email To User"
            icon="pi pi-send"
            className="p-button-outlined"
          />
          <hr style={{ marginTop: "2em", marginBottom: "2em" }}></hr>
          {rows}
        </Dialog>
      </React.Fragment>
    );
  }
}

export default UpdateCircleMembership;
