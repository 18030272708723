import React from "react";
import "primeflex/primeflex.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import utils from "../../../utils/utils";
import moment from "moment";
import { Tooltip } from 'primereact/tooltip';

function TransfersToTable(props) {
  const date = (date, isShort) => {
    const format = isShort ? "MM-YYYY" : "DD-MM-YYYY";
    return date ? moment.utc(date).format(format) : "-";
  };

  const drawnDate = (rowData) => {
    return date(rowData.drawn_date);
  };

  const dropoutDate = (rowData) => {
    return date(rowData.dropout_date);
  };

  const blindSpot = (rowData) => {
    if(rowData.dropoutReason === "End of circle period - missed payments") {
      return <span data-pr-tooltip="Platform will mark this user as dropout during transfer cycle" className={"pi pi-check p-badge p-badge-warning p-badge-lg blindSpot"}></span>
    }
    return <span>-</span>;
  };

  const balance = (balance) => {
    return utils.currencyFormat(balance / 100);
  };

  const showBalance = (rowData) => {
    return balance(rowData.balance);
  };

  const showPayments = (rowData) => {
    return rowData.payments ? rowData.payments.length : "-";
  };

  return (
    <div>
        <Tooltip target=".blindSpot" mouseTrack mouseTrackLeft={10} />
        <h4 className="p-mt-0 p-mb-2">Transfer to wallets</h4>
        <DataTable className="no-shadow-table blue-table" value={props.transferTo}>
            <Column field="name" header="Name" sortable></Column>
            <Column field="drawn_date" header="Drawn Date" sortable body={drawnDate}></Column>
            <Column field="balance" header="Balance" sortable body={showBalance}></Column>
            <Column field="walletID" header="Wallet" sortable></Column>
            <Column field="dropout_date" header="Dropout Date" sortable body={dropoutDate}></Column>
            <Column field="dropoutReason" header="Blind spot Dropout" sortable body={blindSpot}></Column>
            <Column field="payments" header="PayIns" sortable body={showPayments}></Column>
        </DataTable>
    </div>
  );
}

export default TransfersToTable;
