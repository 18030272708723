import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import React, { useEffect, useState, useCallback } from "react";
import LoadingSpinner from "../../../components/loadingSpinner";
import paymentsService from "../../../services/payments";
import UtilsService from "../../../utils/utils";

function FeesMain({setTitle, setSubTitle, showToast}) {
  const [feeData, setFeeData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [expandedRows, setExpandedRows] = useState([]);

  useEffect(() => {
    setTitle("Admin Fees Financial Year");
    setSubTitle("This table shows fees for this financial year and the next.")
    loadFees();
  }, [setTitle, setSubTitle, loadFees]);

  const loadFees = useCallback(async () => {
    try {
      const data = await paymentsService.getFeeForecast();
      const dataAsArray = [];
      const totals = {};

      for (let key of Object.keys(data)) {

        const financialYear = UtilsService.getFiscalYearOfDate(key);

        const fyAsString = "FY "+moment.utc(financialYear.start).format("MMM YYYY")+ " - " + moment.utc(financialYear.end).format("MMM YYYY");

        if(!totals[fyAsString]){
            totals[fyAsString] = {fees: 0, paygate: 0, total: 0, projected: false, months: []};
        }
        
        totals[fyAsString].fees += data[key].fees;
        totals[fyAsString].paygate += data[key].paygate;
        totals[fyAsString].total += (data[key].paygate + data[key].fees);
        totals[fyAsString].months.push({ date: key, ...data[key] })
      }   

      for(let key of Object.keys(totals)){
          dataAsArray.unshift({date: key, ...totals[key]});
      }
      

      setFeeData(dataAsArray);
      
    } catch (error) {
      showToast(`Failed load fees: ${error}`, "error")
    }

  setIsLoading(false);
  }, [showToast]);

  const currency = (value) => {
    return UtilsService.currencyFormat(value / 100)
  }

  const rowClassName = (row) => {
    return {
        "row-highlight": moment(row.date).isAfter(moment())
      }
  }


  const rowExpansionTemplate = (data) => {
    return (
     <DataTable 
        value={data.months}
        rowClassName={rowClassName}>
        <Column header="Period" field="date"></Column>
        <Column header="Circle fees" body={(row) => currency(row.fees)}></Column>
        <Column header="Paygate" body={(row) => currency(row.paygate)}></Column>
        <Column header="Total" body={(row) => currency(row.paygate + row.fees)}></Column>
     </DataTable>
    );
  }

  return (
    <React.Fragment>
      <DataTable
        className="table-rounded"
        value={feeData}
        loading={isLoading}
        expandedRows={expandedRows}
        rowExpansionTemplate={rowExpansionTemplate}
        onRowToggle={(e) => setExpandedRows(e.data)}>
        <Column expander style={{ width: "4rem" }} />
        <Column header="Period" field="date"></Column>
        <Column header="Circle fees" body={(row) => currency(row.fees)}></Column>
        <Column header="Paygate" body={(row) => currency(row.paygate)}></Column>
        <Column header="Total" body={(row) => currency(row.paygate + row.fees)}></Column>
      </DataTable>
    </React.Fragment>
  );
}

export default FeesMain;
