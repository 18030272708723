import { Dialog } from "primereact/dialog";
import React, { useState } from "react";
import {
  articleCategories,
  createOrUpdateArticle,
  deleteArticle,
  getArticleContent,
} from "../../services/articlesService";

import { Button } from "primereact/button";

import LoadingSpinner from "../../components/loadingSpinner";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { InputText } from "primereact/inputtext";
import { useEffect } from "react";

import { replaceBase64WithURL } from "./ImageHandler";
import UploadPic from "./UploadPic";
import HubspotEmailLinkGenerator from "./HubspotEmailLinkGenerator";
import { MultiSelect } from "primereact/multiselect";

function AdminArticleManage(props) {
  const articleConfig = articleCategories;
  const selectedArticle = props.selectedArticle;

  console.log(selectedArticle);

  const [showURLGenerator, setShowURLGenerator] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [type, setType] = useState(
    selectedArticle.type
      ? selectedArticle.type
      : Object.entries(articleConfig)[0][0]
  );
  const [category, setCategory] = useState(selectedArticle.category || [currentCatOptions[0]]);
  const [content, setContent] = useState("");
  const [title, setTitle] = useState(selectedArticle.title);
  const [subHeading, setSubHeading] = useState(selectedArticle.previewText);
  const [subHeading2, setSubHeading2] = useState(selectedArticle.subHeading2);
  const [picture, setPicture] = useState(selectedArticle.picture);
  const [partnerKey, setPartnerKey] = useState(selectedArticle.partnerKey);
  const [headerPicture, setHeaderPicture] = useState(
    selectedArticle.headerPicture
  );
  const [notes, setNotes] = useState(selectedArticle.notes || "");

  const camel2title = (camelCase) =>
    camelCase
      .replace(/([A-Z])/g, (match) => ` ${match}`)
      .replace(/^./, (match) => match.toUpperCase())
      .trim();

  const camelize = (str) => {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
  }

  const currentCatOptions = articleConfig[type].filter(f => f !== "all");


  useEffect(() => {
    if (selectedArticle._id) {
      loadArticleContent();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadArticleContent = async () => {
    setIsLoading(true);
    const articleContent = await getArticleContent(selectedArticle._id);

    if (articleContent.error) {
      alert("There was an error loading the file");
      return;
    }

    setContent(articleContent.content);
    setIsLoading(false);
  };

  const submit = async () => {
    // const content = getContent();
    try {
      setIsLoading(true);
      const contentWithImageURLs = await replaceBase64WithURL(content);

      const submitObject = {
        title,
        partnerKey: partnerKey || camelize(title),
        type,
        picture,
        headerPicture,
        previewText:
          subHeading ||
          (!contentWithImageURLs
            ? ""
            : contentWithImageURLs
                .replace(/<\/?[^>]+(>|$)/g, "")
                .substring(0, 30)),
        subHeading2,
        category,
        content: contentWithImageURLs,
        order: selectedArticle.order,
        notes,
      };

      if (selectedArticle && selectedArticle._id) {
        submitObject._id = selectedArticle._id;
      }

      const response = await createOrUpdateArticle(submitObject);
      setIsLoading(false);

      if (!response.status) {
        console.log(response);
        throw new Error("There was an error in submitting the request");
      }

      alert("Save successful!");

      window.location.reload();
    } catch (err) {
      setIsLoading(false);
      alert(err.message);
      return;
    }
  };

  const showDeleteConfirm = async () => {
    const answer = window.confirm(
      "READ CAREFULLY: Are you sure you want to delete this article? Ok to proceed, or click cancel."
    );

    if (answer) {
      setIsLoading(true);
      const { data } = await deleteArticle(selectedArticle._id);

      if (data) {
        alert("There was an error in deleting your article");
        setIsLoading(false);
        return;
      }

      window.location.reload();
    }
  };

  console.log("cat", category);

  return (
    <Dialog
      className="articleDialog"
      visible={true}
      header={"Create or Update Article"}
      style={{ width: "70vw", height: "80vh", minHeight: "80vh" }}
      onHide={() => props.onClose()}
    >
      {isLoading && <LoadingSpinner />}
      {!isLoading && (
        <React.Fragment>
          <select style={{height:"36px", verticalAlign:"top"}} onChange={(e) => setType(e.target.value)}>
            {Object.keys(articleConfig).map((itm, i) => {
              return (
                <option key={i} value={itm} selected={type === itm}>
                  {camel2title(itm)}
                </option>
              );
            })}
          </select>
          <MultiSelect style={{marginLeft:"10px"}} onChange={(e) => setCategory(e.target.value)} value={category} selectedItemTemplate={(value) => {
            return value ? camel2title(value) + " " : "None"
          }} itemTemplate={(value) => camel2title(value)} options={currentCatOptions} />
          {/* <select onChange={(e) => setCategory(e.target.value)}>
            {currentCatOptions &&
              currentCatOptions.map((itm, i) => {
                return (
                  <option key={i} value={itm} selected={itm === category}>
                    {camel2title(itm)}
                  </option>
                );
              })}
          </select> */}
          {type === "marketplace" && (
            <div style={{ marginTop: "50px", marginBottom: "30px" }}>
              <Button
                label="Hubpot Email Link Generator"
                onClick={() => setShowURLGenerator(true)}
              />
              {showURLGenerator && <HubspotEmailLinkGenerator
                onHide={() => setShowURLGenerator(false)}
                partnerKey={selectedArticle.partnerKey}
              />}
            </div>
          )}
          <div style={{ marginTop: "50px", marginBottom: "30px" }}>
            <label>Title</label>
            <br />
            <InputText
              value={title}
              style={{ width: "100%" }}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
          <div style={{ marginTop: "50px", marginBottom: "30px" }}>
            <label>Sub Heading 1</label>
            <br />
            <InputText
              value={subHeading}
              style={{ width: "100%" }}
              onChange={(e) => setSubHeading(e.target.value)}
            />
          </div>

          <div style={{ marginTop: "50px", marginBottom: "30px" }}>
            <label>Sub Heading 2</label>
            <br />
            <InputText
              value={subHeading2}
              style={{ width: "100%" }}
              onChange={(e) => setSubHeading2(e.target.value)}
            />
          </div>

          {type === "marketplace" && (
          <div style={{ marginTop: "50px", marginBottom: "30px" }}>
            <label>PartnerKey</label>
            <br />
            <InputText
              value={partnerKey}
              style={{ width: "100%" }}
              onChange={(e) => setPartnerKey(`${e.target.value}`.trim())}
            />
          </div>
          )}

          <div style={{ marginTop: "50px", marginBottom: "50px" }}>
            <label>
              Preview URL{" "}
              <i
                style={{ cursor: "pointer" }}
                className="pi-info-circle pi"
                onClick={() => alert("The image you see when it's in the list")}
              ></i>
            </label>
            <br />
            <UploadPic onChange={setPicture} />
            <InputText
              value={picture}
              style={{ width: "100%" }}
              onChange={(e) => setPicture(e.target.value)}
            />
          </div>
          <div style={{ marginTop: "50px", marginBottom: "50px" }}>
            <label>
              Header Image (when you click the preview){" "}
              <i
                style={{ cursor: "pointer" }}
                className="pi-info-circle pi"
                onClick={() => alert("The image you see when you've clicked an item and it shows the article")}
              ></i>
            </label>
            <br />
            <UploadPic onChange={setHeaderPicture} />
            <InputText
              value={headerPicture}
              style={{ width: "100%" }}
              onChange={(e) => setHeaderPicture(e.target.value)}
            />
          </div>
          <div style={{ marginTop: "15px", marginBottom: "50px" }}>
            <label style={{ marginBottom: "10px" }}>Admin Notes</label>
            <ReactQuill
              theme="snow"
              value={notes}
              onChange={setNotes}
              modules={{
                toolbar: [
                  [{ header: [1, 2, false] }],
                  ["bold", "italic", "underline", "strike", "blockquote"],
                  [
                    { list: "ordered" },
                    { list: "bullet" },
                    { indent: "-1" },
                    { indent: "+1" },
                  ],
                  ["link"],
                  ["clean"],
                ],
              }}
            />
          </div>
          <label>Content</label>
          <ReactQuill
            theme="snow"
            value={content}
            onChange={setContent}
            modules={{
              toolbar: [
                [{ header: [1, 2, false] }],
                ["bold", "italic", "underline", "strike", "blockquote"],
                [
                  { list: "ordered" },
                  { list: "bullet" },
                  { indent: "-1" },
                  { indent: "+1" },
                ],
                ["link", "image", "video"],
                ["clean"],
              ],
            }}
          />

          <div style={{ marginTop: "15px" }}>
            <Button label="Save" onClick={() => submit()} />

            <Button
              label="DELETE"
              style={{ backgroundColor: "red", marginLeft: "50px" }}
              onClick={() => showDeleteConfirm()}
            />
          </div>
        </React.Fragment>
      )}
    </Dialog>
  );
}

export default AdminArticleManage;
