import userAPI from "./userAPI";

async function getArticleList(filter){
    return userAPI.call("/articles?filter="+(filter ? JSON.stringify(filter) : ""), {
        method: "GET",
    });
}

async function getArticleContent(_id){
    return userAPI.call("/articles/content?id="+_id, {
        method: "GET",
    });
}


export {getArticleList, getArticleContent};