import React from "react";

function LogoText({logo, title}) {
    return (
        <div className="p-grid divider bolder">
            <div className="p-col-3 p-lg-2 p-mr-3">
            <img
                className="brand-img"
                style={{borderRadius: "4px", height: "80px", width: "80px"}}
                alt="logo"
                src={logo}>
            </img>
            </div>
            <div className="p-col center-el">
                <h3>{title}</h3>
            </div>
        </div>
    );
}

export default LogoText;