import { uploadImage } from "../../services/articlesService";



async function replaceBase64WithURL(contentString) {
  let list = getAttrFromString(contentString, "img", "src").filter(itm => itm.indexOf("base64") > -1);

  if(!list.length){
      return contentString;
  }

  for(let itm of list){
    const data = await uploadImage(itm);

    if(!data.status){
        throw new Error(data.error);
    }
    contentString = contentString.replaceAll(itm, data.url);
  }

  return contentString;
}


function getAttrFromString(str, node, attr) {
    var regex = new RegExp('<' + node + ' .*?' + attr + '="(.*?)"', "gi"), result, res = [];
    while ((result = regex.exec(str))) {
        res.push(result[1]);
    }
    return res;
}

const toBase64 = (file) =>
new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => resolve(reader.result);
  reader.onerror = (error) => reject(error);
});


export { replaceBase64WithURL, toBase64 };
