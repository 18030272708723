import moment from "moment";
import API from "./api";

function getFeeForecast(){
  return API.call({url: "/payments/fees", method: "GET"});
}

const route = "/payments";

function getIgnoredPayments(month, year){
  return API.call({
    url: `${route}/result?month=${month}&year=${year}&ignored=true`,
    method: "GET",
  });
}

async function getPayGates() {
  return API.call({
    url: `${route}/paygates`,
    method: "GET",
  });
}

async function getDropouts(theDate) {
  return API.call({
    url: `${route}/dropouts?month=${moment.utc(theDate).unix()}`,
    method: "GET",
  });
}

function cycleResult(month, year){
  return API.call({
    url:`${route}/cycleResult?month=${month}&year=${year}`,
    method: "GET",
  });
}

async function getStagedFile(month, year) {
  return API.call({
    url: `/payments/stagedFile?month=${month}&year=${year}`,
    method: "GET",
  });
}

async function generatePayments(body) {
  return API.call({
    url: "/payments/generatePayments",
    method: "POST",
    body,
  });
}

async function generateSecondBatchPayments(body) {
  return API.call({
    url: "/payments/generateSecondBatch",
    method: "POST",
    body,
  });
}

function schedulePayments(month, year){
  return API.call({
    url: route + "/schedule",
    method: "POST",
    body: {
      month,
      year,
    }
  });
}


const paymentService = {
  getFeeForecast,
  schedulePayments,
  getIgnoredPayments,
  cycleResult,
  getPayGates,
  getDropouts,
  getStagedFile,
  generatePayments,
  generateSecondBatchPayments
};

export default paymentService;