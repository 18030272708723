import React from "react";
import "primeflex/primeflex.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import EditUserField from "./editUserField";
import {usefulKeys} from "../../../common/services/userHelpers";

class AdminUserDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      globalFilter: "",
    };

    this.editUserFieldRef = React.createRef();
  }

  setGlobalFilter(value) {
    this.setState({ globalFilter: value });
  }

  rowSelected(value) {
    var row = this.getObjectByTableKey(value.key);

    if (!row.editableInputType) {
      return this.props.showToast("You cannot edit this field", "error");
    }

    row.value = value.value;
    row.userID = this.props.userData._id;

    this.editUserFieldRef.current.editField(row);
  }

  getObjectByTableKey(key) {
    return JSON.parse(
      JSON.stringify(
        usefulKeys.filter(function (itm) {
          return itm.label === key;
        })[0]
      )
    );
  }

  render() {
    const userData = this.props.userData;

    const rowClass = (data) => {
      var keytofind = data.key;

      var row = this.getObjectByTableKey(keytofind);

      return { boldRow: row.editableInputType };
    };

    const convertedRows = [];

    for (let attribute of usefulKeys) {
      convertedRows.push({
        key: attribute.label,
        value: attribute.special
          ? attribute.special(userData)
          : userData[attribute.key],
        tag: attribute.tag ? attribute.tag : "Other",
      });
    }

    const header = (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => this.setGlobalFilter(e.target.value)}
            placeholder="Search for anything"
          />
        </span>
      </div>
    );

    return (
      <React.Fragment>
        <EditUserField
          ref={this.editUserFieldRef}
          showToast={this.props.showToast}
          showLoading={this.props.showLoading}
          hideLoading={this.props.hideLoading}
        ></EditUserField>
        <div style={{ marginBottom: "1em" }}>
          You can make edits to <b>bold</b> rows.
        </div>
        <DataTable
          onSelectionChange={(e) => {
            this.rowSelected(e.value);
          }}
          selectionMode="single"
          header={header}
          value={convertedRows}
          globalFilter={this.state.globalFilter}
          rowClassName={rowClass}
        >
          <Column field="key" sortable header="Property"></Column>
          <Column field="value" header="Value"></Column>
          <Column field="tag" header="Tag"></Column>
        </DataTable>
      </React.Fragment>
    );
  }
}

export default AdminUserDetails;
