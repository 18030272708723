import React from "react";
import "primeflex/primeflex.css";

import { useEffect } from "react";

import { useState } from "react";

import FirstCreditCheck from "./firstCreditCheck";
import SecondCreditCheck from "./secondCreditCheck";
import AdminCreditCheckTable from "./creditCheckTable";
import API from "../../services/api";
import { TabPanel, TabView } from "primereact/tabview";

function AdminCreditCheck(props) {
  const [creditCheckData, setCreditCheckData] = useState([]);
  const [selectedCreditCheckData, setSelectedCreditCheckData] = useState({
    creditCheckScore: {},
  });
  const [FirstCheckVisibility, setFirstCheckVisibility] = useState(null);
  const [SecondCheckVisibility, setSecondCheckVisibility] = useState(null);
  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    props.setTitle("Credit Checks")
    const loadData = async () => {
      const data = await API.call({
        url: "/users/filter",
        method: "POST",
        body: {
          filter: {
            creditCheckScorePassed: { $in: [null, false] },
            creditCheckSecondReview: null,
            stage: "Dashboard",
          },
        },
      });

      if (data.error) {
        return props.showToast(
          "Error with the request: " + data.error,
          "error"
        );
      }

      setCreditCheckData(data);
    };

    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showCreditCheckScreen = (userObject, isWaiting) => {
    setSelectedCreditCheckData(userObject);

    if (isWaiting) {
      setFirstCheckVisibility(true);
    } else {
      setSecondCheckVisibility(true);
    }
  };

  return (
    <React.Fragment>
      <TabView
        activeIndex={tabIndex}
        onTabChange={(e) => setTabIndex(e.index)}
      >
        <TabPanel header="First Credit Check">
          <AdminCreditCheckTable
            showCreditCheckScreen={showCreditCheckScreen}
            data={creditCheckData}
            filter="waiting"
          />
        </TabPanel>
        <TabPanel header="Second Review">
          <AdminCreditCheckTable
            showCreditCheckScreen={showCreditCheckScreen}
            data={creditCheckData}
          />
        </TabPanel>
      </TabView>

      <FirstCreditCheck
        closeDialog={(e) => {
          setFirstCheckVisibility(false);
        }}
        visibility={FirstCheckVisibility}
        selectedUser={selectedCreditCheckData}
        showToast={props.showToast}
        showLoading={props.showLoading}
        hideLoading={props.hideLoading}
      ></FirstCreditCheck>
      <SecondCreditCheck
        closeDialog={(e) => {
          setSecondCheckVisibility(false);
        }}
        visibility={SecondCheckVisibility}
        selectedUser={selectedCreditCheckData}
        showToast={props.showToast}
        showLoading={props.showLoading}
        hideLoading={props.hideLoading}
      ></SecondCreditCheck>
    </React.Fragment>
  );
}

export default AdminCreditCheck;
