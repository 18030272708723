import API from "./api";

function getFullVendors(){
    // return API.call2("/vouchers", { method: "GET" });
    return API.call({url: "/vouchers", method: "GET"});
}

function requestGiftCard(){
    // return API.call2("/vouchers/requestGiftCard", { method: "GET" });
    return API.call({url: "/vouchers/requestGiftCard", method: "GET"});
}

function requestGiftCards(){
    // return API.call2("/vouchers/requestGiftCards", { method: "GET" });
    return API.call({url: "/vouchers/requestGiftCards", method: "GET"});
}


function getCardByAuditNumber(auditNumber){
    // return API.call2("/vouchers/requestGiftCards", { method: "GET" });
    return API.call({url: "/vouchers/getCardByAuditNumber?auditNumber=" + auditNumber, method: "GET"});
}

export { 
    getFullVendors,
    requestGiftCard,
    requestGiftCards,
    getCardByAuditNumber
};