import React from 'react';

export default class SimpleCellRenderer extends React.Component {
    render() {
        return (
            <div className={this.props.value === "total fees" ? "total-fees" : ""}>
                {
                    this.props.value ? <span >{this.props.value}</span> :
                    "0"
                }
            </div>
        );
    }
}