import React from "react";
import { useHistory } from "react-router";
import { useState } from "react";
import { isMobile } from "../../../../utils/generic";

import menuOptions from "./menuOptions";
import { useUser } from "../../../../contexts/UserContext";

function DashboardDesktopMenu() {
  const [showMobMenu, setShowMobMenu] = useState();
  const history = useHistory();
  const userContext = useUser();

  const getBackgroundURL = () => {
    const urls = {
      "/dashboard/help":
        "https://images.pexels.com/photos/3184418/pexels-photo-3184418.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      "/dashboard/marketplace":
        "https://images.pexels.com/photos/34577/pexels-photo.jpg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
      "/dashboard/education":
        "https://images.pexels.com/photos/3184396/pexels-photo-3184396.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
    };

    const pathname = history.location.pathname;
    return `url(${urls[pathname] || urls["/dashboard/help"]})`;
  };

  const isAmbassador = !!userContext.user.ambassadorOwner;

  let theMenuOptions = menuOptions;

  if(isAmbassador){
    theMenuOptions = theMenuOptions.concat({
      text: "Ambassadors",
      icon: "fas fa-thumbs-up",
      path: "/dashboard/ambassadors"
    });
  }

  if (!isMobile) {
    return (
      <React.Fragment>
        <div className="desktopTopMenuContainer">
          <div className={"desktopTopMenu"}>
            <div>
              <img
                src="./images/stepLadderLogoWhite.png"
                alt="stepladder logo"
              />
            </div>
            <div className="flex">
              {theMenuOptions.map((itm, i) => (
                <div
                  key={i}
                  onClick={() => history.push(itm.path)}
                  className={
                    "menuItem" +
                    (itm.path === history.location.pathname ? " active" : "")
                  }
                >
                  <i className={itm.icon}></i>
                  <span>{itm.text}</span>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="banner">
          <div
            className="coverimage"
            style={{ backgroundImage: getBackgroundURL() }}
          />
        </div>
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <div className="mobileTopMenu">
        <div className="height-65px"><img src="./images/stepLadderLogo.png" alt="logo"></img></div>
        <div onClick={() => setShowMobMenu(true)} className="layoutMenuIcon">
            <i className="fas fa-align-right"></i>
          </div>
        
      </div>
      {showMobMenu && (
          <div
            onClick={() => setShowMobMenu(false)}
            className="mobileMenuDropdown animate__animated animate__fadeIn"
          >
            <div className="animate__faster animate__animated animate__fadeInDown">
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.joinstepladder.com/how-our-circles-work/"
                >
                  About Our Circles
                </a>
              </div>
              <div>
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.joinstepladder.com/contact/"
                >
                  Contact Us
                </a>
              </div>
              <div>
                <a href="/logout">Logout</a>
              </div>
            </div>
          </div>
        )}
    </React.Fragment>
  );
}

export default DashboardDesktopMenu;
