const articleConfig = {
    education: {
        title: "Knowledge",
        description:"Knowledge is power!  So it's time to start learning.  Here we will be providing you with the best information to help you achieve your financial goals - whether that's buying your first home, increasing your credit score or saving money for a holiday.",
        categories: [
            {
                icon: "fas fa-star",
                text: "All",
                key:"all"
            },
            {
                icon: "fas fa-life-ring",
                text: "StepLadder",
                key:"stepLadder"
            },
            {
                icon: "fas fa-home",
                text: "Homes",
                key:"homes"
            },
            {
                icon: "fas fa-university",
                text: "Finance",
                key:"finance"
            },
            {
                icon: "fas fa-credit-card",
                text: "Credit Score",
                key:"creditScore"
            },
        ],
    },
    marketplace: {
        title:"Member Offers",
        description:"We're all about helping you save money so exclusively for you as a StepLadder Member, here are your current offers and discounts.",
        categories: [
            {
                icon: "fas fa-star",
                text: "All",
                key:"all"
            },
            {
                icon: "fas fa-home",
                text: "Home Buying",
                key:"homeBuying"
            },
            {
                icon: "fas fa-couch",
                text: "Furniture",
                key:"furniture"
            },
            {
                icon: "fas fa-university",
                text: "Financial Wellbeing",
                key:"personalFinance"
            },
            {
                icon: "fas fa-lightbulb",
                text: "Utilities",
                key:"utilities"
            },
            {
                icon: "fas fa-leaf",
                text: "Lifestyle",
                key: "lifestyle"
            },
            {
                icon: "fas fa-running",
                text: "Self Care",
                key: "wellness"
            },
            {
                icon: "fas fa-bus",
                text: "Travel",
                key: "travel"
            },
            {
                icon: "fas fa-university",
                text: "Education",
                key: "education"
            },
        ]
        

    }
}

//old
// categories: [
//     {
//         icon: "fas fa-star",
//         text: "All",
//         key:"all"
//     },
//     {
//         icon: "fas fa-credit-card",
//         text: "Building Credit",
//         key:"credit"
//     },
//     {
//         icon: "fas fa-university",
//         text: "Money Management",
//         key:"moneyManagement"
//     },
//     {
//         icon: "fas fa-home",
//         text: "Moving House",
//         key:"movingHouse"
//     },
//     {
//         icon: "fas fa-chart-line",
//         text: "Buying a Home",
//         key: "buyingAHome"
//     },
// ]

export default articleConfig;