import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function RowExpansion(props) {
  const data = props.participants ? props.participants : [];


  const camel2title = (camelCase) => camelCase
  .replace(/([A-Z])/g, (match) => ` ${match}`)
  .replace(/^./, (match) => match.toUpperCase())
  .trim();

  const getColor = (drawStatus) => {
    const colors = [
      {
        color:"green",
        key: "eligible"
      },
      {
        color: "blue",
        key: "drawn"
      },
      {
        key: "dropout",
        color:"grey"
      },
      {
        key: "behindOnPayments",
        color: "red"
      },
      {
        key:"creditBuilder",
        color: "purple"
      }
    ];

    const foundColor = colors.find((itm) => itm.key === drawStatus);
    return foundColor ? foundColor.color : "grey";
  }

  return (
    <DataTable value={data}>
      <Column
        header="Name"
        field="name"
      ></Column>
      <Column header="Pay Ins" body={(row) => <span>{"£"+(row.payIns/100).toLocaleString()}</span>} />
      <Column
        header="Eligibility"
        body={(row) => (
          <span
            style={{
              padding: "5px 10px",
              borderRadius: "5px",
              color:getColor(row.drawEligibility)
            }}
          >
            {camel2title(row.drawEligibility)}
          </span>
        )}
      ></Column>
    </DataTable>
  );
}

export default RowExpansion;
