import moment from "moment";
import { currencyFormat } from "../../../../utils/generic";
import InfoTooltip from "../../../generic/InfoTooltip";

function PaymentInfo(circle) {

  const chargeAmount = 2;

  return (
    <div className="blueBox investor" style={{ marginTop: "40px" }}>
      <ul className="paymentdetails">
        <li>
          We will charge a {currencyFormat(chargeAmount)}, one-off onboarding
          fee when you click next. 
          <InfoTooltip
            text={`This ${currencyFormat(
              chargeAmount
            )} fee is to setup the direct debit, run a soft credit check and perform admin related activities in relation to your account. This charge is non refundable.`}
            position={window.innerWidth > 600 ? "right" : "left"}
            blueTooltip={true}
          />
        </li>
        <li className="amountCharged">
          From the <b>{moment.utc(circle.startingDate).format("Do MMMM")}</b>,
          every month you'll be charged{" "}
          <b>{currencyFormat(circle.monthly_investment)} </b>
          plus a <b>{currencyFormat(circle.admin_fee, true)}</b>
          {" admin fee "}
          for {circle.period} months via direct debit
        </li>
        <li>
          <a href="https://www.mangopay.com/">MangoPay</a> is our secure
          payments provider who will hold your funds in a secure eWallet. Your
          bank statements will show StepLadder transactions from MangoPay. You
          will form an agreement with MangoPay on the next screen.
        </li>
      </ul>
    </div>
  );
}

export default PaymentInfo;
