import React from "react";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { DataTable } from "primereact/datatable";
import moment from "moment";

import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

class ViewCircles extends React.Component {
  constructor(props) {
    super(props);

    this.updateCircleRef = React.createRef();

    this.state = {
      circleList: [],
      globalFilter: this.props.forceFilter ? this.props.forceFilter : "",
    };
    this.sortFunction = this.sortFunction.bind(this);
    this.formatAvailability = this.formatAvailability.bind(this);
    this.isAvailable = this.isAvailable.bind(this);
  }

  formatStartingDate(circleRow) {
    return moment(circleRow.startingDate).format("MMM YYYY");
  }

  formatEndingDate(circleRow) {
    return moment(circleRow.endingDate).format("MMM YYYY");
  }

  isVisible(startingDate) {
    if(!startingDate) {return false}
    const circleStartDate = moment.utc(startingDate);
    const startThreshold = moment.utc().add(1, "months").startOf("months");
    const endThreshold = moment.utc(startThreshold).endOf("M");
    
   return circleStartDate.isSameOrAfter(startThreshold) && circleStartDate.isSameOrBefore(endThreshold);
  }

  formatAvailability(circleRow) {
    if(!circleRow.availability){
      return "Hidden";
    }

    if(circleRow.ambassador){
      return "Visible to As";
    }

    return circleRow && this.isVisible(circleRow.startingDate) ? "Visible" : "Hidden";
  }

  isAvailable(circle) {
    return this.formatAvailability(circle) !== "Hidden";
  }

  sortFunction(event) {
    return [...this.props.circles].sort((a, b) => (this.isAvailable(a) - this.isAvailable(b)) * event.order);
    
  }

  formatMonthlyInvestment(circleRow) {
    return "£" + circleRow.monthly_investment;
  }

  formatTotal(circleRow) {
    return (
      "£" + (circleRow.period * circleRow.monthly_investment).toLocaleString()
    );
  }

  render() {
    const header = (
      <div className="table-header">
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => {
              if (!this.props.forceFilter) {
                this.setState({ globalFilter: e.target.value });
              }
            }}
            placeholder="Search for anything"
          />
        </span>
      </div>
    );

    const paginatorLeft = (
      <Button type="button" icon="pi pi-refresh" className="p-button-text" />
    );
    const paginatorRight = (
      <Button type="button" icon="pi pi-cloud" className="p-button-text" />
    );

    return (
      <React.Fragment>
        <DataTable
          onSelectionChange={(e) => {
            var circleobject = JSON.parse(JSON.stringify(e.value));
            return this.props.onRowClick
              ? this.props.onRowClick(circleobject)
              : false;
          }}
          className="p-mt-3 blue-table table-rounded "
          paginator
          paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          rows={this.props.slim ? 10 : 30}
          paginatorLeft={paginatorLeft}
          paginatorRight={paginatorRight}
          header={header}
          loading={this.props.loading}
          sortField="excelID"
          sortOrder={-1}
          globalFilter={this.state.globalFilter}
          selectionMode="single"
          dataKey="_id"
          value={this.props.circles}
          scrollable scrollHeight="55vh"
        >
          <Column field="excelID" sortable header="Circle ID"></Column>
          {!this.props.slim && (
            <Column
              field="availability"
              header="Visible to Select"
              sortable
              sortFunction={this.sortFunction}
              body={this.formatAvailability}
            ></Column>
          )}
          <Column body={this.formatTotal} header="Total"></Column>
          <Column
            field="monthly_investment"
            header="Monthly"
            body={this.formatMonthlyInvestment}
          ></Column>
          <Column field="period" header="Months"></Column>
          <Column
            field="startingDate"
            sortable
            body={this.formatStartingDate}
            header="Start"
          ></Column>
          <Column
            field="endingDate"
            sortable
            body={this.formatEndingDate}
            header="End"
          ></Column>
          {!this.props.slim && (
            <Column
              sortable
              field="ambassador.ambassadorName"
              header="Ambassador"
            ></Column>
          )}
          {!this.props.slim && !this.props.participantsLength && (
            <Column
              field="participantsLength"
              header="Participants"
            ></Column>
          )}
        </DataTable>
        
        
      </React.Fragment>
    );
  }
}

export default ViewCircles;
