import React from "react";
import { withRouter } from "react-router";
import "primeflex/primeflex.css";
import { ProgressSpinner } from "primereact/progressspinner";
import  { Button } from "primereact/button";
import API from "../../services/api";
import AdminUserDetails from "./userDetails";
import { TabView, TabPanel } from "primereact/tabview";
import UserTimeline from "./userTimeline";
import UserCircles from "./userCircles";
import PartnerReferrals from "./PartnerReferrals";
import utils from "../../utils/utils";


class page extends React.Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isLoadingUser: true,
      userData: {},
      activeTabIndex: 0,
    };
  }

  async componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const tabIndex = urlParams.get("tabIndex");

    this.setState({
      userID: urlParams.get("id"),
      activeTabIndex: tabIndex ? parseInt(tabIndex) : 0,
    });

    //get the user
    const user_data = await API.call({
      url: "/users/fullUser?userID=" + urlParams.get("id"),
      method: "GET",
    });

    this.setState({ userData: user_data, isLoadingUser: false });

    this.props.setTitle(utils.getName(user_data));
  }

  async launchAsUser() {
    //make the request
    this.props.showLoading();
    var response = await API.call({
      url: "/users/launchAsUser",
      method: "POST",
      body: {
        userID: this.state.userID,
      },
    });
    this.props.hideLoading();
    if (response.launchAsUserStatus) {
      //set logged in as true
      localStorage.setItem("user_logged_in_before", true);
      localStorage.setItem("impersonation", true);

      //redirect
      window.location = "/";
    } else {
      this.props.showToast("Failed to launch as user");
    }
  }

  render() {
    return (
      <div className="animate__animated animate__fadeIn animate__fast">
        {this.state.isLoadingUser && (
          <ProgressSpinner
            style={{
              overflowX: "hidden",
              overflowY: "hidden",
              width: "50px",
              height: "50px",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%,-50%)",
            }}
            strokeWidth="8"
            fill="#EEEEEE"
            animationDuration=".5s"
          />
        )}

        {!this.state.isLoadingUser && (
          <React.Fragment>
            <h3 className="p-mb-6 p-mt-3"> {this.state.userData.email}</h3>

            <div>
              <Button
              className="p-button-outlined p-button-info p-button-raised p-button-help"
                onClick={(e) => this.launchAsUser()}
                label="Launch as User"
                icon="pi pi-user"
              ></Button>
            </div>

            <TabView
              activeIndex={this.state.activeTabIndex}
              onTabChange={(e) => {
                this.setState({ activeTabIndex: e.index });
              }}
            >
              <TabPanel header="Details">
                <AdminUserDetails
                  userData={this.state.userData}
                  showToast={this.props.showToast}
                  showLoading={this.props.showLoading}
                  hideLoading={this.props.hideLoading}
                ></AdminUserDetails>
              </TabPanel>
              <TabPanel header="Circles">
                <UserCircles
                  showToast={this.props.showToast}
                  showLoading={this.props.showLoading}
                  hideLoading={this.props.hideLoading}
                  userData={this.state.userData}
                ></UserCircles>
              </TabPanel>
              <TabPanel header="Timeline">
                <UserTimeline
                  timeline={this.state.userData.timeline}
                ></UserTimeline>
              </TabPanel>
              <TabPanel header="Partner Referrals">
                {this.state.userData && <PartnerReferrals userData={this.state.userData} />}
              </TabPanel>
            </TabView>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const AdminManageUser = withRouter(page);

export default AdminManageUser;
