import userAPI from "./userAPI";

async function getAvailableCircles(ambassadorName) {
  let response = await userAPI.call(
    "/circles/available2" +
      (ambassadorName ? "?ambassador=" + ambassadorName : ""),
    { method: "GET" }
  );

  return response;
}

function requestCircle(circleID) {
  return userAPI.call("/circles/joinCircle2", {
    body: { circleID: circleID },
    method: "PUT",
  });
}

function termsAgreed() {
  return userAPI.call("/circles/termsAgreedForCircle2", {
    method: "PUT",
  });
}

function addMultipleCircles(ids) {
  return userAPI.call("/circles/addMultipleCircles", {
    body: { ids },
    method: "POST",
  });
}

function sendRenwal(isRenew, circle) {
  return userAPI.call("/circles/sendRenwal", {
    body: { circle, isRenew },
    method: "POST",
  });
}

function changeCircleProfile(partID, circleProfileID){
  return userAPI.call("/circles/updateCircleProfile", {method: "PUT", body: {participantID: partID, circleProfileID: circleProfileID}});
}

async function getAvailableAndSelectedCircles(userAmount) {
  return userAPI.call(
    "/circles/getAvailableAndSelectedCircles?userAmount=" + userAmount, 
    { method: "GET" }
  );
}

export { 
  getAvailableCircles,
  requestCircle,
  termsAgreed,
  changeCircleProfile,
  getAvailableAndSelectedCircles,
  addMultipleCircles,
  sendRenwal
 };
