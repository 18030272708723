
import { addMeta } from "../../../../../../services/meta";
const GoBack = ({back, trackBackId}) => {
    const trackAndBack = () => {
        addMeta({backICClicked: trackBackId});
        back();
    }
    return (
    <div>
        Or <span 
            style={{cursor: "pointer"}}
            onClick={trackAndBack}><b>click here</b> to go back and select different Investor Type</span>
    </div>)
}

  export default GoBack;