import React from "react";
import { withRouter } from "react-router";
import "primeflex/primeflex.css";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";

import Auth from "../services/auth";
import { Card } from 'primereact/card';



class page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailAddress: "",
      magicTokenConfirmation: false,
    };

    this.submit = this.submit.bind(this);
  }


  isLoggedIn() {
    if (!localStorage.getItem("adminLoggedIn")) {
      return false;
    } else {
      return true;
    }
  }

  submit() {
    if (this.state.emailAddress === "") {
      this.props.showToast("Please enter a valid email address", "warn", 8000);
      return;
    }

    this.props.showLoading();

    Auth.requestMagicLink(
      {
        email: this.state.emailAddress,
        callback_url: window.location.origin + "/adminPortal/magicToken",
        isAdmin: true
      },
      (data) => {
        this.props.hideLoading();
        if ((data.error === "user_not_found") || data.error === "no_admin_access_for_the_email") {
          this.props.showToast("No access for this email", "error");
        } else {
          this.setState({ magicTokenConfirmation: true });
        }
      }
    );
  }

  render() {
    return (
        <div className="admin-login-page" style={{width: "100%", height: "100%"}}>
        <img
          alt="logo"
          src="images/stepLadderLogo.png"
          style={{ width: "3.5em", marginLeft: "1em", marginTop: "1em" }}
        ></img>
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%,-50%)",
            maxWidth: "28em",
            width: "100%",
          }}
        >
          <Card title="Admin Portal" className="admin-login">
          <div>
            {!this.state.magicTokenConfirmation && (
              <div>
                <InputText
                  style={{
                    width: "100%",
                    marginTop: "1em",
                    marginBottom: "1em",
                  }}
                  placeholder="Email Address"
                  type="email"
                  name="email"
                  onKeyPress={event => {
                    if (event.key === 'Enter') {
                      this.submit()
                    }
                  }}
                  onChange={(e) =>
                    this.setState({ emailAddress: e.target.value })
                  }
                ></InputText>
                <Button className="p-button-raised center-img p-button-outlined p-button-plain" onClick={(e) => this.submit()} label="Login" />
              </div>
            )}

            {this.state.magicTokenConfirmation && (
              <div className="center">Check your email for the login link</div>
            )}
          </div>
          </Card>
          
        </div>
        </div>
    );
  }
}

var AdminLogin = withRouter(page);

export default AdminLogin;
