import { AgGridReact } from "ag-grid-react";
import React, { useCallback, useEffect, useRef, useState } from "react";
import LoadingSpinner from "../../components/loadingSpinner";
import { getPartnerActivitySummary } from "../../services/partnerActivity";
import getColumnHeadings from "./columnHeading";
import SelectPartners from "./SelectPartners";
import tableStructure from "./tableStructure";

function PartnerAnalytics({setTitle}) {
  const [isLoading, setIsLoading] = useState(true);
  const [tableData, setTableData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [filterColDefs, setFilterColDefs] = useState([]);

  const gridRef = useRef();
  
  const filteredDefinitionsForTable = !filterColDefs?.length
    ? columnDefs
    : columnDefs.filter(
        (cd) =>
          cd.headerName === "Month" || filterColDefs.indexOf(cd.headerName) > -1
      );

  useEffect(() => {
    setTitle("Partners Analytics");
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {
    const { data, status } = await getPartnerActivitySummary();

    if (status !== 200) {
      alert("There was an error in loading the data");
      return;
    }

    const newData = tableStructure(data);
    const columnDefinitions = getColumnHeadings(newData);

    setColumnDefs(columnDefinitions);
    setTableData(newData);
    setIsLoading(false);
    autoSizeAll();
  };

  const autoSizeAll = useCallback((skipHeader) => {
    const allColumnIds = [];
    gridRef.current.columnApi.getAllColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef.current.columnApi.autoSizeColumns(allColumnIds, skipHeader);
  }, []);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <React.Fragment>
      <SelectPartners
        columnDefs={columnDefs}
        value={filterColDefs}
        onChange={(value) => {
          setFilterColDefs(value || []);
          window.setTimeout(() => {
            autoSizeAll();
          }, 100);
        }}
      />
      <div className="ag-theme-alpine" style={{ height: "85%", width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          rowData={tableData}
          columnDefs={filteredDefinitionsForTable}
        ></AgGridReact>
      </div>
    </React.Fragment>
  );
}

export default PartnerAnalytics;
