import React from "react";

import "primeflex/primeflex.css";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import {Checkbox} from 'primereact/checkbox';

const PENS = 100;
  function PaymentsDialog(props) {
    const statuses = {
        SUCCEEDED: "success",
        FAILED: "danger",
        "FAILED VALIDATION": "danger",
    };
    const status = (rowData) => {
        const classStatus = `p-tag p-tag-rounded text-xxs p-tag-${
            statuses[rowData.Status] || "info"
        }`;
        return (<span className={classStatus}>{rowData.Status}</span>);
    };
    const fees = (rowData) => {
        return rowData.Fees / PENS;
    };

    const funds = (rowData) => {
        return rowData.Funds / PENS;
    };

    const convertDate = (rowData) => {
        return date(rowData.CreationDate);
    };

    const date = (date) => {
        return date ? moment(date).format("DD-MM-YYYY") : "-";
    };

    const getName = (user) => {
        return user? `${user.firstname} ${user.lastname}` : "";
    };

    return (
      <React.Fragment>
        <Dialog
          header={"Payments"}
          visible={props.visible}
          style={{ width: "70vw" }}
          onHide={() => props.onHide()}
        >
            <div>
                <div>
                <h3>Hide from failed payment list {" "}<Checkbox onChange={props.updateParticipant} checked={props.selected.part ? props.selected.part.hidden_failed_payments : false}></Checkbox></h3>
                </div>
                <div className="flex">
                    <div className="p-m-2"><b>Email: </b>{props.selected.part ? props.selected.part.user.email : ""}</div>
                    <div className="p-m-2"><b>Name: </b>{props.selected.part ? getName(props.selected.part.user) : ""}</div>
                </div>

                <div className="flex">
                    <div className="p-m-2"><b>Wallet: </b>{props.selected.part ? props.selected.part.walletID : ""}</div>
                    <div className="p-m-2"><b>Drawn date: </b>{props.selected.part ? date(props.selected.part.drawn_date) : "-"}</div>
                </div>
                <div className="card blue-table">
                    <DataTable value={props.selected.payments}>
                        <Column field="PaymentID" header="Payment ID"></Column>
                        <Column field="Fees" header="Fees" body={fees}></Column>
                        <Column field="Funds" header="Funds" body={funds}></Column>
                        <Column field="date" header="Date" body={convertDate}></Column>
                        <Column field="Status" header="Status" body={status}></Column>
                    </DataTable>
                </div>
            </div>
        </Dialog>
      </React.Fragment>
    );
}

export default PaymentsDialog;
