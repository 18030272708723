
import React, { useState } from "react";
import { Button } from 'primereact/button';
import { Card } from "primereact/card";
import CircleSelector from "../../components/generic/circleSelector";
import { ScrollPanel } from 'primereact/scrollpanel';
import { InputTextarea } from 'primereact/inputtextarea';
import MessageView from './messageView';

function CircleChat({chats, loading, circles, getChats, hideCircleChat, selectedCircle, setSelectedCircle, postChat}){
    const [message, setMessage] = useState("");
    const selectCircle = async (circle) => {
        if(!circle) {
            return;
          }
          getChats(circle._id)
          setSelectedCircle(circle);
      }

    const onSubmit = () => {
        const chat = {
            message,
            circles: [selectedCircle._id]
        };
        postChat(chat)
        setMessage("")
    }

    return (
        <Card>
            <div className="flex">
                <div className="p-col p-pl-0 p-p-0">
                <h2>Circle: {
                    <CircleSelector
                    circles={circles}
                    circle={selectedCircle}
                    selectCircle={selectCircle}
                    />
                    }
                </h2>
                </div>
                <div className="p-ml-5">
                <Button
                    className="p-button-rounded p-button-text p-button-plain p-button-icon-only"
                    icon="pi pi-times"
                    onClick={hideCircleChat}/>
                </div>
            </div>
            <div style={{height: '70vh'}}>
                {chats?.length ? 
                <ScrollPanel style={{height: '64vh'}}>
                    {chats && chats.map((chat,i) => (
                        <MessageView key={i} chat={chat}/>
                    ))}
                </ScrollPanel>
                :
                <h1 className="center">No chats</h1>}
                
                <div className="p-d-flex p-p-3 card">
                    <InputTextarea
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                        className="message-chat width-100 p-mr-2"
                        type="text"
                        rows="2" />
                    <Button
                        label="Send"
                        className="p-button-outlined p-button-info p-button-raised p-button-help p-ml-auto width-8em"
                        disabled={!message.length}
                        onClick={onSubmit}/>
                </div>
            </div>
      </Card>
    )
}

export default CircleChat;