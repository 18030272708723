import React, { useEffect } from "react";
import { useState } from "react";
import { useUI } from "../../../contexts/UIContext";
import { useUser } from "../../../contexts/UserContext";
import { getArticleContent } from "../../../services/articles";
import { createPartnerActivity } from "../../../services/partnerActivity";
import { isMobile } from "../../../utils/generic";
import InlineLoading from "../../generic/inlineLoading";
import customArticleConfig from "./customArticleConfig";

function ArticleView(props) {
  const UI = useUI();
  const userContext = useUser();
  const user = userContext.user;

  const article = props.article;
  const [animation, setAnimation] = useState(
    isMobile ? "animate__fadeInUp" : "animate__fadeIn"
  );
  const [articleHTML, setArticleHTML] = useState("");
  const [articleLoaded, setArticleLoaded] = useState(false);
  const [innerAnimation, setInnerAnimation] = useState(
    isMobile ? "" : "animate__fadeInRight"
  );
  const [customArticle, setCustomArticle] = useState(false);

  useEffect(() => {
    window.setTimeout(() => {
      loadArticleContent(article._id);
    }, 600);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadArticleContent = async (_id) => {
    try {
      const customArticle = customArticleConfig.find(
        (itm) => itm._id === _id
      );

      if (!!customArticle) {
        setCustomArticle(customArticle);
        setArticleLoaded(true);
        return;
      }

      const { status, data: content } = await getArticleContent(_id);

      if (status !== 200) {
        throw new Error(
          "We couldn't load the article, please try again later or contact support"
        );
      }

      setArticleHTML(content.content);
      setArticleLoaded(true);
    } catch (err) {
      setArticleHTML("<div>No content found!</div>");

      setArticleLoaded(true);
      UI.showToast(err.message);
    }
  };

  const close = () => {
    if (!isMobile) {
      setInnerAnimation("animate__fadeOutRight");
    }
    setAnimation(isMobile ? "animate__fadeOutDown" : "animate__fadeOut");

    window.setTimeout(() => {
      props.onHide();
    }, 650);
  };

  return (
    <div
      className={"circleView animate__animated animate__faster " + animation}
    >
      <div className={"animate__animated animate__faster " + innerAnimation}>
        <div className="circleViewBack" onClick={() => close()}>
          <i className="fas fa-arrow-left"></i>
        </div>
        <div
          className="circleCarouselBox coverimage"
          onClick={() => (props.onClick ? props.onClick() : false)}
          style={{
            backgroundImage: "url(" + (!isMobile ? (article.headerPicture || article.picture) : (article.picture || article.headerPicture)) + ")",
          }}
        >
          <div className="carouselBoxContent">
            <div>{article.previewText || article.title}</div>
          </div>
        </div>

        {!articleLoaded && <InlineLoading />}
        {articleLoaded && (
          <React.Fragment>
            {!!customArticle && <customArticle.component />}

            {!!!customArticle && (
              <React.Fragment>
                <div
                  onClick={() => {
                    if(article.type === "marketplace"){
                      createPartnerActivity(article.partnerKey, "platformClick", user.email);
                    }
                  }}
                  className={"article " + article.type}
                  dangerouslySetInnerHTML={{ __html: articleHTML }}
                />
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default ArticleView;
