import React, { useEffect, useState } from "react";
import PlatformContentContainer from "../_generic/platformContentContainer";
import {options} from "./config";
import { Button } from "primereact/button";
import { addMeta } from "../../../services/meta";
import HelpTodoWithMoney from "./helpTodoWithMoney"
import {useHistory} from "react-router";
import { useUser } from "../../../contexts/UserContext";
import {getABVariant} from "../../../services/meta";
import { isHouseRelated } from "../../../utils/users";

function PartnersView() {
    const [showHelpTodoWithMoney, setShowHelpTodoWithMoney] = useState(false);
    const [variant, setVariant] = useState(0);
    const history = useHistory();
    const option = options["notdrawn"];
    const userContext = useUser();
    const buttons = [{
        label: "Open an ISA",
        link: "https://hl.7eer.net/gb2XYO",
        trackId: "openISA"
    },{
        label: "Buy best selling money management books",
        link: "https://www.amazon.co.uk/shop/joinstepladder/list/17S9L44SOMPMU?ref_=aip_sf_list_spv_ofs_mixed_d",
        trackId: "books"
    },{
        label: "Get cashback on a gift card",
        link: "https://joinstepladder.com/gift-card",
        trackId: "giftCard"
    }];


    useEffect(() => {
        startABTest();
    }, []);
  
    async function startABTest() {
      const abVariant = await getABVariant(userContext.user, "showHelpTodoWithMoney");
      setVariant(abVariant);
      setShowHelpTodoWithMoney(abVariant);

      addTrackMeta(`drawDayPartnerSeen${abVariant}`, true);
    }

    function goTo(isPartner, link, trackId) {
        addTrackMeta(`drawDayPartnerClicked${variant}`, isPartner ? trackId : false);
        if(isPartner) {
            const partnerUrl = link;
            window.open(partnerUrl, '_blank').focus();
        }
        nextStep();
    }
    const addTrackMeta = (name, value) => {
        addMeta({[name]: value});
    }

    const isHelp = (isHelpResult) => {
        addTrackMeta("isHelpTodoWithMoney", isHelpResult);
        if(!isHelpResult) {
            nextStep();
        }
        setShowHelpTodoWithMoney(false);
    }

    const nextStep = () => {
        const path = isHouseRelated(userContext.user?.reason_for_saving) ? "/dashboard/drawday/whatuserstage" : "/dashboard/drawday/lastSreens";
        history.push(path);
    }

    if(showHelpTodoWithMoney && variant) {
        return <HelpTodoWithMoney isHelp={isHelp} />
    }

    return (
        <div 
            className={"drawDayStoryContainer scrollable drawday" } 
            style={{backgroundImage: "url(" + option.bg + ")",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    zIndex: "55"
            }} >
            <PlatformContentContainer className="platform_small p-mt-lg-4 p-pt-6 p-pb-6 p-pr-4 p-pl-4">
                <div className="p-pt-6">
                    <h1 className="center p-mt-1 p-text-center txt-rem-xl">Here’s how you can make your money go further</h1>
                    <div className="p-mt-4 p-mb-3">
                        {
                            buttons.map(({label, link, trackId}, i) => (
                                <Button 
                                    key={i}
                                    label={label}
                                    className={`p-mt-4 button-st-green p-button-raised p-button-info width-100 ${trackId !== "books" ? "p-p-3": ""}`}
                                    onClick={() => goTo(true, link, trackId)} />
                            ))
                        }
                    </div>
                    <div>
                        <Button 
                            label="No thanks"
                            className="p-mt-5 p-button-raised p-button-info width-100 button-purple"
                            onClick={() => goTo(false)} />

                    </div>
                </div>
            </PlatformContentContainer>
        </div>
    );
}

export default PartnersView;
