import React from 'react';

import utils from "../../utils/utils";

export default class CellRendererCounts extends React.Component {

    currencyFormat(num) {
        return utils.isNumeric(num) ? '£' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num;
     }
    render() {
        return (
            <div>
                {

                    this.props.value === 'HC'? <span className="p-tag-config p-tag p-tag-rounded p-tag-info">{this.props.value}</span> :
                    this.props.value === 'FS'? <span className=" p-tag-config p-tag p-tag-rounded p-tag-success">{this.props.value}</span> :
                    this.props.value === 'A'? <span className=" p-tag-config p-tag p-tag-rounded p-tag-success">{this.props.value}</span> :
                    this.props.value ? <span>{this.currencyFormat(this.props.value)}</span> :
                    "£0"
                }
            </div>
        );
    }
}