const stepLadderInfo = [
    {
        img: "./images/houseorganisation.png",
        img2: "./images/rocket.png",
        label: "We're an Appointed Representative of More Lending Solutions.",
        tooltip: "An appointed representative (AR) is a company who runs FCA regulated activities and acts as an agent for a firm that is directly authorised."
    },
    {
        img: "./images/househearts.png",
        img2: "./images/bulb.png",
        label: "We can't offer mortgage advice or mortgages, but can connect you to experts who help our Members.",
        tooltip: "We have mortgage brokers we can recommend to you that other Members have used to help you on your home buying journey."
    },
    {
        img: "./images/phonehouse.png",
        img2: "./images/umbrella.png",
        label: "StepLadder is not a bank, which means capital is at risk. We have plans in place (required by the FCA) in case we ever had to shut down. These are publicly available here.",
        tooltip: "By signing up to StepLadder, you not are signing up to a bank account. Check out our FAQs or contact us if you have any questions."
    },
    {
        img: "./images/stepLadderLogo.png",
        img2: "./images/stepLadderLogo.png",
        label: "Using StepLadder’s P2P product you will lend to and borrow from other Members in your Circle.",
        // tooltip: "By signing up to StepLadder, you not are signing up to a bank account. Check out our FAQs or contact us if you have any questions."
    }
]

export default stepLadderInfo;