
import React, {useEffect} from "react";
import { useHistory } from "react-router";
import { useUI } from "../../../contexts/UIContext";
import { useUser } from "../../../contexts/UserContext";
import { checkDuplicate } from "../../../services/users";
import { getNextRoute } from "../../../utils/userRoutes";
import PageWrapper from "../../generic/CenterMiddle";
import Form from "../../generic/forms/Form";
import FillAddress from "../../../components/generic/forms/components/fillAddress/fillAddress";

function PersonalDetails({setTitle, setSubTitle}) {
  const UI = useUI();
  const userContext = useUser();
  const history = useHistory();

  useEffect(() => {
    setTitle("Your Details");
    setSubTitle(
    <>
      <b>Personal</b> {">"} Goals {">"} Finance {">"} Experience
    </>);
  }, []);

  const submitValues = async (values) => {
    //TRANSFORM
    const updateObject = {
      zip:  values.postcode.toUpperCase().replace(/\s+/g, ""),
      number_of_dependents: parseInt(values.dependentNumbers),
      address_line_1: values.firstLineOfAddress,
      marital_status: values.marital_status,
      town: values.town,
      dob: values.dob,
      englishFirstLanguage: values.englishFirstLanguage
    };

    //SUBMIT
    UI.showLoading();

    if (process.env.REACT_APP_ENV === "production") {
      const { data } = await checkDuplicate(updateObject.zip, updateObject.dob);

      if (data.isDuplicate) {
        UI.hideLoading();
        history.push(
          "/welcomeBack?force=true&email=" + data.match + "&partial=true"
        );
        return;
      }
    }

    const { status } = await userContext.updateUser(updateObject);
    UI.hideLoading();

    if (status === 200) {
      history.push(getNextRoute(userContext.user));
    } else {
      UI.showToast(
        "There was an error in submitting your details, please contact us to fix it."
      );
    }
  };

  return (
    <>
        {userContext.user.joint_application && (
          <div className="blueBox">
            Please enter your own details for now, at the end of the application
            form we'll ask you for your joint applicant details.
          </div>
        )}

        <Form
          inputs={[
            { key: "postcode", value: "" },
            { key: "customComponent", component: FillAddress},
            { key: "firstLineOfAddress", value: "" },
            { key: "town", value: "" },
            { key: "dob", value: "" },
            { key: "marital_status", value: "" },
            { key: "dependentNumbers", value: "" },
            { key: "englishFirstLanguage", value: ""}
          ]}
          onSubmit={(values) => submitValues(values)}
        />
    </>
  );
}

export default PersonalDetails;
