import React, { useEffect, useState } from "react";
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import moment from "moment";
import {getAdminChats, sendChat, updateChat, deleteChat} from "../../services/chats";
import { Card } from "primereact/card";
import ChatTable from "./chatTable";
import CircleChat from "./circleChat";
import PostMessageForm from "./postMessageForm";

function Chats({setTitle, showToast}) {
    const [loading, setLoading] = useState(false);
    const [chats, setChats] = useState(null);
    const [currentChat, setCurrentChat] = useState(null);
    const [circles, setCircles] = useState([]);
    const [selectedChatCircle, setSelectedChatCircle] = useState(null);
    const [circleChats, setCircleChats] = useState(null);
    const [selectedCircle, setSelectedCircle] = useState({});
    const [showPostMessageForm, setShowPostMessageForm] = useState(false);

  useEffect(() => {
    setTitle("Circles chats")
    getCircles();
  }, []);
  
  const getCircles = async () => {
    try {
        setLoading(true)

        const {data: {chats, foundCircles}} = await getAdminChats();
        setCircles(foundCircles.sort((a, b) => b.excelID - a.excelID));
        setChats(chats);
    } catch (error) {
      showToast(error.message || JSON.stringify(error), "error");
    } finally {
      setLoading(false)
    }
  }

  const postChat = async (chat) => {
    const chatAction = chat._id ? "update" :"save"; 
    try {
      if(!chat) {
        return;
      }

      if (chat._id) { //edit the chat
        const {chatUpdated} = await updateChat(chat);
        if (chatUpdated) {
          const newChats = chats.map(ch => {
            if (ch._id !== chat._id) {
              return ch;
            } else {
              return {
                ...ch, 
                message: chat.message,
                circles: chat.circles,
                showFromDate: chat.showFromDate,
                excelID: (chat.circles.length === 1) ? circles.find(c => `${c._id}` === `${chat.circles[0]}`)?.excelID : null
              }
            }
          });
        setChats(newChats);
      }
      } else {  //post new chat
        const {data: savedChat} = await sendChat(chat);
        if(savedChat.circles?.length === 1) {
          savedChat.excelID = circles.find(c => `${c._id}` === `${savedChat.circles[0]}`)?.excelID;
        }
        setChats([{...savedChat }, ...chats]);
      }
      showToast(`Chat ${chatAction}d`, "success");

      setShowPostMessageForm(false)
    } catch (error) {
      showToast(`Chat ${chatAction} error: ${error.message || JSON.stringify(error)}`, "error", 8000);
    } 

  }

  const removeChat = async (chat) => {
    try {
      if(!chat) {
        return;
      }

      const deletedChat = await deleteChat(chat._id);
      if(deletedChat.error) {
        showToast(deletedChat.error, "error", 8000);
      } else{
        setChats([...chats.filter(ch => `${ch._id}` !== `${chat._id}`)]);
        showToast("Chat deleted", "success");
      }
    } catch (error) {
      showToast(error.message || JSON.stringify(error), "error", 8000);
    } 

  }

  useEffect(() => {
    if(chats && selectedChatCircle){
      showCircleChat(selectedChatCircle);
    }
  }, [chats])

  const showCircleChat = (circleId) => {
    if(!circleId) {
        return;
      }
      setSelectedChatCircle(circleId);
      getChats(circleId);
      setSelectedCircle(circles.find(c=> `${c._id}` === `${circleId}`))
  }

  const hideCircleChat = () => {
      setSelectedChatCircle(null);
  }

   const getChats = (circleId) => {
    const chts = chats.filter(ch => {
      if (ch.circle === circleId 
      || ch.circles?.length === 0
      || ch.circles?.findIndex(c=> c === circleId) >= 0 ) {
        return true
      }
      return false
    })
    setCircleChats(chts.sort((a,b) => moment(b.dateSubmitted) - moment(a.dateSubmitted)));
  }

  const postNewMessage = () => {
    setCurrentChat(null);
    setShowPostMessageForm(true);
  }

  const editChat = (chat) => {
    setCurrentChat(chat);
    setShowPostMessageForm(true);
}

  const header = <div className="p-p-3 p-d-flex p-p-0 p-pb-lg-0 p-jc-between">
    <Button
        className="p-button-outlined p-button-info p-button-raised p-button-help"
        onClick={postNewMessage}
        label="Post Message"
        icon="pi pi-pencil" />
    <Button
        className="p-button-outlined p-button-info p-button-raised p-button-help"
        onClick={() => showCircleChat(circles[0]._id)}
        label="Chats"
        icon="pi pi-comment" />
  </div>;

  return (
    <>
    <Card className="b-radius-10" header={header}>
        <div className="p-grid">
            <div className={`${selectedChatCircle ? "p-col-7" : "p-col-12"}`}>
                <ChatTable
                  chats={chats}
                  showCircleChat={showCircleChat}
                  deleteChat={removeChat}
                  editChat={editChat}
                  loading={loading}/>
            </div>
            {
                selectedChatCircle && 
                <div className="p-col-5">
                    <CircleChat 
                      postChat={postChat}
                      hideCircleChat={hideCircleChat}
                      selectedCircle={selectedCircle}
                      setSelectedCircle={setSelectedCircle}
                      getChats={getChats}
                      chats={circleChats}
                      circles={circles}
                      loading={loading}/>
                </div>
            }
        </div>
    </Card>
    <Dialog header="Post Message" visible={showPostMessageForm} onHide={() => setShowPostMessageForm(false)}>
         <PostMessageForm
          postChat={postChat}
          circles={circles}
          currentChat={currentChat}
         />
    </Dialog>
      
    </>
  
    );
}

export default Chats;
