import React from "react";
import "primeflex/primeflex.css";
import { Dialog } from "primereact/dialog";

import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";

import { editUserProperty } from "../../services/users";


class EditUserField extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      editObject: {},
      value: "",
      userID: 0,
    };
  }

  editField(editObject) {
    this.setState({
      editObject: editObject,
      visible: true,
      value: editObject.value,
      userID: editObject.userID,
    });
  }

  async submitChanges() {
    this.props.showLoading();

    let updateObject = {};
    updateObject[this.state.editObject.key] =
      this.state.editObject.editableInputType === "number"
        ? parseFloat(this.state.value)
        : this.state.value;

    let response = await editUserProperty(this.state.userID, updateObject);

    this.props.hideLoading();

    if (response.detailsUpdated) {
      this.props.showToast("Details updated", "success");
      window.setTimeout(function () {
        window.location.reload();
      }, 1000);
    } else {
      this.props.showToast("Failed to update details", "error");
    }
  }

  render() {
    return (
      <Dialog
        visible={this.state.visible}
        onHide={() => this.setState({ visible: false })}
        header={"Edit " + this.state.editObject.label}
        style={{ width: "50vw" }}
      >
        {["text", "number"].indexOf(this.state.editObject.editableInputType) >
          -1 && (
          <InputText
            value={this.state.value}
            onChange={(e) => {
              this.setState({ value: e.target.value });
            }}
          ></InputText>
        )}

        {this.state.editObject.editableInputType === "select" && (
          <React.Fragment>
            <Dropdown
              value={this.state.value}
              options={this.state.editObject.options}
              onChange={(e) => this.setState({ value: e.value })}
              placeholder="Select"
            />
            <br></br>
            <br></br>
            <br></br>
            <br></br>
          </React.Fragment>
        )}
        <br></br>
        <br></br>
        <Button label="Save" onClick={(e) => this.submitChanges()}></Button>
      </Dialog>
    );
  }
}

export default EditUserField;
