import { useState } from "react";
import { InputText } from 'primereact/inputtext';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';

function AddLookUpMeta({groups, saveMetaName, metaNames}) {
    const [newMetaName, setNewMetaName] = useState("");
    const [group, setGroup] = useState("common");

    const checkMetaNameDuplicate = (newMetaName) => {
        return metaNames.find(({metaName}) => metaName.toLowerCase() === newMetaName?.toLowerCase())
    };


    return (
        <>
            <div className="p-fluid">
                <div className="p-field">
                    <label htmlFor="metaName">Meta name</label>
                    <InputText
                        id="metaName"
                        value={newMetaName}
                        onChange={(e) => {
                            setNewMetaName(e.target.value);
                        }}
                        type="text"
                        aria-describedby="metaName-help"
                        />
                    {checkMetaNameDuplicate(newMetaName) &&
                        <small id="metaName-help" className={`p-invalid`}>Meta name already exists</small>
                    }
                </div>
                <div className="p-field">
                    <label htmlFor="metaTypeGroup">Meta Group</label>
                    <Dropdown
                        id="metaTypeGroup"
                        value={group}
                        options={groups}
                        onChange={({value})=> setGroup(value)}
                        // optionLabel="name"
                        editable />
                </div>
                <Button
                    disabled={!newMetaName?.length}
                    className="p-button-info"
                    type="button"
                    onClick={() => saveMetaName(newMetaName, group)}
                    label="Save"/>
            </div>
         </>
    );

}

export default AddLookUpMeta;
