import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import React, { useEffect, useState } from "react";
import { useUI } from "../../../contexts/UIContext";
import { getKYCStatus, submitFirstStepSelection, uploadID } from "../../../services/vouchers";
import LoadingOverlay from "../../generic/LoadingOverlay";
import PaymentsUploadID from "../../onboarding/authenticated/payments/paymentUploadID";
import ThanksConfirmation from "./thanksConfirmation";

function MoneyBack({isWinner, addCircle=false, hide, selected, extraParamaters=false, hideHeader, isNew = false, onFinish}) {

  const [kycStatus, setKYCStatus] = useState("REGULAR");
  const [thirdDigit, setThirdDigit] = useState("");
  const [fifthDigit, setFifthDigit] = useState("");
  const [isComplete, setIsComplete] = useState(false);
  const [signupToNewCircle, ] = useState(addCircle);
  const [fileValue, setFileValue] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const UI = useUI();

  useEffect(() => {
    if(isWinner) {
      findKYCStatus();
    }
  }, []);

  const findKYCStatus = async () => {
      const {data} = await getKYCStatus();

      if(data && data.KYCLevel){
        setKYCStatus(data.KYCLevel);
      }
  };

  const onsubmit = async () => {
    setIsLoading(true);
    if(kycStatus !== "REGULAR"){
      
        if(fileValue === ""){
          setIsLoading(false);
            UI.showToast("Please upload your identiy document");
            return;
        }

        const uploadStatus = await uploadID(fileValue);

        if(!uploadStatus.data.bankDetailsSubmitted){
          setIsLoading(false);
            UI.showToast("Please check your ID. The file needs to be between 1mb and 5mb in size.");
            return;
        }
    }

    submitFirstStepSelection(selected.key, "3rd digit: "+thirdDigit+", 5th digit: " + fifthDigit+". KYC Status: "+(kycStatus === "REGULAR" ? "Already valid" : "Resubmitted")+". Add Circle? " + addCircle+`**${extraParamaters}`);
    setIsLoading(false);
    if(isNew) {
      onFinish()
    } else {
      setIsComplete(true);
    }
  }

  if(isLoading){
    return <LoadingOverlay />
  }

  if (isComplete || !isWinner) {
    return <ThanksConfirmation isWinner={isWinner} onHide={() => {hide()}} hideHeader={hideHeader} signupToNewCircle={signupToNewCircle} />;
  }

  return (
    <div className="offerlist">

      <div>
        <h3>
          Please confirm your 3rd and 5th digit of your bank account number.
        </h3>
        <InputText
          value={thirdDigit}
          onChange={(e) => setThirdDigit(e.target.value)}
          placeholder="3rd"
          className="bankconfirm"
        />
        <InputText
          value={fifthDigit}
          onChange={(e) => setFifthDigit(e.target.value)}
          placeholder="5th"
          className="bankconfirm"
        />
      </div>
      {kycStatus !== "REGULAR" && (
        <div>
          <h3>Please re-upload your passport or driving license.</h3>
          <div style={{ fontSize: "13px", marginBottom: "0px" }}>
            We're required to re-ask this for anti-money laundering checks. Please ensure that your file meets the following criteria:
            <ul>
              <li>Document has no glare</li>
              <li>Full document is clear</li>
              <li>Easily readable text</li>
            </ul>
          </div>
          <PaymentsUploadID onChange={(base64) => setFileValue(base64)} />
        </div>
      )}
      <Button disabled={!thirdDigit || !fifthDigit || (kycStatus !== "REGULAR" && !fileValue) } className="p-mt-3 p-button-raised p-button-info width-100" onClick={() => onsubmit()} label="Submit" />
    </div>
  );
}


export default MoneyBack;
