import React from "react";
import "primeflex/primeflex.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import utils from "../../../utils/utils";
import TransfersToTable from "./transferToTable";
import TransfersList from "./transfersList";
import { Toolbar } from 'primereact/toolbar';

class TransfersLOCList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            globalFilter: null,
            expandedRows: null,
            resultsVisible: false,
            transfers: [],
            selectedCircles: null,
        };
        this.wallets = this.wallets.bind(this);
        this.userToPay = this.userToPay.bind(this);
        this.balance = this.balance.bind(this);
        this.sortFunction = this.sortFunction.bind(this);
        this.sortFunctionP = this.sortFunctionP.bind(this);
        this.date = this.date.bind(this);
        this.rowExpansionTemplate = this.rowExpansionTemplate.bind(this);
        this.showTransfers = this.showTransfers.bind(this);
        this.action = this.action.bind(this);
        this.actualLOC = this.actualLOC.bind(this);
        this.selectedCircles = this.selectedCircles.bind(this);
        this.rowClass = this.rowClass.bind(this);
        this.circleNum = this.circleNum.bind(this);
        this.endDate = this.endDate.bind(this);
        this.leftContents = this.leftContents.bind(this);
        this.rightContents = this.rightContents.bind(this);

    }

    componentDidUpdate(prevProps) {
        if(this.props.circleLocs !== prevProps.circleLocs) {
          this.setState({selectedCircles: this.props.circleLocs});
        }
    }
    circleNum(rowData) {
        return (<span className={"p-badge p-badge-secondary-light badge-no-border"}>{rowData.excelID}</span>);
    }

    date(date, isShort) {
        const format = isShort ? "MM-YYYY": "DD-MM-YYYY";
        return date ? moment.utc(date).format(format) : "-";
    }

    endDate(rowData) {
        const endDate = this.date(rowData.endingDate, true);
        return <span className={endDate === this.props.period ? "p-tag" : ""}>{endDate}</span>
    }

    drawnAmount(rowData) {
        return utils.currencyFormat(rowData.monthly_investment * rowData.period);
    }

    participants(rowData) {
        return rowData.participants.length;
    }

    wallets(rowData) {
        return rowData.transferTo.length
        ? this.getStringFromArray(rowData.transferTo, "walletID")
        : "-";
    }

    getStringFromArray(participants, field) {
        return participants
        .map((part) =>
            field === "balance" ? this.balance(part.balance) : part[field]
        )
        .join(",");
    }

    balance(balance) {
        return utils.currencyFormat(balance / 100);
    }

    userToPay(rowData) {
        return rowData.transferTo.length
        ? this.getStringFromArray(rowData.transferTo, "name")
        : "-";
    }

    transferFrom(rowData) {
        return `${rowData.transferFrom.length} (${
            utils.currencyFormat(rowData.transferFrom.length * rowData.monthly_investment)
        })`;
    }

    sortFunction(event) {
        let sortedCircleLocs = [...this.props.circleLocs];
        sortedCircleLocs.sort(
        (a, b) => (a.transferTo.length - b.transferTo.length) * event.order
        );
        return sortedCircleLocs;
    }
    sortFunctionP(event) {
        let sortedCircleLocs = [...this.props.circleLocs];
        sortedCircleLocs.sort(
        (a, b) => (a.participants.length - b.participants.length) * event.order
        );
        return sortedCircleLocs;
    }

    loc(rowData) {
        return utils.currencyFormat(rowData.loc);
    }

    actualLOC(rowData) {
        return rowData.actualLOC != null ? utils.currencyFormat(rowData.actualLOC) : "-";
    }

    showTransfers(transfers, excelID) {
        this.setState({ resultsVisible: true, transfers, excelID });
    }

    action(rowData) {
        return (
            <span className="p-overlay-badge">
                <Button
                    disabled={!rowData.transfers || !rowData.transfers.length}
                    type="button"
                    onClick={() => this.showTransfers(rowData.transfers, rowData.excelID)}
                    label="Transfers"
                    className="p-button-sm p-button-info p-button-outlined"
                />
                {rowData.transfers.length ? <span className="p-badge p-badge-secondary">{rowData.transfers.length}</span> : ""}
            </span>
        
        );
    }

    selectedCircles(data) {
        this.setState({ selectedCircles: data.value });
        this.props.selectedCircles(data.value);
    }

    rowClass(data) {
        return {
            'warning-bg': data.loc !== data.actualLOC && !data.actualLOC && data.transfers.length,
            'default-bg': data.transfers.length && data.loc === data.actualLOC
        };
    }

  rowExpansionTemplate(data) {
    return (
     <TransfersToTable 
        transferTo={data.transferTo}>
     </TransfersToTable>
    );
  }

  leftContents() {
    return (
      <span className="p-input-icon-left">
      <i className="pi pi-search" />
      <InputText
        type="search"
        onInput={(e) => this.setState({ globalFilter: e.target.value })}
        placeholder="Search"
      />
    </span>
    )
  }

  rightContents() {
    return (<Button type="button" className="p-button-raised p-button-info p-button-sm" icon="pi pi-upload" label="Export CSV" onClick={() => this.dt.exportCSV()}></Button>
    )
  }

  render() {
    const header = (
      <div className="table-header">
        <Toolbar className="bg-white no-border no-pad" left={this.leftContents} right={this.rightContents} />
      </div>
    );
    const paginatorRefresh = (
      <Button
        type="button"
        onClick={() => this.props.refresh()}
        icon="pi pi-refresh"
        className="p-button-text"
      />
    );
    const paginatorLeft = (
      <Button
        type="button"
        onClick={() => this.dt.exportCSV()}
        tooltip="Click to download csv"
        tooltipOptions={{ position: "top" }}
        icon="pi pi-download"
        className="p-button-text"
      />
    );

    return (
      <React.Fragment>
        <Dialog
          header={"Transfers for circle: " + this.state.excelID}
          visible={this.state.resultsVisible}
          style={{ width: "80vw" }}
          onHide={() => this.setState({ resultsVisible: false })}
        >
          <TransfersList transfers={this.state.transfers}></TransfersList>
        </Dialog>
        <div>
          <div className="card">
            <DataTable
              rowClassName={this.rowClass}
              className="table-rounded"
              loading={this.props.loading}
              paginator
              value={this.props.circleLocs}
              globalFilter={this.state.globalFilter}
              header={header}
              ref={(el) => {
                this.dt = el;
              }}
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
              rows={100}
              rowsPerPageOptions={[10, 20, 50, 100, 200]}
              paginatorLeft={paginatorLeft}
              paginatorRight={paginatorRefresh}
              expandedRows={this.state.expandedRows}
              selection={this.state.selectedCircles}
              onSelectionChange={this.selectedCircles}
              rowExpansionTemplate={this.rowExpansionTemplate}
              onRowToggle={(e) => this.setState({ expandedRows: e.data })}
            >
              <Column selectionMode="multiple" headerStyle={{ width: "3.4rem"}} ></Column>
              <Column expander style={{ width: "2.4rem", padding: 0 }} />
              <Column sortable style={{ width: "5em", padding: 0 }} field="excelID" header="Circle" body={this.circleNum} ></Column>
              <Column sortable style={{ width: "6em", padding: 0 }} field="startingDate" header="Start" body={(dt) => this.date(dt.startingDate, true)} ></Column>
              <Column sortable style={{ width: "6em", padding: 0 }} field="endingDate" header="End" body={this.endDate} ></Column>
              <Column style={{ width: "150px" }} field="DrawnAmount" header="Drawn Amount" body={this.drawnAmount} ></Column>
              <Column sortable sortFunction={this.sortFunctionP} style={{ width: "8rem", padding: 0 }} field="Participants" header="Participants" body={this.participants} ></Column>
              <Column style={{ width: "140px" }} field="shouldTransfer" header="Transfer From" body={this.transferFrom} ></Column>
              <Column style={{ width: "6em" }} field="loc" header="Predicted LOC" body={this.loc} ></Column>
              <Column style={{ width: "6em" }} field="actualLOC" header="Actual LOC" body={this.actualLOC} ></Column>
              <Column sortable sortFunction={this.sortFunction} field="WalletId" header="Transfer to" body={this.wallets} ></Column>
              <Column field="Name" header="Transfer to names" body={this.userToPay} ></Column>
              <Column style={{ width: "9em" }} header="Actions" body={this.action} ></Column>
              {/* <Column sortable show={this.props.actions} header="Action" body={this.actionBodyTemplate}></Column> */}
            </DataTable>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TransfersLOCList;
