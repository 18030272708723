
function InputError({formObject, showValidation, value}) {

  return (
      <div
          style={{
            opacity:
              (showValidation || value !== "") &&
              formObject.validate &&
              !formObject.validate(value)
                ? 1
                : 0,
          }}
          className="inputError"
        >
          {formObject.validationMessage}
        </div>
  );
}

export default InputError;
