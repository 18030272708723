import React from "react";

const riskPolicyConfig = {
    excelID: {
        string: "Circle ID",
        type: "string",
        pinned:"left",
    },
    startingDate: {
        string: "Start Date",
        type: "date"
    },
    endingDate: {
        string: "End Date",
        type: "date"
    },
    monthly_investment: {
        string: "Monthly",
        type: "currency"
    },
    period: {
        string: "Length (months)",
        type: "number"
    },
    Total_GMV: {
        string: "Total GMV",
        type: "currency",
        tooltip: "Number of Members x Circle Length x Monthly Investment"
    },
    
    Total_Risk: {
        string: "Total Risk",
        type: "currency",
        tooltip: "Bad Rate x Monthly Investment x Circle Length"
    },

    Credit_Builder_GMV: {
        string: "Credit Builder GMV",
        type: "currency",
        tooltip: "Credit Builder GMV Only (see total GMV)"
    },

    Credit_Builder_Risk: {
        string:"Credit Builder Risk",
        type: "currency",
        tooltip: "Credit Builder GMV x Average CB Bad Rates"
    },

    Risk_Rate_Total: {
        string: "Risk Rate (Total)",
        type: "percentage",
        tooltip: "Total risk / Total GMV"
    },

    Net_Risk: {
        string: "Net Risk",
        type: "percentage",
        tooltip: " ((creditBuilderRisk * 0.25) + (nonCreditBuilderRisk * 0.5)) / totalGMV",
    },

    Risk_Class: {
        string: "Risk Class",
        type: "string",
        tooltip: "Net risk under 4% = 1, under 7.5% = 2, otherwise 3"
    },

    Exposure: {
        string: "Exposure",
        type: "currency",
        tooltip: "Only paid out members: Monthly Investment Left to Pay per Member + Arrears In Circle"
    },

    Bad_Rate_Adj_Exposure: {
        string: "Bad Rate Adj. Exposure",
        type: "currency",
        tooltip: "Only paid out members: Bad Rate x Monthly Investment Left to Pay Per Member + Arrears In Circle"
    },

    Active_Risk_Rate_Total: {
        string: "Active Risk Rate (Total)",
        type: "percentage",
        tooltip: "Exposure / Total GMV"
    },

    Active_Net_Risk: {
        string: "Active Net Risk",
        type: "percentage",
        tooltip: "Same as Net Risk calc, but only non-dropouts"
    },
    
    Active_Risk_Class: {
        string: "Active Risk Class",
        type: "string",
        tooltip: "Same as Risk Calc, but only non-dropouts"
    },
    
}

export default riskPolicyConfig;