import API from "./api";
const path = "/platformSettings";

function getPlatformSettings(type){
    return API.call2(`${path}/platformSettings?type=${type}`, {
        method: "GET",
    });
}

function updatePlatformSettings(body){
    return API.call2(`${path}/platformSettings`, {
        method: "PUT",
        body
    });
}

export {
    getPlatformSettings,
    updatePlatformSettings
};