import moment from "moment";

function tableStructure(data){
    //filter by date

    const monthYearData = mapMonthYearAsKey(data);

    const groupedByDate = groupBy(monthYearData, "monthYear");

    const mapToColumnHeaders = mapDataToColumnHeaders(groupedByDate);

    console.log(mapToColumnHeaders);

    return mapToColumnHeaders.sort((a, b) => moment.utc("1 " + b.monthYear).diff("1 " + a.monthYear));
} 

function mapDataToColumnHeaders (data) { 

    //loop through the month and give every partner key - click combination its own key
    return Object.keys(data).map(yearMonth => {
        const yearRow = data[yearMonth];

        const mapped = yearRow.map(yr => {
            let newData = {};
            const partnerKey = yr._id.partnerKey;

            newData[partnerKey + "-emailClicks"] = yr.emailClicks;
            newData[partnerKey + "-platformClicks"] = yr.platformClicks;
            newData[partnerKey + "-membershipReferrals"] = yr.membershipReferrals;

            return newData;
        });

        let oneObject = mapped.reduce(function(acc, x) {
            for (var key in x) acc[key] = x[key];
            return acc;
        }, {});

        oneObject["monthYear"] = moment.utc(yearMonth).format("MMMM YYYY");

        return oneObject;
    });
}

function mapMonthYearAsKey(data){
    return data.map(d => {
        return {...d, monthYear: moment.utc({date: 1, month: d._id.month - 1, year: d._id.year}).format()}
    })
}

const groupBy = (items, key) => items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [
        ...(result[item[key]] || []),
        item,
      ],
    }), 
    {},
  );

export default tableStructure;