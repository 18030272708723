import moment from "moment";
import { useEffect, useCallback } from "react";
import { useState } from "react";
import paymentService from "../../../../services/payments";
import DownloadDropoutData from "./DownloadData";
import DropoutsList from "./DropoutsList";
import DropoutsSummary from "./DropoutsSummary";
import { Dropdown } from 'primereact/dropdown';
import { Button } from "primereact/button";

function Dropouts({showToast}){
    const currentPeriod = moment.utc().startOf("M");
    const [selectedDate, setSelectedDate] = useState(currentPeriod);
    const [months, setMonths] = useState([]);
    const [actuals, setActuals] = useState([]);
    const [potentials, setPotentials] = useState([]);
    const [masterListFailedPayments, setMasterListFailedPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const limitYear = 2022;

    const loadData = useCallback(async (newDate) => {
        setIsLoading(true);
        const {actualDropoutsFromMonth, potentials, masterListFailedPayments} = await paymentService.getDropouts(newDate);
        setIsLoading(false);

        setActuals(actualDropoutsFromMonth || []);
        setPotentials(potentials || []);
        setMasterListFailedPayments(masterListFailedPayments || [])
    }, []);

    const countMonths = useCallback(() => {
        const months = [];
        let dt = moment(currentPeriod).set("year", limitYear).set("months", 1);
        let stopAmount = 0;
        while(dt < currentPeriod && stopAmount < 1000) {
            stopAmount++;
            dt = moment(dt).add(1, "months");
            months.push(dt)
        }
        setMonths(months)

    }, []);


    useEffect(() => {
        countMonths();
        loadData(selectedDate);
    }, [loadData, selectedDate, countMonths]);

    return <div>
            <h2 style={{marginTop:"20px", marginBottom:"20px"}}>Summary for <Dropdown
                className="b-radius-10"  
                value={selectedDate.format("MMM YYYY")} 
                options={months.map(m=> m.format("MMM YYYY"))} 
                onChange={(e) => {
                    setSelectedDate(months.find(m => m.format("MMM YYYY") === e.value));
                }} 
                placeholder="label" /></h2> 
            <DropoutsSummary potentials={potentials || []} actualDropouts={actuals || []} month={selectedDate} isLoading={isLoading} />
            <DownloadDropoutData
                isLoading={isLoading}
                showToast={showToast}
                potentials={potentials || []}
                actualDropouts={actuals || []}
                masterListFailedPayments={masterListFailedPayments || []}
            />
            <DropoutsList potentials={potentials || []} actualDropouts={actuals || []} isLoading={isLoading} />
        </div>
    
}

export default Dropouts;