import React, { useState, useCallback } from "react";
import { useEffect } from "react";
import moment from "moment";
import { useUI } from "../../../../../contexts/UIContext";
import { getChats } from "../../../../../services/chats";
import ChatMessages from "./chatMessages";

import PostMessageBox from "./postMessageBox";

function CircleChatsMain({circle}) {
  const UI = useUI();
  const [chats, setChats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const {_id: circleId, excelID} = circle;

  const loadMessages = useCallback(async (fresh) => {
    try {
      setIsLoading(true);
      const {status, data: messages} = await getChats(circleId);

      if(status !== 200){
        throw new Error("server_error");
      }

      const chMessages = Array.isArray(messages) ? messages.sort((b, a) => {
        const date1 = a.showFromDate || a.dateSubmitted;
        const date2 = b.showFromDate || b.dateSubmitted;
        return moment(date2).format('X') - moment(date1).format('X')
      }) : [];

      if(excelID === 600) {
        chMessages.unshift({
          user: {
            firstname: "StepLadder"
          },
          message: `We’re excited to have you join us! We’re all here to work on our goal towards clearing debt. What’s the most useful thing you’ve discovered so far to help manage your debt?`,
        })
      } else if(excelID === 601) {
        chMessages.unshift({
          user: {
            firstname: "StepLadder"
          },
          message: `We’re excited to have you join us! Everyone in this special Circle is a parent or guardian. Do you find it harder to save when you have dependents? Do you have any top tips for keeping costs down when you have kids?
          `,
        })
    
      } else {
        chMessages.unshift({
          user: {
            firstname: "StepLadder"
          },
          message: `What are your goals for ${moment().format("YYYY")}?`,
        })
      }

      setChats(chMessages);
    } catch (err) {
      UI.showToast("There was an error in loading your chat messages, please come back later");
    } finally {
      setIsLoading(false);
    }
  },[circleId, excelID]);

  useEffect(() => {
    loadMessages(true);
  }, [loadMessages]);
  
  const newMessageLoaded = (message) => {
    setChats([...chats, message]);
  }



  return (
    <React.Fragment>
      <ChatMessages isLoading={isLoading} messages={chats} />
      <PostMessageBox onNewMessage={(message) => newMessageLoaded(message)} circle={circle} />
    </React.Fragment>
  );
}

export default CircleChatsMain;
