import React, { useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import utils from "../../../utils/utils";

function ConversionsTable({ conversions }) {
    const balance = ({ value }) => {
        return utils.currencyFormat(value);
    };

    function onGridReady(params) {
        const gridColumnApi = params.columnApi;
        params.api.sizeColumnsToFit();
        gridColumnApi.applyColumnState({
            applyOrder: true,
        });
        gridColumnApi.resetColumnState();
    }

    return (
        <div className="ag-theme-alpine" style={{ height: "100%", width: '100%' }}>
            <AgGridReact
                suppressRowTransform={true}
                onGridReady={onGridReady}
                onGridSizeChanged={onGridReady}
                suppressToolPanel={true}
                // defaultColDef={{
                //     resizable: false,
                //     headerComponentParams: {
                //         menuIcon: 'fa-bars'
                //     },
                // }}
                rowData={conversions}>
                <AgGridColumn field="month" sortable={true} ></AgGridColumn>
                <AgGridColumn field="leads" sortable={true}></AgGridColumn>
                <AgGridColumn field="opps" sortable={true} ></AgGridColumn>
                <AgGridColumn field="ic" sortable={true}></AgGridColumn>
                <AgGridColumn field="CoolOff" sortable={true}></AgGridColumn>
                <AgGridColumn field="CoolOffExpired" sortable={true}></AgGridColumn>
                <AgGridColumn field="terms" sortable={true}></AgGridColumn>
                <AgGridColumn field="payments" sortable={true} ></AgGridColumn>
                <AgGridColumn field="customers" sortable={true} ></AgGridColumn>
            </AgGridReact>
        </div>
    );

};

export default ConversionsTable;