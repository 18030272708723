import userAPI from "./userAPI";
const isImpersonating = !!localStorage.getItem("impersonation");
const env = process.env.REACT_APP_ENV;

    function addMeta(meta){
        if(isImpersonating && !env.includes('test')){
            return;
        }
        return userAPI.call("/metas/addMeta", {method: "POST", body: meta});
    }
  
    async function addLeadMeta(meta){
        meta.leadID = localStorage.getItem("leadID");
        const {data} = await userAPI.call("/metas/addLeadMeta", {method: "POST", body: meta});

        if (!meta.leadID && !data.error) {
            localStorage.setItem("leadID", data.leadID);
        }

        return data.metaAdded
    }
  
    async function getABVariant (user, experimentID) {
        let leadIDString = "";
        const abLeadID = localStorage.getItem("abLeadID")
        if (!user && abLeadID) {
            leadIDString = "&leadID=" + abLeadID;
        }
    
        const path = 
        ("/users/abVariant" + (!user ? "AsLead" : "AsUser")) +
        ("?experimentID=" + experimentID + leadIDString);
        
        const response = await userAPI.call( path ,{ method: "GET" });
        
        if (response.data.error || response.status !== 200) {
            throw new Error(response);
        }
        
        if (!user) {
            localStorage.setItem("abLeadID", response.data.userID);
        }
        
        return response.data.variant;
    }
  
  
export {
    getABVariant,
    addMeta,
    addLeadMeta
};