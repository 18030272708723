import React, { useState } from "react";
import moment from "moment";
import { InputTextarea } from 'primereact/inputtextarea';
import { MultiSelect } from 'primereact/multiselect';
import { Inplace, InplaceDisplay, InplaceContent } from 'primereact/inplace';
import { Calendar } from 'primereact/calendar';
import { Button } from 'primereact/button';

function PostMessageForm ({circles, currentChat, postChat}) {
    const initSelectedCircles = !currentChat ? [] : circles?.filter((c) => currentChat.circles.find((id) => id === c._id));
    const [selectedCircles, setSelectedCircles] = useState(initSelectedCircles);
    const [showDateForm, setShowDateForm] = useState(currentChat && currentChat.showDateForm);
    const [showDate, setShowDate] = useState(currentChat && currentChat.showDateForm ? currentChat.showDateForm : null);
    const [message, setMessage] = useState(currentChat ? currentChat.message : "");

    const selectedCircleTemplate = (option) => {
        if (option) {
            return (
                <span className="selected-circle-chip">
                    <span >{option.excelID}</span>
                </span>
            );
        }

        return "Select Circles";
    }

    const onSubmit = () => {
        const chat = !currentChat ? {
            message,
            circles: selectedCircles.length === circles.length ? [] : selectedCircles.map(({_id}) => (`${_id}`))
        } : {
            ...currentChat, 
            message: message, 
            circles: selectedCircles.length === circles.length ? [] : selectedCircles.map(({_id}) => (`${_id}`)),
            showFromDate: showDate ? showDate : currentChat.showFromDate
        };

        if(showDate) {
            chat.showFromDate = showDate;
        }
        postChat(chat)
    }

    const getCirclesConvertExcelID = (cls) => {
        return cls.map(c => ( {...c, excelID: `${c.excelID}`} ) );
    }

    return (<div>
        <div className="p-fluid p-grid" style={{width: "80vh", minHeight: "45vh"}}>
            <div className="p-field p-col-12 p-md-4">
                <label htmlFor="state">Sent to circles</label>
                <MultiSelect
                    style={{minWidth: "220px"}}
                    value={getCirclesConvertExcelID(selectedCircles)}
                    options={getCirclesConvertExcelID(circles)}
                    onChange={(e) => setSelectedCircles(e.value)}
                    optionLabel="excelID"
                    placeholder="Select Circles"
                    filter
                    showClear
                    className="multiselect-custom"
                    selectedItemTemplate={selectedCircleTemplate} 
                    />
            </div>
            <div className="p-field p-col-12 p-md-8">
                <label htmlFor="city">Show since date</label>
                <Inplace className={`inplace-form ${showDateForm? "" :"p-pt-2"}`} 
                        active={showDateForm} 
                        closable 
                        onToggle={(e) => {setShowDateForm(e.value); setShowDate(null)}}>
                    <InplaceDisplay>
                        <b>{currentChat && currentChat.showFromDate ?  `${moment(currentChat.showFromDate).format("DD/MM/YY")}` : "Today"}</b> (Click to change)
                    </InplaceDisplay>
                    <InplaceContent>
                        <Calendar id="basic" value={showDate} onChange={(e) => setShowDate(e.value)} />
                    </InplaceContent>
                </Inplace>
            </div>
            <div className="p-field p-col-12">
                <label htmlFor="message">Message</label>
                <InputTextarea
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="message-chat"
                    id="message" type="text"
                    rows="10" />
            </div>
            <div className="p-field p-col-12 p-md-3 p-offset-9">
                <Button classID="p-button-info p-button-raised p-button-sm" disabled={!message?.length || !selectedCircles?.length} type="button" onClick={onSubmit} label="Submit"/>
            </div>
        </div>
    </div>)
  }


export default PostMessageForm;
