import React, { useState, useEffect } from "react";
import { isMobile } from "../../../../utils/generic";
import { CircleBox } from "../CircleSelection";
import CircleViewTabs from "./CircleViewTabs";
import { useHistory } from "react-router";
import CircleViewMainSkeleton from "./circleViewMainSkeleton";

function CircleViewMain({onClose, circle}) {
  const [animation, setAnimation] = useState(isMobile ? "animate__fadeInUp" : "animate__fadeIn");
  const [innerAnimation, setInnerAnimation] = useState(isMobile ? "" : "animate__fadeInRight");
  const [isLoading, setIsLoading] = useState(true);

  const history = useHistory();

  const close = () => {
    setInnerAnimation(isMobile ? "": "animate__fadeOutRight");
    setAnimation(isMobile ? "animate__fadeOutDown" : "animate__fadeOut");

    history.push("/dashboard");
    window.setTimeout(() => {
      onClose();
    }, 500);
  };

  useEffect(() => {
      window.setTimeout(() => {
        setIsLoading(false);
      }, 450);
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className={"circleView animate__animated animate__faster " + animation}>
      <div className={"scrollTarget animate__animated animate__faster "+innerAnimation}>
        {!isLoading && (
          <React.Fragment>
            <div className="circleViewBack" onClick={() => close()}>
              <i className="fas fa-arrow-left"></i>
            </div>
            <CircleBox data={circle} />
            <CircleViewTabs data={circle} />
          </React.Fragment>
        )}
        <CircleViewMainSkeleton isShow={isLoading}/>

      </div>
    </div>
  );
}

export default CircleViewMain;
