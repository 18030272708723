import React from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import utils from "../../utils/utils";
import MonthCellRenderer from "./monthCellRenderer";
import FixedCellRenderer from "./fixedCellRenderer";

import CellRenderer from "./cellRenderer";
import moment from "moment";

const PENS = 100;
const approxRunCycleDate = 22;

let gridColumnApi = {};
let test = {};
class TrackerTable extends React.Component {
    constructor(props) {
      super(props);
      this.state = {gridColumnApi:"" };
      test.resetColumnState = this.resetColumnState;
    }

    returnUser(user, circle){
        const part = circle.participants[user.spot-1];
        const startDate = this.momentUtc(circle.startingDate).subtract(1, 'months');
        for (let i = 0; i < circle.period; i++) {
            user[this.shortDate(startDate)] = part ? this.payment(part, startDate) : '-';
        }
        return user;
    }

    payment(spot, startDate) {
        if(!spot.walletID) return false;
        const payments = this.payins.filter((p) => p.WalletId === spot.walletID && this.checkDate(p.CreationDate, startDate));
        let payment = null;
        if(payments.length > 1) {
            payment = payments.find(p => p.Status === "SUCCEEDED");
            if(payment) {
                payment = {...payment};
                payment.Funds = payments.filter(p => p.Status === "SUCCEEDED").reduce((prev, cur) => { return prev += cur.Funds; }, 0);
            } else {
                payment = payments.sort((a, b) => new Date(a.CreationDate) - new Date(b.CreationDate))[0];
            }
        } else {
            payment = payments.length === 1 ? payments[0] : payment;
        }
        return !payment ? this.getSymbol(startDate) : payment.Status === "SUCCEEDED" ?  this.getFunds(payment): payment.Status === "CREATED" ? "pending": false;
    }

    getFunds(payment) {
        const funds = payment.Funds / PENS;
        return payment.StatementDescriptor === "fromTransfer" ? funds + "*-*fromTransfer" : funds;
    }

    getSymbol(startDate) {
        return this.isFuture(startDate) ? "will be created" : "not created";
    }

    checkDate(paymentDate, startDate) {
        if(!paymentDate || !startDate) return false;
        const pDate = this.momentUtc(paymentDate);
        let paymentMonth = pDate.month();
        if(pDate.date() >= approxRunCycleDate) {
            ++paymentMonth;
        }

        // this is case for end year month
        if(startDate.year() - pDate.year() === 1 && paymentMonth - startDate.month() === 12) {
            return true;
        }

        return pDate.year() === startDate.year() && paymentMonth === startDate.month();
    }

    isFuture(paymentDate){
        let today = this.momentUtc().add(-5, "d"); 
        //if last payment period but prev month
        if(today.date() >= approxRunCycleDate && paymentDate.month() - today.month() === 1) {
            return false;
        }
        
        return paymentDate.isAfter(today, 'month');
    }

    returnDrawns(circle){
        const drawns = {spot: "", name: "Drawns to:"};
        const drwns = circle.participants.filter((p)=> p.drawn_date);
        const startDate = this.momentUtc(circle.startingDate).subtract(1, 'months');
        for (let i = 0; i < circle.period; i++) {
            drawns[this.shortDate(startDate)] = this.getNames(drwns, startDate, "drawn_date");
        }
        return drawns;
    }

    // returnDrops(circle){
    //     const drops = {spot: "", name: "Dropouts"};
    //     const drps = circle.participants.filter((p)=> p.dropout_date);
    //     const startDate = this.momentUtc(circle.startingDate).subtract(1, 'months');
    //     for (let i = 0; i < circle.period; i++) {
    //         drops[this.shortDate(startDate)] = this.getNames(drps, startDate, "dropout_date");
    //     }
    //     return drops;
    // }

    getNames(drwns, startDate, field) {
        if(!drwns) return "-";

        const drawns = drwns.filter((item) => {
            return this.checkDate(item[field], startDate);
        });

        return drawns.length ? drawns.map((drawn) => `${drawn.user.firstname} ${drawn.user.lastname} `) : "-";
    }

    getDrawns(drwns, startDate) {
        if(!drwns) return "-";

        const drawn = drwns.find((item) => {
            return this.checkDate(item.drawn_date, startDate);
        });

        return drawn;
    }

    returnTotal(circle){
        const total = {spot: "", name: ""};
        let totals = 0;
        const startDate = this.momentUtc(circle.startingDate).subtract(1, 'months');
        for (let i = 0; i < circle.period; i++) {
            const totalRes = this.totals(this.momentUtc(startDate).add(1, 'months'), "Funds");
            let isFuture = utils.isFuturePeriod(this.momentUtc(startDate).add(1, 'months'));
            total[this.shortDate(startDate)] = isFuture ? this.futureFeesOrPains(circle, "Funds") : totalRes;
            totals += totalRes;
        }

        total.payout = `Total = £${totals}`;

        return total;
    }

    returnDrawnNumber(circle){
        const drawn = {spot: "", name: ""};
        const drwns = circle.participants.filter((p)=> p.drawn_date);
        const activeParts = circle.participants.filter((p)=> !p.dropout_date);
        let drawns = 0;
        let leftDrawns = activeParts.length - drwns.length;
        const drawnAmount = circle.period * circle.monthly_investment;
        const startDate = this.momentUtc(circle.startingDate).subtract(1, 'months');
        let isFuture = utils.isFuturePeriod(this.momentUtc(startDate).add(1, 'months'));
        for (let i = 0; i < circle.period; i++) {
            const drawnRes = this.getDrawns(drwns, this.momentUtc(startDate).add(1, 'months')) ? drawnAmount : this.countFutureDrawns(circle, leftDrawns, drawnAmount, i, isFuture);
            drawn[this.shortDate(startDate)] = drawnRes;
            drawns += drawnRes;
            if(circle.reserve_period <= i ){
                --leftDrawns;
            }
        }

        drawn.payout = `Drawns = £${drawns}`;

        return drawn;
    }

    countFutureDrawns(circle, leftDrawns, drawnAmount, i, isFuture) {
        if(circle.reserve_period > i || leftDrawns <= 0){
            return 0
        }
        let multiply = 1;

        if(i === circle.period - 1) {
            multiply = leftDrawns > 1 ? leftDrawns : multiply;
        }

        return drawnAmount * multiply;
    }
    returnLOC(circle, totals, drawns){
        const loc = {spot: "", name: ""};
        let locs = 0;
        const startDate = this.momentUtc(circle.startingDate).subtract(1, 'months');
        for (let i = 0; i < circle.period; i++) {
            const period = this.shortDate(startDate);
            if(i >= circle.reserve_period) {
                const periodloc = circle && circle.locs ? circle?.locs[period] || 0 : 0;
                loc[period] = periodloc;
                locs += periodloc;
            } else {
                loc[period] = "reserved";
            }
        }

        loc.payout = `CURRENT LOC = £${locs}`;

        return loc;
    }

    returnFees(circle){
        const fee = {spot: "", name: ""};
        let fees = 0;
        const startDate = this.momentUtc(circle.startingDate).subtract(1, 'months');
        for (let i = 0; i < circle.period; i++) {
            const feeRes = this.totals(this.momentUtc(startDate).add(1, 'months'), "Fees");
            let isFuture = utils.isFuturePeriod(this.momentUtc(startDate).add(1, 'months'));
            fee[this.shortDate(startDate)] = isFuture ? this.futureFeesOrPains(circle, "Fees") : feeRes;
            fees += feeRes;
        }

        fee.payout = `Fees = £${fees}`;
        return fee;
    }

    momentUtc(value) {
        return moment(value).utc();
    }

    futureFeesOrPains(circle, type) {
        let amount = 0;
        circle.participants
          .filter((p) => !p.dropout_date)
          .map((part) => {
              if(type === "Fees") {
                if(part.custom_fee !== undefined && part.custom_fee !== null) {
                    amount += part.custom_fee;
                  } else {
                    amount += circle.admin_fee;
                  }
              } else {
                amount += circle.monthly_investment;
              }
            return part;
        });
        return amount;
      }
    
    totals(startDate, field) {
        const payments = this.payins.filter((p) => this.checkDate(p.CreationDate, startDate));
        return payments ? payments.reduce((acc, item) => {
            return item.Status === "SUCCEEDED"  ? acc += item[field] / PENS : acc;
        }, 0) : 0;
    }

    //also changing startDate object adding one month
    shortDate(startDate){
        return utils.shortYearAndMonth(startDate);
    }

    payoutsNumber(walletID){
        const payouts = this.payouts.filter((p) => p.WalletId === walletID);
        if (!payouts.length) return "";

        const sum =  payouts.reduce((acc, item) => {
            return acc += item.Funds / PENS ;
        }, 0);

        return sum;
    }

    spots(circle, showCompliance){
        if(!this.payins) return;
        const spots = [];
        circle.participants = circle.participants.sort((a,b) => !!a.dropout_date - !!b.dropout_date);
        const spotsLength = circle.period > circle.participants.length ? circle.period : circle.participants.length;
        for (let i = 0; i < spotsLength; i++) {
            const part = circle.participants[i];

            if(!part || !part.user) {
                spots.push({
                    spot: i+1,
                    name: "-",
                    creditBuilder: "",
                    payout: "",
                    drawn: "",
                    dropouts: ""
                });
                continue;
            }
            const {firstname, lastname, ...restUserInfo} = part.user;
            spots.push({
                spot: i+1,
                creditBuilder: part.credit_builder || "",
                payout: this.payoutsNumber(part.walletID),
                drawn: part.drawn_date || "",
                dropouts: part.dropout_date || "",
                balances: `£${part.balance ? part.balance / PENS : 0}`,
                name: `${firstname} ${lastname}`,
                ...(showCompliance ? restUserInfo : {})
            });
        }
        const fullSpots = spots.map(u => this.returnUser(u, circle));
        const total = this.returnTotal(circle);
        const drawnNums = this.returnDrawnNumber(circle);
        
        const locs = this.returnLOC(circle, total, drawnNums);
        fullSpots.push(total);
        fullSpots.push(drawnNums);
        fullSpots.push(locs);
        fullSpots.push(this.returnFees(circle));
        fullSpots.push(this.returnDrawns(circle));
        // fullSpots.push(this.returnDrops(circle));

        return fullSpots;
    }

    isBigPeriod() {
        return this.props.circle.period >= 20;
    }

    get payins() {
        return this.props.circle.payments ? this.props.circle.payments.filter((p) => p.Type === "PAYIN") : null;
    }
    get payouts() {
        return this.props.circle.payments ? this.props.circle.payments.filter((p) => p.Type === "PAYOUT") : null;
    }

    tooltipText(value) {
        return value === "not created" ? "created" : value === "will be created" ? "will create" : value;
    }

    // generating month columns
    months({startingDate, period, excelID}){
        const months = [];

        for (let i = 0; i < period; i++) {
            const startDate = this.momentUtc(startingDate).startOf('month');
            months.push({i: i, month: startDate.add(i, "M").format("MMMYY")});
        }
        // console.log("months", months);
        // console.log(this.props.circle);
        // if(this.payins){
        //     this.payins.sort((a, b) => new Date(a.CreationDate) - new Date(b.CreationDate)).map(p => console.log(p.CreationDate, p.Status, p.WalletId, p.Fees / PENS, p.Funds / PENS, this.getUser(p.WalletId)));

        // }
        return months;
    }

    //to delete
    // getUser(walletId) {
    //     return this.props.circle.participants.find(p => p.walletID === walletId).user.lastname;
    // }

    onGridReady(params) {
        gridColumnApi = params.columnApi; 
        params.api.sizeColumnsToFit(); 
        gridColumnApi.applyColumnState({
            applyOrder: true,
        });
        gridColumnApi.resetColumnState();
    }

    resetColumnState(params) {
        if(gridColumnApi.resetColumnState) {
            setTimeout(() => {gridColumnApi.resetColumnState();}, 1000);
        } 
    }

    converCreditCheck(v) {
        return v.value ? "Pass" : v.value === false ? "Fail" : "";
    }

    isNotAdult(date) {
        const dt = date.split("/");
        return this.momentUtc().diff(`${dt[2]}-${dt[1]}-${dt[0]}`, 'years') < 18;
    }

    render() {
        const participants = this.props.circle.participants ? this.props.circle.participants.length : 0;
        const filingRows = Math.min(this.props.circle.period, participants);
        const ragCellClassRules = {
            'bg-red': (params) => {
                const isDobNotAdult = params.colDef.field === "dob" && params.value ? this.isNotAdult(params.value) : false;
                return (params.value == null || params.value === '-' || isDobNotAdult) &&
                    params.rowIndex < filingRows;
            },
            'bg-green': (params) => {
                return params.colDef.field === "creditCheck1" && params.value === true;
            },
            'bg-yellow': (params) => {
                return params.colDef.field === "creditCheck1" && params.value === false;
            },
            'reserved': (params) => {
                return params.value === "reserved";
            }
          };
        const classRules = {
            'bg-red': (params) => {
                return params.value < this.props.circle.monthly_investment;
            }
        };

        const classCreditCheck2Rules = {
            'bg-red': (params) => {
                return !params.data.creditCheck1 && !params.value && params.rowIndex < filingRows;
            },
            'bg-yellow': (params) => {
                return params.value === "Credit Builder";
            },
            'bg-green': (params) => {
                return params.value === "Pass";
            }
        };
        return (
            <div className={`ag-theme-alpine ${this.isBigPeriod() ? "tracker-table-big": ""}`} style={{ height: this.isBigPeriod() ? 1150: 710, width: '100%' }}>
                <AgGridReact
                    suppressRowTransform={true}
                    enableBrowserTooltips={true}
                    rowDataChanged={this.resetColumnState()}
                    onGridReady={this.onGridReady}
                    suppressToolPanel={true}
                    defaultColDef={{
                        resizable: true,
                        headerComponentParams: {
                            menuIcon: 'fa-bars'
                        },
                        // tooltipComponent: 'customTooltip',
                    }}
                    tooltipShowDelay={0}
                    // frameworkComponents={{ customTooltip: CustomTooltip }}
                    rowData={this.spots(this.props.circle, this.props.showCompliance)}>
                    {/* adding columns here */}
                    <AgGridColumn field="spot" cellClass="spot" pinned width={55} ></AgGridColumn>
                    <AgGridColumn field="creditBuilder" cellClass="spot" cellRendererFramework={FixedCellRenderer} pinned width={65} ></AgGridColumn>
                    <AgGridColumn field="payout" 
                        colSpan={(params) => {
                            const payout = params.data.payout;
                            const parts = `${payout}`.split('=');
                            if (parts.length > 1) {
                                return 4;
                            } else {
                                return 1;
                            }
                        }}
                        cellClass="spot total-fees" pinned width={95} cellRendererFramework={CellRenderer}></AgGridColumn>
                    <AgGridColumn field="drawn" cellClass="spot" cellRendererFramework={FixedCellRenderer} pinned width={70} ></AgGridColumn>
                    <AgGridColumn field="dropouts" cellClass="spot" cellRendererFramework={FixedCellRenderer} pinned width={75} ></AgGridColumn>
                    <AgGridColumn field="balances" cellClass="spot"  pinned width={75} cellRendererFramework={CellRenderer}></AgGridColumn>
                    <AgGridColumn field="name" width={160} pinned></AgGridColumn>

                    <AgGridColumn field="FMC" width={80} hide={!this.props.showCompliance} cellClassRules={classRules} cellRendererFramework={(v) => v.value ? utils.currencyFormat(v.value): ""}></AgGridColumn>
                    <AgGridColumn field="AML" width={80} hide={!this.props.showCompliance} cellClassRules={ragCellClassRules}></AgGridColumn>
                    <AgGridColumn field="CVA_IVA" width={80} hide={!this.props.showCompliance} cellClassRules={ragCellClassRules}></AgGridColumn>
                    <AgGridColumn field="creditCheck1" width={120} hide={!this.props.showCompliance} cellClassRules={ragCellClassRules} cellRendererFramework={this.converCreditCheck}></AgGridColumn>
                    {/* creditCheckNotes */}
                    <AgGridColumn 
                        field="creditCheck2"
                        tooltipField="creditCheckNotes"
                        tooltipComponentParams={{ color: '#ececec' }}
                        width={120}
                        hide={!this.props.showCompliance}
                        cellClassRules={classCreditCheck2Rules}>
                    </AgGridColumn>
                    <AgGridColumn field="dob" width={120} hide={!this.props.showCompliance} cellClassRules={ragCellClassRules}></AgGridColumn>
                    <AgGridColumn field="equifax" width={80} hide={!this.props.showCompliance} cellClassRules={ragCellClassRules}></AgGridColumn> 
            
                    {this.months(this.props.circle).sort((a, b) => a.i - b.i).map(({i,month}) => {  
                        // width={100}        
                        return (<AgGridColumn key={i} tooltipField={this.tooltipText(month)} suppressSizeToFit={false} width={this.isBigPeriod() ? 70 : 95} cellRendererFramework={MonthCellRenderer} field={month} ></AgGridColumn>) 
                    })}
                    {/* <AgGridColumn suppressSizeToFit={false} width={this.isBigPeriod() ? 70 : 95} cellRendererFramework={MonthCellRenderer} field={month} ></AgGridColumn>
                    <AgGridColumn suppressSizeToFit={false} width={this.isBigPeriod() ? 70 : 95} cellRendererFramework={MonthCellRenderer} field={month} ></AgGridColumn> */}
                </AgGridReact>
            </div>
        );
    }
};

export default TrackerTable;