import { isMobile } from "../../../utils/generic";

function PlatformContentContainer(props) {
  const className = props.nocontent ? (props.className || "") : "platformContent " + (props.className || "");

  let styles = props.style || {};

  if(isMobile){
      styles.marginTop = "0";
  }

  return (
    <div className={className} style={styles}>
      {props.noChildrenDiv ? <>{props.children}</> : <div>{props.children}</div>}
    </div>
  );
}

export default PlatformContentContainer;
