import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import React from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useState } from "react";

function HubspotEmailLinkGenerator({ onHide, partnerKey }) {
  const [value, setValue] = useState("");

  const url =
    "https://www.members.joinstepladder.com/urlRedirect?url=" +
    value +
    "&key=" +
    partnerKey +
    "&email={{contact.email}}";

  return (
    <Dialog
      style={{ width: "60%" }}
      header="Hubspot Email Generator"
      visible={true}
      
      onHide={onHide}
    >
      <b>Enter the URL For This Partner</b>
      <br />
      <br />
      <InputText
        style={{ width: "100%" }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
      <hr style={{ marginTop: "20px", marginBottom: "20px" }} />
      {value !== "" && (
        <React.Fragment>
          Here is the link, make sure you test it on a test email sending to a
          real contact.
          <br />
          <br />
          {value.toLowerCase().indexOf("http") === -1 ? (
            <React.Fragment>
              <span style={{color:"red"}}>Your link needs to contain a HTTP link or HTTPS link before it can be generated.</span>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <div style={{ marginBottom: "15px" }}>{url}</div>
              <CopyToClipboard text={url} onCopy={() => alert("Copied!")}>
                <Button label="Copy To Clipboard" />
              </CopyToClipboard>
            </React.Fragment>
          )}
        </React.Fragment>
      )}
    </Dialog>
  );
}

export default HubspotEmailLinkGenerator;
