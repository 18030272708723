function DrawDayTotal({className, total, label}){
    return <div 
        className={`${className}`}
        style={{
            border:"2px solid #0388d1",
            display:"inline-block",
            padding:"10px",
            borderRadius:"20px",
            fontWeight:"bold"
        }}>{label}{total ? total.toLocaleString() : 0}</div>
}

export default DrawDayTotal;