import PlatformContentContainer from "../_generic/platformContentContainer";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import React, { useEffect, useState } from "react";
import InlineLoading from "../../generic/inlineLoading";
import { getFullAmbassadorCircles } from "../../../services/ambassadors";
import { useUI } from "../../../contexts/UIContext";
import { currencyFormat } from "../../../utils/generic";
import moment from "moment";
import AmbassadorSelectedPopup from "./ambassadorSelectedPopup";
import { useUser } from "../../../contexts/UserContext";
import { getNextRoute } from "../../../utils/userRoutes";
import { useHistory } from "react-router";
import NewCircleForm from "./newCircleForm";

function AmbassadorMain() {
  const UI = useUI();
  const userContext = useUser();
  const history = useHistory();


  const [isLoading, setIsLoading] = useState(false);
  const [ambassadorData, setAmbassadorData] = useState([]);
  const [selectedAmbassador, setSelectedAmbassador] = useState(null);
  const [showSelectedAmbassador, setShowSelectedAmbassador] = useState(false);
  const [showNewCircleForm, setShowNewCircleForm] = useState(false);
  
    
  useEffect(() => {
    loadData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadData = async () => {
    const {status, data: ambData} = await getFullAmbassadorCircles();

    if(status === 200){
      setAmbassadorData(ambData);
    } else {
      console.log(status);
      console.log(ambData);
      UI.showToast("There was an error loading the data, please contact us");
    }

    setIsLoading(false);
  }

  if(isLoading){
    return <InlineLoading />
  }

  return (
    <React.Fragment>
      {showSelectedAmbassador && <AmbassadorSelectedPopup onHide={() => setShowSelectedAmbassador(false)} data={selectedAmbassador} />}
      {showNewCircleForm && <NewCircleForm onHide={() => setShowNewCircleForm(false)} />}

      <PlatformContentContainer>

      {userContext.user.stage !== "Dashboard" && <div className="ambassadorSignupWarning" onClick={() => {
        userContext.updateUser({ambassadorOwner: null}, true);
        history.push(getNextRoute(userContext.user));
      }}>You can sign up to StepLadder anytime yourself by clicking here.</div>}

      <h2 className="capitalize">Your Ambassador Circles</h2>

      <DataTable style={{marginTop:"-60px"}} value={ambassadorData} selectionMode="single" selection={selectedAmbassador} onSelectionChange={e => {setSelectedAmbassador(e.value); setShowSelectedAmbassador(true)}}>
        <Column field="excelID" header="Circle ID" />
        <Column body={(row) => currencyFormat(row.monthly_investment)} header="Monthly Investment" />
        <Column body={(row) => currencyFormat(row.monthly_investment * row.period)} header="Award Amount" />
        <Column body={(row) => moment.utc(row.startingDate).format("MMMM YYYY")} header="Start" />
        <Column body={(row) => moment.utc(row.endingDate).format("MMMM YYYY")} header="End" />
        <Column body={(row) => "7th " + moment.utc(row.startingDate).add(row.reserve_period).format("MMMM")} header="First Draw" />
        <Column body={(row) => row.participants.filter(part => part.membership_status === "approved").length + "/" + row.period} header="Filled Spots" />
      </DataTable>

      <div style={{textAlign:"center", marginBottom:"50px"}}>
      <div className="feedComponentContainer cursorPointer"  onClick={() => setShowNewCircleForm(true)} style={{marginRight:"35px"}}>
        <div className="title">Request New Circle</div>
        <div className="content">
          Looking to start a Circle up? Click here to send us a request.
        </div>
      </div>

      <div className="feedComponentContainer cursorPointer" onClick={() => window.location = "mailto: osei@step-ladder-solutions.com"}>
        <div className="title">Contact Us</div>
        <div className="content">
          Need to talk to one of our Ambassador team? Let's chat. Click here.
        </div>
      </div>
        </div>

      
    </PlatformContentContainer>
    </React.Fragment>
    
  );
}

export default AmbassadorMain;
