import {currencyFormat, requiredValue} from "../../common/services/utils";

function getQueryParams(){
   return decodeURI(window.location.search)
   .replace('?', '')
   .split('&')
   .map(param => param.split('='))
   .reduce((values, [ key, value ]) => {
     values[ key ] = value
     return values
   }, {});
}

const isMobile = window.innerWidth <= 650;

//remove (0) and any non-digit characters from the phone number
// "+44 (0) 123 456 57890"  => "4412345657890" 
const phoneMask2Number = (phoneNumber) => {
   return phoneNumber?.replace("(0)", "").replaceAll(/\D/g, "");
}


export { currencyFormat, isMobile, getQueryParams, phoneMask2Number, requiredValue };