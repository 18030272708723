import { useEffect } from "react";
import { useState } from "react";
import { useHistory } from "react-router";
import { useUser } from "../../../../contexts/UserContext";
import { termsAgreed } from "../../../../services/circles";
import CenterMiddle from "../../../generic/CenterMiddle";
import LoadingOverlay from "../../../generic/LoadingOverlay";

function MangoPayReturn() {
  const userContext = useUser();
  const [view, setView] = useState("loading");
  const history = useHistory();

  useEffect(() => {
    submit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submit = async () => {

      const urlParams = new URLSearchParams(window.location.search);
      const mandateID = urlParams.get("MandateId");
      
  
      if (mandateID == null) {
        setView("error");
        return;
      }
  
      //add user to permanent circle
      const { status, data } = await termsAgreed();
  
  
      if (status !== 200 || !data.circleAccepted) {
        setView("error");
        return;
      }
      await userContext.fetchUser();

      history.push("/HelperInfo");
  }

  if (view === "loading") {
    return <LoadingOverlay></LoadingOverlay>;
  }

  if (view === "error") {
    return (
      <CenterMiddle>
        <h2>Uh-oh, we couldn't confirm your account with us!</h2>
        <div className="blueBox" style={{ marginTop: "30px" }}>
          Don't worry, its us, not you!{" "}
          <a href="https://www.joinstepladder.com/contact/">Contact us</a> now
          to resolve the issue, we can't wait to welcome you to StepLadder!
        </div>
      </CenterMiddle>
    );
  }
}

export default MangoPayReturn;
