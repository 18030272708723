import React, {useState} from "react";
import GetStartedApply from "./getStartedApply";
import GetStartedRiskDisclaimer from "./disclaimer";
import PersonalGoals from "../authenticated/personalGoals";
import { getCookie } from "../../../utils/users";
import { refferalsToAmbassadors } from "../../../../common/services/userHelpers";

function GetStarted({setTitle, setSubTitle}) {
  const referralCodeCookie = getCookie("referralCode");
  const initUser = {};

  if (refferalsToAmbassadors[referralCodeCookie]) {
    initUser.ambassador_id= refferalsToAmbassadors[referralCodeCookie];
  }

  const [tabIndex, setTabIndex] = useState(0);
  const [userInfo, setUserInfo] = useState(initUser);
  const tabs = [
      {
        component: GetStartedApply
      },
      {
        component: GetStartedRiskDisclaimer
      },
      {
        component: PersonalGoals
      },
    ];

  const Component = tabs[tabIndex].component;

  const setPage = (index) => {
      setTabIndex(index);
  };

  return (
    <Component setTitle={setTitle} setSubTitle={setSubTitle} setPage={(index) => setPage(index)} userInfo={userInfo} setUserInfo={setUserInfo}/>
  );
}


export default GetStarted;
