import React from "react";
import "primeflex/primeflex.css";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from "moment";

class FailedPaymentList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payments: [],
    };
  }
    circleNum(rowData) {
        return (<span className={"p-badge p-badge-secondary badge-border"}>{rowData.excelID}</span>);
    }

    getName(rowData) {
        return `${rowData.participant.user.firstname} ${rowData.participant.user.lastname}`;
    }


    date(rowData) {
        return moment(rowData.CreationDate).format("DD-MM-YYYY");
    }

    payments() {
        const fpayments = this.props.payments.map((p) => {
            const failed = p.failedPayments.map((fp) => ({...fp, excelID: p.excelID, name: this.getName(p), email: p.participant.user.email,}));
            return failed;
        }).flat()
        return this.props.payments ? fpayments : [];
    }

  render() {
    return (
      <React.Fragment>
        <div>
            <div className="card blue-table">
                <DataTable 
                  value={this.payments()}
                  paginator
                  paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                  currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                  rows={50}
                  rowsPerPageOptions={[10, 20, 50, 100]}
                  >
                    <Column field="excelID" header="Circle ID" body={this.circleNum}></Column>
                    <Column field="name" header="Name" ></Column>
                    <Column field="email" header="Email" ></Column>
                    <Column field="AuthorId" header="AuthorId" ></Column>
                    <Column field="Status" header="Status" ></Column>
                    <Column field="WalletId" header="WalletId" ></Column>
                    <Column field="Type" header="Type" ></Column>
                    <Column field="CreationDate" header="Date" body={this.date}></Column>
                </DataTable>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default FailedPaymentList;
