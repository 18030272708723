import ReactGA from 'react-ga';
const trackingIdGA4 = process.env.REACT_GA_MEASUREMENT_ID;
const trackingId = "UA-88380825-1";

const AnalyticsService = {
    submitPageView(){
        if(trackingId && trackingId !== "test") {
            this.initializeGoogleAnalytics();
            ReactGA.set({ page: window.location.pathname }); // Update the user's current page
            ReactGA.pageview(window.location.pathname); // Record a pageview for the given page
        }
    },
    //not uses
    submitEvent(action, label="", actionOnly=false){

        if(actionOnly){
            ReactGA.event({
                action: action,
            });
        } else {
            ReactGA.event({
                category: "PlatformEvent",
                action: action,
                label: label
            });
        }
        
    },
    initializeGoogleAnalytics(){
        ReactGA.initialize([
            {trackingId},
            {
                trackingId: trackingIdGA4, 
                gaOptions: { name: "trackingGA4" }
            }]);
    }
};

export default AnalyticsService;

