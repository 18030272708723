const getTheMost = {
    title: "Get the most from your StepLadder experience",
    next: "goodToKnow",
    slabs:[
    {
        img2: "./images/helpers/people-dancing.png",
        label: "Click on to see a special offer just for you as a new StepLadder Member",
        tooltip: null
    },
    {
        img2: "./images/helpers/calendar.png",
        label: "Mark your calendar for Draw Day on the 7th of every month",
        tooltip: null
    },
    {
        img2: "./images/helpers/money-flower.png",
        label: "Join extra spots to increase your chances of being drawn!",
        tooltip: null
    }
]};

const goodToKnow = {
    title: "Good things to know...",
    next: "partnersOrBigAmount",
    slabs:[
    {
        img2: "./images/helpers/phone-hand.png",
        label: "Payments will come out under the name Mango Pay. We’ll remind you by email before.",
        tooltip: null
       
    },
    {
        img2: "./images/helpers/direct-debit-report.png",
        label: "Payments are taken on the closest working day to the 1st of the month.",
        tooltip: null
    },
    {
        img2: "./images/helpers/award-woman.png",
        label: "Making your payments on time means you are eligible for the draw!",
        tooltip: null
    }
]};


const partners = {
    checkmyfile: "checkmyfile",
    hl: "hl",
    proportunity: "proportunity",
    snoop: "snoop"
};

const reasons = {
    deposit: "Raising money for your deposit",
    credit: "Building your credit score",
    debt: "Paying off your debts",
    mortgage: "Getting mortgage ready",
    business: "Starting your own business",
    else: "Something else"
  };

const content = {
    getTheMost,
    goodToKnow,
    reasons,
    partners
}

export default content;