import { useState } from "react";
import InfoPopup from "./InfoPopup";

function InfoSlab({ data, variant }) {
    const [sidebarVisible, setSideBarVisible] = useState(false);
  
    const textColor = `${variant ? "white" : ""}`;
    const imgWidth1 = `${variant ? "100px" : ""}`;
    const imgWidth = `${variant ? "100px" : ""}`;
    return (
      <div className={`flex infoSlab ${variant ? "new" : ""}`}>
        <div style={{width: imgWidth1}} className={"center"} >
          <img alt={"infoSlabPicture"} style={{width: imgWidth}} src={variant ? data.img2 : data.img}></img>
        </div>
        <div style={{color: textColor}}>
          {data.label}{" "}
          {data.tooltip && <i
            onClick={() => setSideBarVisible(true)}
            className="fas fa-info-circle"
          />}
        </div>
        {sidebarVisible && (
          <InfoPopup title={data.label} content={data.tooltip} onHide={() => setSideBarVisible(false)} />
        )}
      </div>
    );
  }


export default InfoSlab;