import React from "react";
import LogoText from "./logoText";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { useHistory } from "react-router";
import { Button } from "primereact/button";
import { useUI } from "../../../../../contexts/UIContext";
import { useUser } from "../../../../../contexts/UserContext";
import { createPartnerActivity } from "../../../../../services/partnerActivity";

function OptionItem({option}) {
    const history = useHistory();
    const UI = useUI();
    const userContext = useUser();
    const user = userContext.user;
    async function goTo(link, trackName) {
        await createPartnerActivity(trackName, "platformClick", user.email);
        if(link === "") {
            UI.showToast("Thanks for letting us know! You should receive an email shortly.");
            return;
        }
        if(link.charAt(0) === '/') {
            history.push(link);
        } else {
            window.open(link, '_blank');
        }
    }
    return (
        <div>
            <div className="blueBox investor p-mb-3">
                <LogoText logo={option.logo} title={option.logoTitle}/>
                <div className="p-mt-4 p-mb-4" >
                    {option.texts.map((text) => {
                        if(text.type === "b") {
                            return (<p><b>{text.text}</b></p>);
                        }
                        if(text.type === "i") {
                            return (<p><i>{text.text}</i></p>);
                        }
                        return (<p>{text.text}</p>);
                    })}
                </div>
               
                {option.table && 
                    <div className="p-mb-4">
                        <DataTable value={option.table} className="table-rounded blue-table">
                            <Column field="deposit" header="Your deposit"></Column>
                            <Column style={{width:'35%'}} field="bonus" header="Goverment bonus"></Column>
                            <Column field="newTotal" header="New total*"></Column>
                        </DataTable>
                    </div>
                }
                <Button 
                    label={option.button.name}
                    className="p-button-info p-button-raised p-component p-mt-3 center-el"
                    onClick={() => goTo(option.button.link, option.button.trackName)}/>
            </div>
        </div>
    );
}

export default OptionItem;