import { MultiSelect } from "primereact/multiselect";

function SelectPartners({ columnDefs, value, onChange }) {

    const columns = columnDefs.slice(1);

    const camel2title = (camelCase) => camelCase
  .replace(/([A-Z])/g, (match) => ` ${match}`)
  .replace(/^./, (match) => match.toUpperCase())
  .trim();

  const options = columns.map(cd => {
    return cd.headerName
  }).sort((a, b) => {
    return a > b ? 1 : a < b ? -1 : 0;
  });

  return (
    <MultiSelect
      style={{marginBottom:"20px"}}
      value={value}
      placeholder="Filter Partners"
      options={options}
      showClear={true}
      itemTemplate={(option) => camel2title(option)}
      onChange={(e) => onChange(e.value)}
    />
  );
}

export default SelectPartners;
