import React from "react";
import { withRouter } from "react-router";
import UserSearch from "../components/userSearch";


class page extends React.Component {
  constructor(props) {
    super(props);
    this.props.setTitle("User Search");

    this.state = {
      users:[]
    };
  }

  async componentDidMount() {
    if (!this.isLoggedIn()) {
      this.props.history.push("/adminPortal/login");
    } else {
      this.props.history.push("/adminPortal/home");
    }
  }

  isLoggedIn() {
    return !!localStorage.getItem("adminLoggedIn");
  }

  render() {
    return (
      <div>
        <UserSearch></UserSearch>
        <div className="ag-theme-alpine pading-top" style={{ height: 600, width: '100%' }}>
        {/* <StTable users={this.state.users}></StTable> */}
          
        </div>
      </div>
    
      );
  }
}

const AdminHome = withRouter(page);

export default AdminHome;
