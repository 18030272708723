import React from "react";
import { Button } from "primereact/button";
import {useHistory} from "react-router";

function AddonsNavigation({title=""}) {
    const history = useHistory();

    function goTo() {
        history.goBack()
    }
    return (
        <div className="p-grid divider p-mb-5 p-pb-3">
            <div className="p-sm-4 p-md-3 p-mr-3">
                <Button icon="pi pi-arrow-left" label={"Back"} className="p-button-sm p-button-plain p-button-outlined center-el" onClick={() => goTo()}/>
            </div>
            <div className="p-col">
                <h2 className="p-d-lg-inline">{title}</h2>
            </div>
        </div>
    );
}

export default AddonsNavigation;