import { Button } from "primereact/button";
import { useHistory } from "react-router";
import { isMobile } from "../../../../utils/generic";
import CenterMiddle from "../../../generic/CenterMiddle";

function TermsMaxCircleError() {
  const history = useHistory();

  return (
    <CenterMiddle>
      <img
      alt="membership team"
        src="https://2442073.fs1.hubspotusercontent-na1.net/hubfs/2442073/Iesha%20Sarah%20And%20Peter%201.jpg"
        style={{ width: "100%", maxWidth: "400px", borderRadius: "10px", marginBottom:"15px" }}
      />
      <h2>We're here to help!</h2>
      <div>
        You've reached the maximum of spots that you can add yourself.
        <br />
        <br />
        We'd love to help you reach your own goals. You can add more Circles by
        talking to our membership team.
        <br />
        <br />
        <Button
          onClick={() =>
            (window.location.href = "https://joinstepladder.com/contact")
          }
          label="Call Us"
          style={{
            marginTop: "25px",
            textAlign: "center",
            marginRight: isMobile ? "0px" : "15px",
          }}
        ></Button>
        <Button
          style={{ textAlign: "center", marginTop: "15px" }}
          className="p-button-outlined"
          onClick={() => {
            history.push("/dashboard");
          }}
          label="Back"
        ></Button>
      </div>
    </CenterMiddle>
  );
}

export default TermsMaxCircleError;
