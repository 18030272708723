import { Button } from "primereact/button";
import React from "react";
import { Toolbar } from 'primereact/toolbar';
import { Card } from 'primereact/card';

function SubmitPaymentCycle({period, months, onSubmit, getIgnoredPayments, getFinal}) {

    const leftContents = (<>
        <div className="p-mr-2">
            <Button label="Submit" className="p-button-info p-button-raised" onClick={onSubmit} ></Button>
        </div>

        <div className="p-mr-2">
            <Button label="ignored payments" className="p-button-info p-button-raised" onClick={getIgnoredPayments} ></Button>
        </div>

        <div className="p-mr-2">
            <Button label="final payments" className="p-button-info p-button-raised" onClick={getFinal}></Button>
        </div></>);
    const paymentForLabel = `Payment for ${months.filter((itm) => (itm.value === period.month))[0].label} ${period.year}`;
    return (
        <Card
            className="sl-card"
            title="3. Submit the payment cycle">
            <div className="payment-block">
                <h4>{paymentForLabel}</h4>
                <Toolbar left={leftContents} />
            </div>
        </Card>
    );
}

export default SubmitPaymentCycle;