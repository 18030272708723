import { useHistory } from "react-router";

import PlatformContentContainer from "../_generic/platformContentContainer";

function HelpMain() {
  const history = useHistory();

  return (
    <PlatformContentContainer>
      <h2>Help</h2> <div className="headerDescription">
          We're here to answer all your questions!
        </div>
      <div className="feed">
       
        <div
          className="feedComponentContainer cursorPointer"
          onClick={() => history.push("/dashboard/education")}
        >
          <div className="title">Knowledge Is Power</div>
          <div className="content">
          Check out the <b>knowledge section</b> to learn more about Draw Day, payments and other frequently asked questions about your StepLadder Membership.
          </div>
        </div>
        <div
          className="feedComponentContainer cursorPointer"
          onClick={() =>
            (window.location.href = "https://www.joinstepladder.com/contact/")
          }
        >
          <div className="title">Membership Team</div>
          <div className="content">
          If you prefer to chat to an actual human then contact our lovely Membership team{" "}
            <b>here</b>.
          </div>
        </div>
        <div
          className="feedComponentContainer cursorPointer"
          onClick={() =>
            (window.location.href = "https://www.joinstepladder.com/contact/")
          }
        >
          <div className="title">Your Account</div>
          <div className="content">
            If your circumstances change, please contact us by clicking{" "}
            <b>here</b>.
          </div>
        </div>
        <div
          className="feedComponentContainer cursorPointer"
          onClick={() => history.push("/logout")}
        >
          <div className="title">Logout</div>
          <div className="content">
            Click <b>here</b> to logout.
          </div>
        </div>
      </div>
    </PlatformContentContainer>
  );
}

export default HelpMain;
