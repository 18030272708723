import React from "react";
import "primeflex/primeflex.css";
import { Button } from 'primereact/button';
import moment from "moment";
import { InputText } from "primereact/inputtext";
import utils from "../../utils/utils";
import { Dropdown } from 'primereact/dropdown';
import { Calendar } from "primereact/calendar";
import { Checkbox } from "primereact/checkbox";

function ParticipantRow({participantRow, spot, updateChangesLocallyForSpot, userIDMembershipData, isLast, askRemoveSpot, saveChangesForSpot, dropoutReasons}) {
    return (
        <React.Fragment>
          <div style={{ fontWeight: "700", marginBottom: "0.5em" }}>
            Spot {spot} ({participantRow._id})
          </div>
            <div className="p-fluid p-formgrid p-grid p-pt-3">
                <div className="p-field p-col p-pr-6">
                    <label htmlFor="walletId">Wallet ID</label>
                    <InputText
                        id="walletId"
                        type="text"
                        value={participantRow.walletID}
                        onChange={(e) => {
                            updateChangesLocallyForSpot("walletID", e.target.value);
                        }}
                        disabled={
                            userIDMembershipData.membership_status !==
                            "approved"
                        }
                        ></InputText>
                </div>
                <div className="p-field p-col p-pr-6">
                    <label htmlFor="customFee">Custom Admin Fee</label>
                    <InputText
                        id="customFee"
                        type="number"
                        value={participantRow.custom_fee}
                        onChange={(e) => {
                            updateChangesLocallyForSpot("custom_fee", e.target.value);
                        }}
                        disabled={
                            userIDMembershipData.membership_status !== "approved"
                        }
                        ></InputText>
                </div>
            </div>

            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col p-pr-6">
                    <label htmlFor="drawDate">Draw Date</label>
                    <Calendar
                        value={utils.getDateFromString(participantRow.drawn_date)}
                        dateFormat="dd/mm/yy"
                        id="drawDate"
                        onChange={(e) => {
                            const value = e.value === "Invalid date" || !e.value ? null : e.value;
                            updateChangesLocallyForSpot(
                            "drawn_date",
                            value ? moment(value).format("YYYY-MM-DDTHH:mm:ss") + "Z" : null
                            );
                        }}
                        disabled={
                            userIDMembershipData.membership_status !==
                            "approved"
                        }
                        />
                </div>
                <div className="p-field p-col p-pr-6">
                    <label htmlFor="dropoutDate">Dropout Date</label>
                    <Calendar
                        id="dropoutDate"
                        dateFormat="dd/mm/yy"
                        value={utils.getDateFromString(participantRow.dropout_date)}
                        onChange={(e) => {
                            const value = e.value === "Invalid date" || !e.value ? null : e.value;
                            updateChangesLocallyForSpot(
                            "dropout_date",
                            value ? moment(value).format("YYYY-MM-DDTHH:mm:ss") + "Z" : null
                            );
                        }}
                        disabled={
                            userIDMembershipData.membership_status !==
                            "approved"
                        }
                        />
                </div>
            </div>

            {participantRow.dropout_date && <div className="p-fluid p-formgrid p-grid">
                <div className="p-field p-col p-pr-6">
                    <label htmlFor="dropoutReason">Dropout Reason</label>
                    <Dropdown
                        id="dropoutReason"
                        value={participantRow.dropoutReason}
                        options={dropoutReasons}
                        onChange={(e) => {
                            updateChangesLocallyForSpot(
                            "dropoutReason",
                            e.value
                            );
                        }}
                        placeholder="Select"
                        />
                </div>
                <div className="p-field p-col p-pr-6">
                    <label htmlFor="notes">Notes(optional)</label>
                    <InputText
                        id="notes"
                        type="text"
                        value={participantRow.notes}
                        onChange={(e) => {
                            updateChangesLocallyForSpot("notes", e.target.value);
                        }}
                        ></InputText>
                </div>
            </div>}


            <div className="p-fluid p-formgrid p-grid">
                <div className="p-field-checkbox p-col p-pr-6">
                    
                    <Checkbox
                        inputId="creditBuilder"
                        disabled={
                        userIDMembershipData.membership_status !==
                        "approved"
                        }
                        onChange={(e) => {
                        updateChangesLocallyForSpot(
                            "credit_builder",
                            e.checked
                        );
                        }}
                        checked={participantRow.credit_builder}
                    ></Checkbox>
                    <label htmlFor="creditBuilder">Credit Builder</label>
                </div>
            </div>
          <br></br>
          <Button
            onClick={(e) => {
              saveChangesForSpot();
            }}
            label="Save Changes"
          ></Button>
          <Button
            label="Remove Spot From Circle"
            className="p-button-outlined p-button-danger"
            onClick={(e) => {
              askRemoveSpot();
            }}
            style={{ marginLeft: "1.5em" }}
          ></Button>
         {isLast && <hr style={{ marginTop: "2em", marginBottom: "2em" }}></hr>}
        </React.Fragment>
    )

}
export default ParticipantRow;