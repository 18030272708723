import InsuranceQuizQuestion from "../components/InsuranceQuizQuestion";

function RelyIncomeInsurance({ onNext, onChange }) {
  return (
    <InsuranceQuizQuestion
      title="Does your family rely on your income?"
      onChange={(newval) => onChange({ familyRelyOnIncome: newval })}
      description="Question 1 of 3"
      onNext={onNext}
    />
  );
}

export default RelyIncomeInsurance;
