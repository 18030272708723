import React from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
class ParticipantsView extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      status_selection: "approved",
      statuses: [
        {
          label: "Members",
          key: "approved",
        },
        
        {
          label: "Dropouts",
          key: "dropout",
        },
        {
          label: "Requested",
          key: "requested",
        },
      ],
    };
  }

  getCount(membership_status) {
    return this.getFilteredRows(membership_status).length;
  }

  getFilteredRows(status) {
    if (status === "dropout") {
      return this.props.participants.filter(function (itm) {
        return itm.membership_status === "approved" && itm.dropout_date;
      });
    }

    if(status === "approved"){
      return this.props.participants.filter(function (itm) {
        return itm.membership_status === "approved" && !itm.dropout_date;
      });
    }

    return this.props.participants.filter((itm) => {
      return itm.membership_status === status;
    });
  }

  render() {
    return (
      <div>
        {this.state.statuses.map((itm, i) => {
          return (
            <span
              key={itm.key}
              style={{
                cursor: "pointer",
                fontWeight: this.state.status_selection === itm.key ? 700 : 400,
              }}
              onClick={(e) => {
                this.setState({ status_selection: itm.key });
              }}
            >
              {itm.label} ({this.getCount(itm.key)}) {this.state.statuses[i + 1] ? " | " : ""}
            </span>
          );
        })}

        <DataTable
          onSelectionChange={(e) => {
            var participantRow = JSON.parse(JSON.stringify(e.value));
            this.props.history.push(
              "/adminPortal/user?id=" + participantRow.user._id + "&tabIndex=1"
            );
          }}
          selectionMode="single"
          dataKey="_id"
          value={this.getFilteredRows(this.state.status_selection)}
          style={{ marginTop: "2em" }}
        >
          <Column
            body={(row) => {
              return row.user && row.user.firstname && row.user.lastname
                ? row.user.firstname + " " + row.user.lastname
                : "No Connected User";
            }}
            header="Name"
          ></Column>
          <Column field="user.email" header="Email"></Column>
        </DataTable>
      </div>
    );
  }
}

export default ParticipantsView;
