import InsuranceBox from "../components/InsuranceBox";
import InsuranceQuizPage from "../components/InsuranceQuizPage";

function InsuranceFrontPage({onNext}) {
  return (
    <InsuranceQuizPage onClick={onNext}>
      <div style={{ marginTop: "120px" }}></div>
      <InsuranceBox>
        <div
          style={{
            textAlign: "center",
            marginTop: "-90px",
            marginBottom: "15px",
          }}
        >
          <img
            style={{ width: "150px" }}
            src="https://i.ibb.co/rwpz1RN/couple.png"
          />
        </div>
        To understand whether you may need to protect the people important to
        you and your things, we’re going to ask you 3 simple questions.
      </InsuranceBox>
    </InsuranceQuizPage>
  );
}
export default InsuranceFrontPage;
