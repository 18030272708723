import moment from "moment";
import { Card } from "primereact/card";

import React from "react";


function TermsBox(props) {
  const circle_info = props.circleData;

  const currencyFormat = (number, addzeros = false) => {
    if(number == null){
      return "£"+0;
    }

    if (addzeros) {
      return (
        "£" +
        number.toLocaleString("en", {
          useGrouping: false,
          minimumFractionDigits: 2,
        })
      );
    } else {
      return "£" + number.toLocaleString("en");
    }
  };

  const startingDateAsString = () => {
    const start = moment.utc(circle_info.startingDate);
    return start.format("Do MMM");
  };

  const getenddate = () => {
    return moment.utc(circle_info.endingDate).format("MMM YYYY");
  };

  const getdrawdaydate = () => {
    var startDate = moment(circle_info.startingDate);
    var drawdaydate = moment(startDate).add(circle_info.reserve_period, "M");
    return drawdaydate.format("MMMM");
  };

  return (
    <div className="animate__animated animate__fadeIn animate__faster terms_summary">
      <Card style={{ borderRadius: "1em" }}>
        <div>
          <div>Target</div>
          <div>
            {currencyFormat(
              circle_info.period * circle_info.monthly_investment
            )}
          </div>
        </div>
        <div>
          <div>Monthly</div>
          <div>{currencyFormat(circle_info.monthly_investment)}</div>
        </div>
        <div>
          <div>For</div>
          <div>{circle_info.period}mo</div>
        </div>
        <div>
          <div>Start</div>
          <div>{startingDateAsString()}</div>
        </div>
        <div>
          <div>End</div>
          <div>{getenddate()}</div>
        </div>
        <div>
          <div>Fees</div>
          <div>{currencyFormat(circle_info.admin_fee, true)}</div>
        </div>
        <div className="firstDrawDay">
          🎉 You could be awarded your {currencyFormat(circle_info.period * circle_info.monthly_investment)} as early as {getdrawdaydate()}!
        </div>
      </Card>
    </div>
  );
}

export default TermsBox;
