const creditCheckUtils = {
    validEquifaxScore: function(equifaxScore){
        return equifaxScore >= 350;
    },
    validIVA: function(ivaAnswer){
        return ivaAnswer ? ivaAnswer === "No" : false;
    },
    validAML: function(AMLAddress, AMLIdentity, AMLRisk){
        var addressCheck = AMLAddress > 1;
        var identityCheck = AMLIdentity > 0;
        var riskCheck = AMLRisk < 2;
    
        return addressCheck && identityCheck && riskCheck;
    }
};

export default creditCheckUtils;