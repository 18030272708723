import React, {useState, useEffect} from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";

import { SelectButton } from "primereact/selectbutton";
import { Dropdown } from "primereact/dropdown";
import creditCheckUtils from "./creditCheckUtils";
import { Card } from "primereact/card";
import API from "../../services/api";

function SecondCreditCheck(props){
    const [creditCheckFinalOutcome, setCreditCheckFinalOutcome] = useState();
    const [creditCheckNotes, setCreditCheckNotes] = useState("");
    const [circleMemberList, setCircleMemberList] = useState([]);
    const [isCircleMemberListLoading, setIsCircleMemberListLoading] = useState(false);

    const submitReview = async () => {
        if(!creditCheckFinalOutcome || !creditCheckNotes || creditCheckNotes === ""){
           return props.showToast("Please select an answer to both questions before proceeding", "error");
        }
        
        const updateObject = {
            creditCheckSecondReview: creditCheckFinalOutcome.code,
            creditCheckNotes: creditCheckNotes
        };

        props.showLoading();

        var response = await API.call({
            url: "/users/adminUserUpdate",
            method: "PUT",
            body: {
              userID: props.selectedUser._id,
              updateObject: updateObject,
            },
        });

        props.hideLoading();

        if (response.detailsUpdated) {
            props.showToast("Submission Complete", "success");
            window.setTimeout(() => {
                window.location.reload();
            }, 1000);
        } else {
            props.showToast("Failed to submit", "error");
        }
    };

    const createPassFailHTML = (outcome) => {
        return (
            <span style={{color: outcome ? "green":"red"}}>({outcome ? "Pass" : "Fail"})</span>
        )
    }

    const loadCircleInfoForUser = async (userID) => {
        if(!userID){
            return;
        }

        setIsCircleMemberListLoading(true);
       

        const circleMembershipList = await API.call({
            url: "/circles/userCircles2?UserId=" + userID,
            method: "GET",
        });

        var filteredList = [];

        for(var circle of circleMembershipList){
            const memberSpots = circle.participants.filter((itm) => {
                return itm.membership_status === "approved" && itm.user._id === userID;
            });

            if(memberSpots.length > 0){
                filteredList.push(circle);
            }
        }

        setCircleMemberList(filteredList);
        setIsCircleMemberListLoading(false);
    };

    const generateCircleBulletPoints = () => {
        return <ul>{circleMemberList.map((circle) => {
            return (
                <li key={circle._id}><b>Circle {circle.excelID}{circle.ambassador?"("+circle.ambassador.ambassadorName+")":""}</b>: {formatCurrency(circle.monthly_investment)} X {circle.period}</li>
            );
        })}</ul>
    };

    useEffect(() => {
        loadCircleInfoForUser(props.selectedUser._id);
        setCircleMemberList([]);
        setCreditCheckNotes("");
        setCreditCheckFinalOutcome();
    }, [props.selectedUser]);

    const failureReasons = [
        "CCJ Satisfied",
        "Legacy CCJ - outstanding",
        "No material issues",
        "Thin file",
        "Equifax file note",
        "Hire-purchase adjust" ,
        "Legacy infraction",
        "Credit rehabilitation",
        "One-off stress period",
        "Excess applications",
        "Excess balances",
        "Inability to complete",
    ];

    const formatCurrency = (someNumber) => {
        return typeof someNumber === "number" ?  "£"+ Number((someNumber).toFixed(2)).toLocaleString() : ""
    };

    

    return (
        <Dialog
          header={props.selectedUser.firstname + " " + props.selectedUser.lastname + " (" + props.selectedUser.email +")"}
          visible={props.visibility}
          style={{ width: "70vw" }}
          onHide={() => {props.closeDialog()} }
    >
        This member requires a second review.
          <br></br>
          <br></br>
          <br></br>
          <div className="adminSecondCreditCheckInfo">
            <Card title="Credit Score">
            <ul>
                <li><b>Equifax</b>{" "} 
                {props.selectedUser.creditCheckScore && <React.Fragment>
                    {createPassFailHTML(creditCheckUtils.validEquifaxScore(props.selectedUser.creditCheckScore.equifax))} 
                    {": "} {props.selectedUser.creditCheckScore.equifax}
                </React.Fragment>}</li>
                
                <li><b>AML{" "}</b>
                {props.selectedUser.creditCheckScore && <React.Fragment>
                    {createPassFailHTML(creditCheckUtils.validAML(props.selectedUser.creditCheckScore.AMLAddress, props.selectedUser.creditCheckScore.AMLIdentity, props.selectedUser.creditCheckScore.AMLRisk))}
                    {": "} 
                    {props.selectedUser.creditCheckScore.AMLAddress+ 
                    "-"+
                    props.selectedUser.creditCheckScore.AMLIdentity
                    +"-"+
                    props.selectedUser.creditCheckScore.AMLRisk}
                </React.Fragment>}</li>

                <li><b>IVA/CCJ </b>
                {props.selectedUser.creditCheckScore && <React.Fragment>
                    {createPassFailHTML(creditCheckUtils.validIVA(props.selectedUser.f_have_you_ever_experienced_iva_ccj_repossession_or_bankruptcy_))}
                    {" "}- {props.selectedUser.f_have_you_ever_experienced_iva_ccj_repossession_or_bankruptcy_}
                </React.Fragment>}</li>
            </ul>
            
        </Card>
            <Card title="Member Info">
                <ul>
                    <li><b>Household Income: </b>{formatCurrency(props.selectedUser.HI)}</li>
                    <li><b>FMC: </b>{typeof props.selectedUser.minimumTestBeforeActiveCircleDeduction === "number" ? formatCurrency(props.selectedUser.minimumTestBeforeActiveCircleDeduction) : formatCurrency(props.selectedUser.minimumTest)}</li>
                    <li><b>Debt Declared: </b> {formatCurrency(props.selectedUser.how_much_debt_do_you_have_)}</li>
                </ul>
            </Card>
            <Card title="Circle">
                {isCircleMemberListLoading ? "Loading..." : generateCircleBulletPoints()}
            </Card>
          
          </div>
         
          
          
          <br></br>
          <br></br>
          <hr></hr>
          <br></br>
          Make your decision and select a reason.<br></br>
          <br></br>
          <b>Applicant Status</b>
          <br></br>
          <SelectButton
            optionLabel="name"
            value={creditCheckFinalOutcome}
            options={[
              { name: "Pass", code: "Pass" },
              { name: "Credit Builder", code: "Credit Builder" },
              { name: "Fail", code: "Fail" },
            ]}
            onChange={(e) => setCreditCheckFinalOutcome(e.value)}
          ></SelectButton>
          <br></br>
          <br></br>
          <b>Reason</b>
          <br></br>
          <Dropdown
            value={creditCheckNotes}
            options={failureReasons.map((itm) => {
                return {label: itm, value: itm}
            })}
            editable={true}
            onChange={(e) => setCreditCheckNotes(e.value)}
            placeholder="Type or Select"
          />
          <br></br>
          <br></br>
          <Button label="Submit" onClick={(e) => {submitReview();}}></Button>
    </Dialog>
    );
    
}

export default SecondCreditCheck;