import { Slider } from "primereact/slider";
import React from "react";

function SliderInput(props) {
  const formObject = props.formObject;
  return (
    <Slider
      style={{ position: "absolute", bottom: 0, left: 0, width: "100%" }}
      min={formObject.min}
      max={props.max || formObject.max}
      value={props.value}
      step={formObject.step || 1}
      onChange={(e) => props.onChange(e.value)}
    />
  );
}

export default SliderInput;
