import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import React from "react";
import { useState } from "react";

import UserSearch from "../../../components/userSearch";
import { editUserProperty } from "../../../services/users";

function AmbassadorAccess(props) {
  const [showUserAdd, setShowUserAdd] = useState(false);
  const users = props.access;
  const ambassador = props.ambassador;

  const changeUserConfirmation = (user, addTrueRemoveFalse=true) => {
    const isConfirmed = window.confirm("Are you sure you want to " + (addTrueRemoveFalse ? "add":"remove") + " " + (user.firstname || user.lastname || user.name) + "?");

    if(isConfirmed){
        updateAmbassadorOwner(user._id, addTrueRemoveFalse);
    }
  };

  //adds or removes the value
  const updateAmbassadorOwner = async (userIDSelected, addTrueRemoveFalse=true) => {

    props.showLoading();
    const response = await editUserProperty(userIDSelected, {
      ambassadorOwner: addTrueRemoveFalse ? ambassador.ambassadorName : null,
    });
    props.hideLoading();

    if (!response.detailsUpdated) {
        props.showToast("We couldn't update those users details", "error");
        return;
    }

    props.showToast("User details updated!", "success");

    window.setTimeout(() => {
        window.location.reload();
    }, 1500);
    
  };

  return (
    <React.Fragment>
     

      <Button
        label="Add +"
        style={{ marginBottom: "12px" }}
        onClick={() => setShowUserAdd(true)}
      />
      <DataTable value={users} selectionMode="single" onSelectionChange={(e) => changeUserConfirmation(e.value, false)}>
        <Column header="Name" field="name"></Column>
        <Column header="Email" field="email"></Column>
      </DataTable>
      {showUserAdd && (
        <Dialog header="Add a User" visible={true} onHide={() => setShowUserAdd(false)}>
          <div>1. Check if the user already has an account</div>
          <UserSearch onClick={(us) => changeUserConfirmation(us, true)} />
          <hr></hr>
          <div>Add the User if they don't..</div>
          {/* CREATE ACCOUNT FORM */}
          <div>Note to Osei, will be done on Monday!</div>
        </Dialog>
      )}
    </React.Fragment>
  );
}

export default AmbassadorAccess;
