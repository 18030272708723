import React from "react";
import PlatformContentContainer from "../../_generic/platformContentContainer";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LogoText from "./common/logoText";
import addonsConfig from "./common/addonsConfig";
import AddonsNavigation from "./common/navigation";
import { Button } from "primereact/button";
import { createPartnerActivity } from "../../../../services/partnerActivity";
import { useUser } from "../../../../contexts/UserContext";


function ISA() {

    const userContext = useUser();
    const user = userContext.user;

    async function goTo(link) {
        await createPartnerActivity("hargreavesLansdowne", "platformClick", user.email);
        window.open(link, '_blank');
    }
    return (
        <PlatformContentContainer className="platform_small p-mt-lg-4">
            <AddonsNavigation title="Lifetime ISA" />
            <div className="blueBox investor p-mb-3">
                <b>Why a Lifetime ISA?</b>
                <p>
                    The Lifetime ISA is a savings scheme that gives you an extra 25% boost to everything you save each year. 
                </p>
                <p>
                    The maximum top up you could get is £1,000 if you deposit £4,000.
                </p> 
                <p>
                    Here’s an example of how the bonus is calculated.
                </p>
            </div>

            <div>
                <DataTable value={addonsConfig.options.hargreavesL.table} className="table-rounded blue-table">
                    <Column field="deposit" header="Your deposit"></Column>
                    <Column style={{width:'35%'}} field="bonus" header="Goverment bonus"></Column>
                    <Column field="newTotal" header="New total*"></Column>
                </DataTable>
            </div>
            <p>
                Investments can go down as well as up in value, so you could get back less than you put in. 
            </p>
            <p>
                Your Lifetime ISA can only be used to purchase your first house or for your retirement at 60. If you use it for any other purpose you will lose your bonus.
            </p>
            <div className="divider p-mb-5 p-mt-5"></div>
            <LogoText logo={addonsConfig.options.hargreavesL.logo} title={addonsConfig.options.hargreavesL.logoTitle}/>
            <p>
                Hargreaves Lansdown are one of the UKs leading providers of Lifetime ISAs. Click below to learn more about ISAs or to open one today.
            </p>
            <Button 
                    label="Open a LifeTime ISA"
                    className="p-button-info p-button-raised p-mb-6 center-el"
                    onClick={() => goTo(addonsConfig.options.hargreavesL.button.link)}/>
        </PlatformContentContainer>
    );
}

export default ISA;