import React from "react";
import { withRouter } from "react-router";
import "primeflex/primeflex.css";
import { InputText } from "primereact/inputtext";
import _, { map } from "lodash";
import adminDashboardStyles from "../style/admin_dashboard.module.css";
import { searchUserByName } from "../services/users";
import utils from "../utils/utils";
import { Tooltip } from 'primereact/tooltip';

class page extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersearchvalue: "",
      usersearchrows: [],
      isLoading: false,
      users: []
    };

    this.searchForUser = this.searchForUser.bind(this);
    this.viewUser = this.viewUser.bind(this);

    this.search = _.debounce(async function (e) {
      if(e) {
        const data = await searchUserByName(e.trim());
        this.setState({ usersearchrows: data, isLoading: false });
      }
    }, 1000);
  }

  isLoggedIn() {
    if (!localStorage.getItem("adminLoggedIn")) {
      return false;
    } else {
      return true;
    }
  }

  searchForUser(value) {
    if(value) {
      this.setState({ isLoading: true });
      this.search(value);
    }
  }

  viewUser(id) {
    this.props.history.push("/adminPortal/user?id=" + id);
  }

  render() {
    return (
      <>

      <Tooltip target=".circ-tooltip" mouseTrack mouseTrackLeft={10} />
        <span
          className={
            "p-input-icon-left p-mt-3 " + adminDashboardStyles.adminSearchBar
          }
          style={{ display: this.isLoggedIn() ? "block" : "none" }}
        >
          <i
            className={
              !this.state.isLoading ? "pi pi-search" : "pi pi-spin pi-spinner"
            }
          />
          <InputText
            autoFocus
            className={adminDashboardStyles.adminSearchBar}
            value={this.state.value3}
            onChange={(e) => this.searchForUser(e.target.value)}
            placeholder="Search for a User"
          />
        </span>
        {this.state.usersearchrows.map((su, i) => (
          <div
          key={i}
          className={`${adminDashboardStyles.userSearchRow} animate__animated animate__fadeIn animate__fast`}
          onClick={(e) => {
            if (!this.props.onClick) {
              this.viewUser(su._id);
            } else {
              this.props.onClick(su);
            }
          }}
        >
          <div className="p-grid p-m-0">
          {i === 0 &&  <Tooltip target=".circle-tooltip" mouseTrack mouseTrackLeft={10} />}
            <span className="gray-text centerMiddle p-mr-2">{i+1}.</span>
            <div className="p-col">
              <div className={adminDashboardStyles.userSearchRowTitle}>
              {utils.getName(su)}
              </div>
              <div>{su.email}</div>
              <div className="p-text-secondary">{su.mobilephone}</div>
            </div>
            <div className="p-col">
              Circles: {su.circles.map((c, idx) => (
                <div 
                  key={idx}
                  data-pr-tooltip={`${utils.isCircleFinished(c) ? "circle finished" : ""}`}
                  className={`circ-tooltip p-badge p-badge-secondary badge-border p-mr-1 ${utils.isCircleFinished(c) ? "disabled circle-tooltip" : ""}`}>
                    {c.excelID}
                </div>))}
            </div>
            <div className="p-col-align-center gray-text min-width-200">
              Stage: <b>{su.stage}</b>
            </div>
          </div>
         
        </div>
        ))}
      </>
    );
  }
}

const UserSearch = withRouter(page);

export default UserSearch;
