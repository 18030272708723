import { useState } from "react";

import pageConfig from "./templates/pageConfig";
import {useUI} from '../../../../../../../contexts/UIContext';
import { useUser } from "../../../../../../../contexts/UserContext";
import { generateNewQuizArray } from "../../logic/scoreLogic";
import { useHistory } from "react-router";
import { getNextRoute } from "../../../../../../../utils/userRoutes";

function TakeQuizMain({setTitle, setIsFull, lastOutcome}){
    const pages = pageConfig;
    const [pageKey, setPageKey] = useState("frontPage");
    const [quizOutcome, setQuizOutcome] = useState(lastOutcome || "");
    const UI = useUI();
    const userContext = useUser();
    const user = userContext.user;
    const history = useHistory();

    const nextPage = (pageKey) => {
        setPageKey(pageKey);
    }

    const submitAnswers = async (answers) => {
        UI.showLoading();

        let quiz = generateNewQuizArray(user.quiz || [], answers);

        await userContext.updateUser({quiz});

        const outcome = quiz[quiz.length - 1].outcome;
        setQuizOutcome(outcome);

        if(outcome === "pass"){
            const {status: updateStageStatus} = await userContext.updateUser({nextStage: true});

            if(updateStageStatus !== 200){
                UI.showToast("There was an error, please try refreshing the page or contact us to fix it");
            }
            UI.hideLoading();
          
            history.push(getNextRoute(userContext.user));
        } else  if(outcome === "highFail"){
            setPageKey("education");
        } else {
            window.location.reload()
        }

        //submit answers
        UI.hideLoading();
    }

    const Component = pages[pageKey];

    return <Component 
        setTitle={setTitle} 
        setIsFull={setIsFull} 
        outcome={quizOutcome} 
        nextPage={nextPage} 
        onSubmit={submitAnswers} />
}


export default TakeQuizMain;