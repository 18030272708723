import { useEffect } from "react";
import PlatformContentContainer from "../_generic/platformContentContainer";
import { Button } from "primereact/button";
import { addMeta } from "../../../services/meta";
import { useHistory } from "react-router";
import { isHouseRelated } from "../../../utils/users";
import { useUser } from "../../../contexts/UserContext";

function PartnersViewChip() {
    const userContext = useUser();
    const history = useHistory();
    useEffect(() => {
        addMeta({drawDayPartnerChipSeen: true})
    }, []);

    function goTo(isPartner) {
        if (isPartner) {
            addMeta({drawDayPartnerChipClicked: "openChip"});
            const partnerUrl = "https://chip.sjv.io/5bWMeN";
            window.open(partnerUrl, '_blank').focus();
        }
        nextStep();
    }
    const nextStep = () => {
        const path = isHouseRelated(userContext.user?.reason_for_saving) ? "/dashboard/drawday/whatuserstage" : "/dashboard/drawday/lastSreens";
        history.push(path);
    }

    return (
        <div
            className={"drawDayStoryContainer drawday"}
            style={{
                backgroundImage: "url(./images/partners/hl-bg.jpg)",
                backgroundPosition: 'left 70% center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: "55"
            }} >
            <PlatformContentContainer className="platform_small p-mt-lg-4 p-pt-6 p-pb-6 p-pr-4 p-pl-4">
                <div className="p-mt-6 p-pt-6" >
                    <img
                        style={{ width: '150px', marginTop: `40%` }}
                        className="p-pt-3 center-img"
                        alt="logo"
                        src="./images/partners/chip.png"/>
                    <h3 className="text-shadow center p-mt-5 p-pt-5 p-text-center">Build your savings and get more from your money</h3>
                    <div className="p-mt-4 p-mb-3">
                    <Button
                        label={"Open a Savings account"}
                        className={`p-mt-4 button-st p-button-raised width-100 p-p-7`}
                        onClick={() => goTo(true)} />
                    <Button
                        label={"No thanks"}
                        className={`p-mt-4 p-button-text width-100 p-p-7 black`}
                        onClick={() => goTo(false)} />
                    </div>
                </div>
            </PlatformContentContainer>
        </div>
    );
}

export default PartnersViewChip;
