import { Button } from "primereact/button";
import { useUser } from "../../../../contexts/UserContext";

function PaymentsWelcome({ setSeenFinalStepWelcome }) {
  const userContext = useUser();
  const user = userContext.user;

  return (
    <div className="p-grid center">
      <div style={{ fontSize: "1.5em", fontWeight: "bold", marginTop: "1em" }}>
        Welcome to StepLadder {(<span className="p-st-green">{user.firstname}. </span>)}
        We are so excited to help you save money!
      </div>
      <div className="font-lg" style={{ fontWeight: "bold", marginTop: "1em" }}>
        We're delighted you've chosen StepLadder to support you on your journey towards {(<span className="p-st-green">{user.reason_for_saving}</span>)}.
        We've helped 1,000s of people like you gain confidence to manage their finances better.
        Now is the perfect time to take this step.
      </div>
      <div className="p-grid center" style={{ marginTop: "1em" }}>
        <img
          alt="people-stepping-up"
          src="images/helpers/people-step-up.png"
          style={{ width: "70%" }}
        ></img>
      </div>
      <div
        style={{
          backgroundImage: "", //url(./images/header-confetti-bg-white.png)",
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
        }}
      >

        <div className="font-lg f-weight400" style={{ marginTop: "1em" }}>
          The last thing we need are your payment details to setup your monthly contribution.
          Don't worry, every month we notify you prior to the payment date so you can make sure you have your savings ready for your Circle!
        </div>
        <Button
          label="Take the Final Step"
          className="p-button p-button-raised p-mt-5"
          style={{ marginTop: "1em", width: "100%" }}
          onClick={() => setSeenFinalStepWelcome(true)}
        />
      </div>
    </div>
  );
}

export default PaymentsWelcome;
