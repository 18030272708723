function PrivacyPolicy(){
    return <>
            By proceeding, you agree to our Privacy Policy and are aware of our Risk Policy. Learn more about our Risk Policy{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.joinstepladder.com/risk"
            >
              here
            </a>
            .
          </>
  }
  
  export default PrivacyPolicy;