import React from "react";
import InputError from "./inputError";

function VisibleSelect({formObject, onChange, value, showValidation}) {

  return (
    <React.Fragment>
      <label>{formObject.label}</label>
      <div className="visibleOptionContainer">
        {formObject.options.map((itm, i) => (
          <div
            key={i}
            onClick={() => onChange(itm)}
            style={{ fontWeight: "600" }}
            className={
              "visibleOption" + (itm === value ? " selected" : "")
            }
          >
            {itm}
          </div>
        ))}
      </div>

      <InputError showValidation={showValidation} value={value} formObject={formObject} />
    </React.Fragment>
  );
}

export default VisibleSelect;
