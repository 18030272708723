import moment from "moment";
import { nextChanceToTakeQuiz } from "./nextChanceTakeQuiz";

const passMark = 2;

function generateNewQuizArray(oldQuiz, answers){
    const score = getScore(answers);
    const dateTaken = moment.utc().format();
    const answersObject = transformAnswers(answers);

    const hasPassed = score >= passMark;

    let newQuizObject = {dateTaken, score, answers: answersObject, outcome: hasPassed ? "pass": "fail"};

    let newQuizArray = [...oldQuiz, newQuizObject];

    if(!hasPassed){
        const nextQuizDate = nextChanceToTakeQuiz(newQuizArray);

        if(nextQuizDate.isSame(moment.utc(), "day")){
            newQuizArray[newQuizArray.length - 1].outcome = "highFail";
        } else {
            newQuizArray[newQuizArray.length - 1].outcome = "lowFail";
        }
    }

    return [...oldQuiz, ...[newQuizObject]];
}

function getScore(answers){
    let score = 0;

    for(var key in answers){
        score += answers[key].score;
    }

    return score;
}

function transformAnswers(answers){
    let transformedAnswers = {};

    for(var key in answers){
        transformedAnswers[key] = answers[key].key;
    }

    return transformedAnswers;
}


export {generateNewQuizArray}