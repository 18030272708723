import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment-timezone";

function LifecycleCalculations(props) {

  const data = props.data;
  console.log(props.data);

  if (!data.length) {
    return (
      <div>
        There is no data to show, either because you have not uploaded a file or
        there is no data to show.
      </div>
    );
  }

  const startDate = moment.utc(moment(props.dateRange ? props.dateRange[0] : "").format("YYYY-MM-DD")).set({"hours": 0, "minutes": 0});
  const endDate = moment.utc(moment(props.dateRange ? props.dateRange[1] : "").format("YYYY-MM-DD")).set({"hours": 23, "minutes": 59});

  const getStage = (itm) => {
    const stage = itm.new_stage;

    if (!stage) {
      return null;
    }

    return moment.utc(itm.date).isSameOrAfter(startDate) &&  moment.utc(itm.date).isSameOrBefore(endDate) 
      ? stage
      : null;
  };

  const stages = [
    null,
    "Affordability",
    "CircleInvite",
    "Quiz",
    "InvestorClassification",
    "CoolOff",
    "Terms",
    "Payments",
    "Dashboard",
  ].map((itm) => {
    return {
      new_stage: itm ? (itm === "Dashboard" ? "Customers" : itm) : "No Change",
      total: data.filter((dataitm) => itm === getStage(dataitm)).length,
    };
  });

  return (
    <DataTable value={stages}>
      <Column field="new_stage" header="New Stage"></Column>
      <Column field="total" header="Totals"></Column>
    </DataTable>
  );
}

export default LifecycleCalculations;
