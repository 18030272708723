import EveryDayAndHighNetWorthDetails from "./common/everyDayAndHighNetWorthDetails";

const title = "High Net Worth Investor";
const trackBackId = "BackHNWI";

const QuickFacts = () => {
  return (
    <ul>
      <li>
        <span>
          I have an annual income of £100,000 or more during the year
          immediately preceding the date they sign this classification
        </span>
      </li>
      <li>
        <span>
          OR I have net assets of &pound;250,000 or more - not including
          primary residence, rights under an insurance contract, pension or
          termination benefits
        </span>
      </li>
    </ul>
  );
}

const Acceptance = () => {
  return (
    <>
      <p className="informationLabel">
      I accept that being a high-net-worth investor will expose me to promotions for investment where there is a significant risk of losing all the money I invest. I am aware that it is open to me to seek professional advice before making any investment in a high-risk investment.
      </p>
    </>
  );
}

const Details = (props) => 
  <EveryDayAndHighNetWorthDetails
    {...props}
    trackBackId={trackBackId}
    title={title}
  />

const highNetWorth = {
  title,
  type: title,
  QuickFacts,
  Acceptance,
  Details
};

export default highNetWorth;
