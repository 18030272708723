import BeforeYouStart from "../beforeYouStart";
import QuizEducation from "../education";
import FrontPage from "../frontPage";
import QuizQuestions from "../quizQuestions";

const pageConfig = {
    frontPage: FrontPage,
    beforeYouStart: BeforeYouStart,
    questions: QuizQuestions,
    education: QuizEducation
}

export default pageConfig;