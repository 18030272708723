import React, { useContext, createContext, useState } from "react";
import LoadingOverlay from "../components/generic/LoadingOverlay";
import ToastMessage from "../components/generic/ToastMessage";
import SmallLoadingOverlay from "../components/generic/SmallLoadingOverlay";

const UIContext = createContext();

export function UIContextProvider({ children }) {
  const [toastMessage, setToastMessage] = useState();
  const [isLoading, setIsLoading] = useState();
  const [isSmallLoading, setIsSmallLoading] = useState(false);

  const showToast = (message) => {
    setToastMessage({message, date: new Date()});
  };

  const showLoading = () => {
    setIsLoading(true);
  }

  const hideLoading = () => {
      setIsLoading(false);
  }

  const setSmallLoading = (value) => {
    setIsSmallLoading(value);
  }

  return (
    <UIContext.Provider
      value={{
        showToast,
        showLoading,
        hideLoading,
        setSmallLoading
      }}
    >
        <ToastMessage message={toastMessage} />
        {isLoading && <LoadingOverlay></LoadingOverlay>}
        {isSmallLoading && <SmallLoadingOverlay/>}
      {children}
    </UIContext.Provider>
  );
}


export function useUI() {
  const context = useContext(UIContext);
  if (context === undefined) {
    throw new Error("Context must be used within a Provider");
  }
  return context;
}