function CircleViewMainSkeleton ({isShow}) {
    if(!isShow) {
        return <></>
    }
    return <div className="skeleton">
        <div style={{height:"250px"}} />
        <div style={{marginTop:"30px", height:"50px"}} />
        <div style={{marginTop:"40px", height:"10px"}} />
        <div style={{marginTop:"40px", height:"10px"}} />
        <div style={{marginTop:"40px", height:"10px"}} />
        <div style={{marginTop:"40px", height:"10px"}} />
    </div>
  }


export default CircleViewMainSkeleton;