import React from "react";
import "primeflex/primeflex.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
class UserTimeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: false,
      editObject: {},
      value: "",
      userID: 0,
    };
  }

  render() {
    return (
      <React.Fragment>
        <DataTable
          sortField="event_date"
          sortOrder={-1}
          selectionMode="single"
          value={this.props.timeline}
        >
          <Column field="eventName" header="Name"></Column>
          <Column
            field="eventMeta"
            body={(row) => {
              console.log(row);
              return JSON.stringify(row.eventMeta);
            }}
            header="Value"
          ></Column>
          <Column field="event_date" sortable header="Date"></Column>
        </DataTable>
      </React.Fragment>
    );
  }
}

export default UserTimeline;
