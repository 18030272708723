import React, { useEffect, useState } from "react";
import { useUI } from "../../../contexts/UIContext";
import { useUser } from "../../../contexts/UserContext";
import { getQueryParams} from "../../../utils/generic";

import PlatformContentContainer from "../_generic/platformContentContainer";
import CircleFeedMain from "./circleFeed/CircleFeedMain";
import { CircleSelection } from "./CircleSelection";
import CircleViewMain from "./circleView/CircleViewMain";
import CircleRenewal from "./circleRenewal";
import AddonsMain from "./addons/addonsMain";

function CirclesMain() {
  const [circleSelected, setCircleSelected] = useState({});
  const [showCircleSelected, setShowCircleSelected] = useState(false);
  const [renewals, setRenewals] = useState([]);
 

  const userContext = useUser();
  const UI = useUI();
  const circles = userContext.user.circles;

  const showCircle = (circle) => {
    setCircleSelected(circle);
    setShowCircleSelected(true);
  };

  useEffect(() => {
    setRenewals(circles.renewals);
    const params = getQueryParams();
    // renewalFromMagicLink
    if(params && params.showCircle){
      const circleToShow = circles.approved.find(circ => circ._id === params.showCircle);
      
      if(!circleToShow){
        UI.showToast("We couldn't load your Circle, please contact us if it continues to be an issue");
        return;
      }

      setCircleSelected(circleToShow);
      setShowCircleSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  
  return (
    <React.Fragment>
      <PlatformContentContainer>
        <h2 className="capitalize">Hi, {userContext.user.firstname}</h2>
        <AddonsMain circles={circles.approved}/>
        {/* <Highlights /> */}
        <CircleSelection
          circles={circles.approved}
          onClick={(circle) => showCircle(circle)}
        />
        <CircleFeedMain circles={circles}/>
      </PlatformContentContainer>
      {showCircleSelected && (
        <CircleViewMain
          circle={circleSelected}
          onClose={() => setShowCircleSelected(false)}
        />
      )}
      { renewals && (renewals.map((circle, i) => 
        <CircleRenewal circle={circle} fetchUser={userContext.fetchUser} />
      ))}

    </React.Fragment>
  );
}

export default CirclesMain;
