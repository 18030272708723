import { InputText } from "primereact/inputtext";
import { useEffect } from "react";
import { useState } from "react";
import TextInput from "../../../generic/forms/components/textInput";

function PaymentsUploadID(props) {
  const [selectText, setSelectText] = useState("");
  const [base64Image, setBase64Image] = useState("");
  const [uploadError, setUploadError] = useState("");

  useEffect(() => {
    props.onChange(base64Image);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [base64Image]);

  const imageSelected = async (e) => {
    try {
      const file = e.target.files[0];

      //Check size of file and file type-----------
      const filesizeinmb = file.size / 1024 / 1024;

      if (filesizeinmb > 5) {
        setUploadError("size");
        resetInputFileValues();
        return;
      }

      if (!file.name.match(/.(jpg|jpeg|png)$/i)) {
        setUploadError("type");
        resetInputFileValues();
        return;
      }

      const base64 = await toBase64(file);
      setSelectText(file.name);
      setBase64Image(base64.split(",")[1]);
    } catch (err) {
      setUploadError("generic");
      resetInputFileValues();
      return;
    }
  };

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const resetInputFileValues = () => {
    setSelectText("");
    setBase64Image("");
  };

  return (
    <div
      className="form"
      style={{
        marginBottom: "-15px",
      }}
      
    >
      <div className="formComponent" style={{ position: "relative", width:window.innerWidth < 600 ? "100%":"93%" }}>
        <TextInput
          formObject={{
            type: "text",
            label: "📁 Passport or Driving License",
          }}
          value={selectText}
          readonly={true}
        />
        <InputText
          type="file"
          style={{
            opacity: "0",
            cursor: "pointer",
            height: "100%",
            width: "100%",
            position: "absolute",
            top: "0",
            left: "0",
          }}
          onChange={(e) => imageSelected(e)}
          accept="image/*"
        />
        <div style={{marginTop:"10px", fontSize:"14px"}}>Please ensure your file is an image and is under 5mb in size.</div>
      </div>

      {uploadError !== "" && (
        <div className="inputError">
          {uploadError === "type" && (
            <span>
              The file that you have uploaded is not in the right format, please
              only use images that are in .png, .jpeg, .jpg format.
            </span>
          )}

          {uploadError === "size" && (
            <span>
              The file you have uploaded is too big, please try uploading a
              different image that is smaller or{" "}
              <a href="https://www.joinstepladder.com/contact/">contact us</a>{" "}
              to send us the document if you have no other option.
            </span>
          )}

          {uploadError === "generic" && (
            <span>
              There was an error in uploading your file. Please try using a
              different file, or{" "}
              <a href="https://www.joinstepladder.com/contact/">contact us</a>{" "}
              to send it in via email.
            </span>
          )}
        </div>
      )}
    </div>
  );
}

export default PaymentsUploadID;
