import moment from "moment";

function getCorrectCircleDates(requestedCircle){

    if(!requestedCircle){
        return null;
    }

    const circleStart = moment.utc(requestedCircle.startingDate);
    const today = moment.utc();

    if(circleStart.isAfter(today, "month")){
        return requestedCircle;
    }

    const startingDate = moment().add(1, 'months').set('date', 1).toDate();
    const endingDate = moment().add(1 + requestedCircle.period, 'months').set('date', 1).toDate();

    return {startingDate, endingDate, ...requestedCircle};
}

export {
    getCorrectCircleDates
}