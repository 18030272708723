
import PaymentsTestimonial from "../PaymentsTestimonial";

const paymentInputs = (paymentInfo, userContext) => {
  let inputs = [
    { key: "accountNumber", value: "", img: "./images/directdebit-long.png" },
    { key: "sortCode", value: "" },
    {
      key: "billing_address",
      value: true,
      label: "Billing address is same as " + userContext.user.zip,
    },
    {
      key: "firstLineOfAddress",
      value: userContext.user.address_line_1,
      showCondition: function (values) {
        return !values.find((itm) => itm.key === "billing_address").value;
      },
    },
    {
      key: "zip",
      value: userContext.user.zip,
      showCondition: function (values) {
        return !values.find((itm) => itm.key === "billing_address").value;
      },
    },
    
  ];

  if(paymentInfo){
    inputs.push(
      {
        key: "customComponent",
        component: paymentInfo
      });
  }

  inputs.push({key:"customComponent", component: PaymentsTestimonial})

  return inputs;
}

  export default paymentInputs;