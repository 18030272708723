import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useUser } from "../../../contexts/UserContext";
import {
  circleProfiles,
  getSpecificParticipant,
  hasDrawn,
  hasMissedPayments,
  isMemberDropout,
} from "../../../utils/circles";
import { isMobile } from "../../../utils/generic";

import { getCircleDurationRemaining } from "../_generic/platformUtils";

function CircleSelection(props) {

  const circles = props.circles ? props.circles : [];

  const history = useHistory();

  return (
    <div style={{borderTop:"1px solid #bebebe", borderBottom:"1px solid #bebebe", marginTop:"50px", marginBottom:"50px", paddingBottom:"40px"}}>
      <div className="platformTitle" onClick={() => history.push("/circleInvite")} style={{textAlign: !isMobile ? "center":"left", marginBottom: !isMobile ? "40px" : "20px"}}>YOUR CIRCLES <i className="fas fa-plus-circle"></i></div>
      <div className="circleCarousel" style={props.style || {}}>
        <div className="add" onClick={() => history.push("/circleInvite")}>
          <i className="fas fa-plus"></i>
        </div>

        {circles.map((circle, i) => (
          <CircleBox
            key={i}
            data={circle}
            onClick={() => props.onClick(circle)}
          />
        ))}

        {circles.length < 2 && <CircleBoxPlaceholder />}
      </div>
    </div>
  );
}

function CircleBox(props) {
  const [width, setWidth] = useState(0);
  const userContext = useUser();
  const circle = props.data;

  useEffect(() => {
    let timer = setTimeout(() => {
      const percentageWidth =
        (1 - getCircleDurationRemaining(circle).percentage) * 100;
      setWidth(
        (!percentageWidth || percentageWidth < 5 ? 5 : percentageWidth) + "%"
      );
    }, 800);
    return () => {
      clearTimeout(timer);
  };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const myParts = getSpecificParticipant(userContext.user, circle);
  const spotLength = myParts.length;

  const statuses = [
    {
      criteria: function () {
        return isMemberDropout(userContext.user, circle);
      },
      color: "#ff9090",
      text: "DROPPED OUT",
    },
    {
      criteria: function () {
        return hasMissedPayments(userContext.user, circle);
      },
      color: "#ff9090",
      text: "MISSED PAYMENTS",
    },
    {
      criteria: function () {
        return hasDrawn(userContext.user, circle);
      },
      color: "#8cc58c",
      text: "🎉 DRAWN",
    },
  ];

  const status = statuses.find((itm) => itm.criteria());

  const circleProfile =
    myParts[0] && myParts[0].circle_profile
      ? circleProfiles[myParts[0].circle_profile]
      : circleProfiles[0];

  return (
    <div
      className="circleCarouselBox coverimage"
      onClick={() => (props.onClick ? props.onClick() : false)}
      style={{
        backgroundImage:
          "url(./images/circleProfiles/" + circleProfile.img + ")",
      }}
    >
      {circle.ambassador && (
        <div
          className="ambassadorCirclePic coverimage"
          style={{
            backgroundImage: "url(" + circle.ambassador.circleImage + ")",
          }}
        ></div>
      )}
      {status && (
        <div
          className="carouselBoxStatus"
          style={{ backgroundColor: status.color }}
        >
          {status.text}
        </div>
      )}

      <div className="carouselBoxContent">
        <div>{circleProfile.short}</div>
        <div className="raiseAmount">
          Raise £{(circle.monthly_investment * circle.period).toLocaleString()}
          {spotLength > 1 && <span> | {spotLength} spots</span>}
        </div>
        <div className="carouselBoxLength">
          <div
            className="transition"
            style={{
              width: width,
            }}
          ></div>
        </div>
      </div>
    </div>
  );
}

function CircleBoxPlaceholder() {
  const history = useHistory();

  return (
    <div
      className="circleCarouselBox coverimage"
      onClick={() => history.push("/circleInvite")}
      style={{
        backgroundImage: "url(./images/circleProfiles/placeholder.jpg)",
      }}
    >
      <div className="carouselBoxContent">
        <div>New Circles Available</div>
        <div className="raiseAmount">Circles start from £25</div>
      </div>
    </div>
  );
}

export default CircleBoxPlaceholder;

export { CircleSelection, CircleBox };
