import { Button } from "primereact/button";
import { useState, useEffect } from "react";
import { useUser } from "../../../../contexts/UserContext";
import PlatformContentContainer from "../../../platform/_generic/platformContentContainer";
import { addMeta } from "../../../../services/meta";
import InfoSlab from "../../../generic/InfoSlab";
import content from "./content";
import { useHistory } from "react-router";

function HelperInfo() {
    const {getTheMost, goodToKnow, reasons, partners} = content;
    const [curentContent, setCurentContent] = useState(getTheMost);
    const userContext = useUser();
    const history = useHistory();
    const goodToKnowView = "goodToKnow";

    useEffect(() => {
        addMeta({getTheMostShown: true})
    }, []);

    const goTo = () => {
        if(curentContent.next === goodToKnowView) {
            setCurentContent(goodToKnow);
            addMeta({goodToKnowShown: true})
        } else{
            const partner = getPartner();
            if(!partner) {
              history.push("/ApplicationCompleteBigAmount");
            } else {
              history.push({pathname: "/ApplicationCompletePartners", state: {partner}})
            }
        }
    }

    const getPartner = () => {
        const reason = userContext.user.reason_for_saving;
        const fmc = userContext.user.minimumTest;
        if(reason === reasons.else || reason === reasons.business) {
          return partners.snoop;
        } else if(fmc <= 25) {
          if(reason === reasons.debt) {
            return partners.snoop;
          }
          return partners.checkmyfile;
        } else if(fmc <= 250) {
          if(reason === reasons.debt) {
            return partners.snoop;
          }
          if(reason === reasons.credit) {
            return partners.checkmyfile;
          }
          return partners.hl;
        } else { // * more than 250
          if(reason === reasons.debt || reason === reasons.credit) {
            return null; // we are showing bundle here
          }
          return partners.proportunity;
        }
      }
    
    return (
        <div className={"drawDayStoryContainer drawday"} 
                style={{backgroundImage: "url(./images/drawday/drawday-bg.png)",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: "55"
        }} >
            <PlatformContentContainer className="platform_small p-mt-6 p-pb-6 ">
                <div className="shortTitle">
                    <h1 className="p-mt-6">{curentContent.title}</h1>
                </div>
                <div>
                    {curentContent.slabs.map((itm, key) => (
                        <InfoSlab key={key} data={itm} variant={1}/>
                    ))}
                </div>
                <div>
                    <Button
                        label={`Next`}
                        className="p-mt-5 p-button-raised p-button-stepladder width-100"
                        onClick={() => {goTo()}} />
                </div>
            </PlatformContentContainer>
        </div>
    );
}


export default HelperInfo;
