import React from "react";
import ReactDOM from "react-dom";


import {
  BrowserRouter as Router,
} from "react-router-dom";

import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "./components/styles/global.css";


import AdminPortal from "./components/admin/adminPortal";
import UserContainer from "./components/users/UserContainer.js";
import { getURLParam } from "./components/users/utils/users";


const startingURL = window.location.pathname.split("/")[1];
const env = process.env.REACT_APP_ENV;


Storage.prototype.setObj = function (key, obj) {
  return this.setItem(key, JSON.stringify(obj));
};

Storage.prototype.getObj = function (key) {
  try {
    return JSON.parse(this.getItem(key));
  } catch {
    return null;
  }
};

  //added code expires in 45 days for production and 5 mins for test
  if(getURLParam("code")){
    let myDate = new Date();
    if(env.includes("test")) {
      myDate.setMinutes(myDate.getMinutes() + 5)
    } else {
      myDate.setDate(myDate.getDate() + 45);
    }
    const domain = env === "testdev" ? "" : "domain=.joinstepladder.com;"

    document.cookie = 'referralCode=' + getURLParam("code") +
    ';expires=' + myDate + domain + ";path=/";
  }


const ComponentToReturn =
  startingURL !== "adminPortal" ? (
    UserContainer
  ) : (
    AdminPortal
  );

ReactDOM.render(
  <Router>
    <ComponentToReturn />
  </Router>,
  document.getElementById("root")
);

