import { useState } from "react";
import InsuranceBox from "./InsuranceBox";
import { RadioButton } from "primereact/radiobutton";
import InsuranceQuizPage from "./InsuranceQuizPage";

function InsuranceQuizQuestion({ title, description, onNext, onChange }) {
  const [selected, setSelected] = useState("");

  const changeval = (val) => {
    setSelected(val);
    onChange(val);
  };
  return (
    <InsuranceQuizPage onClick={() => {
        if(selected === ""){
            alert("Please select an option!");
            return;
        }

        onNext();
    }}>
      <InsuranceBox>
        <div style={{ color: "grey", fontWeight: 600 }}>{description}</div>
        <div style={{ fontSize: "22px" }}>{title}</div>
      </InsuranceBox>
      <div
        className="field-radiobutton"
        onClick={() => changeval("Yes")}
        style={{
          padding: "20px",
          marginBottom: "15px",
          marginTop: "35px",
          border: "1px solid #bebebe",
          borderRadius: "10px",
          cursor:"pointer"
        }}
      >
        <RadioButton
          value="Yes"
          name="Yes"
          onChange={(e) => {
            changeval(e.value);
          }}
          inputId="yes"
          checked={selected === "Yes"}
        />
        <label htmlFor="yes" style={{ fontWeight:"700", marginLeft: "10px", fontSize: "20px" }}>
          Yes
        </label>
      </div>
      <div
        className="field-radiobutton"
        onClick={() => changeval("No")}
        style={{
          padding: "20px",
          fontSize: "20px",
          marginBottom: "15px",
          marginTop: "15px",
          cursor:"pointer",
          border: "1px solid #bebebe",
          borderRadius: "10px",
        }}
      >
        <RadioButton
          value="No"
          name="No"
          onChange={(e) => {
            setSelected(e.value);
            onChange(e.value);
          }}
          inputId="no"
          checked={selected === "No"}
        />
        <label htmlFor="no" style={{ fontWeight:"700", marginLeft: "10px" }}>
          No
        </label>
      </div>
    </InsuranceQuizPage>
  );
}

export default InsuranceQuizQuestion;
