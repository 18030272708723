import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { currencyFormat, isMobile } from "../../../utils/generic";
import { Calendar } from "primereact/calendar";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { requestNewCircle } from "../../../services/ambassadors";
import { useUI } from "../../../contexts/UIContext";

function NewCircleForm(props) {
  const UI = useUI();

  const [startDate, setStartDate] = useState(
    moment().add(1, "month").format("mm/yy")
  );
  const [period, setPeriod] = useState(10);
  const [monthlyInvestment, setMonthlyInvestment] = useState(100);
  const [notes, setNotes] = useState("");

  const submitForm = async () => {
    UI.showLoading();
    const { status } = await requestNewCircle({
      monthlyInvestment,
      period,
      notes,
      startDate,
    });
    UI.hideLoading();
    console.log(status);

    if (status !== 200) {
      UI.showToast(
        "There was an error with submitting the details, please contact us to resolve your query"
      );
      return;
    }

    UI.showToast(
        "Submission successful! We usually reply within a couple of hours, but please allow up to 2 working days."
      );
    props.onHide();
    
  };

  return (
    <Dialog
      header={"New Circle"}
      visible={true}
      isMobile
      style={{ width: isMobile ? "90vw" : "50vw" }}
      onHide={() => props.onHide()}
    >
      <NewCircleFormInput label="Start Date">
        <Calendar
          value={startDate}
          onChange={(e) => setStartDate(e.value)}
          view="month"
          dateFormat="mm/yy"
          yearNavigator
          yearRange="2021:2022"
        />
      </NewCircleFormInput>

      <NewCircleFormInput label="Circle Length / Number of Members">
        <InputText
          type="number"
          value={period}
          onChange={(e) => setPeriod(e.target.value)}
        />
      </NewCircleFormInput>

      <NewCircleFormInput label="Monthly Investment">
        <InputText
          type="number"
          value={monthlyInvestment}
          onChange={(e) => setMonthlyInvestment(e.target.value)}
        />
      </NewCircleFormInput>

      <hr />

      <NewCircleFormInput label="Draw Total">
        <InputText
          value={currencyFormat(monthlyInvestment * period)}
          readOnly={true}
        />
      </NewCircleFormInput>

      <NewCircleFormInput label="1st Draw Date">
        <InputText
          value={
            "7th " +
            moment
              .utc(startDate)
              .add(period < 100 ? 2 : 1)
              .format("MMMM")
          }
          readOnly={true}
        />
      </NewCircleFormInput>

      <NewCircleFormInput label="Referral Fee">
        <InputText value={"£25"} readOnly={true} />
      </NewCircleFormInput>

      <NewCircleFormInput label="Circle Type">
        <InputText
          value={monthlyInvestment > 100 ? "Home Circle" : "First Steps"}
          readOnly={true}
        />
        {monthlyInvestment > 100 && (
          <div style={{fontSize:"13px", marginTop:"15px"}}>
            Whilst the Circle is running, your members won't be able to get the
            drawn amount back into their account immediately unless its to a
            solicitor for buying a home. Once the Circle has ended, we can
            release the money back to member's accounts. This happens for all
            Circles with a monthly investment of more than £100.
          </div>
        )}
      </NewCircleFormInput>

      <hr />

      <NewCircleFormInput label="Special Requests/Notes">
        <InputTextarea
          value={notes}
          onChange={(e) => setNotes(e.target.value)}
        />
      </NewCircleFormInput>

      <Button label="Submit" onClick={() => submitForm()} />
    </Dialog>
  );
}

function NewCircleFormInput(props) {
  const label = props.label;

  return (
    <div style={{display:"inline-block", marginRight:"15px"}}>
      <div className="label">{label}</div>
      <div className="readonlylisten">{props.children}</div>
    </div>
  );
}

export default NewCircleForm;
