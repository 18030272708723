import React, {useState, useEffect, useRef} from "react";
import { useUser } from "../../../contexts/UserContext";
import PlatformContentContainer from "../_generic/platformContentContainer";
import {options} from "./config";
import GifPlayer from "react-gif-player";
import { addMeta } from "../../../services/meta";

function SpinningWheel({seenResult, hasWon}) {
  const userContext = useUser();
  const name = userContext.user.firstname;
  const [title, setTitle] = useState("Spin the StepLadder Circle to see if it’s your turn this month...")
  const [text, setText] = useState(""); 
  const [spinningWheelResult, setSpinningWheelResult] = useState("./images/drawday/spinning-wheel.gif"); 
  let timer = null;
  let timer2 = null;
  // let spinningWheelResult = "./images/drawday/spinning-wheel.gif";
  const inputRef = useRef(null)

  useEffect(() => {
    return () => {
        clearTimeout(timer);
        clearTimeout(timer2);
    };
}, [timer, timer2]);

  function SpinnerClick() {
    addMeta({ drawDayVisit: hasWon ? 'DrawnThisMonth' : 'NotDrawnThisMonth' });
    if(hasWon) {
      showResult(["Congratulations", "You’ve been drawn!"])
    } else {
      showResult(["We’re sorry", "You haven’t drawn this month"])
    }
    const spinningWheelRes = hasWon ? "./images/drawday/spinning-wheel-drawn.gif" : "./images/drawday/spinning-wheel-not-drawn.gif";
    setSpinningWheelResult(spinningWheelRes)
  }

  const showResult = ([text1, text2]) => {
    timer = setTimeout(() => {
      setTitle(text1)
      setText(text2)
    }, 1 * 1100);
    timer2 = setTimeout(() => seenResult(), 8 * 1000);
  }

  return (
    <div 
        className={"drawDayStoryContainer scrollable drawday" } 
        style={{backgroundImage: "url(" + options["notdrawn"].bg + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: "55"
        }} >
        <PlatformContentContainer
          noChildrenDiv={true}
          className="platform_small p-mt-lg-4 p-pt-6 p-pr-5 p-pl-5 height-100">
            <div className="p-pt-6 height-100 p-pb-0 p-grid p-m-0">
              <div className="p-pt-6 p-mt-1 p-text-center p-align-end show-desktop width-100">

                <h1 className="center p-text-center show-desktop p-col-12 p-pt-0">
                  It’s Draw Day
                </h1>
                <div className="height-100 p-pb-5 p-grid p-m-0 p-align-center">
                  <h2 className="center p-mt-1 p-text-center show-desktop p-col-12 p-pt-0">
                    {title}
                  </h2>
                  {text && (<><h2 className="center-el p-mt-0 p-st-purple show-desktop p-col-12 p-p-0">
                    {name}
                  </h2>
                  <h2 className="center-el p-mt-0 show-desktop p-col-12 p-pt-0">
                    {text}
                  </h2></>)}
                </div>
               
              </div>
                

                <div className="p-align-end p-grid p-p-0">
                    <GifPlayer
                      ref={inputRef}
                      onClick={() => setTimeout(() => SpinnerClick(), 3 * 1000)}
                      gif={spinningWheelResult}
                       />
                  </div>
            </div>
        </PlatformContentContainer>
    </div>
  );
}

export default SpinningWheel;
