import { useEffect } from "react";
import PlatformContentContainer from "../_generic/platformContentContainer";
import { Button } from "primereact/button";
import { addMeta } from "../../../services/meta";
import { useHistory } from "react-router";
import { useUser } from "../../../contexts/UserContext";

function PartnersViewHargreaves() {
    const userContext = useUser();
    const history = useHistory();

    useEffect(() => {
        addMeta({ drawDayPartnerSeenHargreaves: true })
    }, []);

    function goTo(isPartner) {
        addMeta({ drawDayPartnerClickedHargreaves: isPartner ? "openISA" : false });
        if (isPartner) {
            const partnerUrl = "http://hl.7eer.net/0JKe0V";
            window.open(partnerUrl, '_blank').focus();
        }

        history.push("/dashboard/drawday/lastSreens");
    }

    return (
        <div
            className={"drawDayStoryContainer scrollable drawday"}
            style={{
                backgroundImage: "url(./images/partners/hl-bg.jpg)",
                backgroundPosition: 'left 60% center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: "55"
            }} >
            <PlatformContentContainer className="platform_small"
                noChildrenDiv={true}>
                <div className="p-m-0 p-grid center" style={{ paddingTop: '30vh', justifyContent: "center" }}>
                    <div className="p-grid" style={{ flexDirection: "row-reverse", }}>
                        <div className="p-grid text-shadow p-text-center main-color font-lg p-pt-5"
                            style={{ flexDirection: "column", fontWeight: "700", width: '50%', }}>
                            Make more from your money, <span className="p-text-center text-white">{userContext.user?.firstname}</span>
                        </div>
                    </div>
                    <div className="p-grid center" style={{ paddingTop: '2vh' }}>
                        <img
                            style={{ paddingTop: '5vh', width: '90%', }}
                            className="p-pl-3 p-pr-3 p-pb-3 center-img"
                            alt="logo"
                            src="./images/partners/logo-hargreaves.png" />
                        <Button
                            label={"Open an ISA"}
                            className={`p-button font-sm center`}
                            style={{ fontWeight: "500", width: '90%', }}
                            onClick={() => goTo(true)} />
                        <Button
                            label={"No thanks"}
                            className={`p-button-text font-sm main-color`}
                            style={{ textDecoration: 'underline', width: '90%', }}
                            onClick={() => goTo(false)} />
                    </div>
                    <div className="center p-grid p-pl-5 p-pr-5"
                        style={{ paddingTop: '5vh' }} >
                        <div className="p-text-center font-md main-color"
                            style={{ fontWeight: '700', paddingBottom: '2vh' }} >
                            Don't miss your chance for the tax-free perks of an ISA
                        </div>
                        <div className="center p-text-center font-sm width-100 main-color">
                            Some of our trusted partners pay us a commission should you choose to use them.
                        </div>
                    </div>
                </div>
            </PlatformContentContainer>
        </div>
    );
}

export default PartnersViewHargreaves;
