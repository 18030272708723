import React from "react";
import { withRouter } from "react-router";
import "primeflex/primeflex.css";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import adminDashboardStyles from "../../style/admin_dashboard.module.css";
import { Button } from "primereact/button";
import API from "../../services/api";
import ViewAmbassadors from "../adminAmbassadors/viewAmbassadors";
import AmbassadorService from "../../services/ambassadorService";

class page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      monthly_amount: 100,
      admin_fee: 5,
      participants: 10,
      startingDate: "",
      reserve_period: 1,
      visibility: { name: "Hidden", code: false },
      ambassador: null,
      ambassadors: [],
      ambassadorNameSelected: "",
      selectAmbassadorVisible: false,
    };
  }

  async componentDidMount() {
    if (!this.state.ambassadors?.length > 0) {
      const { data: foundAmbassadors } = await AmbassadorService.getAmbassadorsList2();
      this.setState({ ambassadors: foundAmbassadors });
      }
  }

  showAmbassadorSelection() {
    this.setState({ selectAmbassadorVisible: true });
  }

  async submitCircle() {
    if (this.state.startingDate === "") {
      return this.props.showToast("Enter a valid starting date", "error");
    }

    if (this.state.visibility == null) {
      return this.props.showToast(
        "Select whether the Circle is avaialable",
        "error"
      );
    }

    this.props.showLoading();

    var circleObject = {
      monthly_investment: this.state.monthly_amount,
      admin_fee: this.state.admin_fee,
      period: this.state.participants,
      availability: this.state.ambassador ? this.state.visibility.code : true,
      reserve_period: this.state.reserve_period,
      startingDate:
        moment(this.state.startingDate).format("YYYY-MM-DDTHH:mm:ss") + "Z",
    };

    if (this.state.ambassador) {
      circleObject.ambassador = this.state.ambassador;
    }

    var submitCircle = await API.call({
      url: "/circles/addCircle2",
      method: "POST",
      body: {
        params: {
          circle: circleObject,
        },
      },
    });

    this.props.hideLoading();

    if (submitCircle.circleAdded) {
      this.props.showToast("Circle added!", "success");
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      this.props.showToast("Circle not added, reason: " + submitCircle.error);
    }
  }

  render() {
    return (
      <React.Fragment>
        <Dialog
          header={"Select an Ambassador To Add"}
          visible={this.state.selectAmbassadorVisible}
          style={{ width: "50vw" }}
          onHide={() => this.setState({ selectAmbassadorVisible: false })}
        >
          <ViewAmbassadors
            ambassadorsList={this.state.ambassadors}
            onSelectionChange={(row) => {
              this.setState({
                ambassador: row._id,
                ambassadorNameSelected: row.ambassadorName,
                selectAmbassadorVisible: false,
              });
            }}
          ></ViewAmbassadors>
        </Dialog>
        <Dialog
          header={"Add Circle"}
          visible={this.props.visible}
          style={{ width: "70vw" }}
          onHide={() => this.props.onHide()}
        >
          <div className={adminDashboardStyles.modalInputBox}>
            <div>Start Date</div>
            <Calendar
              value={this.state.startingDate}
              onChange={(e) => this.setState({ startingDate: e.value })}
              view="month"
              dateFormat="mm/yy"
              yearNavigator
              yearRange="2010:2030"
            />
          </div>
          <div className={adminDashboardStyles.modalInputBox}>
            <div>Monthly Amount</div>
            <InputText
              type="number"
              value={this.state.monthly_amount}
              onChange={(e) => {
                this.setState({ monthly_amount: e.target.value });
              }}
            ></InputText>
          </div>
          <div className={adminDashboardStyles.modalInputBox}>
            <div>Participants/Months</div>
            <InputText
              type="number"
              value={this.state.participants}
              onChange={(e) => {
                this.setState({ participants: e.target.value });
              }}
            ></InputText>
          </div>
          <div className={adminDashboardStyles.modalInputBox}>
            <div>Total</div>
            <InputText
              type="number"
              value={this.state.participants * this.state.monthly_amount}
              disabled={true}
            ></InputText>
          </div>
          <div className={adminDashboardStyles.modalInputBox}>
            <div>Admin Fee</div>
            <InputText
              type="number"
              value={this.state.admin_fee}
              onChange={(e) => {
                this.setState({ admin_fee: e.target.value });
              }}
            ></InputText>
          </div>
          <div className={adminDashboardStyles.modalInputBox}>
            <div>Reserve Period</div>
            <InputText
              type="number"
              value={this.state.reserve_period}
              onChange={(e) => {
                this.setState({ reserve_period: e.target.value });
              }}
            ></InputText>
          </div>

          {this.state.ambassador && <div className={adminDashboardStyles.modalInputBox}>
            <div>Visible to select in platform?</div>
            <SelectButton
              optionLabel="name"
              value={this.state.visibility}
              options={[
                { name: "Visible", code: true },
                { name: "Hidden", code: false },
              ]}
              onChange={(e) => this.setState({ visibility: e.value })}
            ></SelectButton>
          </div>}
          <div className={adminDashboardStyles.modalInputBox}>
            <div
              style={{ cursor: "pointer" }}
              onClick={(e) => {
                this.setState({ ambassador: null, ambassadorNameSelected: "" });
              }}
            >
              Ambassador{" "}
              {this.state.ambassador && (
                <i className="pi pi-times-circle" style={{ color: "red" }}></i>
              )}
            </div>
            <InputText
              onClick={(e) => {
                this.showAmbassadorSelection();
              }}
              style={{ cursor: "pointer" }}
              value={this.state.ambassadorNameSelected}
              readOnly
            ></InputText>
          </div>
          <div>
            <Button
              onClick={(e) => {
                this.submitCircle();
              }}
              label="Submit"
            ></Button>
          </div>
        </Dialog>
      </React.Fragment>
    );
  }
}

var AddCircle = withRouter(page);

export default AddCircle;
