
import { Redirect} from "react-router-dom";
import { useUser } from "../../../contexts/UserContext";
import { getNextRoute } from "../../../utils/userRoutes";

function DecideNextAction(){

    const userContext = useUser();

    return <Redirect to={getNextRoute(userContext.user)} />
}

export default DecideNextAction;