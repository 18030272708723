import moment from "moment-timezone";
import {reasonsForSavingValues} from "../../../../../common/services/userHelpers";
import { phoneMask2Number, requiredValue } from "../../../../utils/generic";


const FormConfig = {
    email: {
      label: "Email Address",
      validate: function (email) {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/; //eslint-disable-line 
        return re.test(String(email.trim()).toLowerCase());
      },
      validationMessage: "Enter a valid email address",
      inputType:"email"
    },
    firstname: {
      label: "First Name",
      validate: function (fullName) {
        return fullName.length > 1;
      },
      validationMessage: "Please enter your first name",
    },
    lastname: {
      label: "Last Name",
      validate: function (fullName) {
        return fullName.length > 1;
      },
      validationMessage: "Please enter your last name",
    },
    mobilephone: {
      label: "Phone Number",
      validate: function (mobileNumber) {
        mobileNumber = phoneMask2Number(mobileNumber);
        return mobileNumber === "123456789" || mobileNumber === "441234567890" ||
          (/^\d+$/.test(mobileNumber) && mobileNumber.length === phoneMask2Number(this.mask).length && /^(\(?(0|44)[1-9]{1}\d{1,4}?\)?\s?\d{3,4}\s?\d{3,4})$/.test(mobileNumber));
      },
      validationMessage: "Please enter a valid phone number",
      inputType: "number",
      mask: "+44 (0) 999 999 9999"
    },
    accountNumber: {
      label: "Account Number",
      validate: function (accountNumber) {
        return (
          (accountNumber.length === 7 || accountNumber.length === 8) &&
          !isNaN(accountNumber)
        );
      },
      optional: true,
      validationMessage:
        "Please enter a valid account number with no spaces or hyphens",
    },
    sortCode: {
      label: "Sort Code",
      validate: function (sortCode) {
        return sortCode.length === 6 && !isNaN(sortCode);
      },
      optional: true,
      validationMessage:
        "Please enter a valid sort code with no spaces or hyphens",
    },
    postcode: {
      label: "Postcode",
      validate: function (postcode) {
        return /^([A-Za-z][A-Ha-hJ-Yj-y]?[0-9][A-Za-z0-9]? ?[0-9][A-Za-z]{2}|[Gg][Ii][Rr] ?0[Aa]{2})$/.test(
          postcode.trim()
        );
      },
      validationMessage: "Please enter a valid postcode",
    },
    joint_application: {
      label: "Joint Application?",
      type: "toggle",
      tooltip: "If there's more than one of you applying, you can sign up with the additional applicant."
    },
    billing_address: {
      label: "Billing address is the same as my address",
      type: "toggle"
    },
    dependentNumbers: {
      label: "No. Of Dependents",
      validate: function (dnumber) {
        return /^-?\d+$/.test(dnumber) && Number(dnumber) >= 0;
      },
      options: ["0", "1", "2", "3", "4", "5", "6", "7", "8"],
      inputType: "select",
      validationMessage: "Please select an option",
    },
    firstLineOfAddress: {
      label: "First Line Of Address",
      validate: function (firstLine) {
        return !(!firstLine || firstLine.length === 0 || !firstLine.trim());
      },
      validationMessage: "Please enter a valid first line of address",
    },
    town: {
      label: "City/Town",
      validate: function (city) {
        return !(!city || city.length === 0 || !city.trim());
      },
      validationMessage: "Please enter a valid city/town name",
    },
    referralCode: {
      label: "Referral code",
    },
    referralToggle: {
      label: "Referral Code?",
      type: "toggle"
    },
    debt_question: {
      min: 0,
      max:100000,
      step: 500,
      slider: true,
      inputType: "number",
      label: "Total Debt",
      tooltip: "Total outstanding debts (e.g. credit cards, loans, card finance but NOT including student debt)",
      validationMessage: "This field is mandatory",
      validate: requiredValue,
    },
    zip: {
      validate: function (value) {
        return true;
      },
      label: "Postcode",
      validationMessage: "",
    },
    dob: {
      label:"Date of Birth",
      validate: function (value) {

        if(value === "" || value.split("").filter(letter => letter !== "/" && letter.match(/[a-z]/i)).length > 0){
          return false;
        }

        const now = moment();
        const birthDate = moment(value,'DD/MM/YYYY');
        const yearDiff = now.diff(birthDate, "years");
        const age = Math.floor(yearDiff);
        return age >= 18 && age < 105;
      },
      mask:"dd/mm/yyyy",
      validationMessage:"Please enter a valid date of birth"
      
    },
    applicantSalary: {
      label: "Yearly Salary",
      min: 0,
      max: 150000,
      step: 500,
      inputType:"number",
      slider: true,
      tooltip: "Include all income, even if you have multiple jobs.",
      validationMessage: "This field is mandatory",
      validate: requiredValue,
    },
    monthlyRent: {
      label: "Monthly Rent",
      min: 0,
      max: 3500,
      step: 50,
      slider: true,
      inputType: "number",
      validationMessage: "This field is mandatory",
      validate: requiredValue,
      tooltip: "If you already own a mortgage, use your monthly payments"
    },
    householdExpenses: {
      label: "Monthly Household Expenses",
      min: 0,
      max: 3500,
      step: 50,
      default: 200,
      slider: true,
      inputType: "number",
      tooltip: "Include your phone, broadband, leisure, transport and any other regular bills. Don't include your rent.",
      validationMessage: "This field is mandatory",
      validate: requiredValue,
    },
    savingsAndAssets: {
      label: "Savings and Assets (including large value items, e.g. your car)",
      min: 0,
      max: 500000,
      step: 500,
      default: 0,
      slider: true,
      inputType: "number",
      tooltip: "Include your ISAs, investments, other savings, your car(s) and other large value items. Don’t include your home or pension.",
      validationMessage: "This field is mandatory",
      validate: requiredValue,
    },
    investments: {
      label: "How frequently do you make investments?",
      options: ["Never", "Once or Twice", "Occasionally", "Frequently"],
      validationMessage: "Please select how frequently you make investments.",
      validate: function (value) {
        return value && this.options.indexOf(value) > -1;
      },
      type: "visibleSelect"
    },
    marital_status: {
      label: "Marital Status",
      options: [
        "Married",
        "Cohabiting",
        "Single",
        "Common Law",
        "Separated",
        "Divorced",
        "Widowed",
      ],
      inputType:"select",
      validationMessage: "Please select your marital status",
      validate: function (value) {
        return value && this.options.indexOf(value) > -1;
      },
    },
    education: {
      label: "What is your highest level of education?",
      options: ["GCSEs", "A Levels", "University"],
      validationMessage: "Please select your education level.",
      validate: function (value) {
        return value && this.options.indexOf(value) > -1;
      },
      type: "visibleSelect"
    },
    reason_for_saving: {
      label: "",
      options: reasonsForSavingValues,
      validationMessage: "Please select your financial goals",
      validate: function (value) {
        return value && this.options.indexOf(value) > -1;
      },
      type: "visibleSelect"
    },
    reason_for_saving_other: {
      label: "What is your reason for saving with us?"
    },
    yes: {
      label: "",
      options: ["Yes"],
      validate: function (value) {
        return !!value;
      },
      validationMessage: "Please select an answer.",
      type: "visibleSelect"
    },
    financial_services: {
      label: "Have you ever worked in the financial services industry?",
      options: ["Yes", "No"],
      validationMessage: "Please select an answer to the question above.",
      validate: function (value) {
        return value && this.options.indexOf(value) > -1;
      },
      type: "visibleSelect"
    },
    financial_services_how_long: {
      label:
        "How long have you, or did you, work in the financial services industry?",
      options: ["Over 5 Years", "Under 5 Years"],
      validationMessage: "Please select an answer.",
      validate: function (value) {
        return value && this.options.indexOf(value) > -1;
      },
      type: "visibleSelect"
    },
    iva: {
      label:
        "Have you ever experienced Individual Voluntary Arrangement (IVA), County Court Judgment (CCJ), repossession, or bankruptcy?",
      options: ["Yes", "No"],
      validate: function (value) {
        return value && this.options.indexOf(value) > -1;
      },
      validationMessage: "Please select an answer to this question.",
      type: "visibleSelect"
    },
    peertopeer: {
      label: "Have you ever used a peer to peer loan before?",
      options: ["Never", "Once or Twice", "Occasionally", "Frequently"],
      validate: function (value) {
        return value && this.options.indexOf(value) > -1;
      },
      validationMessage:
        "Please select whether you have used a peer to peer loan before",
        type: "visibleSelect"
    },
    instagramHandle: {
      label:"(Optional) What's your Instagram handle?",
      validationMessage:"",
      validate: function(value) {
        return true;
      }
    },
    englishFirstLanguage: {
      label: "Is English your first language?",
      options: ["Yes", "No"],
      type: "visibleSelect",
      validate: function(value){
        return value && this.options.indexOf(value) > -1;
      },
      validationMessage:
        "Please select whether English is your first language",
    },
    heardOfRoscas: {
      label: "Do you recognise this way of saving which is used all over the world?",
      options: ["Susu", "Committee", "Ajo", "Chit Fund", "Paluwagan", "Tontine", "Gameya", "Don't know",],
      type: "visibleSelect",
    },
  };
  
  export default FormConfig;
  