
import React, { useState } from "react";
import { ToggleButton } from 'primereact/togglebutton';
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import Addresses from "./addresses";
import InputError from "../inputError";
import FormConfig from "../formConfig";


function FillAddress ({list, onChange, showValidation}) {
    const [enterManuallyLocal, setEnterManually] = useState(false);
    const [showAddresses, setShowAddresses] = useState(false);
    const getInput = (key) => {
      return list && list.find(it => it.key === key) ? list.find(it => it.key === key) : {value: "", valid: false};
    }
    const postCodeInput = getInput("postcode");

    const isValid = (itm) => {
      const formObject = FormConfig[itm.key];
      if (
        formObject &&
        !formObject.optional &&
        formObject.validate &&
        !formObject.validate(itm.value) 
      ) {
        return false;
      }

      return true;
    };
    
    const setEnterManuallyChange = ({value}) => {
      setEnterManually(value);
      onChange({enterManually: value})
    }
    
    const inValidValues = () => {
      const isValidAddress = isValid(getInput("firstLineOfAddress"));
      const isValidTown = isValid(getInput("town"));
      return showValidation && (!isValidAddress || !isValidTown);
    }

    return <div>
      <span className="p-grid p-mb-1">
        <div className="p-col-6">
          <Button
            label={"Find Address"}
            disabled={enterManuallyLocal || !postCodeInput.value.length || !postCodeInput.valid}
            onClick={() => setShowAddresses(true)}></Button>
        </div>
        <div className="p-col-6">
          <ToggleButton
            className="toggle-cancel fit-text"
            onLabel="Cancel"
            offLabel="Enter manually"
            checked={enterManuallyLocal}
            onChange={setEnterManuallyChange} />
        </div>
        <div className="p-ml-2 p-mb-2">
          <InputError showValidation={showValidation} value={!inValidValues()} formObject={{validationMessage: "Please enter full address", validate: (value) => (!!value)}} />
        </div>
      </span>

      <Dialog
          className="no-padding-dialog"
          header={`Select your address for ${postCodeInput.value} postcode`}
          visible={showAddresses}
          style={{ width: "90vw", maxHeight: "600px", maxWidth: "600px" }}
          onHide={() => setShowAddresses(false)}
        >
          <Addresses postCode={postCodeInput.value} selectAddress={(address) => {onChange({address}); setShowAddresses(false)}}></Addresses>
        </Dialog>
    </div>
  }

  export default FillAddress;