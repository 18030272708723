import API from "./api";

const route = "/ambassadors";

const AmbassadorService = {
  async getAmbassadorsList2() {
    return API.call2(`${route}/list`, {
        method: "GET",
      });
    },
    
    async getAmbassadorsList() {
      return this.api({
        url: "/list",
        method: "GET",
      });
    },

    async createAmbassador(ambassador){
      return this.api({
        url: "",
        method: "POST",
        body: {
          params: {
            ambassador,
          },
        },
      });
    },

    async updateAmbassador(ambassador){
        return this.api({
            url: "",
            method: "PUT",
            body: {
            params: {
                ambassador: ambassador,
            },
            },
        });
    },

    async getAmbassadorByName(ambassadorName){
        return this.api({
            method: "GET",
            url: "?name=" + ambassadorName,
        });
    },

  async api(params) {
    return API.call({
      url: route + params.url,
      method: params.method,
      body: params.body,
    });
  }
};

export default AmbassadorService;