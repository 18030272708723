import React, { useEffect, useState } from "react";
import { Card } from 'primereact/card';
import { getFullVendors } from '../../../services/vouchers';
import VouchersRequestList from './vouchersReqestList';

function VouchersView({showLoading, hideLoading}) {

    const [vendors, setVendors] = useState(null);
    const [balance, setBalance] = useState(null);
    const [requestedVouchers, setRequestedVouchers] = useState(null);

    const testLogoMapper = {
        TEST03GBP: 'argos',
        TEST04EUR: 'john_lewis',
        TEST01GBP: 'ikea',
    };

    // const requestCard = async () => {
    //     const {status, data} = await requestGiftCards();
    // };

    useEffect(() => {
        async function loadVouchers() {
            showLoading();
            const data = await getFullVendors();
            console.log(data);
            setBalance(data.balance);
            setVendors(data.vendors);
            setRequestedVouchers(data.requestedVouchers)
            hideLoading();
        }
        if(!vendors) {
            loadVouchers();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },[]);
    
    return (
        <React.Fragment>
            <Card className="title-card card-rounded">
                <h1 className="center p-mb-3">Vendors Info</h1>
                <div className="p-grid p-m-0">
                    <h2 className="center-el p-mb-2"><span className="gray-text">Balance</span> £{balance}</h2>
                    {vendors && vendors.map((vendor, i) => (
                        <div key={i} className="p-grid p-m-1 width-100 vendor-info">
                            <div className="p-col-2 center">
                                <img
                                    className="center-img brand-img"
                                    style={{borderRadius: "4px", height: "50px", width: "80px"}}
                                    alt="tada"
                                    src={vendor.vendorLogo || `images/vouchers/${testLogoMapper[vendor.vendorCode]}.png`}>
                                </img>
                            </div>
                            <div className="p-grid p-m-0 p-col">
                                <div className="vendor-name center-el">
                                    Vendor <span>{vendor.name}</span>
                                </div>
                                <div className="vendor-name center-el">
                                    Vendor code: <span>{vendor.vendorCode}</span>
                                </div>
                            </div>
                            
                            <div className="p-col p-grid">
                                {/* Vouchers:  */}
                                {vendor.vouchers.map(voucher => (
                                    <div className="vendor-name p-col-6">
                                        <span>{voucher.value} {voucher.currency}</span>  Count: <span>{voucher.count || "∞"}</span>
                                    </div>
                            ))}</div>
                        </div>
                    ))}
                </div>
            </Card>
            <h1 className="center p-mb-3">Requested Vouchers</h1>
            <VouchersRequestList requestedVouchers={requestedVouchers}/>
        </React.Fragment>);
}

export default VouchersView;