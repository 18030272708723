import { Button } from "primereact/button";
import { TabPanel, TabView } from "primereact/tabview";
import { useState } from "react";
import CenterMiddle from "../../../../generic/CenterMiddle";

function QuizEducationMain(props) {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <CenterMiddle>
      <h2>Learn About StepLadder</h2>
      <div className="headerDescription">
        Before we let you retake the quiz, watch the video and read the content.
      </div>

      <TabView
        onTabChange={(e) => {
          setActiveTab(e.index);
        }}
        activeIndex={activeTab}
      >
        <TabPanel header="Video Tutorial">
          <video
            style={{
              width: "100%",
              height: "auto",
              borderRadius: "1em",
            }}
            controls
          >
            <source src="https://f.hubspotusercontent20.net/hubfs/2442073/Quiz%20Video/QuizIntro.mp4?t=1606993261356"></source>
          </video>
        </TabPanel>
        <TabPanel header="Read Content">
          <div>
            <h4>How do Circles work?</h4>
            <ul>
              <li>
                <strong>
                  StepLadder Circles are based on peer-to-peer lending
                </strong>
                , which means that we match people together in groups to lend
                and borrow from each so that you can work together to reach your
                financial goals. The most you’ll ever loan any other Member is
                one month's contribution.{" "}
              </li>
              <li>
                <strong>
                  Each month somebody is awarded the total money from the Circle
                  at random.
                </strong>{" "}
                <strong>
                  Once you’ve been Drawn you will need to continue making
                  payments until the end of the Circle.
                </strong>{" "}
                Joining a StepLadder Circle is different to depositing money
                with a bank because your money is not guaranteed by the
                Financial Services Compensation Scheme (FSCS)
              </li>
            </ul>
          </div>
          <div>
            <h4>What happens if I drop out?</h4>
            <ul>
              <li>
                <strong>
                  We understand that life changes, so we’ve built in lots of
                  flexibility.
                </strong>{" "}
                If you haven’t used the money from your Circle you can Drop Out
                and won’t need to make any further payments and we will return
                any money you’ve paid in to date at the end of the Circle.{" "}
              </li>
              <li>
                <strong>
                  We only release the funds from your StepLadder Circle for the
                  stated purpose of your Circle
                </strong>
                , so if you’re joining a Home Circle that means while your
                Circle is live we will only release the funds to your solicitor
                to use as a property deposit.{" "}
              </li>
            </ul>
          </div>
          <div>
            <h4>What happens if StepLadder went out of business?</h4>
            <ul>
              <li>
                We have wind-down arrangements that would be triggered. These
                arrangements are designed to ensure that your funds will be
                returned as you expected, but they are not a guarantee.
              </li>
              <li>
                If StepLadder went out of business, the team at More Lending
                Solutions (who in Financial Services speak are known as the
                Principal, which basically means they provide us with our
                regulated status) would take over the management of the Circles
                under the same terms you have entered into with Stepladder.
              </li>
              <li>
                And if they went out of business, your Circle would be suspended
                until we could arrange another Principal. If we couldn’t, your
                Circle would be wound down and this may affect the timing and
                amount of contributions returned to you. This means your capital
                is at risk.
              </li>
            </ul>
          </div>
          <div>
            <h4>What else should I know about StepLadder?</h4>
            <ul>
              <li>
                <strong>
                  We’re an appointed representative of More Lending Solutions
                  Limited
                </strong>{" "}
                who are Authorised and regulated by the Financial Conduct
                Authority.You can find us on the FCA register (our firm
                reference number is 783003).
              </li>
              <li>
                If you’re joining a Home Circle to raise a property deposit then
                we can help by connecting you to a mortgage broker, solicitor
                and other service providers to help you on your journey.{" "}
                <strong>
                  Just so you know, a mortgage broker will need to undertake
                  their own creditworthiness and affordability check and
                  StepLadder does not provide mortgages.
                </strong>
              </li>
            </ul>
          </div>
        </TabPanel>
      </TabView>

      <Button style={{marginTop:"80px"}} label="Next" onClick={() => props.onComplete()}></Button>
    </CenterMiddle>
  );
}

export default QuizEducationMain;
