import moment from "moment";
import CenterMiddle from "../../../../generic/CenterMiddle";

function WaitQuizMain(props) {
  const lastTaken = props.lastTaken;
  const daysToWait = props.daysToWait;

  const nextTake = props.nextTake ? moment(props.nextTake): moment(lastTaken)
    .startOf("day")
    .add(daysToWait, "days");

    let daysLeft = nextTake.diff(moment(), "days", false) + 1;


  return (
    <CenterMiddle>
      <h2>We’re really sorry, you haven’t passed the quiz.</h2>
      <div style={{ marginTop: "30px" }}>
        <ul className="waitQuizBullets">
          <li>Your quiz will be reset in <b>{daysLeft} day{daysLeft > 1? "s":""}</b>.</li>
          <li>
            You’ll be sent email reminders, and can retake on <b>{nextTake.format("Do MMMM")}</b>!
          </li>
          <li>We can’t wait to welcome you back!</li>
          <li>
            <a href="https://www.joinstepladder.com/contact/">Contact us</a> if
            you have any questions.
          </li>
        </ul>
      </div>
    </CenterMiddle>
  );
}

export default WaitQuizMain;
