import { useEffect } from "react";

function CenterMiddle(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    const layout = document.getElementsByClassName("innerLayoutContainer");

    if(layout.length){
      layout[0].scrollTo(0, 0);
    }
    
    document.getElementById("root").scrollTo(0, 0);
  }, []);

  return (
    <div className={"animate__animated animate__fadeIn centerMiddle " + (props.className || "")}>
      <div>{props.children}</div>
    </div>
  );
}

export default CenterMiddle;
