import { ProgressSpinner } from "primereact/progressspinner";
function InlineLoading() {
  return (
    <div style={{ textAlign: "center" }}>
      <ProgressSpinner />
    </div>
  );
}

export default InlineLoading;
