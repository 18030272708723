import moment from "moment-timezone";
import React, { useState } from "react";
import { useUser } from "../../../../../contexts/UserContext";

import {
  getSpecificParticipant,
  hasMissedPayments,
} from "../../../../../utils/circles";
import { currencyFormat } from "../../../../../utils/generic";
import PlatformTitle from "../../../_generic/platformTitle";

function CirclePaymentsMain(props) {
  const userContext = useUser();
  const user = userContext.user;
  const showPaymentFailures = !user.disableAutomaticPayments;

  const circle = props.circle;

  const participants = getSpecificParticipant(user, circle);

  const [selectedIndex, setSelectedIndex] = useState(0);

  const hasUserMissedPayments = hasMissedPayments(user, circle);

  const selectedPart = participants[selectedIndex];

  const payIns = selectedPart && selectedPart.payments ? selectedPart.payments.filter(paym => paym.Type === "PAYIN") : [];

  const statuses = {
    SUCCEEDED: {
      color:"var(--stepladder-green)",
      icon: "fas fa-shield-alt"
    },
    FAILED: {
      color:"red",
      icon: "fas fa-times"
    },
    CREATED: {
      color: "orange",
      icon:"fas fa-stopwatch"
    }
  };

  return (
    <React.Fragment>
      {participants.length > 1 && (
        <React.Fragment>
          <PlatformTitle title="Multiple Spots"></PlatformTitle>
          <div>
            You have {participants.length} spots in this Circle. Select a spot
            to see your payments for that spot.
          </div>
          <div className="spotSelection">
            {participants.map((part, i) => (
              <div
                onClick={() => setSelectedIndex(i)}
                className={i === selectedIndex ? "active" : ""}
              >
                {"Spot " + (i + 1)}
              </div>
            ))}
          </div>
        </React.Fragment>
      )}

      {!showPaymentFailures && (
        <React.Fragment>
          <PlatformTitle title="Custom Payment Arrangement"></PlatformTitle>
          <div>
            Hey there, you have a custom payment arrangement with us. If you
            have any questions, please contact us.
          </div>
        </React.Fragment>
      )}

      <PlatformTitle title="Payments"></PlatformTitle>
      {showPaymentFailures && (
        <React.Fragment>
          {!hasUserMissedPayments ? (
            <div>Your payments are up to date.</div>
          ) : (
            <div style={{ color: "red" }}>
              Your payments are not up to date, please{" "}
              <a
                href="mailto:membership@step-ladder-solutions.com?subject=Missed%20Payment&body=Hi%20Membership%20Team%2C%0D%0A%0D%0APlease%20could%20we%20arrange%20to%20take%20the%20payment%20out%20again%20as%20it%20says%20it's%20failed%20on%20the%20platform.%0D%0A%0D%0AThanks!"
                rel="noopener noreferrer"
                target="_blank"
              >
                contact us here
              </a>{" "}
              to arrange a payment.
            </div>
          )}

          {!hasUserMissedPayments && !payIns.length && (
            <div className="customerInfoBox">
              Once your Circle gets started, we'll show you all your payments
              here. On the 1st of every month, we'll take a direct debit payment of £
              {circle.monthly_investment.toLocaleString()}.
            </div>
          )}
        </React.Fragment>
      )}

      <div className="paymentList">
        {payIns && payIns.sort((a, b) =>  moment(b.ExecutionDate || b.CreationDate).format('X')-moment(a.ExecutionDate || a.CreationDate).format('X')).map((itm, i) => {
          const status = statuses[itm.Status] ? statuses[itm.Status] : {icon: "fas fa-credit-card", color:"grey"};

          return (
            <div key={i}>
              <div>
                <i style={{color: status.color}} className={status.icon}></i>
              </div>
              <div>
                <div className="number">{currencyFormat(itm.Funds / 100)}</div>
                <div className="date">
                  {moment(itm.ExecutionDate || itm.CreationDate).format("Do MMM")}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default CirclePaymentsMain;
