import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { useUI } from "../../../contexts/UIContext";
import { useUser } from "../../../contexts/UserContext";
import { getNextRoute } from "../../../utils/userRoutes";
import CenterMiddle from "../../generic/CenterMiddle";
import Form from "../../generic/forms/Form";

function JointApplication() {
  const UI = useUI();
  const userContext = useUser();
  const history = useHistory();

  useEffect(() => {
    UI.hideLoading();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const submitValues = async (values) => {
    try {
      //TRANSFORM
      const updateObject = {
        date_of_birth_applicant_2: values.dob,
        applicant_salary_applicant_2: values.applicantSalary,
        f_have_you_ever_experienced_iva_ccj_repossession_or_bankruptcy_applicant_2:
          values.iva,
        firstname_applicant_2: values.firstname,
        lastname_applicant_2: values.lastname,
      };

      //SUBMIT
      UI.showLoading();
      const { status } = await userContext.updateUser(updateObject);

      if (status !== 200) {
        throw new Error("update user details failed");
      }

      const { status: updateStageStatus } = await userContext.updateUser({
        nextStage: true,
      });

      if (updateStageStatus !== 200) {
        throw new Error("update stage failed");
      }

      history.push(getNextRoute(userContext.user));
      UI.hideLoading();
    } catch (err) {
      UI.hideLoading();
      UI.showToast(
        "There was an error in submitting your details, please contact us to fix it."
      );
    }
  };

  return (
    <CenterMiddle>
      <h2>Joint Application</h2>
      <div className="headerDescription">
        Please enter the details of the second applicant.
      </div>

      <Form
        inputs={[
          { key: "firstname", value: "" },
          { key: "lastname", value: "" },
          { key: "dob", value: "" },
          { key: "applicantSalary", value: 25000 },
          { key: "iva", value: "" },
        ]}
        onSubmit={(values) => submitValues(values)}
      />
    </CenterMiddle>
  );
}

export default JointApplication;
