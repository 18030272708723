import React, {useEffect} from "react";
import { useUI } from "../../../contexts/UIContext";
import CenterMiddle from "../../generic/CenterMiddle";
import Form from "../../generic/forms/Form";
import { useUser } from "../../../contexts/UserContext";
import { useHistory } from "react-router";
import { getNextRoute } from "../../../utils/userRoutes";

function PersonalExperience({setTitle, setSubTitle}) {

  const UI = useUI();
  const userContext = useUser();
  const history = useHistory();


  useEffect(() => {
    setTitle("Your Details");
    setSubTitle(
    <>
      Personal {">"} Goals {">"} Finance {">"} <b>Experience</b>
    </>);
  }, []);

  const submitValues = async (values) => {

    let updateObject = {
      f_have_you_ever_experienced_iva_ccj_repossession_or_bankruptcy_: values.iva,
      have_you_ever_used_a_peer_to_peer_loan_before_: values.peertopeer,
      have_you_ever_worked_in_the_financial_services_industry_: values.financial_services,
      education_type: values.education,
      how_frequently_do_you_make_investments_: values.investments,
    };

    if(values.heardOfRoscas !== ""){
      updateObject.heardOfRoscas = values.heardOfRoscas;
    }

    if(values.financial_services_how_long){
      updateObject.how_long_have_you_or_did_you_work_in_the_financial_services_industry_ = values.financial_services_how_long;
    }

    UI.showLoading();
    const {status: updateStatus} = await userContext.updateUser(updateObject);

    if(updateStatus !== 200){
      UI.showToast("We couldn't submit your details, please contact us to fix it");
      UI.hideLoading();
      return;
    }

    if(userContext.user.joint_application){
      history.push(getNextRoute(userContext.user));
      return;
    }

    const {status: nextStageStatus} = await userContext.updateUser({nextStage: true});
    UI.hideLoading();

    if(nextStageStatus !== 200){
      UI.showToast("We couldn't take you to the next stage of the application. Contact us to fix it.");
      UI.hideLoading();
      return;
    }

    history.push(getNextRoute(userContext.user));

  };

  return (
    <>
      <Form
        inputs={[
          
          { key: "education", value: "" },
          { key: "financial_services", value: "" },
          { key: "financial_services_how_long", value: "", showCondition: function(allValues){
            return allValues.find(itm => itm.key === "financial_services").value === "Yes";
          }},
          { key: "investments", value: "" },
          { key: "peertopeer", value: "" },
          { key: "iva", value: "" },
          {key: "heardOfRoscas", value: ""}
        ]}
        onSubmit={(values) => {
            submitValues(values);
        }}
      />
      
    </>
  );
}

export default PersonalExperience;
