import { Button } from "primereact/button";
import { isMobile } from "../../../../../utils/generic";

function InsuranceQuizPage({ children, onClick }) {
  return (
    <div style={{paddingBottom:"120px"}}>
      {children}
      <div
        style={{
          position: isMobile ? "fixed" : "relative",
          bottom: 0,
          width: "100%",
          padding: isMobile ? "30px" : "30px 0px",
          left: 0,
        }}
      >
        <Button style={{ padding: isMobile ? "10px" : "10px" }} label="Next" onClick={onClick} />
      </div>
    </div>
  );
}

export default InsuranceQuizPage;
