import { useEffect } from "react";
import PlatformContentContainer from "../_generic/platformContentContainer";
import { Button } from "primereact/button";
import { addMeta } from "../../../services/meta";
import { useHistory } from "react-router";
import { useUser } from "../../../contexts/UserContext";

function PartnersViewFairfax() {
    const userContext = useUser();
    const history = useHistory();

    useEffect(() => {
        addMeta({ drawDayPartnerSeenFairfax: true })
    }, []);

    function goTo(isPartner) {
        addMeta({ drawDayPartnerClickedFairfax: isPartner ? "openFairfax" : false });
        if (isPartner) {
            const partnerUrl = "https://calendly.com/andrew-fairfaxfinancial";
            window.open(partnerUrl, '_blank').focus();
        }

        history.push("/dashboard/drawday/lastSreens");
    }

    return (
        <div
            className={"drawDayStoryContainer scrollable drawday"}
            style={{
                backgroundImage: "url(./images/partners/bg-young-woman-moving-new-home-1.jpg)",
                backgroundPosition: 'left 70% center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: "55"
            }} >
            <PlatformContentContainer className="p-grid center"
                noChildrenDiv={true}>
                <div className="p-pl-3 p-pr-3 p-pb-0 p-m-0 p-grid center"
                    style={{ paddingTop: '30vh', }} >
                    <div className="p-grid center">
                        <div className="text-shadow p-text-center font-xlg f-weight700 p-pl-3 p-pr-3"
                            style={{ width: '75%', }} >
                            Make sure you've got the best mortgage deal, {userContext.user?.firstname}
                        </div>
                    </div>
                    <div className="p-pl-5 p-pr-5 p-pt-5 p-grid center"
                        style={{ paddingBottom: '3vh' }}>
                        <img
                            style={{ width: '300px', paddingTop: '3vh' }}
                            className="p-pl-3 p-pr-3 p-pb-3 center-img"
                            alt="logo"
                            src="./images/partners/logo-fairfax.png" />
                        <Button
                            label={"Speak to our recommended broker"}
                            className={`p-button font-sm bg-stepladder-green main-color`}
                            style={{ border: "none", width: "80%" }}
                            onClick={() => goTo(true)} />
                        <Button
                            label={"No thanks"}
                            className={`p-button-text text-shadow width-100 p-p-2 font-sm text-white`}
                            style={{ textDecoration: 'underline' }}
                            onClick={() => goTo(false)} />
                    </div>
                    <div className="p-align-end p-pl-5 p-pr-5 p-grid center"
                        style={{ paddingTop: '3vh' }} >
                        <div className="text-shadow center p-text-center font-md f-weight700"
                            style={{ paddingBottom: '3vh', }} >
                            Speak to our recommended partner, Andrew, at Fairfax Financial for independent advice
                        </div>
                        <div className="center p-text-center width-100 font-sm">
                            Some of our trusted partners pay us a commission should you choose to use them.
                        </div>
                    </div>
                </div>
            </PlatformContentContainer>
        </div>
    );
}

export default PartnersViewFairfax;
