import React from "react";
import { Route, Switch, withRouter } from "react-router";
import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import "primeflex/primeflex.css";
import "./style/platform.css";

import LeftNav from "./components/leftNav";
import { ProgressSpinner } from "primereact/progressspinner";
import { Toast } from "primereact/toast";

import AdminPageWrapper from "./components/pageWrapper";
import AdminRoutes from "./adminRoutes";

class page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      title: null,
      subTitle: null
    };

    this.showLoading = this.showLoading.bind(this);
    this.hideLoading = this.hideLoading.bind(this);
    this.showToast = this.showToast.bind(this);
    this.setTitle = this.setTitle.bind(this);
    this.setSubTitle = this.setSubTitle.bind(this);
  }

  showLoading() {
    this.setState({ isLoading: true });
  }

  setSubTitle(subTitle) {
    this.setState({ subTitle });
  }
  
  setTitle(title) {
    this.setState({ title });
  }

  hideLoading() {
    this.setState({ isLoading: false });
  }

  showToast(textstring, severity = "info", life=3000) {
    this.toast.show({
      severity: severity,
      summary: "Info",
      detail: textstring,
      life,
    });
  }

  render() {
    return (
      <div className="p-grid p-m-0" style={{height:"100%", maxWidth: "100%", flexWrap: "nowrap" }}>
        <Toast ref={(el) => (this.toast = el)}></Toast>
        {this.state.isLoading && (
          <div className="spinner-wrapper">
            <ProgressSpinner
              className="progress-spinner"
              strokeWidth="2"
              fill="#EEEEEE"
              animationDuration=".5s"
            />
          </div>
        )}
        <LeftNav></LeftNav>
        <AdminPageWrapper 
          title={this.state.title}
          subTitle={this.state.subTitle}
          showToast={this.showToast}>
          <Switch>
              {AdminRoutes.map((route, index) => (
                <Route
                  key={index}
                  path={route.path}
                  exact={route.exact}
                  children={
                    <route.children
                      setTitle={this.setTitle}
                      title={this.state.title}
                      setSubTitle={this.setSubTitle}
                      subTitle={this.state.subTitle}
                      showLoading={this.showLoading}
                      hideLoading={this.hideLoading}
                      showToast={this.showToast}
                    />
                  }
                />
              ))}
            </Switch>
        </AdminPageWrapper>
      </div>
    );
  }
}

const AdminPortal = withRouter(page);

export default AdminPortal;
