
import {userPicture, userName} from "./chatHelper";
import moment from "moment";

function MessageView({chat}){
    const getDateString = (date) => {
        const chatDate = date ? moment(date) : moment();
    
        if(chatDate.isSame(moment(), "day")){
          return chatDate.format("HH:mm");
        }
    
        return chatDate.format("Do MMM");
    }

    return (
        <div className="blueBox p-mt-1 p-d-flex p-p-3">
                <div className="p-mr-2 p-as-stretch">{userPicture(chat)}</div>
                <div className="p-mr-2 p-as-stretch width-100">
                    <div className="p-text-bold font-sm">{userName(chat)}</div>
                    <div>{chat.message}</div>
                </div>
                
                <div className="p-mr-2 p-as-start p-text-italic width-6em font-sm">{getDateString(chat.dateSubmitted)}</div>
            </div>
    )
}

export default MessageView;