import { Button } from "primereact/button";
import { useEffect } from "react";
import { useUser } from "../../../contexts/UserContext";
import PlatformContentContainer from "../../platform/_generic/platformContentContainer";
import { addMeta } from "../../../services/meta";
import { partners } from "./appCompletePartnersConfig";
import { createPartnerActivity } from "../../../services/partnerActivity";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

function ApplicationCompletePartners() {
    const history = useHistory();
    const userContext = useUser();
    const location = useLocation();
    const user = userContext.user;
    const partnerName = location.state ? location.state.partner : "checkmyfile";
    const partner = partners[partnerName]; //proportunity, checkmyfile, hl, snoop

    useEffect(() => {
        addMeta({partnerPopUpShown: true})
    }, []);

    const goTo = async () => {
        await createPartnerActivity(partner.button.trackName, "platformClick", user.email);
        window.open(partner.button.link + user.email, '_blank');
        addMeta({partnerPopUpClick: partner.button.trackName})
        finish()
    }

    const finish = () => {
        history.push("/applicationComplete")
    }
    
    return (
        <div className={`drawDayStoryContainer partner-popup ${partnerName}`} 
                style={{background: `url(./images/partners/${partnerName}-bg.jpg)`,
        }} >
            <img className="partners-header" src="./images/header_circles.png" alt="header_circles"></img>
            <PlatformContentContainer className="platform_small p-mt-lg-4 p-pb-6 desktop-card">
                <div className="p-pt-2">
                    <div className="p-grid p-m-0">
                        <div className="p-col p-offset-6 p-md-offset-0">
                            <h3 className="p-mt-6 p-pt-6 text-center p-mb-0 bigger">{`${partner.title}`}
                            </h3>
                            <h3 className="text-center p-mt-0 text-white bigger">{user.firstname}</h3>
                        </div>
                    </div>
                </div>
                <div className="padding-top-md">
                    <img
                        style={{ width: '170px' }}
                        className="p-pt-3 center-img"
                        alt="logo"
                        src={partner.logo}></img>
                </div>
                <div>
                    <Button
                        label={partner.button.name}
                        className="p-mt-5 p-button-raised p-button-stepladder width-100 max-width-400 center-el"
                        onClick={goTo} />
                    <Button
                        className="p-button-link width-100 text-white"
                        label={`No thanks`}
                        onClick={finish} />
                </div>
                <p className={`p-mb-3 text-center p-text-bold ${ partnerName !== "hl" ? "text-white" : ""}`}>
                    {partner.text}
                </p>
            </PlatformContentContainer>
        </div>
    );
}

export default ApplicationCompletePartners;
