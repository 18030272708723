import React, { useEffect, useCallback } from "react";
import { useUser } from "../../../contexts/UserContext";

import PlatformContentContainer from "../_generic/platformContentContainer";
import { options } from "./config";
import moment from "moment";
import { addMeta } from "../../../services/meta";
import { useHistory } from "react-router";

function DaysUntilNextDrawDay() {
    const userContext = useUser();

    const drawnSpots = userContext.drawnSpots();
    const hasWon = drawnSpots.length > 0;
    const option = options[hasWon ? "drawn" : "notdrawn"];
    const delay = 3;
    const history = useHistory();

    const drawDaySeen= useCallback(() => {
        addMeta({drawDaySeen: true});
    },[])

    useEffect(() => {
        drawDaySeen();
        let timer;
        if(hasWon) {
            isSeen()
        } else {
            timer = setTimeout(() => finish(), delay * 1000);

            const finish = () => {
                isSeen()
            }
        }
        return () => {
           if(timer) {
            clearTimeout(timer)
           }
        };
    }, [drawDaySeen, history]);

    const isSeen = () => {
        history.push("/dashboard")
    };

  function countDays() {
    const datefMonth = moment().date();
    if(datefMonth < 7){
        return 7 - datefMonth;
    }
        return moment().add(1, "months").set("date", 7).diff(moment(), "days")
  }

  return (
    <div onClick={() => {isSeen()}}
        className={"drawDayStoryContainer scrollable-y drawday" } 
        style={{backgroundImage: "url(" + option.bg + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: "55"
        }} >
        <PlatformContentContainer className="platform_small p-mt-lg-4 p-pr-5 p-pl-5">
            <div>
                <h1 className="center drawdyCounter">{countDays()}</h1>
                <h1 className="center p-mt-1 p-text-center">days until the next draw</h1>
                <div className="p-pt-5">
                    <img
                        src={"./images/drawday/sand-clock.png"}
                        className="width-8em center-img"
                        alt="sand-clock" />
                </div>
            </div>
        </PlatformContentContainer>
    </div>
  );
}

export default DaysUntilNextDrawDay;
