import React from "react";
import { useUser } from "../../../../contexts/UserContext";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

function PaymentsTestimonial() {
  const { user } = useUser();


  const images = user?.stage === "Terms"
    ? [
        "https://i.ibb.co/9vjk5Ls/landing-page-testimonials-04.png",
        "https://i.ibb.co/S6S8p7X/landing-page-testimonials-01.png",
        "https://i.ibb.co/RQSHMw7/landing-page-testimonials-02.png",
        "https://i.ibb.co/tCy66rG/landing-page-testimonials-03.png",
      ]
    : [
        "https://i.ibb.co/S6S8p7X/landing-page-testimonials-01.png",
        "https://i.ibb.co/RQSHMw7/landing-page-testimonials-02.png",
        "https://i.ibb.co/tCy66rG/landing-page-testimonials-03.png",
        "https://i.ibb.co/9vjk5Ls/landing-page-testimonials-04.png",
      ];


  const nextArrow = (onClick) => {
    return <div onClick={onClick} style={{position:"absolute", right:0, top:"120px", borderRadius:"50%", backgroundColor:"white", padding:"5px"}}><i className="pi pi-arrow-right" /></div>
  }

  const backArrow = (onClick) => {
    return <div onClick={onClick} style={{position:"absolute", zIndex:"50", left:"0px", top:"120px", border:"1px solid black", borderRadius:"50%", backgroundColor:"white", padding:"5px"}}><i className="pi pi-arrow-left" /></div>
  }


    return (
      <div style={{paddingBottom:"50px", width: "100%"}}>
        <Carousel renderArrowPrev={backArrow} renderArrowNext={nextArrow} showIndicators={true} showThumbs={false} showStatus={false}>
          {images.map((i, k) => {
            return (
              <div key={i}>
                <img src={i} alt={"image " + k} />
              </div>
            );
          })}
        </Carousel>
      </div>
    );

}

export default PaymentsTestimonial;
