
import utils from "../../utils/utils";

const userPicture = ({user, isAdmin}) => {
    const pictureSrc = isAdmin ? "./images/stepLadderLogo.png" : utils.getPictureURL(user);
    return <div className="coverimage center-el" style={{backgroundImage:"url("+pictureSrc+")", width: "40px", height: "40px"}} />;
}

const userName = ({user, isAdmin}) => {
    return isAdmin ? "StepLadder" : `${user?.firstname || ""} ${user?.lastname || ""}`;
}

export {
    userPicture,
    userName
};