import React, { useState } from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import utils from "../../../utils/utils";

function SignupsTable({signups}) {

    const balance = ({value}) => {
        return utils.currencyFormat(value);
      };
    
    return (
        <div className="ag-theme-alpine" style={{ height: "100%", width: '100%' }}>
            <AgGridReact
                // pagination={true}
                // paginationAutoPageSize={true}
                rowData={signups}>
                <AgGridColumn field="month" sortable={ true } ></AgGridColumn>
                <AgGridColumn field="total" sortable={ true }></AgGridColumn>
                <AgGridColumn field="additionalSpots" sortable={ true } ></AgGridColumn>
                <AgGridColumn field="newSpots" sortable={ true } ></AgGridColumn>
                <AgGridColumn field="fees" cellRendererFramework={balance} sortable={ true }></AgGridColumn>
                <AgGridColumn field="gmv" cellRendererFramework={balance} sortable={ true } ></AgGridColumn>
            </AgGridReact>
        </div>
    );
    
};

export default SignupsTable;