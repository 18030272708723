import React from "react";
import DrawDayCountDown from "./components/drawDayCountDown";
import FeedReferrals from "./components/referrals";


const componentList = [DrawDayCountDown, FeedReferrals];

function CircleFeedMain(props) {

  return (
    <div className="feed">
      {componentList.map((itm, i) => {
        const Component = itm;
        return <Component key={i} />
      })}
    </div>
  );
}

export default CircleFeedMain;
