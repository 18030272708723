import React, { useEffect, useState } from "react";
import {getConversions} from "../../../services/users";
import ConversionsTable from "./conversionsTable";


function Conversions({setTitle, showToast}) {
    const [loading, setLoading] = useState(false);
    const [conversions, setConversions] = useState(null);

  useEffect(() => {
    setTitle("Conversion Funnel")
    getSignup();
  }, []);
  
  const getSignup = async () => {
    try {
      setLoading(true)
      const convs = await getConversions();
      setConversions(convs);
    } catch (error) {
      showToast(error.message || JSON.stringify(error), "error");
    } finally {
      setLoading(false)
    }
  }


  return (
    <>
      <div style={{height: '94%'}} >
        <div className="p-mt-3 topsheet-table">
            <ConversionsTable loading={loading} conversions={conversions}></ConversionsTable>
        </div>
      </div>
    </>
  
    );
}

export default Conversions;
