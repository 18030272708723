import React from "react";
import { withRouter } from "react-router";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import API from "../../services/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import UserSearch from "../../components/userSearch";
import { InputTextarea } from 'primereact/inputtextarea';

class page extends React.Component {
  constructor(props) {
    super(props);

    this.props.setTitle("Disable Automatic Payments");
    this.props.setSubTitle("On this screen, you can disable payments from automatically running each month.");
  
    this.state = {
      userData: [],
      userDataSearchFiltered: [],
      disabledPaymentsUserSearch: "",
      removeQuestionDisplay: false,
      updateUserDisplay: false,
      editUserObject: {},
      addUserDisplay: false,
    };
  }

  async componentDidMount() {
    //get the user ID from query
    this.getdisabledlist();
  }

  async getdisabledlist() {
    var data = await API.call({
      url: "/users/disabledPayments",
      method: "GET",
    });

    console.log(data);

    this.setState({ userData: data });
  }

  async removedisableduser() {
    this.updateDisabledPaymentsForUser(this.state.editUserObject._id, false);
  }

  async filterSearch(value) {
    var tempData = JSON.parse(JSON.stringify(this.state.userData));

    tempData = tempData.filter(function (itm) {
      return (
        itm.email.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        itm.firstname.toLowerCase().indexOf(value.toLowerCase()) > -1 ||
        itm.lastname.toLowerCase().indexOf(value.toLowerCase()) > -1
      );
    });

    this.setState({
      userDataSearchFiltered: tempData,
      disabledPaymentsUserSearch: value,
    });
  }

  async updateDisabledPaymentsForUser(userID, value) {
    this.props.showLoading();
    var response = await API.call({
      url: "/users/adminUserUpdate",
      method: "PUT",
      body: {
        userID: userID,
        updateObject: { disableAutomaticPayments: value },
      },
    });
    this.props.hideLoading();
    if (response.detailsUpdated) {
      this.props.showToast("Change successful", "success");
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      this.props.showToast("Failed to update details", "error");
    }
  }

  addDisabledPaymentsToUser(userID) {
    this.updateDisabledPaymentsForUser(userID, true);
  }

 async updateNotes(){
    const updatedNotes = this.state.editUserObject.disableAutomaticPaymentNotes;
    this.props.showLoading();
    var response = await API.call({
      url: "/users/adminUserUpdate",
      method: "PUT",
      body: {
        userID:this.state.editUserObject._id,
        updateObject: { disableAutomaticPaymentNotes: updatedNotes },
      },
    });

    if(response.detailsUpdated){
      this.props.hideLoading();
      this.props.showToast("Change successful", "success");
      window.setTimeout(() => {
        window.location.reload();
      });
    } else {
      this.props.showToast("We couldn't make the change", "error");
    }
    
  }

  render() {
    return (
      <React.Fragment>
        <Dialog
          header={
            "Are you sure you want to remove " +
            this.state.editUserObject.email +
            " from the disabled payments list?"
          }
          visible={this.state.removeQuestionDisplay}
          style={{ width: "50vw" }}
          onHide={() => this.setState({ removeQuestionDisplay: false })}
        >
          <div>
            If you click yes, next time an automatic payments cycle is ran, this
            user will be charged for all their active circles.
          </div>
          <Button
            onClick={(e) => {
              this.removedisableduser();
            }}
            label="Yes"
            style={{ marginTop: "2em" }}
          ></Button>
        </Dialog>
        <Dialog
          header={"Add a user"}
          visible={this.state.addUserDisplay}
          style={{ width: "50vw" }}
          onHide={() => this.setState({ addUserDisplay: false })}
        >
          <UserSearch
            onClick={(userObject) => {
              this.addDisabledPaymentsToUser(userObject._id);
            }}
          ></UserSearch>
        </Dialog>
        <Dialog
          header={"Update"}
          visible={this.state.updateUserDisplay}
          style={{ width: "50vw" }}
          onHide={() => this.setState({ updateUserDisplay: false })}
        ><br></br>
          <InputTextarea 
          rows={5} 
          cols={50} 
          value={
            this.state.editUserObject.disableAutomaticPaymentNotes
          } 
          onChange={
            (e) => {
              const editObject = this.state.editUserObject; 
              editObject.disableAutomaticPaymentNotes = e.target.value; 
              this.setState({...editObject})
            }} autoResize /><br></br>
          <Button label="Save Changes" onClick={(e) => this.updateNotes()}></Button>
          <br></br>
          <br></br>
          <hr></hr>
          <Button className="p-button-outlined" onClick={(e) => {this.setState({removeQuestionDisplay: true})}} label="Remove From List"></Button>
        </Dialog>
        <div>
        <Button
          onClick={(e) => {
            this.setState({ addUserDisplay: true });
          }}
          label="Add a user"
        ></Button>
        </div>
        <hr className="p-mb-6"></hr>
        {/*
        <br></br>
        <br></br>
        <br></br> */}
        <h4 className="p-m-0">
          Here's a list of users who have disabled payments, click to remove:
        </h4>
        <br></br>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={this.state.disabledPaymentsUserSearch}
            onChange={(e) => this.filterSearch(e.target.value)}
            placeholder="Search"
          />
        </span>
        <br></br>
        <DataTable
          onSelectionChange={(e) => {
            this.setState({
              updateUserDisplay: true,
              editUserObject: JSON.parse(JSON.stringify(e.value)),
            });
          }}
          selectionMode="single"
          dataKey="id"
          value={
            this.state.userDataSearchFiltered.length > 0
              ? this.state.userDataSearchFiltered
              : this.state.userData
          }
          style={{ marginTop: "2em" }}
        >
          <Column field="email" header="Email"></Column>
          <Column field="firstname" header="First Name"></Column>
          <Column field="lastname" header="Surname"></Column>
          <Column field="disableAutomaticPaymentNotes" header="Notes"></Column>
        </DataTable>
      </React.Fragment>
    );
  }
}

var AdminPaymentDisabled = withRouter(page);

export default AdminPaymentDisabled;
