import { useState } from "react";
import PlatformContentContainer from "../_generic/platformContentContainer";
import { Button } from "primereact/button";
import {useHistory} from "react-router";
import { updateUserInHubspot } from "../../../services/users";
import LastSreensWrapper from "./lastSreensWrapper";


function WhatUserStage() {
    const history = useHistory();
    const [selectedOption, setSelectedOption] = useState(false);

    const isImpersonating = !!localStorage.getItem("impersonation");

    const buttons = [
        {
            id: "I'm not interested in buying a house",
            text: "I don’t want to buy a house",
        },
        {
            id: "Paying debts",
            text: "Paying off debts",
        },
        {
            id: "Getting ready",
            text: "Still saving my deposit",
        },
        {
            id: "Almost ready",
            text: "Almost ready to buy",
        },
        {
            id: "Viewing",
            text: "Started viewing houses",
        },
        {
            id: "Had offer accepted",
            text: "Had an offer accepted",
        },
        {
            id: "Moved in",
            text: "I’ve bought a house already",
        },
    ];


    function goTo(id) {
        if(!isImpersonating){
            updateUserInHubspot({home_buying_stage: id})
          }
        setSelectedOption(true)
    }

    function done() {
        history.push("/dashboard");
    }

    if(selectedOption) {
        return <LastSreensWrapper  />
    }

    return (
        <div 
            className={"drawDayStoryContainer scrollable-y drawday"} 
            style={{
                    backgroundImage: "url(" + `${ selectedOption ? "./images/drawday/drawday-bg.png" : ""}` + ")",
                    background: "#27395E",
                    backgroundPosition: 'center',
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                    zIndex: "55",
            }} >
            <PlatformContentContainer noChildrenDiv={true} nocontent={true} className={"platform_small p-mt-lg-4 p-pt-6 height-100"} >
                <h1 className={"text-center p-mt-3 " + (selectedOption ? " p-pt-6 p-mt-7 p-pb-6 p-pr-4 p-pl-4" : "p-pb-3")}>
                    {selectedOption ? "Thanks! Keep an eye on your dashboard for our latest guides." : "Where you are on your homebuying journey?"}
                </h1>
                {!selectedOption && 
                    <p className="text-center p-mt-0 p-pb-3 p-pr-4 p-pl-4" style={{"color": "var(--stepladder-green)"}}>
                        We’ll use this to give you relevant educational content
                    </p>}

                <div className="p-grid p-pr-6 p-pl-6" style={{"zIndex": "1"}}>
                    {!selectedOption ? buttons.map((btn, i) => (
                        <Button 
                            key={i}
                            className={" p-col-12 p-mb-3 button-purple"}
                            onClick={(e) => { goTo(btn.id);}}
                            label={btn.text}>
                        </Button>
                    )) :
                    <Button 
                            className={" p-col-12 p-mb-3 p-mt-7"}
                            style={{background: `white`, color: "black"}}
                            onClick={(e) => { done();}}
                            label="Done!">
                        </Button>
                    }
                </div>
                <img src="./images/drawday/bottom.png" style={{width: "100%", position: "absolute",left: "0", bottom: "0"}} alt="bottom" />
            </PlatformContentContainer>
        </div>
  );
}

export default WhatUserStage;
