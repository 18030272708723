import React from "react";
import "primeflex/primeflex.css";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import moment from "moment";
import { InputText } from "primereact/inputtext";
import utils from "../../utils/utils";

const PENS = 100;
class TransfersList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payments: [],
      globalFilter: null,
    };
    this.actionBodyTemplate = this.actionBodyTemplate.bind(this);
    this.fees = this.fees.bind(this);
    this.funds = this.funds.bind(this);
    this.sortFunction = this.sortFunction.bind(this);
  }
    
  status(rowData) {
    const statuses = {
      SUCCEEDED: "success",
      FAILED: "danger",
      "FAILED VALIDATION": "danger",
  };
      const classStatus = `p-tag p-tag-rounded text-xxs p-tag-${
        statuses[rowData.Status] || "info"
      }`;
      return (<span className={classStatus}>{rowData.Status}</span>);
  };
  balance(money) {
    return money ? utils.currencyFormat(money / PENS) : "-";
  };

  fees(rowData) {
    return this.balance(rowData.Fees);
  };

  funds(rowData){
      return this.balance(rowData.Funds);
  };


  date(rowData) {
      return moment(rowData.CreationDate).format("DD-MM-YYYY");
  }

  copyTransferToPayin(transfer){
      this.props.copyTransferToPayin(transfer);
  }

  undoTransferAsPayin(transfer){
      this.props.undoTransferAsPayin(transfer);
  }

  actionBodyTemplate(rowData) {
    const tooltip = rowData.Status === "SUCCEEDED" ? "Click to copy transfer as payin" : "Transfer is not SUCCEEDED";
      return rowData.StatementDescriptor && rowData.StatementDescriptor === "movedToPayin" ? 
      (<Button label="Undo" tooltip="Click to delete created payin" tooltipOptions={{position: 'left'}} className="p-button-warning p-button-raised" onClick={() => this.undoTransferAsPayin(rowData)} />) : 
      (<Button label="Save as Payin" disabled={rowData.Status !== "SUCCEEDED"}  tooltip={tooltip} tooltipOptions={{position: 'left'}}  className="p-button-info p-button-raised" onClick={() => this.copyTransferToPayin(rowData)} />);
  }
  sortFunction(event) {
    console.log("sortFunction")
    console.log(event.order)
    return [...this.props.payments].sort((a, b) => (!!a.StatementDescriptor - !!b.StatementDescriptor) * event.order);
  }
  
  // circleNum(rowData) {
  //     return (<span className={"p-badge p-badge-secondary"}>{rowData.excelID}</span>);
  // }

  // getName(rowData) {
  //     return `${rowData.participant.user.firstname} ${rowData.participant.user.lastname}`;
  // }

  render() {
    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
            </span>
        </div>
    );

    return (
      <React.Fragment>
        <div>
            <div className="card blue-table">
                <DataTable 
                    className="table-rounded"
                    paginator
                    value={this.props.payments}
                    globalFilter={this.state.globalFilter}
                    header={header}
                    loading={this.props.loading}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={10} rowsPerPageOptions={[10,20,50]}
                >
                    {/* <Column field="excelID" header="Circle ID" body={this.circleNum}></Column>
                    <Column field="name" header="Name" ></Column>
                    <Column field="email" header="Email" ></Column> */}
                    <Column field="PaymentID" header="PaymentID" ></Column>
                    <Column field="AuthorId" header="AuthorId" ></Column>
                    <Column sortable field="Fees" style={{width: "100px"}} header="Fees" body={this.fees}></Column>
                    <Column sortable field="Funds" style={{width: "120px"}} header="Funds" body={this.funds}></Column>
                    <Column sortable field="Status" header="Status" body={this.status} ></Column>
                    <Column field="WalletId" header="WalletId" ></Column>
                    <Column field="TransferTo" header="TransferTo" ></Column>
                    <Column sortable field="Type" header="Type" ></Column>
                    <Column sortable field="CreationDate" header="Date" body={this.date}></Column>
                    <Column sortable sortFunction={this.sortFunction} field="StatementDescriptor" style={{width: "180px"}} header="Action" body={this.actionBodyTemplate}></Column>
                </DataTable>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default TransfersList;
