import { useState, useEffect } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { useUser } from "../../contexts/UserContext";
import AnalyticsService from "../../services/analytics";
import OnboardingContainer from "../onboarding/onboardingContainer";
import { getCookie, getURLParam, deleteCookie } from "../../utils/users";
import { refferalsToAmbassadors } from "../../../common/services/userHelpers";
import { getNextRoute } from "../../utils/userRoutes";
function RouteCheck({path, authenticated, criteria, component}) {
  const userContext = useUser();
  const history = useHistory();
  const [title, setTitle] = useState();
  const [subTitle, setSubTitle] = useState();
  const [isFull, setIsFull] = useState();
  const isImpersonating = !!localStorage.getItem("impersonation");

  useEffect(() => {
    if(!isImpersonating){
      applyCode()
    } else{
      // deleting referralCode for admins
      return deleteCookie("referralCode");
    }
  }, []);
  
  const applyCode = async () => {
    try {
      const referralCodeCookie = getCookie("referralCode");
      const refferalToAmbassadorFormCode = refferalsToAmbassadors[referralCodeCookie];
      if (refferalToAmbassadorFormCode && userContext.user) {
        if(userContext.user.ambassador_id === refferalToAmbassadorFormCode) {//delete cookie if code already aplied
          return deleteCookie("referralCode");
        } else {
          const updateParams = { ambassador_id: refferalToAmbassadorFormCode }
         
          if(userContext.user.reason_for_saving !== refferalToAmbassadorFormCode && refferalToAmbassadorFormCode !== "WomensCircle") {
            updateParams.reason_for_saving = refferalToAmbassadorFormCode;
          }

          const {data:{detailsUpdated}} = await userContext.updateUser(updateParams);
          
          if(detailsUpdated) {
            deleteCookie("referralCode");
            const user = await userContext.fetchUser()
            history.push(getNextRoute(user));
          }

        }
    }
    } catch (error) {
      console.log(error)
    }
  }

  const renderUserRoute = () => {
    const isForced = getURLParam("force");
    AnalyticsService.submitPageView();

    if (
      ((typeof authenticated === "boolean" &&
      authenticated !== !!userContext.user) ||
        (criteria && !criteria(userContext.user, true))) &&
      !isForced
    ) {
      return <Redirect to="/"></Redirect>;
    }

    const Component = component;

    if (history.location.pathname.indexOf("dashboard") !== -1) {
      return <Component></Component>;
    } else {
      
      return (
        <OnboardingContainer 
          isFull={isFull}
          title={title}
          subTitle={subTitle}>
          <Component
            setTitle={setTitle}
            setIsFull={setIsFull}
            setSubTitle={setSubTitle}/>
        </OnboardingContainer>
      );
    }
  }

  return (
    <Route
      path={path}
      render={renderUserRoute}
    ></Route>
  );
}

export default RouteCheck;
