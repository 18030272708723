import { useState, useEffect } from "react";
import { Checkbox } from "primereact/checkbox";
import Form from "../../../../../generic/forms/Form";
import GoBack from "./goBack";

const EveryDayAndHighNetWorthDetails = ({user, setFormlist, isDisableNext, back, title, trackBackId}) => {
    const [confirm, setConfirm] = useState(false);
    const [showForm, setShowForm] = useState(false);
    const {
        savings_and_assets: assets,
        how_much_debt_do_you_have_: debt,
        applicant_salary: salary } = user;

    useEffect(() => {
      isDisableNext(!confirm)
    }, [confirm]);
    return (
      <>
        <h3 className="investorTitle">
              Your reason for selecting <br/> {title}
          </h3>
          <p>
              You have already told us that your:
          </p>
          <ul>
              <li>
                  savings and other assets are <b>{assets == null ? "<missing>" : `<£${assets}>`}</b>
              </li>
              <li>
                  total debt is <b>{`<£${user?.how_much_debt_do_you_have_ || 0}>`}</b>
              </li>
              <li>
                  salary is <b>{`<£${user?.applicant_salary || 0}>`}</b>
              </li>
          </ul>
          <div >
              <h3 className="p-sm-col-1">Please confirm this is correct by clicking here:{" "}
                  <Checkbox
                      checked={confirm}
                      onChange={(e) => setConfirm(e.checked)} />
              </h3>
          </div>
          <GoBack
            back={back} 
            trackBackId={trackBackId} />
          <div className="p-mt-2">
              Or <span 
                  style={{cursor: "pointer"}}
                  onClick={()=> {setShowForm(true)}}><b>click here</b> to go change your details</span>
          </div>
          {showForm && 
              <Form
                  className="inline-form"
                  inputs={[
                      { key: "savingsAndAssets", value: assets || 0 },
                      { key: "debt_question", value: debt || 0 },
                      { key: "applicantSalary", value: salary || 0 },
                  ]}
                  formList={(formList) => setFormlist(formList)}
                  hideNextButton={true}
          />}
      </>
    );
  }

  export default EveryDayAndHighNetWorthDetails;