import API from "./api";

const route = "/circles";

const CircleService = {
  async saveNotes(notes, circleID) {
    return this.api({
      url: `/updatenotes`,
      method: "PUT",
      body: {
        params: {
          notes,
          circleID,
        },
      },
    });
  },

  async getCircleList(offset = 0, full = false, noparts = false) {
    return this.api({
      url: `/circleList2?offset=${offset}&full=${full}&noparts=${noparts}`,
      method: "GET",
    });
  },

  async circleTracker(circleID, withCircles = false) {
    return this.api({
      url: `/circleTracker?circleID=${circleID}&withCircles=${false}`,
      method: "GET",
    });
  },

  async getCirclesForTransfers(
    period,
    offset = 0,
    full = false,
    noparts = false
  ) {
    return this.api({
      // /userCircles2?UserId="
      url: `/getCirclesForTransfers?period=${period}`,
      method: "GET",
    });
  },

  async getParticipantsByIDs(IDs) {
    return this.api({
      url: "/getParticipantsByIDs",
      method: "POST",
      body: {
        participants: IDs,
      },
    });
  },

  async getRiskPolicy() {
    return this.api({
      url: "/riskProfiles",
      method: "GET",
    });
  },

  async emailTermsToUser(userID, circleID) {
    return this.api({
      url: "/emailTerms",
      method: "POST",
      body: {
        userID,
        circleID,
      },
    });
  },

  async api(params) {
    return API.call({
      url: route + params.url,
      method: params.method,
      body: params.body,
    });
  },
};

export default CircleService;
