
import { Checkbox } from 'primereact/checkbox';
 

function ArrearsCheckbox({ value, id, label, onChange }) {
  const makeChange = (val) => {
    onChange(id, val);
  };

  return (
    <div>
      <b>{label}</b>{" "}
      <Checkbox onChange={e => makeChange(e.checked)} checked={value}></Checkbox>
    </div>
  );
}

export default ArrearsCheckbox;
