import React from "react";
import "primeflex/primeflex.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import moment from "moment";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import AddUserToCircle from "./addUserToCircle";
import UpdateCircleMembership from "./updateCircleMembership";
import API from "../../services/api";
class UserCircles extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      userCircles: [],
      globalFilter: "",
      addUserDisplay: false,
      loading: false
    };

    this.updateCircleMembershipRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      loading: true,
    });
    this.getUserCircles(this.props.userData._id);
  }

  getSpots(userID, circleParticipants) {
    return circleParticipants.filter((itm) => (
            itm.user &&
            itm.user._id === userID &&
            itm.membership_status === "approved"
    ));
  }

  hasDrawn(userID, circleParticipants) {
    const spots = this.getSpots(userID, circleParticipants);

    const hasDrawnDate = spots.filter(function (itm) {
      return itm.drawn_date;
    });

    return hasDrawnDate.length > 0;
  }

  getDurationString(startDate, length) {
    var startDateAsDate = moment(startDate);
    var endDate = moment(startDateAsDate).add(length, "M");

    return (
      startDateAsDate.format("MMM YYYY") + " - " + endDate.format("MMM YYYY")
    );
  }

  getMembershipStatus(userID, circleParticipants) {
    const spots = this.getSpots(userID, circleParticipants);

    const isApprovedInAnySpot = spots.filter(function (itm) {
      return itm.membership_status === "approved";
    });

    return !isApprovedInAnySpot.length ? "Requested" : "Member";
  }

  rowSelected(userID, circleObject) {
    this.updateCircleMembershipRef.current.showUpdateCircleMembership(
      userID,
      circleObject
    );
  }

  async getUserCircles(userID) {
    const userCirclesRes = await API.call({
      url: "/circles/userCircles2?UserId=" + userID,
      method: "GET",
    });

    const userCircles = userCirclesRes.map(c => {
      const circle = c;//showRequested
      circle.membership_status = this.getMembershipStatus(userID, c.participants);
      circle.spots = this.getSpots(userID, c.participants);
      const circlesSpots =  circle.spots.map(spot => ({...circle, spot}))
      return circlesSpots;
    }).flat().filter(c => c.membership_status !== "Requested")
    this.setState({
      userCircles,
      loading: false,
    });

    // console.log(userCircles)
  }

  render() {
    const header = (
      <div className="table-header">
        <Button
          label="Add User To Circle"
          style={{ marginRight: "2em" }}
          onClick={(e) => {
            console.log(this.state);
            this.setState({ addUserDisplay: true });
          }}
        ></Button>
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            type="search"
            onInput={(e) => this.setState({ globalFilter: e.target.value })}
            placeholder="Filter anything"
          />
        </span>
      </div>
    );

    return (
      <React.Fragment>
        <UpdateCircleMembership
          ref={this.updateCircleMembershipRef}
          showToast={this.props.showToast}
          showLoading={this.props.showLoading}
          hideLoading={this.props.hideLoading}
        ></UpdateCircleMembership>
        <AddUserToCircle
          showToast={this.props.showToast}
          showLoading={this.props.showLoading}
          hideLoading={this.props.hideLoading}
          onHide={(e) => {
            this.setState({ addUserDisplay: false });
          }}
          userData={this.props.userData}
          visible={this.state.addUserDisplay}
        ></AddUserToCircle>
        <div style={{ marginBottom: "1em" }}>
          A green row indicates that the user has been drawn in that Circle.
          Click a circle to edit the members details.
        </div>
        <DataTable
          loading={this.state.loading}
          header={header}
          onSelectionChange={(e) => {
            this.rowSelected(this.props.userData._id, e.value);
          }}
          globalFilter={this.state.globalFilter}
          selectionMode="single"
          value={this.state.userCircles}
          rowClassName={(row) => {
            return {
              greenRow: this.hasDrawn(
                this.props.userData._id,
                row.participants
              ),
            };
          }}
        >
          <Column field="excelID" sortable header="Circle ID"></Column>
          <Column field="membership_status" header="Status"></Column>
          <Column
            body={(row) => {
              return this.getDurationString(row.startingDate, row.period);
            }}
            header="Duration"
          ></Column>
          <Column field="monthly_investment" header="Monthly"></Column>
          <Column field="period" header="Months"></Column>
          <Column
            body={(row) => {
              return row.spot.dropout_date ? "YES" : "NO";
            }}
            field="spots"
            header="Dropout"
          ></Column>
          <Column
            field="ambassador.ambassadorName"
            header="Ambassador"
          ></Column>
        </DataTable>
      </React.Fragment>
    );
  }
}

export default UserCircles;
