import React from "react";
import { useHistory, useLocation } from "react-router";
import { useState, useEffect } from "react";
import Form from "../../generic/forms/Form";
import { requestMagicToken } from "../../../services/users";
import { useUI } from "../../../contexts/UIContext";

function WelcomeBack({setTitle, setSubTitle}) {
  const [loginState, setLoginState] = useState("submitEmail");
  const location = useLocation();
  const UI = useUI();
  const history = useHistory();

  useEffect(() => {
    setTitle("Welcome Back");
    setSubTitle(`We just need to confirm its you by sending a one-time login link to your email address. You won't need to do this every time.`);

    localStorage.removeItem("user_logged_in_before");
  }, []);

  const submit = async (values) => {
    UI.showLoading();
    const { data, status } = await requestMagicToken(values.email);
    UI.hideLoading();

    if (
      status !== 200 ||
      (data.code && data.code === "user_not_found") ||
      !data.magic_link_sent
    ) {
      setLoginState("failed");
      return;
    }

    setLoginState("submitted");
  };

  const getDefaultEmail = (returnPartialEmail = false) => {
    const queryURL = new URLSearchParams(location.search);
    
    const email = queryURL.get("email");
    const partial = queryURL.get("partial");

    return (email && !!partial === returnPartialEmail) ? email : "";
  };

  return (
      <React.Fragment>
        {loginState === "submitEmail" && (
          <React.Fragment>
            {getDefaultEmail(true) !== "" && (
              <div className="blueBox">
                Try entering your email address that looks something like this:{" "}
                {getDefaultEmail(true)}
              </div>
            )}
            <Form
              inputs={[{ key: "email", value: getDefaultEmail() }]}
              onSubmit={(values) => submit(values)}
              onCancel={() => history.push("/getStartedApply")}
            ></Form>
          </React.Fragment>
        )}

        {loginState === "submitted" && (
          <div className="blueBox success">
            <i className="pi pi-envelope"></i> Your email is on it's way! Click
            the link in your email and we'll log you back in..
          </div>
        )}

        {loginState === "failed" && (
          <div
            className="blueBox fail"
            onClick={() => setLoginState("submitEmail")}
          >
            <i className="pi pi-times"></i> We couldn't find an email address
            that matched with the one you provided. <b>Click here</b> to try
            again.
          </div>
        )}
      </React.Fragment>
  );
}

export default WelcomeBack;
