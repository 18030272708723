import { useEffect } from "react";

function PageWrapper(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
    const layout = document.getElementsByClassName("onboardingContainer");

    if(layout.length){
      layout[0].scrollTo(0, 0);
    }
    
    document.getElementById("root").scrollTo(0, 0);
  }, []);
  return (
    <div className={"animate__animated animate__fadeIn max-width-600 min-height-800-desktop p-pt-0 p-jc-center p-d-flex p-flex-column" + (props.className || "")}>
      <div>{props.children}</div>
    </div>
  );
}

export default PageWrapper;
