import moment from "moment";
import React from "react";
import { useUser } from "../../../../../contexts/UserContext";
import {
  getCircleNextDrawDay,
  getSpecificParticipant,
  getTotalFromArray,
} from "../../../../../utils/circles";
import { currencyFormat } from "../../../../../utils/generic";
import { getNameOfMember, getPictureURL } from "../../../../../utils/users";
import PlatformTitle from "../../../_generic/platformTitle";

function CircleSummaryMain(props) {
  const userContext = useUser();
  const user = userContext.user;

  const circle = props.circle;

  const payins = getTotalFromArray(
    getSpecificParticipant(user, circle, true)
      .map((part) => part.payments)
      .flat()
      .filter((paym) => paym.Type === "PAYIN" && paym.Status === "SUCCEEDED"),
    "Funds"
  );

  const nextDrawDay = getCircleNextDrawDay(circle);

  const getMonthsLeft = () => {
    if(moment.utc(circle.startingDate).isAfter(moment.utc())){
      return 0;
    }
    return (
      Math.abs(moment().diff(moment.utc(circle.startingDate), "months")) + 1
    );
  };

  const spots = getSpecificParticipant(user, circle);
  const isCreditBuilder = spots.some((part) => part.credit_builder);

  const daysUntilDrawDay = Math.abs(moment().diff(nextDrawDay, "days"));

  const summaryBoxes = [
    {
      number: isNaN(daysUntilDrawDay) ? 0 : daysUntilDrawDay,
      text: "days until draw day",
    },
    {
      number: currencyFormat(payins / 100),
      text: "paid in",
    },
    {
      number:
        (getMonthsLeft() > circle.period ? circle.period : getMonthsLeft()) +
        " / " +
        circle.period,
      text: "months",
    },
  ];

  let membersToShow = circle.participants.sort((left, right) =>
    !right.drawn_date
      ? -1
      : moment.utc(right.drawn_date).diff(moment.utc(left.drawn_date))
  );

  return (
    <React.Fragment>
      {isCreditBuilder && (
        <div className="creditBuilderWarning">
          We'd love you to become a Member but based on the details you've provided and your recent credit history, we need to make you a Credit Budiler Member.  This means you will only be eligible to be drawn in the second half of your Circle but you'll still get all the other benefits of Membership.  We've sent you an email with more details. If you have any questions about
          this, <a target="_blank"  rel="noopener noreferrer" href="https://www.joinstepladder.com/contact/">contact us</a>.
        </div>
      )}
      <PlatformTitle title="Quick Summary" />
      <div className="summaryBoxes">
        {summaryBoxes.map((itm, i) => (
          <div key={i}>
            <div className="number">{itm.number}</div>
            <div className="description">{itm.text}</div>
          </div>
        ))}
      </div>
      <PlatformTitle title="Members"></PlatformTitle>
      <div className="circleMembers">
        {membersToShow.map((part, i) => {
          return (
            <div key={i}>
              <div
                className="circleMemberPic coverimage"
                style={{
                  backgroundImage: "url(" + getPictureURL(part.user) + ")",
                }}
              ></div>
              <div className="circleMemberName">
                <div>{getNameOfMember(part.user)}</div>
                <div className={part.drawn_date ? "drawn" : ""}>
                  {part.drawn_date
                    ? "Drawn | " + moment(part.drawn_date).format("MMM")
                    : "Not Drawn"}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </React.Fragment>
  );
}

export default CircleSummaryMain;
