import { useEffect, useState } from "react";
import CertifiedReason from "./certifiedReason";

const CertifiedReasons = ({data, setFormlist, isValid}) => {
    const [reasons, setReasons] = useState(data)
    useEffect(() => {
        isValid(!reasons.some(isReasonFilled));
        setFormlist(reasons.filter(isReasonFilled))
    }, [reasons, isValid, setFormlist]);
  
    const reasonSelected = (checked, i) => {
      reasons[i].selected = checked;
      reasons[i].answer = "";
      setReasons([...reasons]);
    }

    const answered = (value, i) => {
      reasons[i].answer = value;
      setReasons([...reasons]);;
    }

    const isReasonFilled = (r) => (r.selected && r.answer.length > 0);
  
    return (
        <div> 
            {
            reasons.map((reason, i) => (
                <CertifiedReason
                    key={i}
                    reason={reason}
                    reasonSelected={(e) => reasonSelected(e, i)}
                    answered={(e) => answered(e, i)}
                />
            ))
            }
      </div>
    );
  }

  export default CertifiedReasons;