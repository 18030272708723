
import { Button } from "primereact/button";
import React, { useEffect } from "react";
import { useState } from "react";
import LoadingSpinner from "../../components/loadingSpinner";

import {
  articleCategories,
  getAllArticles,
} from "../../services/articlesService";
import AdminArticleManage from "./AdminArticleManage";
import AdminCategoryView from "./AdminCategoryView";

const changedNames = {
  personalFinance: "Financial Wellbeing",
  wellness: "Self Care"
}

function AdminArticlesMain({setTitle}) {
  const [isLoading, setIsLoading] = useState(true);
  const [articleData, setArticleData] = useState([]);
  const articleConfig = articleCategories;
  const [categorySelected, setCategorySelected] = useState();
  const [typeSelected, setTypeSelected] = useState(
    Object.keys(articleConfig)[0]
  );
  const [articleSelected, setArticleSelected] = useState({});
  const [articleDialogVisible, setArticleDialogVisible] = useState(false);

  const camel2title = (camelCase) => {
    if(camelCase === "wellness" || camelCase === "personalFinance") {
      return changedNames[camelCase];
    } else {
      return camelCase
            .replace(/([A-Z])/g, (match) => ` ${match}`)
            .replace(/^./, (match) => match.toUpperCase())
            .trim();
    }
  }

  useEffect(() => {
    setTitle("Knowledge/Offers")
    loadContent();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadContent = async () => {
    const data = await getAllArticles();
    setArticleData(data);
    setIsLoading(false);
  };

  useEffect(() => {
    setCategorySelected(articleConfig[typeSelected][0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [typeSelected]);

const articleFilter = (article, category, type) => {
  const filterType = type || typeSelected;
  const filterCategory = category || categorySelected;
  return (filterCategory === "all" ? true : article.category.indexOf(filterCategory) > -1) && article.type === filterType
}
  const currentSelection = articleData.filter(
    (article) => articleFilter(article)
  );
  const currentTypeCategories = articleConfig[typeSelected];

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const showArticle = (item) => {
    setArticleSelected(item);
    setArticleDialogVisible(true);
  };

  const createArticle = (item) => {
      showArticle({order: currentSelection.length, type: typeSelected, category: [categorySelected]})
  }

  return (
    <React.Fragment>
      {articleDialogVisible && (
        <AdminArticleManage
          onClose={() => setArticleDialogVisible(false)}
          selectedArticle={articleSelected}
        />
      )}

      <div className="articleSelection articleSelections p-mt-4">
        {Object.keys(articleConfig).map((key, i) => (
          <div
            key={i}
            className={`p-shadow-4 ${key === typeSelected ? "active" : ""}`}
            onClick={() => setTypeSelected(key)}
          >
            {camel2title(key)}
          </div>
        ))}
      </div>
      <hr style={{ marginTop: "30px", marginBottom: "30px" }}></hr>
      <div className="categorySelection articleSelections">
        {currentTypeCategories.map((itm, i) => (
          <div
            className={`p-shadow-4 ${itm === categorySelected ? "active" : ""}`}
            key={i}
            onClick={() => setCategorySelected(itm)}
          >
            {camel2title(itm)}
          </div>
        ))}
      </div>

      <Button
        className="p-button-outlined p-button-info p-button-raised p-mb-2"
        icon="pi pi-plus"
        onClick={() => createArticle()} label="Add Article" />

      <AdminCategoryView
        onClick={(item) => showArticle(item)}
        articles={currentSelection}
      />
    </React.Fragment>
  );
}

export default AdminArticlesMain;
