import React from 'react';
import utils from "../../utils/utils";


class CellMcn extends React.Component {
    render() {
        return (
            <div>
                {
                   <span className={"p-badge p-badge-secondary badge-no-border"}>{this.props.value}</span> 
                }
            </div>
        );
    }
}

class CellTotalLoac extends React.Component {
    currencyFormat(num) {
        return utils.isNumeric(num) ? '£' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num;
     }
    render() {
        return (
            <div>
                {
                  <span className={`${this.props.value === 0 ? "bold-green" : ""}`}>{this.currencyFormat(this.props.value)}</span>
                }
            </div>
        );
    }
}

export {
    CellMcn,
    CellTotalLoac
}