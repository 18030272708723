import PartnerCellRenderer from "./partnerCellRenderer";

function getColumnHeadings(tableStructureData){

    const allKeys = tableStructureData.map(row => getKeysForRow(row)).flat();

    const uniqueKeysOnAllRows = [...new Set(allKeys)];

    const columnDefs = createColumnDefinitionsForKeys(uniqueKeysOnAllRows);

    const dateColumn = [{headerName: "Month", children: [{field: "monthYear"}]}];

    return dateColumn.concat(columnDefs);
    
}

function createColumnDefinitionsForKeys(keys){
    return keys.map(k => {
        return {
            headerName: camel2title(k),
            children: [
                {
                    field: k + "-emailClicks",
                    headerName: "Email Clicks",
                    cellRendererFramework: PartnerCellRenderer
                },
                {
                    field: k + "-platformClicks",
                    headerName: "Platform Clicks",
                    cellRendererFramework: PartnerCellRenderer
                },
                {
                    field: k + "-membershipReferrals",
                    headerName: "Membership",
                    cellRendererFramework: PartnerCellRenderer
                }
            ]
        }
    })
}

const camel2title = (camelCase) => camelCase
  .replace(/([A-Z])/g, (match) => ` ${match}`)
  .replace(/^./, (match) => match.toUpperCase())
  .trim();

function getKeysForRow(row){
    let keys = [];

    Object.keys(row).forEach(key => {
        if(key === "monthYear"){
            return;
        }

        const partnerKey = key.split("-")[0];

        keys.push(partnerKey);
    });

    return keys;
}

export default getColumnHeadings;