
const partners = {
    checkmyfile: {
        title: "You should know your credit score, ",
        logo: "./images/partners/checkmyfile.png",
        greenText: "",
        text: "Use CheckMyFile today for a 30 day free trial.",
        button: {
            link: "https://www.members.joinstepladder.com/urlRedirect?url=https://www.checkmyfile.com/?ref=stepladder&cbap=1&key=checkMyFile&email=",
            name: "Start my trial",
            trackName: "checkMyFile"
        }
    },
    hl: {
        title: "Learn how to make more from your money,",
        logo: "./images/partners/hl.png",
        greenText: "Learn how to make more from your money",
        text: "Investing is a great way to make your savings grow further.",
        button: {
            link: "https://www.members.joinstepladder.com/urlRedirect?url=https://hl.7eer.net/rnye55&key=hargreavesLansdowne&email=",
            name: "Download your free guide to investing",
            trackName: "hargreavesLansdowne"
        }
    },
    proportunity: {
        title: "Buy with just a 5% deposit",
        logo: "./images/partners/proportunity.png",
        text: "Proportunity is similar to the government's Help to Buy scheme, except you are not limited to just new builds. Find out if they could stretch your money further today.",
        greenText: "Buy with just a 5% deposit!",
        button: {
            link: "https://www.members.joinstepladder.com/urlRedirect?url=https://proportunity.co/?utm_source=affiliates_sldr&utm_medium=conv_sign&utm_campaign=SLDRCSFTB&utm_term=XXX&utm_content=XXX&key=proportunity&email=",
            name: "Stretch your money with Proportunity",
            trackName: "Proportunity"
        }
    },
    snoop: {
        title: "FREE app to manage your money",
        logo: "./images/partners/snoop.png",
        text: "A free app to help you cut down costs and switch to cheaper bills.",
        greenText: "",
        button: {
            link: "https://www.members.joinstepladder.com/urlRedirect?url=https://snoop.sjv.io/b31QJB&key=Snoop&email=",
            name: "Download free app",
            trackName: "Snoop"
        }
    },
    // stepladder: {
    //     logo: "./images/stepLadderLogo2.png",
    //     text: "Take a stride towards your house deposit with a Circle dedicated towards buying a house",
    //     button: {
    //         link: "/circleInvite",
    //         name: "Join today",
    //         trackName: "joincircle"
    //     }
    // },
}

export {partners};