import React from 'react';
import utils from "../../utils/utils";

export default class MonthCellRenderer extends React.Component {
    currencyFormat(num) {
        return utils.isNumeric(num) ? '£' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num;
     }
     isFromtransfer(value) {
        const [first, second] = `${value}`.split("*-*");
        return second === "fromTransfer";
     }
     fromtransfer(value) {
         const [first, second] = `${value}`.split("*-*");
        return  <span>{this.currencyFormat(+first)}</span>;
     }
    render() {
        return (
            <div>
                {
                    // this.props.value.Funds ? <span className={"active"}>{this.currencyFormat(this.props.value)}</span>  :
                    this.props.value === "not created" ? <span><i className={ "pi pi-exclamation-triangle not-exist"} /></span>  :
                    this.props.value === "reserved" ? <span className="p-tag p-tag-rounded badge-border reserved-tag">{this.props.value}</span>  :
                    this.props.value === "will be created" ? <span><i className={ "pi pi-check-circle warning"} /></span>  : //<span><i className={"pi pi-times failed"} /></span> :
                    this.props.value === "pending" ? <span><i className={ "pi pi-calendar pending"} /></span> :
                    this.props.value < 0 ? <span className="failed">{this.currencyFormat(this.props.value)}</span> :
                    this.props.value !== true && this.props.value !== false ?
                    this.isFromtransfer(this.props.value) ? <span className="blue">{this.fromtransfer(this.props.value)}</span> :
                     <span>{this.currencyFormat(this.props.value)}</span>  : 
                     <span><i className={ this.props.value ? "pi pi-check active" : "pi pi-times failed"} /></span>
                }
            </div>
        );
    }
}
// 🍩