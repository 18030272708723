//used with text input
function SelectInput(props){
    return (<select 
                onChange={(e) => props.onChange(e.target.value)}
                style={{opacity:0, cursor:"pointer", position:"absolute", left:0, top:0, width:"100%", height:"100%"}}>
        <option value="">Select</option>
        {props.options.map((itm, i)=> <option key={i} >{itm}</option>)}
    </select>)
}

export default SelectInput;