import React from "react";
import "primeflex/primeflex.css";
import ViewCircles from "../../adminCircles/viewCircles";
import { TabView, TabPanel } from "primereact/tabview";
import UpdateAmbassadorSettings from "./updateAmbassadorSettings";
import AmbassadorAccess from "./ambassadorAccess";

function EditAmbassador(props) {
  return (
    <>
      <TabView>
        <TabPanel header="Settings">
          <UpdateAmbassadorSettings
            showToast={props.showToast}
            newAmbassador={props.isNewAmbassador}
            ambassadorObject={props.ambassador}
            showLoading={props.showLoading}
          />
        </TabPanel>
        <TabPanel disabled={props.isNewAmbassador} header="Circles" height="50vh">
          For adding a new Circle, go to the{" "}
          <a href="/adminPortal/circles">Add Circle screen</a> and connect it
          to the Ambassador.
          <ViewCircles
            className="div.circleViewTabContent"
            // ambassadorView={true} //?
            circles={props.circleList}
            onRowClick={(circle) => {
              props.history.push(
                "/adminPortal/circles?showCircleID=" + circle._id
              );
            }}
          ></ViewCircles>
        </TabPanel>
        <TabPanel disabled={props.isNewAmbassador} header="User Access" scrollable="true">
          <AmbassadorAccess
            showLoading={props.showLoading}
            hideLoading={props.hideLoading}
            showToast={props.showToast}
            ambassador={props.ambassador}
            access={props.ambassador.userAccess || []}
          />
        </TabPanel>
      </TabView>
    </>
  );
}

export default EditAmbassador;
