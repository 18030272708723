import React from "react";

import "primeflex/primeflex.css";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";

import ViewCircles from "../adminCircles/viewCircles";

import CircleService from "../../services/circleService";
import API from "../../services/api";

class AddUserToCircle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      circleSearchResults: [
        { excelID: 5, startingDate: new Date(), participants: [] },
      ],
      circleSelected: {},
      confirmationVisible: false,
      spotTypeConfirmationVisible: false,
    };
  }

  async getCircles() {
    //gets all circles
    const newCircleData = await CircleService.getCircleList();

    this.setState({
      circleList: newCircleData,
    });
  }

  async componentDidMount() {
    await this.getCircles();
  }

  async addUserToCircleConfirmed(membership_status, spotType) {


    this.props.showLoading();
    var data = await API.call({
      url: "/circles/addUserToCircle2",
      method: "PUT",
      body: {
        params: {
          participant: {
            _id: this.props.userData._id,
            membership_status: membership_status,
          },
          circleID: this.state.circleSelected._id,
          forceNewSpot: spotType
        },
      },
    });
    this.props.hideLoading();


    if (data.participantAdded) {
      this.props.showToast("User added!", "success");
      window.location.reload();
    } else {
      this.props.showToast(
        data.error
          ? "Failed: " + data.error
          : "The user could not be added to the Circle"
      );
    }
  }

  askForCircleConfirmation(circleObject) {
    this.setState({ circleSelected: circleObject, confirmationVisible: true });
  }

  render() {
    return (
      <React.Fragment>
        {this.state.spotTypeConfirmationVisible && <ConfirmFullMemberAddType onClick={(forceSpotType) => {
          this.setState({spotTypeConfirmationVisible: false});
          this.addUserToCircleConfirmed("approved", forceSpotType);
        }} />}

        <Dialog
          header={"Select a Circle To Add"}
          visible={this.props.visible}
          style={{ width: "60vw" }}
          onHide={() => this.props.onHide()}
        >
          <div>
            {this.props.userData.firstname}{"'s"} max FMC is{" "}
            <b>{this.props.userData.minimumTest}</b>. In the admin portal, we do
            not check the FMC in case you need to override the settings. If you
            do not understand how FMC works, please ask someone who does to add
            the user to the right Circle.
          </div>
          <hr style={{ marginBottom: "1.5em", marginTop: "1.5em" }}></hr>

          <ViewCircles
            onRowClick={(row) => {
              this.setState({ circleSelected: row, confirmationVisible: true });
            }}
            slim={false}
            circles={this.state.circleList}
          ></ViewCircles>
        </Dialog>
        <Dialog
          header={
            "Select membership type for Circle " +
            this.state.circleSelected.excelID
          }
          visible={this.state.confirmationVisible}
          style={{ width: "50vw" }}
          onHide={() => this.setState({ confirmationVisible: false })}
        >
          <Button
            label="Add as full member"
            style={{ marginRight: "1em" }}
            onClick={(e) => {
              this.setState({spotTypeConfirmationVisible: true});
            }}
          ></Button>
          <Button
            className="p-button-outlined"
            onClick={() => this.addUserToCircleConfirmed("requested")}
            label="Add as requested Circle"
            style={{ marginRight: "1em" }}
          ></Button>
        </Dialog>
      </React.Fragment>
    );
  }
}

function ConfirmFullMemberAddType(props){

  const buttonClick = (forceNewSpotType) => {
    props.onClick(forceNewSpotType);
  };

  return  <Dialog
  header={"Should this be counted as a new spot?"}
  visible={true}
  style={{ width: "60vw" }}
  onHide={() => this.props.onHide()}
>
  <div style={{marginBottom:"20px"}}>Select "Yes" if we should count it towards our signups. Select "No" if it's part of Circle moves.</div>
  <Button label="Yes" onClick={() => buttonClick(true)} style={{marginRight: "1em"}} />
  <Button label="No"  onClick={() => buttonClick(false)}/>
</Dialog>
}

export default AddUserToCircle;
