import { useEffect, useState } from "react";
import { submitInsuranceDetails } from "../../../../../services/users";

function InsuranceOutcome({ answers }) {

    const url = window.location.href.split("/");
    let foundEmail = url.pop();

    if (foundEmail=== "insuranceQuiz") {
        foundEmail = null;
    }

    const [email, setEmail] = useState(foundEmail);


  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {

    let newemail = email;

    if (!email) {
        newemail = prompt("Please confirm your email address");
        setEmail(newemail);
    }


    let answersToSubmit = {};

    Object.keys(answers).forEach(a => answersToSubmit[a] = answers[a] === "Yes");

    if(newemail && newemail !== ""){
        submitInsuranceDetails({ email: newemail, answers: answersToSubmit });
    } else {
      setEmail("noEmail@example.com")
    }
    
  }

  const onClick = async (id) => {
   
    let goToURL = "";

    if (id === "locket") {
      goToURL =
      window.location.origin + "/urlRedirect?url=https://quote.locket.insure/stepladder&key=locket&email=";
    } else {
      goToURL =
        window.location.origin + "/urlRedirect?url=https://bequest.com/stepladder/?utm_source=iar&utm_campaign=stepladder&utm_content=50voucher&key=bequest&email=";
    }

    window.open(goToURL + email, "_blank");
  };

  const allAnswersNo = () => {
    return answers.familyRelyOnIncome === "No" && answers.ownValuableItems === "No";
  };

  return (
    <div>
      Based on your answers, we suggest that you look at..
      {(allAnswersNo() || answers.familyRelyOnIncome === "Yes") && (
        <InsuranceCompany
          title="Protect Your Loved Ones"
          img="https://i.ibb.co/jJh5nNM/bequest.png"
          callToAction="Click here to protect your loved ones"
          bullets={[
            "✓ Protect the people you love by ensuring your loved ones have financial security in case you couldn’t be there",
            "✓ Free Will included so your loved ones are taken care of",
            "✓ Easy and affordable",
          ]}
          id="bequest"
          onClick={onClick}
        />
      )}
      {(allAnswersNo() || answers.ownValuableItems === "Yes") && (
        <InsuranceCompany
          title="Protect Your Things"
          img="https://i.ibb.co/FDppCj0/locket.png"
          callToAction="Click here to protect your things"
          id="locket"
          bullets={[
            "✓ Feel safe in knowing that your items are protected from damage & loss",
            "✓ Monthly rolling contracts, flexible cover. No lock-in",
            "✓ Access to home technology deals at a cheaper rate than usual",
          ]}
          onClick={onClick}
        />
      )}
    </div>
  );
}

function InsuranceCompany({ title, img, bullets, callToAction, id, onClick }) {
  return (
    <div
      style={{
        border: "1px solid grey",
        borderRadius: "10px",
        overflow: "hidden",
        marginTop: "30px",
      }}
    >
      <div
        className="flexeven"
        style={{ alignItems: "center", borderBottom: "1px solid grey" }}
      >
        <div style={{ padding: "20px", borderRight: "1px solid grey" }}>
          <img src={img} style={{ width: "80%" }} />
        </div>
        <div style={{ fontWeight: "700", paddingLeft: "20px" }}>{title}</div>
      </div>
      <div style={{ padding: "20px" }}>
        {bullets.map((b) => (
          <div key={b} style={{ marginBottom: "20px" }}>
            {b}
          </div>
        ))}
      </div>
      <div
        style={{
          fontWeight: "700",
          fontSize: "18px",
          backgroundColor: "var(--primary-color)",
          color: "white",
          padding: "15px 50px",
          textAlign: "center",
          textAlign: "center",
          cursor:"pointer"
        }}
        onClick={() => onClick(id)}
      >
        {callToAction}
      </div>
    </div>
  );
}

export default InsuranceOutcome;
