import React from "react";

import "primeflex/primeflex.css";
import moment from "moment";
import { Dialog } from "primereact/dialog";

  function VouchersDialog({selected, visible, onHide}) {
    const {email, name, walletId, requestedDate, status, error} = selected || {};
    
    const statuses = {
        ACTIVE: "success",
        FAIL: "danger",
        PROCESSING: "warning",
    };

    const StatusBadge = (status) => {
        const classStatus = `p-tag p-tag-rounded p-tag-${
          statuses[status] || "info"
        }`;
        return (<span style={{minWidth: "60px"}} className={classStatus}>{status}</span>);
      };


    const date = (date) => {
        return date ? moment(date).format("DD-MM-YYYY") : "-";
    };


    return (
      <React.Fragment>
        <Dialog
          header={"Requested vouchers"}
          visible={visible}
          style={{ width: "70vw" }}
          onHide={onHide}
        >
            <div>
                <div className="flex">
                    <div className="p-m-2">
                    <b>Status: </b> {StatusBadge(status)}</div>
                </div>
                <div className="flex">
                    <div className="p-m-2"><b>Email: </b>{email || ""}</div>
                    <div className="p-m-2"><b>Name: </b>{name || ""}</div>
                </div>

                <div className="flex">
                    <div className="p-m-2"><b>Wallet: </b>{walletId || ""}</div>
                    <div className="p-m-2"><b>Requested date: </b>{date(requestedDate) || "-"}</div>
                </div>
                {error && <div className="flex">
                    <div className="p-m-2"><b>Error: </b>{error}</div>
                </div>}

            </div>
        </Dialog>
      </React.Fragment>
    );
}

export default VouchersDialog;
