function PaymentRedirectMessage() {
  return (
    <div className="paymentRedirectMessage animate__animated animate__fadeIn animate__faster">
      <div>
        <div>
          <i className="pi pi-spin pi-spinner" style={{ animationDuration:"0.8s", fontSize: "2em", marginBottom:"15px" }}></i>
        </div>
        <div>
          We're redirecting you to Mangopay to confirm your direct debit.
        </div>
      </div>
    </div>
  );
}

export default PaymentRedirectMessage;
