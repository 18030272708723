
import { useEffect } from "react";
import { logout } from "../../../services/users";
import { useUI } from "../../../contexts/UIContext";
import LoadingOverlay from "../../generic/LoadingOverlay";

function Logout(){

    const UI = useUI();
 

    useEffect(() => {
        logoutUser();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const logoutUser = async () => {
        const {status} = await logout();

        if(status === 200){
            window.location = "/";
        } else {
            UI.showToast("We ran into an error while logging you out, please contact us.");
        }
    };

    return <LoadingOverlay />
}

export default Logout;