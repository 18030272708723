import API from "./api";
const path = "/metas";


function getSignups(){
      return API.call2(
        `${path}/signups`,
        {
          method: "GET",
        });
}

function getMetas({name, from, to, groupedByUserId=true}){
      return API.call2(
        `${path}/?name=${name}&from=${from}&to=${to}&groupedByUserId=${groupedByUserId}`, 
        {
          method: "GET",
        });
}

export {
    getSignups,
    getMetas
};