import React, {useEffect, useRef} from "react";
import { useUser } from "../../../../../contexts/UserContext";
import InlineLoading from "../../../../generic/inlineLoading";
import ChatMessage from "./chatMessage";

function ChatMessages({isLoading, messages}) {
  const userContext = useUser();
  const user = userContext.user;
  const messagesEndRef = useRef(null)

   useEffect(() => {

    const timer = setTimeout(() => {
      scrollToBottom();
    }, 0);
    return () => {
      clearTimeout(timer);
  };
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" })
  }

  if(isLoading){
      return <InlineLoading />
  }
  
  return (
    <div className="chatMessageContainer">
      {messages.map((message, i) => (
          <ChatMessage key={i} message={message} user={user} />
      ))}
      <div ref={messagesEndRef} />
    </div>
  );
}

export default ChatMessages;
