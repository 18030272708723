import moment from "moment";

function getNumberOfActiveCircleSpots(user, approvedCircles) {
  if (!approvedCircles || !approvedCircles.length) {
    return 0;
  }

  return approvedCircles
    .filter(ac => moment().isBefore(moment(ac.endingDate)))
    .map((ac) => ac.participants)
    .flat()
    .filter((part) => !part.dropout_date && user._id === part.user._id).length;
}

export default getNumberOfActiveCircleSpots;
