import React, { useState } from "react";
import { useUser } from "../../../contexts/UserContext";
import { useHistory } from "react-router";
import { Button } from "primereact/button";
import CheckMyfileView from "./checkMyfileView";

function ThanksConfirmation(props) {
    const [isComplete, setIsComplete] = useState(false);
    const userContext = useUser();
    const signupToNewCircle = props.signupToNewCircle;

    const hide = () => {
        props.onHide();
        if (signupToNewCircle) {
          window.setTimeout(() => {
            history.push("/circleInvite");
          }, 1000); 
        } else {
          history.push("/dashboard");
        }
    }
  
    const history = useHistory();
    const next = () => {
      setIsComplete(true);
      if(props.hideHeader) {
        props.hideHeader(true)
      }
    };
    if (isComplete || !props.isWinner) {
        return <CheckMyfileView hide={() => {hide()}} />;
    }
    return (
      <div>
        <b>Thanks for confirming that you want the money returned to you.</b>
        <br />
        <br />
        We’ll send the money back to your account in <b>3-5 working days</b>.
        <br />
        <br />
        Please check your inbox at <b>{userContext.user.email}</b> periodically in
        case we have any follow up questions that we need to ask you.
        <br />
        <br />
        {signupToNewCircle && (
          <span>On the next screen you can select a new Circle.</span>
        )}
        <Button
        className="p-button-raised p-button-info width-100"
          onClick={() => {
            next()
          }}
          label="Next"
        />
      </div>
    );
  }
  export default ThanksConfirmation;