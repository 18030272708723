import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import ReactDragListView from "react-drag-listview";
import LoadingSpinner from "../../components/loadingSpinner";
import { createOrUpdateArticle } from "../../services/articlesService";

function AdminCategoryView(props) {
  const [isOrderMoved, setIsOrderMoved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);


  const [data, setData] = useState([]);

  useEffect(() => {
    setData(!props.articles ? [] : props.articles.sort((a, b) => a.order < b.order));
    setIsLoading(false);
    setIsOrderMoved(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.articles])


  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const currentData = [...data];
      const item = currentData.splice(fromIndex, 1)[0];
      currentData.splice(toIndex, 0, item);
      setIsOrderMoved(true);
      setData(currentData);
    },
    nodeSelector: "li",
    handleSelector: "a",
  };

  const submitChangeOrder = async () => {
    const updates = data
      .map((itm, i) => (i !== itm.order ? { ...itm, order: i } : null))
      .filter((itm) => itm);

    try {
      setIsLoading(true);
      for (const item of updates) {
        const response = await createOrUpdateArticle(item);

        if (!response.status) {
          throw new Error(
            "We couldn't submit the changes, please refresh the page and try again"
          );
        }
      }
      setIsLoading(false);
      window.location.reload();
    } catch (err) {
      alert(err.message);
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <LoadingSpinner></LoadingSpinner>;
  }

  return (
    <React.Fragment>
      {isOrderMoved && (
        <div className="b-radius-10 p-mt-3 p-mb-3 p-p-4" style={{ backgroundColor: "rgb(255 239 0 / 40%)"}}>
          Click here to save the changed order or refresh the page to cancel..
          <br></br>
          <br></br>
          <Button onClick={() => submitChangeOrder()} label="Save Order" />
        </div>
      )}

      <ReactDragListView {...dragProps}>
        <ul className="articleList">
          {data.map((item, index) => (
            <li className="b-radius-10 p-shadow-3 p-mt-2" key={index}>
                {/* eslint-disable */}
                <a style={{marginRight:"10px"}}>
                {/* eslint-enable */}
              
                <i className="pi-bars pi"></i>
              </a>
              <div style={{width:"80%"}} onClick={() => props.onClick(item)}>{item.title.substring(0, 45)}</div>
              
            </li>
          ))}
        </ul>
      </ReactDragListView>
    </React.Fragment>
  );
}

export default AdminCategoryView;
