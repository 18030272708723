import { Sidebar } from "primereact/sidebar";

function InfoPopup({ title, content, onHide }) {
    return (
      <Sidebar visible={true} style={{zIndex:20}} className="p-sidebar-md animate__animated animate__fadeInUp animate__faster" modal={true} position="bottom" onHide={onHide}>
        <b>{title}</b>
        <hr style={{marginTop:"5px", marginBottom:"25px"}} />
        {content}
      </Sidebar>
    );
  }

export default InfoPopup;