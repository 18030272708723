import React, { useEffect } from "react";
import { useUser } from "../../../../contexts/UserContext";
import { addMeta } from "../../../../services/meta";

function CircleInviteError() {

  useEffect(() => {
    addMeta({ CircleInviteErrorSeen: true });
  });
  return (
    <React.Fragment>
      <h2>We ran into an issue, but we're here to help!</h2>
      <div style={{marginTop:"15px"}}>
        We're really sorry that we ran into an error on our side. This is an error on
        our part, and we'd love for you to <a href="https://www.joinstepladder.com/contact/">contact us</a> so we can find something that works for you.
        <br></br><br></br>
        We have thousands of happy members, and we'd love you to join us, <a href="https://www.joinstepladder.com/contact/">email us or give us a call</a> today and we'll get you setup!
      </div>
    </React.Fragment>
  );
}

export default CircleInviteError;
