import {useEffect} from "react";
import { useState } from "react";
import { findAddress } from "../../../../../services/users";
import { ListBox } from 'primereact/listbox';

function Addresses({postCode, selectAddress}) {
    const [addresses, setAddresses] = useState(null);
    const [loading, setLoading] = useState(null);

    useEffect(() => {
      findAddresses()
    }, []);

  const transformAddress = (address) => {
    return {
      address: `${address.line_1} ${address.line_2} ${address.line_3} ${address.line_4}`,
      town_or_city: address.town_or_city,
      postcode: addresses.postcode || postCode
    }
  }
  const findAddresses = async () => {
    try {
      if(!postCode) {
        return;
      }
      setLoading(true)

      const {data} = await findAddress(postCode);

      if(!data.error) {
        setAddresses(data.addresses);
      } 
    } finally {
      setLoading(false)
    }
  }

  if(loading) {
    return <><h3 className="center p-mt-6 p-mb-6">Loading Addresses...</h3></>
  }

  if(!addresses || !addresses.addresses.length) {
    return <p className="center p-mt-6 p-mb-6">Address not found postcode:  <b className="p-text-uppercase">{postCode || " "}</b>   </p>
  }

  return (
    <ListBox className="addresses" optionLabel="address" options={addresses.addresses.map(transformAddress)} onChange={(e) => selectAddress(e.value)} />
  );
}

export default Addresses;
