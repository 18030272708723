import { Dropdown } from "primereact/dropdown";
import { useState } from "react";
import CenterMiddle from "../../../generic/CenterMiddle";
import InfoTooltip from "../../../generic/InfoTooltip";

import StickyButtons from "../../../generic/stickyButtons";

function InvestorClassification({setOption, investorOptions}) {

  const [selectedIndex, setSelectedIndex] = useState(0);
  const [btnsHeight, setBtnsHeight] = useState(0);

  const selectedOption = investorOptions[selectedIndex];
  const {QuickFacts} = selectedOption;

  const changeSelection = (title) => {
    setSelectedIndex(investorOptions.findIndex((itm) => title === itm.title));
  };

  return (
    <CenterMiddle>
      <div className="congratsInvestor">
        <img src="./images/check.png" alt="check"></img>
        <h2 className="investorTitle">
          You've <span>passed</span> the quiz!
        </h2>
      </div>
      <div className="headerDescription" style={{ marginBottom: "30px", fontSize: "18px" }}>
        Just one more step to make sure you qualify and we follow regulations.
      </div>

      <div className="investorSubTitle">
        What type of investor are you?{" "}
        <InfoTooltip
          text={
            "To determine your investor type, read the quick facts below to see if you're an every day investor"
          }
          position={window.innerWidth > 600 ? "right" : "left"}
          blueTooltip={true}
        />
      </div>

      <Dropdown
        style={{
          marginBottom: "50px",
          width: window.innerWidth < 600 ? "100%" : "auto",
        }}
        value={selectedOption.title}
        options={investorOptions.map((itm) => itm.title)}
        onChange={(e) => changeSelection(e.value)}
        placeholder="Select a City"
      />

      <div className="investorSubTitle">
        Quick Facts{" "}
        <InfoTooltip
          text={
            "Read the quick facts to see whether they apply to your situation"
          }
          position={"right"}
          blueTooltip={true}
        />
      </div>
      <div className="blueBox investor">{<QuickFacts />}</div>

      <StickyButtons
          nextText={"Next"}
          nextClick={()=> setOption(selectedOption)}
          getBtnHeightFn={setBtnsHeight}
           />
    </CenterMiddle>
  );
}

export default InvestorClassification;
