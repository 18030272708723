import React, { useState, useRef, useEffect, useCallback }  from 'react';
import { AgGridColumn, AgGridReact } from 'ag-grid-react';
import utils from "../../utils/utils";
import CellRenderer from "./cellRenderer";
import CellRendererCounts from "./cellRendererCounts";
import SimpleCellRenderer from "./simpleCellRenderer";
import { CellMcn, CellTotalLoac } from "./commonCells";

function TopSheetTable({topSheetData, search, loading}) {
    const [spine] = useState(["Draw", "StartDate", "EndDate","MCN", "Type", "TotalLocs"]);
    const [simpleRender] = useState([ "StartDate","EndDate", "MCN"]);
    const gridRef = useRef();
    const prevAmount = utils.usePrevious({search, loading});

    const showLoading = useCallback(() => {
        if(gridRef && gridRef.current.api) {
            gridRef.current.api.showLoadingOverlay();
        }
       
      }, []);
    
    const hideLoading = useCallback(() => {
        if(gridRef && gridRef.current.api) {
            gridRef.current.api.hideOverlay();
        }
        
    }, []);

    useEffect(() => {
        if(prevAmount && prevAmount.search !== search) {
            gridRef.current.api.setQuickFilter(search);
        }
        // if(prevAmount && prevAmount.loading !== loading) {
        //     if(loading) {
        //         showLoading()
        //     } else {
        //         hideLoading()
        //     }
        // }
    }, [search, loading, showLoading, hideLoading, prevAmount]);

    const headersSpine = () => {
        let i = 0;
        return spine.map((item) => ({i: i++, item}));
        
    }

    const headersMonths = (data) => {
        if(!data) return [];
        let i = 0;
        return Object.keys(data[0].locs).map((item) => {
            return spine.indexOf(item) === -1 ? {i: i++, item} : null;
        }).filter((i) => i );
        
    }

    const tooltipText = (value) => {
        return value;
    }

    const rows = (topSheetData) => {
        if(!topSheetData) return [];
        const fullRows = [];
        topSheetData.map((c) => fullRows.push(c.locs));
        return fullRows;
    }

    const getCell = (item) => {
        return item === "MCN" ? CellMcn :
        item === "TotalLocs" ? CellTotalLoac :
        simpleRender.indexOf(item) >= 0 ? SimpleCellRenderer : CellRendererCounts;
    }

    const cellClass = (item) => {
        const spineClass = cellSpineClass(item);
        const date = utils.convertPeriodToDate(item);
        const futurePeriodClass = date && utils.isFuturePeriod(date)?  "future-period" : "";
        return `${spineClass} ${futurePeriodClass}`;
    }

    const cellSpineClass = (item) => {
        return spine.indexOf(item) >= 0  ? "spine" : "";
    }
    
    return (
        <div style={{ height: '100%', width: '100%' }} className="ag-theme-alpine">
            <AgGridReact
                ref={gridRef}
                suppressRowTransform={true}
                enableBrowserTooltips={true}
                enableFilter={true}
                rowSelection={'single'}
                autoGroupColumnDef={{ minWidth: 200 }}

                rowClassRules={{
                    'ambassador-row': (params) => {
                        return !!params.data.ambassador;
                    },
                    'totals-row': "data.Type !== 'HC' && data.Type !== 'FS' && data.Type !== 'A'",
                    'match': "data.Draws !== 0 && data.Draws === data.Mbr",
                    }}
                defaultColDef={{
                    resizable: true,
                    floatingFilter: true,
                    headerComponentParams: {
                        menuIcon: 'fa-bars'
                    },
                    floatingFilterComponentParams: {suppressFilterButton:true}
                }}
                rowData={rows(topSheetData)}>
                {headersSpine().sort((a, b) => a.i - b.i).map(({i,item}) => { 
                    return <AgGridColumn 
                        key={i}
                        tooltipField={tooltipText(item)}
                        cellClass={cellSpineClass(item)}
                        cellRendererFramework={getCell(item)}
                        suppressSizeToFit={false}
                        filter={item === "StartDate" || item === "Type"}
                        width={95}
                        field={item} ></AgGridColumn>
                })}
                {headersMonths(topSheetData).sort((a, b) => a.i - b.i).map(({i,item}) => {  
                    return item !== "ambassador" ? 
                        (<AgGridColumn
                            key={i}
                            cellClass={ cellClass(item)}
                            tooltipField={tooltipText(item)}
                            cellRendererFramework={CellRenderer}
                            suppressSizeToFit={false}
                            width={95}
                            field={item} ></AgGridColumn>) : 
                    <AgGridColumn key={i} hide field={item} ></AgGridColumn>
                })}
                
            </AgGridReact>
        </div>
    );
};

export default TopSheetTable;