import API from "./api";

const Auth = {
  has_logged_in_before: function () {
    return localStorage.getItem("user_logged_in_before") === "true";
  },
  is_impersonating: function () {
    return localStorage.getItem("impersonation") === "true";
  },
  is_authenticated: function () {
    return sessionStorage.getObj("user") ? true : false;
  },
  logout: async function (callback) {
    const user = await API.call({
      method: "POST",
      url: "/users/logout",
    });
    sessionStorage.clear();

    return callback(user);
  },
  authenticate: async function (callback) {
    const user = await API.call({
      method: "GET",
      url: "/users/",
    });

    if (user) {
      sessionStorage.setObj("user", user);
      API.call({
        method: "POST",
        url: "/users/addMeta",
        body: { authenticated: true},
      });
    }

    return callback(user);
  },
  get_user: function () {
    //if authenticated
    if (this.is_authenticated()) {
      return sessionStorage.getObj("user");
    } else {
      return { error: true };
    }
  },
  new_user: async function (userObject, callback) {
    const user = await API.call({
      method: "POST",
      url: "/users/",
      body: {
        user: userObject,
      },
    });

    localStorage.setItem("user_logged_in_before", true);

    sessionStorage.setObj("user", user.user);

    callback(user);
  },
  update_local_session: function (userObject) {
    const user = this.get_user();
    for (const key in userObject) {
      user[key] = userObject[key];
    }
    sessionStorage.setObj("user", user);
  },
  update_user: async function (userObject, callback) {
    const response = await API.call({
      method: "PUT",
      url: "/users/",
      body: {
        user: userObject,
      },
    });

    if (response.detailsUpdated) {
      this.update_local_session(userObject);
    }

    callback(response);
  },
  requestNextStage: async function (callback) {
    const response = await API.call({
      method: "PUT",
      url: "/users/requestNextStage",
    });

    if (response.updatedStage) {
      const user = this.get_user();
      user.stage = response.newStage;
      sessionStorage.setObj("user", user);
    }

    callback(response);
  },
  requestMagicLink: async function (body, callback) {
    const response = await API.call({
      method: "POST",
      body,
      url: "/users/requestMagicLink",
    });

    callback(response);
  },
  verifyMagicLink: async function (token, callback) {
    const response = await API.call({
      method: "POST",
      body: { token: token },
      url: "/users/login",
    });

    callback(response);
  },
};

export default Auth;
