import { useHistory } from "react-router";
import React, { useState } from "react";

function DesktopOnboardingMenu({variant}) {
  const path = window.location.pathname;
  const history = useHistory();
  const [showMobMenu, setShowMobMenu] = useState();

  return (
    <React.Fragment>
      {showMobMenu && (
        <div
          onClick={() => setShowMobMenu(false)}
          className="mobileMenuDropdown animate__animated animate__fadeIn"
        >
          <div className="animate__faster animate__animated animate__fadeInDown">
            <div>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.joinstepladder.com/how-our-circles-work/"
              >
                About Our Circles
              </a>
            </div>
            <div>
              <a target="_blank" rel="noopener noreferrer" href="https://www.joinstepladder.com/contact/">
                Contact Us
              </a>
            </div>
            <div>
              <a href="/logout">Logout</a>
            </div>
          </div>
        </div>
      )}
      <div className="layoutMenu">
        <div>
          <img
            style={{ cursor: "pointer" }}
            alt="logo"
            className="logo"
            src="./images/stepLadderLogo.png"
            onClick={() =>
              (window.location.href = "https://joinstepladder.com")
            }
          ></img>
        </div>
        {path !== "/getStartedApply" ? (
          <div onClick={() => setShowMobMenu(true)} className="layoutMenuIcon">
            <i className="fas fa-align-right"></i>
          </div>
        ) : (
          <div
            className= {`loginMobile ${variant ? "" : "main-color"}`}
            onClick={() => history.push("/welcomeBack")}
          >
            <i className="far fa-user"></i> Login
          </div>
        )}

        <h2>
          Raise money for your<br></br>future with StepLadder
        </h2>

        <div>
          <img
            src="./images/signup/phonesignup.png"
            alt="phones"
            className="phones"
          ></img>
        </div>
      </div>
    </React.Fragment>
  );
}

export default DesktopOnboardingMenu;
