import { isMobile } from "../../../utils/generic";

function GenericCategories(props) {
  const selected = props.selected;
  const list = props.data;

  return (
    <div>
    <div className="hideScrollbar categories">
      {list.map((itm, i) => (
        <div key={i} className={(selected.key && selected.key === itm.key ? "active":"")} onClick={() => props.onChange(itm)}>
            <div>
                <i className={itm.icon} />
                <div style={{marginTop:"5px"}}>{itm.text}</div>
            </div>
        </div>
      ))}
     
    </div>
    {isMobile && <div style={{position:'absolute', backgroundColor:"transparent", width:"auto", height:"auto", top:'40px', right:'0px', color:"grey"}}>
        <i className="pi pi-chevron-circle-right" style={{fontSize:"24px"}} />
      </div>}
    </div>
  );
}

export default GenericCategories;
