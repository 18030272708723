import { useState } from "react";
import { useUser } from "../../../../contexts/UserContext";

import certifiedSophisticatedInvestor from "./InvestorSelection/certifiedSophisticated";
import everyDayInvestor from "./InvestorSelection/everyDay";
import highNetWorth from "./InvestorSelection/highNetWorth";
import selfCertifiedInvestor from "./InvestorSelection/selfCertified";
import InvestorClassification from "./InvestorClassification";
import InvestorClassificationDetails from "./InvestorClassificationDetails";

function InvestorClassificationMain() {
  const investorOptions = [
    everyDayInvestor,
    selfCertifiedInvestor,
    certifiedSophisticatedInvestor,
    highNetWorth,
  ];
  const userContext = useUser();
  const [selectedOption, setSelectedOption] = useState(investorOptions.find((itm) => userContext.user?.TypeOfInvestor === itm.title));


  const back = () => {
    setSelectedOption(null)
  }

  if(selectedOption) {
    return <InvestorClassificationDetails back={back} selectedOption={selectedOption}/>
  }

  return (
    <InvestorClassification setOption={setSelectedOption} investorOptions={investorOptions} />
  );
}

export default InvestorClassificationMain;
