import React from "react";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import "./styles/userStyles.css";

import { UserContextProvider } from "./contexts/UserContext";
import { UIContextProvider } from "./contexts/UIContext";
import { userRoutes } from "./utils/userRoutes";
import RouteCheck from "./components/generic/RouteCheck";
import URLRedirect from "./components/onboarding/unauthenticated/URLRedirect";
import MMOB from "./components/onboarding/unauthenticated/mmob/MMOB";
import InsuranceQuiz from "./components/onboarding/unauthenticated/insuranceQuiz/InsuranceQuiz";
import PreFill from "./components/onboarding/unauthenticated/PreFill";

function UserContainer() {
  const routes = userRoutes;

  const urlRedirect = window.location.pathname.indexOf("urlRedirect") > -1;
  const mmob = window.location.pathname.indexOf("mmob") > -1;
  const insuranceQuiz = window.location.pathname.indexOf("insuranceQuiz") > -1;
  const preFill = window.location.pathname.indexOf("preFill") > -1;

  if(urlRedirect){
    return <URLRedirect />
  }

  if(mmob){
    return <MMOB />
  }

  if(insuranceQuiz){
    return <InsuranceQuiz />
  }

  if(preFill){
    return <PreFill />
  }

  return (
    <Router>
      <UIContextProvider>
        <UserContextProvider>
            <Switch>
              {routes.map((route, i) => (
                <RouteCheck {...route} key={i} />
              ))}
            </Switch>
        </UserContextProvider>
      </UIContextProvider>
    </Router>
  );
}

export default UserContainer;
