import API from "./api";

function searchUserByName(name){
    return API.call({
        url: "/users/search?name=" + encodeURIComponent(name),
        method: "GET",
      });
}

function editUserProperty(userID, updateObject){
    return API.call({
        url: "/users/adminUserUpdate",
        method: "PUT",
        body: {
          userID: userID,
          updateObject: updateObject,
        },
      });
}

function getConversions(){
  return API.call({
      url: "/users/conversions",
      method: "GET",
    });
}

export {searchUserByName, editUserProperty, getConversions};