import { useState } from "react";

import { Dropdown } from "primereact/dropdown";

function ArrearsYesNoOption({ id, onChange, label, value }) {
  const makeChange = (val) => {
    onChange(id, val);
  };

  const options = ["Yes", "No"];

  return (
    <div>
      <b>{label}</b><br />
      <Dropdown
        value={value}
        options={options}
        onChange={(e) => makeChange(e.value)}
        placeholder="Select"
      />
    </div>
  );
}

export default ArrearsYesNoOption;
