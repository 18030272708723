import { Button } from "primereact/button";
import { useHistory } from "react-router";

function PaymentsCircleMissing() {
  const history = useHistory();

  return (
    <div>
      <h2>Uh-oh, we couldn't find your Circle!</h2>

      <div style={{marginTop:"20px", marginBottom:"15px"}}>Not to worry, try clicking here to select a new Circle:</div>
      <Button
        label="Select New Circle"
        onClick={() => history.push("/circleInvite")}
      />
    </div>
  );
}

export default PaymentsCircleMissing;
