import React, {useState} from "react";
import {useHistory} from "react-router";
import { Dialog } from "primereact/dialog";
import TermsBox from "../../../components/onboarding/authenticated/terms/termsBox";
import { Button } from 'primereact/button';
import { sendRenwal } from '../../../services/circles';


function CircleRenewal({circle, fetchUser}) {
    const [isLoading, setLoading] = useState(false);
    const history = useHistory();
    console.log("CircleRenewal", circle)

    const [showRenewal, setShowRenewal] = useState(!!circle);

    async function applyResult(isRenew) {
        await sendRenewalResult(isRenew)
        hideRenewal();
    }

    function hideRenewal() {
        setShowRenewal(false);
    }

    async function circleInvite() {
        await applyResult(false);
        history.push("/circleInvite");
    }
    
    async function sendRenewalResult(isRenew) {
        try {
            setLoading(true)
            const {data} = await sendRenwal(isRenew, circle);
            if(data.renewalSeen) {
                fetchUser();
                history.push("/dashboard");
            }
        } finally {
            setLoading(false)
        }
    }
    const pickCircleText = (
        <div className="p-p-3">
            <h2>Your Circle is ending soon and we'd love to invite you to join a new Circle.</h2>
        </div>
    );
    const body = () => {

        if(circle.excelID) {
            return <TermsBox circleData={circle} />
        } else {
            return pickCircleText
        }

    }
    const footer = (
        <div className="p-grid">
            <Button disabled={isLoading} label="Close" className="p-col p-button-text p-button-plain" onClick={() => applyResult(false)} />
            <Button 
                disabled={isLoading}
                label={circle.excelID ? "Apply" : "Join a new Circle"}
                className="p-col p-button-info p-button-raised"
                onClick={() => circle.excelID ? applyResult(true) : circleInvite()} 
            />
        </div>
    );


    const header = (
        <span className="txt-rem-md">
            Your Circle's coming to an end, would you like to renew your spot?
        </span>
    );

    return (
        <Dialog
            header={header}
            footer={footer}
            className={`renewal-dialog ${circle.excelID ? "purple" : ""}`}
            visible={showRenewal}
            onHide={(sendToCircleInvite) => hideRenewal(sendToCircleInvite)}>  
            {body()}
        </Dialog>
    );
}

export default CircleRenewal;