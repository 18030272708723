import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { TabPanel, TabView } from "primereact/tabview";

function DropoutsList({month, actualDropouts, potentials, isLoading}){

    const totals = summariseData(actualDropouts);

    return <DataTable
        style={{marginTop:"50px"}}
        value={totals}
        loading={isLoading}>
        <Column field="dropoutReason" header="Dropout Reason" />
        <Column field="count" header={"Count ("+actualDropouts.length+")"} />
    </DataTable>
}

function summariseData(actuals){
    const actualTotals = [];

    for(const item of actuals){
        let index = actualTotals.findIndex(i => i.dropoutReason === item.dropoutReason);

        if(index === -1){
            actualTotals.push({
                dropoutReason: item.dropoutReason,
                count: 0
            });

            index = actualTotals.length - 1;
        }

        actualTotals[index].count++;
    }

    return actualTotals.sort((a, b) => b.count - a.count);
}

export default DropoutsList;