import InsuranceQuizQuestion from "../components/InsuranceQuizQuestion";

function OwnValuableItems({ onNext, onChange }) {
  return (
    <InsuranceQuizQuestion
      title="Do you own valuable items in your home that you wouldn’t want to lose?"
      onChange={(newval) => onChange({ ownValuableItems: newval })}
      description="Question 3 of 3"
      onNext={onNext}
    />
  );
}

export default OwnValuableItems;
