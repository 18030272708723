import React, { useRef } from "react";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";

import { useHistory } from "react-router";
import DrawDayTotal from "../drawDayTotal";
import { Button } from "primereact/button";

function DrawDaySavedTable({isLoading, circleData=[] }) {
  const history = useHistory();

  const dt = useRef(null);

  const viewUser = (e) => {
    if(!e.value.participants.length) {
      const id = e.value.participants ? e.value.participants.user : null;
      if(id) {
          history.push("/adminPortal/user?id=" + id);
      }
    }
  }
  const exportCSV = () => {
    dt.current.exportCSV();
  }

  const getTotal = () => {
    return circleData.reduce(
      (accumulator, current) =>
        current.period * current.monthly_investment + accumulator,
      0
    );
  };

  const header = <div className="flex">
    <div className="flex-wide">
    <DrawDayTotal label={"Total Drawn: £"} className="bg-white p-mr-2 p-shadow-1" total={getTotal()}/>
    <DrawDayTotal
      label={"Total circles: "}
      className="bg-white p-mr-2 p-shadow-1"
      total={circleData.length}/>
    </div>
    
    <Button 
      type="button"
      className="p-button-raised p-button-info p-button-sm"
      icon="pi pi-upload"
      label="Export CSV"
      onClick={exportCSV}>
    </Button>

  </div>;

  return (
    <React.Fragment>
      
      <DataTable
        sortField="excelID"
        value={circleData.map((c, i) =>({...c, num: i+1}))}
        onSelectionChange={viewUser} 
        selectionMode="single"
        ref={dt}
        loading={isLoading}
        header={header}
      >
        <Column field="num" style={{width: "100px"}} sortable header="#"></Column>
        <Column field="excelID" style={{width: "150px"}} sortable header="Circle ID"></Column>
        <Column
          body={(row) => row.user.firstname + " " + row.user.lastname}
          header="Name"
        ></Column>
        <Column
          header="Email"
          field="user.email"
        ></Column>
        <Column
          body={(row) =>
            "£" + (row.monthly_investment * row.period).toLocaleString()
          }
          header="Amount"
        ></Column>
      </DataTable>
    </React.Fragment>
  );
}

export default DrawDaySavedTable;
