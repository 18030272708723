import {houseRelatedReasons, userStages, refferalsToAmbassadors} from "../../common/services/userHelpers";

const env = process.env.REACT_APP_ENV;

function getNameOfMember(user, isAdmin) {
  try {
    if(isAdmin) {
      return "StepLadder"
    }
    return user.nickname ? user.nickname : user.firstname;
  } catch (err) {
    return "Anonymous";
  }
}

const pictures = new Array(9)
  .fill(undefined)
  .map((itm, i) => "./images/userPictures/" + i + ".png");

function getPictureURL(user) {
  try {
    if (user && user.picture) {
      const userChosenPicture = pictures[user.picture];

      if (userChosenPicture) {
        return userChosenPicture;
      }
    }
    return pictures[0];
  } catch (err) {
    return pictures[0];
  }
}

function isHouseRelated(reasonForSaving) {
  return houseRelatedReasons.indexOf(reasonForSaving) > -1;
}

const getCookie = (name) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
};
const deleteCookie = (name) => {
  const domain = env === "testdev" ? "" : "domain=.joinstepladder.com;"
  document.cookie = `${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;${domain}`;
  console.log(`${name}=;path=/;expires=Thu, 01 Jan 1970 00:00:01 GMT;${domain}`)
};

const getURLParam = (name) => {
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get(name);
};

const isStageAfter = (stage1, stage2) => {
  return userStages.indexOf(stage1) > userStages.indexOf(stage2);
};

function isRefferalsToAmbassadors(ambassador) {
  return Object.entries(refferalsToAmbassadors).some(([key, value]) => value === ambassador);
}

export {
  getNameOfMember,
  getPictureURL,
  getCookie,
  deleteCookie,
  isHouseRelated,
  getURLParam,
  isStageAfter,
  pictures,
  isRefferalsToAmbassadors
 };
