import React from "react";

import "primeflex/primeflex.css";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { SelectButton } from "primereact/selectbutton";
import adminDashboardStyles from "../../style/admin_dashboard.module.css";
import { Button } from "primereact/button";
import ParticipantsView from "./participantsView";
import API from "../../services/api";
import ViewAmbassadors from "../adminAmbassadors/viewAmbassadors";
import AmbassadorService from "../../services/ambassadorService";

class UpdateCircle extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      circleObject: {
        excelID: 53,
        availability: true,
        participants: [],
        monthly_investment: 100,
        reserve_period: 2,
        admin_fee: 2,
        period: 10,
        ambassador: { ambassadorName: "" },
        ambassadors: [],
        startingDate: moment().format("YYYY-MM-DDTHH:mm:ss") + "Z",
        isLoading: true,
      },
      changes: {},
    };

    this.visibleOptions = [
      { name: "Yes", code: true },
      { name: "No", code: false },
    ];
  }

  async componentDidMount() {
    if (!this.state.ambassadors?.length > 0) {
      const { data: foundAmbassadors } = await AmbassadorService.getAmbassadorsList2();
      this.setState({ ambassadors: foundAmbassadors });
    }
  }

  isVisibleEditable(){
    if(this.state.circleObject.ambassador){
      return true;
    }

    const circleStartDate = moment.utc(this.state.circleObject.startingDate);
    const startThreshold = moment.utc().add(1, "M").startOf("M");
    const endThreshold = moment.utc(startThreshold).endOf("M");
    
    return circleStartDate.isSameOrAfter(startThreshold) && circleStartDate.isSameOrBefore(endThreshold);
  }


  //this function receives data from the parent (the circle to edit)
  async updateCircleObject(circleObject) {
    this.setState({ isLoading: true });

    //get the full object
    const circle = await API.call({
      url: "/circles/circle?withRequested=true&id=" + circleObject._id,
      method: "GET",
    });

    console.log(circle);

    //set availability locally to the format we need for selection (js object)
    circle.availability = this.visibleOptions.filter(function (itm) {
      return circle.availability === itm.code;
    })[0];

    //set startingDate locally to the format we need for selection (date)
    circle.startingDate = new Date(circle.startingDate);

    this.setState({ circleObject: circle, isLoading: false });
  }

  //this function updates it locally before submission
  updateCircleLocally(key, value) {
    var localCircleObject = this.state.circleObject;

    //if its not these fields, its a number because inputtext passes as a string
    if (["startingDate", "ambassador", "availability"].indexOf(key) ===  -1) {
      value = parseFloat(value);
    }

    localCircleObject[key] = value;

    //store the changes
    var changes = this.state.changes;
    changes[key] = value;

    this.setState({ circleObject: localCircleObject, changes: changes });
  }

  async saveCircleUpdates() {
    var changes = JSON.parse(JSON.stringify(this.state.changes));

    if (changes.availability) {
      changes.availability = changes.availability.code;
    }

    if (changes.startingDate) {
      changes.startingDate =
        moment(changes.startingDate).format("YYYY-MM-DDTHH:mm:ss") + "Z";
    }

    if (changes.ambassador) {
      changes.ambassador = changes.ambassador._id;
    }

    this.props.showLoading();

    var response = await API.call({
      url: "/circles/updateCircle2",
      method: "PUT",
      body: {
        params: {
          circleUpdates: changes,
          circleID: this.state.circleObject._id,
        },
      },
    });

    this.props.hideLoading();

    if (response.circleUpdated) {
      this.props.showToast("Update successful!", "success");
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      this.props.showToast(
        "Failed, reason:" + response.error ? response.error : "Generic failure"
      );
    }
  }

  showAmbassadorSelection() {
    this.setState({ ambassadorSelectionVisible: true });
  }

  render() {
    return (
      <React.Fragment>
        <Dialog
          header={"Select an Ambassador"}
          visible={this.state.ambassadorSelectionVisible}
          style={{ width: "50vw" }}
          onHide={() => this.setState({ ambassadorSelectionVisible: false })}
        >
          <ViewAmbassadors
            ambassadorsList={this.state.ambassadors}
            onSelectionChange={(ambassador) => {
              this.updateCircleLocally("ambassador", ambassador);
              this.setState({ ambassadorSelectionVisible: false });
            }}
          ></ViewAmbassadors>
        </Dialog>
        <Dialog
          header={"Update Circle: " + this.state.circleObject.excelID}
          visible={this.props.visible}
          style={{ width: "50vw" }}
          onHide={() => this.props.onHide()}
        >
        
          {this.state.isLoading && <div>Loading..</div>}
          {!this.state.isLoading && (
            <React.Fragment>
              {!this.state.circleObject.ambassador && <div style={{marginBottom:"1.5em"}}>
                Members will be able to select this Circle in {moment.utc(this.state.circleObject.startingDate).subtract("1", "M").startOf().format("MMMM YYYY")}.
              </div>}
              <div className={adminDashboardStyles.modalInputBox}>
                <div>Start Date</div>
                <Calendar
                  value={this.state.circleObject.startingDate}
                  onChange={(e) =>
                    this.updateCircleLocally("startingDate", e.value)
                  }
                  view="month"
                  dateFormat="mm/yy"
                  yearNavigator
                  yearRange="2010:2030"
                />
              </div>
              <div className={adminDashboardStyles.modalInputBox}>
                <div>Monthly Amount</div>
                <InputText
                  type="number"
                  value={this.state.circleObject.monthly_investment}
                  onChange={(e) => {
                    this.updateCircleLocally(
                      "monthly_investment",
                      e.target.value
                    );
                  }}
                ></InputText>
              </div>
              <div className={adminDashboardStyles.modalInputBox}>
                <div>Participants/Months</div>
                <InputText
                  type="number"
                  value={this.state.circleObject.period}
                  onChange={(e) => {
                    this.updateCircleLocally("period", e.target.value);
                  }}
                ></InputText>
              </div>
              <div className={adminDashboardStyles.modalInputBox}>
                <div>Total</div>
                <InputText
                  type="number"
                  value={
                    this.state.circleObject.period *
                    this.state.circleObject.monthly_investment
                  }
                  disabled={true}
                ></InputText>
              </div>
              <div className={adminDashboardStyles.modalInputBox}>
                <div>Admin Fee</div>
                <InputText
                  type="number"
                  value={this.state.circleObject.admin_fee}
                  onChange={(e) => {
                    this.updateCircleLocally("admin_fee", e.target.value);
                  }}
                ></InputText>
              </div>
              <div className={adminDashboardStyles.modalInputBox}>
                <div>Reserve Period</div>
                <InputText
                  type="number"
                  value={this.state.circleObject.reserve_period}
                  onChange={(e) => {
                    this.updateCircleLocally("reserve_period", e.target.value);
                  }}
                ></InputText>
              </div>
              <div className={adminDashboardStyles.modalInputBox}>
                <div>{!this.state.circleObject.ambassador ? "Accepting New Members?" : "Visible to select?"}</div>
                <SelectButton
                  optionLabel="name"
                  value={this.isVisibleEditable() ? this.state.circleObject.availability : { name: "No", code: false }}
                  options={this.visibleOptions}
                  onChange={(e) =>
                    this.updateCircleLocally("availability", e.value)
                  }
                  disabled={!this.isVisibleEditable()}
                ></SelectButton>
              </div>
              <div className={adminDashboardStyles.modalInputBox}>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    this.updateCircleLocally("ambassador", {
                      _id: "removeAmbassador",
                      ambassadorName: "",
                    });
                  }}
                >
                  Ambassador{" "}
                  {this.state.circleObject.ambassador && (
                    <i
                      className="pi pi-times-circle"
                      style={{ color: "red" }}
                    ></i>
                  )}
                </div>
                <InputText
                  readOnly
                  onClick={(e) => {
                    this.showAmbassadorSelection();
                  }}
                  style={{ cursor: "pointer" }}
                  value={
                    this.state.circleObject.ambassador
                      ? this.state.circleObject.ambassador.ambassadorName
                      : ""
                  }
                ></InputText>
              </div>
              {this.state.circleObject.ambassadorID && (
                <div className={adminDashboardStyles.modalInputBox}>
                  <div>Ambassador ID</div>
                  <InputText
                    disabled={true}
                    value={this.state.circleObject.ambassadorID}
                  ></InputText>
                </div>
              )}
              <div>
                <Button
                  onClick={(e) => {
                    this.saveCircleUpdates();
                  }}
                  label="Save Changes"
                ></Button>
              </div>
              <br></br>
              <hr></hr>
              <br></br>
              <ParticipantsView
                history={this.props.history}
                participants={this.state.circleObject.participants}
              ></ParticipantsView>
            </React.Fragment>
          )}
        </Dialog>
      </React.Fragment>
    );
  }
}

export default UpdateCircle;
