import userAPI from "./userAPI";

function getFullVendors(){
    return userAPI.call("/vouchers/availableVouchers", { method: "GET" });
}

function requestGiftCard(){
    return userAPI.call("/vouchers/requestGiftCard", { method: "GET" });
}

function requestGiftCards(vouchers, userInfo, userId){
    return userAPI.call("/vouchers/requestVouchersForUser", { method: "POST", body: {vouchers, userInfo, userId} });
}

function hideRequestedView(){
    return userAPI.call("/vouchers/hideRequestedView", { method: "GET" });
}

function submitFirstStepSelection(optionkey, extraInfo){
    return userAPI.call("/users/submitDrawDayOption", {method: "POST", body: {
        option: optionkey,
        extra: extraInfo
    }});
}

function uploadID(base64, ignoreMango){
    return userAPI.call("/users/kycDoc", {method: "POST", body: {
        kycDocBase64: base64,
        ignoreMango
    }});
}

function getKYCStatus(){
    return userAPI.call("/users/kyc", {method: "GET"});
}

export { 
    submitFirstStepSelection,
    getFullVendors,
    requestGiftCard,
    requestGiftCards,
    hideRequestedView,
    getKYCStatus,
    uploadID
};