import { InputText } from "primereact/inputtext";
import { Checkbox } from "primereact/checkbox";

const CertifiedReason = ({reason, reasonSelected, answered}) => {
    return <div className="p-mb-4 p-shadow-1 p-p-3">
      <div className="p-grid">
        <div className="p-col-1">
          <Checkbox
            checked={reason.selected}
            onChange={(e) => reasonSelected(e.checked) } />
        </div>
        <div className="p-col-11 p-text-bold">
          {reason.text}
        </div>
      </div>
      {reason.selected && 
        <div className="p-mt-3">
          <div>
            {reason.question}
          </div>
          <div>
            <InputText
                  className="p-mt-2 width-100"
                  value={reason.answer}
                  onChange={(e) => answered(e.target.value)} />
          </div>
        </div>}
    </div>
  }

  export default CertifiedReason;