import React, { useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import moment from "moment";
import { currencyFormat } from '../../../../users/utils/generic';
import VouchersDialog from './vouchersDialog';
import { InputText } from "primereact/inputtext";

const statuses = {
    ACTIVE: "success",
    FAIL: "danger",
    PROCESSING: "warning",
  };
  
function VouchersRequestList({requestedVouchers}) {
    const [globalFilter, setGlobalFilter] = useState(null);
    const [showDialog, setShowDialog] = useState(false);
    const [selected, setSelected] = useState(null);
    // const vouch = {value: 5, count: 2};
    // const req = {
    //     name: "name",
    //     email: "email@dddddd.com",
    //     status: "success",
    //     walletId: "11244535",
    //     requestedDate: new Date(),
    //     vendors: [{ name: "ikea", vouchers: [vouch, vouch, vouch]}, { name: "argos", vouchers: [vouch]}]
    // };
    // const req1 = {...req, status: "fail"}; 
    // const req2 = {...req, name: "ttttt", status: "pending", vendors: [{ name: "ikea", vouchers: [vouch, vouch, vouch]}, { name: "john lewis", vouchers: [vouch, vouch, vouch]}, { name: "argos", vouchers: [vouch]}]}; 
        
    const reqs = requestedVouchers; //[req,req1,req2,req,req,req,req ];

    // const [requests, setRequests] = useState(reqs);
    const date = (rowData) =>  {
        return moment(rowData.requestedDate).format("DD-MM-YYYY");
    };

    const status = (rowData) => {
        const classStatus = `p-tag p-tag-rounded p-tag-${
          statuses[rowData.status] || "info"
        }`;
        return (<span style={{minWidth: "60px"}} className={classStatus}>{rowData.status}</span>);
      };

    const amount = (rowData) => {
        return currencyFormat(rowData.amount || 0) ;
    };

    const vendorsBody = (rowData) => {
        return <span className={"p-button p-button-outlined p-badge p-button-rounded vendor-tag p-mr-1"}>{rowData.vendorName}</span>;
    };

    const name = (rowData) => {
        return `${rowData.user.firstname || ""} ${rowData.user.lastname || ""}`
    };
    const email = (rowData) => {
        return `${rowData.user.email || ""}`
    };

    const onRowClick = (userInfo) => {
        setSelected(userInfo);
        setShowDialog(true);
        console.log(userInfo)
    }

    const header = (
        <div className="table-header">
            <span className="p-input-icon-left">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
            </span>
        </div>
    );
    
    return (
        <React.Fragment>
            <div>
                <div className="card blue-table">
                    <DataTable 
                        className="table-rounded"
                        value={reqs}
                        globalFilter={globalFilter}
                        header={header}
                        paginator
                        selectionMode="single"
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={50}
                        onSelectionChange={(e) => {
                            // const payments = e.value.succeededPayments.concat(e.value.failedPayments);
                            console.log("click")
                            return onRowClick(e.value);
                        }}
                        rowsPerPageOptions={[10, 20, 50, 100]}>
                            <Column sortable field="name" header="Name" body={name}></Column>
                            <Column sortable field="email" header="Email" body={email}></Column>
                            <Column sortable field="walletId" header="Wallet" ></Column>
                            <Column field="paymentId" header="PaymentId" ></Column>
                            <Column sortable field="status" header="Status" body={status}></Column>
                            <Column sortable field="requestedDate" header="Date" body={date}></Column>
                            <Column field="vendorName" header="Vendor" body={vendorsBody}></Column>
                            <Column sortable field="amount" header="Amount" body={amount}></Column>
                    </DataTable>
                </div>
            </div>
            <VouchersDialog
                visible={showDialog}
                selected={selected}
                onHide={(e) => {
                    setShowDialog(false);
                }}
            ></VouchersDialog>
        </React.Fragment>);
}

export default VouchersRequestList;