import React, { useEffect, useState, useRef } from "react";
import { MultiSelect } from 'primereact/multiselect';
import "primeflex/primeflex.css";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

function GeneratedPaymentsView({ stagedPayments }) {
    const [loading, setLoading] = useState(false);
    const columns = [
        {field: 'creditCheck', header: 'credit check'},
        {field: 'creditCheck2', header: 'credit check 2'},
        {field: "lateJoiner", header: "late joiner"}
    ];
    const dt = useRef(null);
    const [selectedColumns, setSelectedColumns] = useState([]);

    useEffect(() => {
        setLoading(!stagedPayments)
      }, [stagedPayments]);

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const formatToPounds = (value) => {
        return "£" + value;
    }

    const columnComponents = selectedColumns.map(col => {
        return <Column sortable style={{width: "50px"}} key={col.field} field={col.field} header={col.header} />;
    });

    const onColumnToggle = (event) => {
        const orderedSelectedColumns = columns.filter(col => [...event.value].some(sCol => sCol.field === col.field));
        setSelectedColumns(orderedSelectedColumns);
    }

    const header = <div className="flex">
        <div className="flex-wide">
            <MultiSelect
                value={selectedColumns}
                options={columns}
                optionLabel="header"
                onChange={onColumnToggle}
                style={{width:'20em'}}/>
        </div>
        
        <Button 
            type="button"
            className="p-button-raised p-button-info p-button-sm"
            icon="pi pi-upload"
            label="Export CSV"
            onClick={exportCSV}>
        </Button>

    </div>;

    return (<>
        <div className="blue-table">
            <DataTable
                ref={dt}
                header={header}
                className="table-rounded p-datatable-sm"
                loading={loading}
                value={stagedPayments?.map((p, idx) => ({...p, idx: idx+1}))}
            >
                <Column style={{width: "20px"}} field="idx" sortable header="#"></Column>
                <Column style={{width: "50px"}} field="fullName" sortable header="Name"></Column>
                <Column style={{width: "50px"}} field="mangoID" sortable header="mangoID"></Column>
                <Column style={{width: "60px"}} field="email" sortable header="email"></Column>
                <Column style={{width: "30px"}} field="admin_fee" body={({admin_fee})=> formatToPounds(admin_fee)} sortable header="fee"></Column>
                <Column style={{width: "50px"}} field="monthly_investment" body={({monthly_investment})=> formatToPounds(monthly_investment)} sortable header="month invest"></Column>
                <Column style={{width: "30px"}} field="period" sortable header="period"></Column>
                <Column style={{width: "50px"}} field="walletID" sortable header="wallet"></Column>
                <Column style={{width: "50px"}} field="ambassadorName" sortable header="ambassador"></Column>
                <Column style={{width: "50px"}} field="excelID" sortable header="excelID"></Column>
                <Column style={{width: "50px"}} field="availability" sortable header="availability"></Column>
                {columnComponents}
                <Column style={{width: "50px"}} field="mandate" sortable header="mandate"></Column>
            </DataTable>
        </div>
    </>
    )
}

export default GeneratedPaymentsView;