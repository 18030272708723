
import { ProgressSpinner } from "primereact/progressspinner";

function SmallLoadingOverlay() {
  return (
    <div className="spinner-wrapper">
        <ProgressSpinner
        className="progress-spinner"
        strokeWidth="2"
        fill="#EEEEEE"
        animationDuration=".5s"
        />
    </div>
  );
}

export default SmallLoadingOverlay;