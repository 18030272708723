
const partners = {
    checkMyFile: {
        logo: "./images/addons/logos/checkmyfile-long.png",
        text: "See how your debts are being reported about you",
        button: {
            link: "https://www.checkmyfile.com/?ref=stepladder&cbap=1&tap_s=2531037-f156f6",
            name: "Start Free Trial",
            trackName: "checkMyFile"
        }
    },
    hargreavesL: {
        logo: "./images/addons/logos/hargreaves-lansdown.png",
        text: "Invest your money to build on what you have already saved with us",
        button: {
            link: "https://hl.7eer.net/rnye55",
            name: "Invest your money",
            trackName: "hargreavesLansdowne"
        }
    },
    snoop: {
        logo: "./images/addons/logos/snoop.png",
        text: "Connect your bank account and receive notifications on where you can save money at places you are already spending",
        button: {
            link: "https://www.members.joinstepladder.com/urlRedirect?url=https://snoop.sjv.io/b31QJB&key=Snoop&email=",
            name: "Join today",
            trackName: "snoop"
        }
    },
    fairfax: {
        logo: "./images/addons/logos/fairfax.png",
        text: "Speak to a mortgage broker for free to understand your affordability",
        button: {
            link: "https://fairfaxfinancial.co.uk/",
            name: "Find out more",
            trackName: "fairfaxFinancial"
        }
    },
    stepladder: {
        logo: "./images/stepLadderLogo2.png",
        text: "Take a stride towards your house deposit with a Circle dedicated towards buying a house",
        button: {
            link: "/circleInvite",
            name: "Join today",
            trackName: "joincircle"
        }
    },
    boots: {
        logo: "./images/addons/logos/boots.png",
        text: "Browse the latest health and beauty products",
        button: {
            link: "https://www.members.joinstepladder.com/urlRedirect?url=https://tidd.ly/3y35Ng6&key=boots&email=",
            name: "Browse products",
            trackName: "boots"
        }
    },
    blow: {
        logo: "./images/addons/logos/blow.png",
        text: "Order an at home massage or treatment and get £12 off with code FIRST12",
        button: {
            link: "https://www.members.joinstepladder.com/urlRedirect?url=https://tidd.ly/3w0mDt9&key=Blow&email=",
            name: "Treat yourself",
            trackName: "blow"
        }
    }
}

const options = {
    drawn: {
        img: "",
        bg: "./images/drawday/drawday-confetti-bg.png",
        header: "./images/drawday/header-confetti.png",
        text: "Your money is ready for you",
        title: "Congratulations! We’d love to know what you will do with your savings now",
        texts: [],
        button: {
            link: "/dashboard/referfriend",
            name: "Invite My Friends"
        },
        otherText: "Well done for hitting your goal! We would love to know what you plan to do with your savings so we can try to help your further."
    }, 
    notdrawn: {
        img: "./images/drawday/light.png",
        bg: "./images/drawday/drawday-bg.png",
        header: "./images/drawday/header.png",
        text: "",
        title: "Have you been thinking about what you will do with your savings when you are drawn?",
        texts: [],
        button: {
            link: "https://blog.joinstepladder.com/time-to-tackle-some-debt",
            name: "Read Top Tips"
        },
        otherText: "Keep working towards your goal. Please tell us on what you plan to do with your savings."
    }
}

// const drawdayConfig = {
//     options,
//     partners
// };

export {options, partners};