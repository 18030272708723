import React, {useEffect, useState} from "react";
import TrackerTable from "./trackerTable";
import API from "../../services/api";

import Moment from 'moment';
import NotesEditorPopup from "./notesEditorPopup";
import CircleSelector from "../../components/generic/circleSelector";
import { Fieldset } from "primereact/fieldset";
import CircleService from "../../services/circleService";
import { InputSwitch } from 'primereact/inputswitch';
import { Toolbar } from 'primereact/toolbar';

function CircleTracker({showToast}) {

  const [circle, setCircle] = useState({});
  const [circles, setCircles] = useState([]);
  const [showCompliance, setShowCompliance] = useState(false);
  const [showNotesPopup, setShowNotesPopup] = useState(false);
  const circleIDFromStorage = 
    localStorage.getItem("circleID") !== "undefined" &&
    localStorage.getItem("circleID") !== "null" ?
    localStorage.getItem("circleID") : null;

  useEffect(() => {
    getCircles();
  }, []);

  useEffect(() => {
    if(circles.length) {
      getCircle();
    }
  }, [circles]);

  async function getCircles() {
    //gets all circles
    const foundCircles = await CircleService.getCircleList();
    setCircles(foundCircles.sort((a, b) => b.excelID - a.excelID));
  }

  async function getCircle(circ) {
    try {
      const circleID = circ ? circ._id : circleIDFromStorage || circles[0]._id;

      if(!circleID) {
        return;
      }
      const data = await CircleService.circleTracker(circleID); // await API.call2(`/circles/circleTracker?circleID=${circleID}&withCircles=${false}`, { method: "GET" });
 
      if(data.error) {
        throw new Error(data.error.message || data.error)
      }
      if(data.circle) {
        localStorage.setItem("circleID", data.circle._id);
        setCircle(data.circle);

      }
      
    } catch (error) {
      showToast(error.message || error, "error");
    }
  }

  function isFinished({ startingDate, period }) {
    const today = Date.now();
    const startDate = new Date(startingDate);
    const endDate = new Date(startDate.setMonth(startDate.getMonth() + period));
    return today > endDate;
  }

  function updateNotes(notes, circle) {
    setCircle({...circle, notes})
  }

  function startDate() {
    return circle.startingDate
      ? Moment(new Date(circle.startingDate)).format("MMM-YYYY")
      : "-";
  }
  function endDate() {
    return circle.endingDate
      ? Moment(new Date(circle.endingDate)).subtract(1, "day").format("MMM-YYYY")
      : "-";
  }

  const leftContents = (
  <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-between"}}>
    <div className="prop p-mr-5">
      <h4> 
        Start: <span>{startDate()}</span>
      </h4>
    </div>

    <div className="prop p-mr-5">
      <h4> 
        End: <span>{endDate()}</span> 
      </h4>
    </div>
    <div className="prop p-mr-5">
      <h4> 
        Status: <span className={isFinished(circle) ? "finished" : "active"}>{isFinished(circle) ? " finished" : " active"}</span>
      </h4>
    </div>
     
  </div>);

  const rightContents = (
    <div style={{display: 'flex', flexDirection: "row", justifyContent: "space-between"}}>
      <div className="prop p-mr-5">
        <h4> Participants: { }
          <span className={circle.participants && circle.participants.length > circle.period ? "failed" : ""}>
            {circle.participants ? circle.participants.length : "-"}
          </span>
        </h4>
      </div>
      <div className="prop p-mr-5">
        <h4> Monthly investment: { }
          <span>{circle.monthly_investment ? circle.monthly_investment + " £" : "-"}</span>
        </h4>
      </div>
      <div className="prop p-mr-5">
        <h4> Period: { }
          <span>{circle.period ? circle.period + " months" : "-"}</span>
        </h4>
      </div>
      <div className="prop">
        <h4> Fee: { }
          <span>{circle.admin_fee ? circle.admin_fee : "-"}</span>
        </h4>
      </div>
    </div>);
    return (
      <React.Fragment>
        <div style={{display: 'flex'}}>
          <div style={{flex: 1}} className="circle-tracker">
            
            <div className="flex">
              <div className="p-col p-pl-0 p-p-0">
                <h1>Circle: {
                  <CircleSelector
                    circles={circles}
                    circle={circle}
                    selectCircle={getCircle}
                    />
                  }
                </h1>
              </div>
              <div className="p-ml-5 ">
                <h3 className="p-m-2">
                <span>Compliance</span>
                  <InputSwitch
                    className="p-ml-1 vertical-align-center"
                    checked={showCompliance}
                    onChange={(e) => setShowCompliance(e.value)}
                  />
                  
                </h3>
              </div>
            </div>
            <Toolbar className="p-pt-0 p-pb-0 p-mt-2 p-mb-2 toolbar-purple b-radius-10" left={leftContents} right={rightContents} />
          </div>

        </div>
        <Fieldset onClick={(e) => setShowNotesPopup(true)} className="notes-view" legend="Notes"> 
            {circle.notes || <span className="gray-text">click to add notes</span>}
        </Fieldset>
        <div className="ag-theme-alpine pading-top" style={{ width: '100%' }}>
          <TrackerTable circle={circle} showCompliance={showCompliance}></TrackerTable>
        </div>
        <NotesEditorPopup 
          showPopup={showNotesPopup}
          onHide={setShowNotesPopup}
          showToast={showToast}
          savedNotes={circle.notes}
          circleID={circle._id}
          onUpdateNotes={(notes) => updateNotes(notes, circle)} >
        </NotesEditorPopup>
      </React.Fragment>
    );
}

export default CircleTracker;
