import userAPI from "./userAPI";

async function createPartnerActivity(
  partnerKey,
  interactionType,
  email,
) {
  return userAPI.call(
    "/partnerActivity",
    {
      method: "POST",
      body: {
        activity: {
          partnerKey,
          interactionType,
          email,
        },
      },
    }
  );
}

export { createPartnerActivity };
