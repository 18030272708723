import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { createUserThroughMMOB } from "../../../../services/users";
import { isMobile } from "../../../../utils/generic";
import MMOBError from "./MMOBError";
import MMOBInfo from "./MMOBInfo";

function MMOBLoading({ id }) {
  const [isLoading, setIsLoading] = useState(!!id);
  const [isError, setIsError] = useState(!id);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    initialize();
  }, []);

  const initialize = async () => {
    if (!id) {
      return;
    }

    const { status, data } = await createUserThroughMMOB(id);
    
    if (status !== 200) {

      if(data?.error?.error === "user_exists"){
        setErrorMessage("You already have an account with us! We'd love to start you off where you left off by clicking the next button to login.")
      }

      setIsError(true);
      setIsLoading(false);
      return;
    }

    localStorage.setItem("user_logged_in_before", true);
    setIsLoading(false);
  };

  return (
    <div style={{width: isMobile ? "100%" : "450px", padding: isMobile ? "20px" : "auto"}}>
      <div style={{borderBottom:"1px solid #bebebe", marginBottom:"35px", paddingBottom:'15px'}}><img src="https://images.squarespace-cdn.com/content/v1/617bc5e730991c7912fd176c/5107aa2e-a3d2-4837-8fcc-6b125cab0ecb/logo_green.png" style={{width:"300px"}} /></div>
      <h2>Welcome to StepLadder!</h2>

      <div>
        <h3>
        Building Your Savings Faster & Better With Our Circles<br /><br />
          We’ll support you to reach your financial goals. Lets take you on that
          next step.

          
        </h3>
        {isError && <MMOBError errorMessage={errorMessage} />}
        <MMOBInfo />
      </div>

      <div
        style={{
          position: "fixed",
          padding: "20px 30px",
          backgroundColor: "rgba(255, 255, 255, 0.8)",
          bottom: 0,
          left: 0,
          width: "100vw",
          textAlign: !isMobile ? "center" : "left"
        }}
      >
        <Button
          onClick={() => {
            if (isError) {
              if(!!errorMessage){
                window.location.href= "/welcomeBack";
              } else{
                window.location.href = "/getStartedApply";
              }
             
            } else {
              window.location.href = "/";
            }
          }}
          label={
            !isLoading ? 
              "Join StepLadder"
             : (
              <React.Fragment>
                <i
                  className="pi pi-spin pi-spinner"
                  style={{ fontSize: "0.9em", marginRight: "10px" }}
                ></i>
                Loading Your Details
              </React.Fragment>
            )
          }
          disabled={isLoading}
        />
      </div>
    </div>
  );
}

export default MMOBLoading;
