import GoBack from "./common/goBack";
import CertifiedReasons from "./common/certifiedReasons";

const title = "Self Certified Sophisticated Investor";
const trackBackId = "BackSelfCert";

const QuickFacts = () => {
  return (
    <ul>
      <li>
        <span>
          I have made more than one investment in a P2P agreement or P2P
          portfolio in the last two years
        </span>
      </li>
      <li>
        <span>
          OR I have worked for the past two years in a professional capacity
          in provision of finance, resulting in an understanding of the P2P
          agreements or P2P portfolios to which the promotions will relate
        </span>
      </li>
      <li>
        <span>
          OR I have in the last two years been a director of a company with at
          least &pound;1 million turnover
        </span>
      </li>
      <li>
        <span>OR I am a member of a business angel network.</span>
      </li>
    </ul>
  );
}

const Acceptance = () => {
  return (
    <>
      <p className="informationHeader">
      I accept that being a self-certified sophisticated investor will expose me to promotions for investments where there is a significant risk of losing all the money I invest. I am aware that it is open to me seek advice from someone who specialises in advising on P2P agreements.
      </p>
    </>
  );
}



const Details = ({back, setFormlist, isDisableNext}) => {
  const reasons = [{
    selected: false,
    type: "privateEquitySmallOrMedium",
    text: "I have worked in private equity or in the provision of finance for small and medium enterprises.",
    question: "What is/was the name of the organisation?",
    answer: ""

  },{
    selected: false,
    type: "director",
    text: "I have been the director of a company with an annual turnover of at least £1 million.",
    question: "What is/was the name of the company?",
    answer: ""

  },{
    selected: false,
    type: "twoOrMoreInvestments",
    text: "I have made two or more investments in an unlisted company in the last two years.",
    question: "How many investments in unlisted companies have you made in the last two years?",
    answer: ""

  },{
    selected: false,
    type: "syndicate",
    text: "I have been a member of a network or syndicate of business angels for more than six months.",
    question: "What is the name of the syndicate?",
    answer: ""

  }];

  return (
    <>
      <h3 className="investorTitle">
            Your must have one of the reasons for selecting {title}
      </h3>
      <CertifiedReasons
        data={reasons}
        setFormlist={setFormlist}
        isValid={isDisableNext} />
      
      <GoBack
        back={back}
        trackBackId={trackBackId} />
    </>
  );
}


const selfCertifiedInvestor = {
  title,
  type: title,
  QuickFacts,
  Acceptance,
  Details
};

export default selfCertifiedInvestor;
