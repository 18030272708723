import React from "react";
import { withRouter } from "react-router";
import "primeflex/primeflex.css";
import API from "../../services/api";
import TransfersList from "./transfersList";

class page extends React.Component {
  constructor(props) {
    super(props);
    this.props.setTitle("Transfers to payins")
    this.props.setSubTitle("Copy transfer payment to a payin")
    this.state = {
        payments: [],
        filteredPayments: [],
    };
    this.copyTransferToPayin = this.copyTransferToPayin.bind(this);
    this.undoTransferAsPayin = this.undoTransferAsPayin.bind(this);
  }

  async componentDidMount() {
    this.getTransfersList();
  }

  async getTransfersList() {
    this.setState({ loading: true });
    const data = await API.call({
      url: "/payments/getPayments?type=TRANSFER",
      method: "GET",
    });

    this.setState({ payments: data.payments, filteredPayments: data.payments, loading: false });
  }

  async copyTransferToPayin(transfer) {
      //insert into our payments db
        const transferToPayIn = {
            PaymentID: transfer.PaymentID,
            Fees: transfer.Fees,
            Funds: transfer.Funds,
            AuthorId: transfer.AuthorId,
            Status: transfer.Status,
            Type: "PAYIN",
            WalletId: transfer.TransferTo,
            CreationDate: transfer.CreationDate,
            StatementDescriptor: "fromTransfer",
        };

        if(transfer.ExecutionDate){
            transferToPayIn.ExecutionDate = transfer.ExecutionDate;
        }

        try {
            const response = await API.call({
                url: "/payments/copyTransferToPayin",
                method: "POST",
                body: {
                    transfer,
                    payment: transferToPayIn,
                },
            });
            if(response.transfer) {
                this.updateTransfer(response.transfer);
                this.props.showToast("Payin added", "success");
            } else {
                this.props.showToast("Transfer to payin failed", "error");
            }
        } catch (error) {
            this.props.showToast("Transfer to payin failed", "error");
        }
  }


  async undoTransferAsPayin(transfer) {
      try {
          const response = await API.call({
              url: "/payments/undoTransferAsPayin",
              method: "POST",
              body: {
                  transfer,
              },
          });
          if(response.transfer) {
              this.updateTransfer(response.transfer);
              this.props.showToast(`${response.deletedPayment} payin removed `, "success");
          } else {
                this.props.showToast("Payin removed failed", "error");
          }
      } catch (error) {
          this.props.showToast("Payin removed failed", "error");
      }
}

    updateTransfer(transfer) {
        let index = null;
        const filteredPayments = this.state.filteredPayments.filter((t, idx) => {
            if(t._id === transfer._id) {
                index = idx;
            }
            return t._id !== transfer._id;
        } );
        filteredPayments.splice(index, 0, transfer);
        this.setState({filteredPayments: [...filteredPayments]});
    }

  render() {
    return (
      <React.Fragment>
        <TransfersList 
            copyTransferToPayin={this.copyTransferToPayin} 
            undoTransferAsPayin={this.undoTransferAsPayin}
            actions={true}
            loading={this.state.loading}
            payments={this.state.filteredPayments}>
        </TransfersList>
      </React.Fragment>
    );
  }
}

const TransfersToPayins = withRouter(page);

export default TransfersToPayins;
