import React from "react";
import "primeflex/primeflex.css";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import PaymentsDialog from "./paymentsDialog";
import { InputText } from "primereact/inputtext";
// import { Dialog } from "primereact/dialog";
// import UserSearch from "../../components/userSearch";
// import { InputTextarea } from 'primereact/inputtextarea';

class FailedPaymentSpotsList extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            userData: [],
            showPayments: false,
            selected: {},
            globalFilter:null,
        };
        this.sortFunction = this.sortFunction.bind(this);
    }

    circleNum(rowData) {
        return (<span className={"p-badge p-badge-secondary"}>{rowData.excelID}</span>);
    }

    getName(rowData) {
        return rowData.participant.user ? `${rowData.participant.user.firstname} ${rowData.participant.user.lastname}` : "no user";
    }

    isDrawn(rowData) {
        return (<span className={`product-badge status-${rowData.participant.drawn_date ? "drawn" : ""}`}>{rowData.participant.drawn_date ? "drawn" : ""}</span>);
    }
    isCB(rowData) {
        return (<span><i className={ rowData.participant.credit_builder ? "boldRow pi pi-check active" : ""} /></span>);
    }

    leftToPay(rowData) {
        return rowData.shouldPay - rowData.payed;
    }

    showP() {
        return this.setState({showPayments: true});
    }

    hideP() {
        return this.setState({showPayments: false});
    }

    onRowClick(spotInfo) {
        this.setState({selected: spotInfo});
        this.showP();
    }

    sortFunction(event) {
        return [...this.props.payments].sort((a, b) => (!!a.participant[event.field] - !!b.participant[event.field]) * event.order);
    }

    dateToBool(date) {
        return date;
    }

    updateParticipant() {
        // const res
        // const updated = this.props.updateParticipant(this.state.selected.hidden_failed_payments);
        // if(updated) {
        //     this.setState({selected: updated});
        // }
    }

    render() {
        const header = (
            <div className="table-header">
                <span className="p-input-icon-left">
                    <i className="pi pi-search" />
                    <InputText type="search" onInput={(e) => this.setState({ globalFilter: e.target.value })} placeholder="Global Search" />
                </span>
            </div>
        );
    return (
        <React.Fragment>
            <div>
                <div className="card blue-table">
                    <DataTable
                        className="table-rounded"
                        selectionMode="single"
                        loading={this.props.loading}
                        value={this.props.payments}
                        globalFilter={this.state.globalFilter}
                        header={header}
                        paginator
                        paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
                        rows={50}
                        rowsPerPageOptions={[10, 20, 50, 100]}
                        emptyMessage="No spots found."
                        onSelectionChange={(e) => {
                            const payments = e.value.succeededPayments.concat(e.value.failedPayments);
                            console.log("click")
                            return this.onRowClick({payments, part: e.value.participant});
                    }}>
                        <Column sortable field="excelID" style={{width:'110px'}} header="Circle" body={this.circleNum}></Column>
                        <Column field="monthly_investment" header="Monthly"></Column>
                        <Column field="total" header="Total"></Column>
                        <Column field="name" header="Name" body={this.getName}></Column>
                        <Column field="participant.user.email" header="Email"></Column>
                        <Column field="payed" header="Paid"></Column>
                        <Column field="shouldPay" header="Should Pay"></Column>
                        <Column field="leftToPay" header="Left to Pay" body={this.leftToPay}></Column>
                        <Column sortable sortFunction={this.sortFunction} field="drawn_date" header="Drawn" body={this.isDrawn}></Column>
                        <Column sortable sortFunction={this.sortFunction} field="credit_builder" style={{width:'84px'}} header="CB" body={this.isCB}></Column>
                        <Column field="participant.walletID" header="Wallet"></Column>
                    </DataTable>
                </div>
            </div>
            <PaymentsDialog
                updateParticipant={this.updateParticipant}
                visible={this.state.showPayments}
                selected={this.state.selected}
                onHide={(e) => {
                    this.setState({ showPayments: false });
                }}
                ref={this.updateCircleRef}
            ></PaymentsDialog>
        </React.Fragment>
    );
    }
}

export default FailedPaymentSpotsList;
