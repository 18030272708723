const quizQuestions = [
    {
        key: "whenDrawnKeepMakingPayments",
        question: "When you’ve drawn, do you need to keep making payments?",
        answers: [
            {
                label: "Yes",
                key: "yes",
                score: 1
            },
            {
                label: "No",
                key: "no",
                score: 0
            },
            {
                label: "It's optional",
                key: "optional",
                score: 0
            }
        ],
        answerInfo: "For Circles where you pay in more than £100 per month, we can only release the drawn money by sending it to a solicitor for the purpose of buying a home whilst the Circle is still running."
    },
    {
        key: "whoBorrowingAndLendingFrom",
        question: "Who are you borrowing and lending from when using a StepLadder Circle?",
        answers: [
            {
                label: "Nobody",
                key: "nobody",
                score: 0
            },
            {
                label: "Other Members",
                key: "otherMembers",
                score: 1
            },
            {
                label: "StepLadder",
                key: "stepLadder",
                score: 0
            }
        ],
        answerInfo: "The most you ever loan to another Member is one month's contribution. Credit risk is greater the later you're drawn."
    },
    {
        key: "ifDropoutWhenReceiveMoney",
        question: "If you have to drop out of the Circle, when can you expect to receive your money back?",
        answers: [
            {
                label: "Immediately",
                key: "immediately",
                score: 0
            },
            {
                label: "At the end of the Circle",
                key: "endOfCircle",
                score: 1
            },
            {
                label: "Within 1 month",
                key: "within1month",
                score: 0
            }
        ],
        answerInfo: "However, if you want to get your money back immediately you can elect to pay the remainder of the monthly fees to access the money."
    }
];

export default quizQuestions;