import userAPI from "./userAPI";

function isAmbassador(user){
    return !!user.ambassadorOwner;
}

async function requestNewCircle(circleRequestObject){
    return userAPI.call("/ambassadors/requestNewCircle", {
        method: "POST",
        body: {request: circleRequestObject}
    });
}

async function getFullAmbassadorCircles(){
    return userAPI.call("/ambassadors/circles", {
        method: "GET"
    });
}

export {isAmbassador, getFullAmbassadorCircles, requestNewCircle};
