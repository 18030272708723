import React from "react";
import { withRouter } from "react-router";
import "primeflex/primeflex.css";
// import API from "../../../../utils/api";
import TransfersLOCList from "./transferLOCList";
import { Button } from "primereact/button";
import circleService from "../../../services/circleService";
import { Dropdown } from "primereact/dropdown";
import { Card } from 'primereact/card';
import moment from "moment";
import { io } from "socket.io-client";
import { Dialog } from "primereact/dialog";

const query =  { query: "token=C0B88D5C-8BD8-43E7-A21F-2F458C417407" };
const year = new Date().getFullYear();
const toastLife = 5000;
const periods = [
  {month: 10, year: year - 1},
  {month: 11, year: year - 1},
  {month: 12, year: year - 1},
  ////
  {month: 1},
  {month: 2},
  {month: 3},
  {month: 4},
  {month: 5},
  {month: 6},
  {month: 7},
  {month: 8},
  {month: 9},
  {month: 10},
  {month: 11},
  {month: 12},
  {month: 1, year: year+1},
];

class page extends React.Component {
  constructor(props) {
    super(props);
    this.props.setTitle("Transfers");
    this.state = {
        circleLocs: [],
        filteredCircles: [],
        predictedLOC: 0,
        actualLOC: null,
        currentPeriod: moment(),
        transfers: null,
        transfersConfirmation: false,
        nsp: io.connect(process.env.REACT_APP_WS_URL + "/admin", query),
        period: moment().format("MM-YYYY")
    };
    this.getTransfersList = this.getTransfersList.bind(this);
    this.selectedCircles = this.selectedCircles.bind(this);
  }

  async componentDidMount() {
    this.listenning();
    this.getTransfersList();
  }

  async getTransfersList() {
    try {
      this.setState({ loading: true });
      const circleLocs = await circleService.getCirclesForTransfers(this.state.period);
      if(!circleLocs.error) {
        let totalTransfers = 0;
        const filteredCircles = circleLocs.map(c => {
          c.transfers = c.transfers || []; 
          totalTransfers += c.transfers.length
          return c;
        });
        
        this.setState({ circleLocs: circleLocs, filteredCircles, transfers: totalTransfers }, this.afterSetStateFinished);
        this.props.showToast(`LOC: ${this.state.period}`, "success", toastLife);
      } else {
        this.props.showToast(circleLocs.error, "error", toastLife);
      }
    } catch (error) {
        this.props.showToast("Run tsransfers failed: " + error.message || error , "error", toastLife);
    }

    this.setState({ loading: false });
  }

  countTotalAndActualLOC() {
    const predictedLOC = this.state.filteredCircles.reduce((acc, curr) => (acc+= curr.loc), 0);
    const actualLOC = this.state.filteredCircles.reduce((acc, curr) => (acc+= curr.actualLOC || 0), 0);
    this.setState({ predictedLOC, actualLOC });
  }

  selectedCircles(filteredCircles) {
    this.setState({ filteredCircles }); // , this.afterSetStateFinished
  }

  afterSetStateFinished(){
    this.countTotalAndActualLOC(this.state.filteredCircles);
  }

  componentWillUnmount() {
    this.state.nsp.off('runtransactions', this.listenning);
  }

  listenning() {
    this.state.nsp.on('runtransactions', (msg) => {
      this.messageHandler(msg);
    });

    this.state.nsp.on('disconnect', (msg) => {
        this.props.showToast(`Disconnected: ${msg}`, "warn", toastLife);
        this.setState({ loading: false });
    });
    this.state.nsp.on("connect_error", (err) => {
      this.props.showToast("Connection error", "error");
      console.log(`connect_error due to ${err.message}`);
    });
  }

  messageHandler(message){
    if (!message) return;

    if (message.transfers) {
      this.succeededTransactions(message.transfers);
      this.setState({ loading: false });
    }

    if (message.status) {
      this.props.showToast(message.status, message.severity || "info", toastLife);
    }

    if (message.error) {
      this.props.showToast(message.error, "error", toastLife);
      this.setState({ loading: false });
    }
  }

  runTransferWS() {
    this.setState({ loading: true, transfersConfirmation: false });
    this.state.nsp.emit('runtransactions', {
      circles: this.state.filteredCircles.map(c => {
        const {payins, payouts, ...rest} = c;
        return rest;
      }),
      period: this.state.period
    });
  }

  succeededTransactions(transfersResult) {
    const circleLocsNew = this.state.circleLocs.map(c => {
      const transfers = transfersResult.find(tr => tr.excelID === c.excelID && !tr.error);
      if(transfers) {
        c.transfers = c.transfers ? c.transfers.concat(transfers.transfers) : transfers.transfers;
      }
      return c;
    })
    this.setState({ circleLocs: circleLocsNew });
    this.countTotalTransfers(transfersResult);
    this.props.showToast(`Circles ${transfersResult.length} of ${this.state.filteredCircles.length}`, this.state.filteredCircles.length > transfersResult.length ? "warn" : "success", 5000);
  }

  countTotalTransfers(circles) {
    const totTransfers = circles.filter(t=> t.transfers).map(t=> t.transfers).flat().length;
    this.setState({transfers: totTransfers});
  }

  // updateTransfer(transfer) {
  //   let index = null;
  //   const filteredPayments = this.state.filteredPayments.filter((t, idx) => {
  //       if(t._id === transfer._id) {
  //           index = idx;
  //       }
  //       return t._id !== transfer._id;
  //   } );
  //   filteredPayments.splice(index, 0, transfer);
  //   this.setState({filteredPayments: [...filteredPayments]});
  // }

  isTransfersRan() {
    return this.state.filteredCircles.some(t => t.transfers.length);
  }

  render() {
    return (
      <React.Fragment>
        <Dialog
            header={
              "Run trnasfers cycle"
            }
            visible={this.state.transfersConfirmation}
            style={{ width: "40vw" }}
            onHide={() => this.setState({ transfersConfirmation: false })}
          >
            <div className="center"> You will be running transfers for: &nbsp;
              <b>
                {this.state.currentPeriod.format("MM-YYYY")}
              </b> &nbsp;
              period

            </div>
            <div className="center p-mt-5">
              <Button
                onClick={(e) => {
                  this.runTransferWS();
                }}
                icon="pi pi-check"
                label="Yes"
                className="p-button-success"
              ></Button>
              <Button
                onClick={(e) => {
                  this.setState({ transfersConfirmation: false });
                }}
                icon="pi pi-times"
                className="p-button-outlined p-button-plain p-ml-4"
                label="No"
              ></Button>
            </div>
          </Dialog>
        <Card className="title-card p-mt-2 p-mb-3 card-rounded" >

          <div className="p-grid">
            <div className="p-col">
              <h2 className="p-pt-2"> <span>Period</span> 
              <Dropdown
                className="p-ml-3"
                style={{width: "8rem"}}
                value={this.state.period}
                options={periods.map((period) => {
                    return {label: `${period.month}-${period.year || year}`, value: `${period.month < 10 ? "0"+ period.month : period.month }-${period.year || year}`}
                })}
                editable={true}
                onChange={(e) => this.setState({period: e.value}, this.getTransfersList)}
                placeholder="Type or Select"
              />
              </h2>
              <div className="p-grid p-mt-2 p-mb-2">
                <div>
                  <h4 className="p-mt-2 p-mb-0 p-ml-2 p-mt-2 loc-title">
                    Predicted LOC: <span className="loc-amount p-mr-4">£{this.state.predictedLOC}</span>
                    Actual LOC: <span className="loc-amount p-mr-4">{this.state.actualLOC ? "£" + this.state.actualLOC : "-"}</span>
                    Transfers: <span className="loc-amount">{this.state.transfers ? this.state.transfers : "-"}</span>
                  </h4>
                </div>
              </div>
            </div>
            <div className="p-col-fixed p-mr-2">
            <Button className="p-button-info p-button-raised" iconPos="right" icon="pi pi-sort-alt rotate-90" disabled={!this.state.filteredCircles.length || this.state.loading || this.isTransfersRan()} label="Run transfers" onClick={(e) => { this.setState({ transfersConfirmation: true }); }} />
            <div>
              <h4 className="p-mt-3 p-mb-0 loc-title">Total Circles: <span className="loc-amount">{this.state.filteredCircles.length}</span></h4>
            </div>
            </div>
          </div>
        </Card>
      
            
        <TransfersLOCList 
            refresh={this.getTransfersList}
            loading={this.state.loading}
            period={this.state.period}
            actions={false}
            selectedCircles={this.selectedCircles}
            circleLocs={this.state.circleLocs}>
        </TransfersLOCList>
      </React.Fragment>
    );
  }
}

const Transfers = withRouter(page);

export default Transfers;
