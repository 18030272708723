import React from "react";
import { useHistory } from "react-router";
import { useUI } from "../../../../../contexts/UIContext";
import { useUser } from "../../../../../contexts/UserContext";
import AgreementSwitches from "../agreementSwitches/agreementSwitches";
import { termsAgreed, addMultipleCircles } from "../../../../../services/circles";
import { getNextRoute} from "../../../../../utils/userRoutes";

function Agreement({hideTitle=false, multipleCirclesIds}) {
    const userContext = useUser();
    const UI = useUI();
    const history = useHistory();
    const submit = async (isCircleAutoRenew) => {
        UI.showLoading();
        if(userContext.user.stage === "Dashboard"){
          const {status: termsAgreedStatus, data} = await termsAgreed();
          // await userContext.fetchUser();
          UI.hideLoading();
    
          if (termsAgreedStatus !== 200 || !data.circleAccepted) {
            UI.showToast("There was an error in signing up to the circle, please contact us to fix it.");
          }
    
          history.push("/");
          return;
        }
    
        if(userContext.user.stage === "Terms"){
            const {status} = await userContext.updateUser({nextStage: true, isCircleAutoRenew});
            if(multipleCirclesIds && multipleCirclesIds.length) {
                const {status: status2} = await addMultipleCircles(multipleCirclesIds);
                await userContext.fetchUser();
                if(status2 !== 200){
                    UI.showToast("There was an error, please contact the membership team");
                    return;
                }
            }

            if(status !== 200){
                UI.showToast("There was an error, please contact the membership team");
                return;
            }
        }
        
        UI.hideLoading();
        history.push(getNextRoute(userContext.user));
      };
 
  return (
      <React.Fragment>
          {!hideTitle && <h2 className="p-mb-3">Please read and agree to the terms and conditions</h2>}
        <AgreementSwitches compressed={true} onSubmit={(isCircleAutoRenew) => submit(isCircleAutoRenew)}/>
      </React.Fragment>
  );
}

export default Agreement;