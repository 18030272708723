const reasonsForSaving = {
  deposit: "Raising money for your deposit",
  credit: "Building your credit score",
  debt: "Paying off your debts",
  mortgage: "Getting mortgage ready",
  business: "Starting your own business",
  selfDevelopment: "Self development",
  family: "Starting a family",
  marriage: "Getting Married",
  cosmetic: "Cosmetic procedure",
  else: "Something else"
};

const reasonsForSavingValues = Object.entries(reasonsForSaving).map(([key, value]) => value);

//"mortgageReady", "houseDeposit" - old values
const houseRelatedReasons = ["Getting mortgage ready", "Raising money for your deposit", "mortgageReady", "houseDeposit"];


const usefulKeys = [
    {
      label: "Platform ID",
      key: "_id",
      tag: "Personal",
    },
    {
      label: "Name",
      special: function (user) {
        return user.firstname && user.lastname
          ? user.firstname + " " + user.lastname
          : null;
      },
      tag: "Personal",
    },
    {
      label: "Email",
      key: "email",
      tag: "Personal",
      editableInputType: "text",
    },
    {
      label: "Date of Birth",
      key: "dob",
      tag: "Personal",
      editableInputType: "text"
    },
    {
      label: "Phone Number",
      key: "mobilephone",
      tag: "Personal",
      editableInputType: "text",
    },
    {
      label: "Stage",
      key: "stage",
      tag: "Personal",
      editableInputType: "select",
      options: [
        "Affordability",
        "CircleInvite",
        "InvestorClassification",
        "Terms",
        "Payments",
        "Dashboard",
      ],
      type: "select",
    },
    {
      label: "Ambassador ID",
      key: "ambassador_id",
      tag: "Personal",
    },
    {
      label: "FMC",
      key: "minimumTest",
      tag: "Finance",
    },
    {
      label: "MangoPay ID",
      key: "userID",
      tag: "Finance",
      editableInputType: "number",
    },
    {
      label: "Address",
      key: "address_line_1",
      tag: "Personal",
      editableInputType: "text",
    },
    {
        label: "Nickname",
        key: "nickname",
        tag: "Personal",
        editableInputType: "text",
      },
    {
      label: "Postcode",
      key: "zip",
      tag: "Personal",
      editableInputType: "text",
    },
    {
      label: "Marital Status",
      key: "marital_status",
      tag: "Personal",
    },
    {
      label: "Dependents",
      key: "number_of_dependents",
      tag: "Personal",
      editableInputType: "number",
    },
    {
      label: "Rent Per Month",
      key: "rent_per_month",
      tag: "Finance",
      editableInputType: "number",
    },
    {
      label: "Applicant Salary",
      key: "applicant_salary",
      tag: "Finance",
      editableInputType: "number",
    },
    {
      label: "Debt",
      key: "how_much_debt_do_you_have_",
      tag: "Finance",
      editableInputType: "number",
    },
    {
      label: "Household Expenses",
      key: "other_p_m",
      tag: "Finance",
      editableInputType: "number",
    },
    {
      label: "Reason for saving",
      key: "reason_for_saving",
      tag: "Finance",
      editableInputType: "select",
      options: reasonsForSavingValues,
    },

    {
      label: "Education Type",
      key: "education_type",
      tag: "Personal",
    },
    {
      label: "IVA/CCJ?",
      key: "f_have_you_ever_experienced_iva_ccj_repossession_or_bankruptcy_",
      tag: "Finance",
    },
    {
      label: "Worked in financial services?",
      key: "have_you_ever_worked_in_the_financial_services_industry_",
      tag: "Finance",
    },
    {
      label: "Used a p2p loan before?",
      key: "have_you_ever_used_a_peer_to_peer_loan_before_",
      tag: "Finance",
    },
    {
      label: "Joint Applicant Salary",
      key: "applicant_salary_applicant_2",
      tag: "Finance",
      editableInputType: "number",
    },
];

const userStages = ['Affordability', 'CircleInvite', 'Quiz', 'InvestorClassification', 'Terms', 'Payments', 'Dashboard']


const refferalsToAmbassadors = {
  StartingAFamily: "Starting a Family",
  GettingMarried: "Getting Married",
  Cosmetic: "Cosmetic",
  SelfDevelopment: "Self Development and Education",
  WomensCircle: "WomensCircle"
}

export {
  reasonsForSaving,
  reasonsForSavingValues,
  houseRelatedReasons,
  usefulKeys,
  refferalsToAmbassadors,
  userStages
};