
const userAPI = {
    call: async function (path, fetchParamaters) {

      if (fetchParamaters.body) {
        fetchParamaters.body = JSON.stringify(fetchParamaters.body);
      }
  
      const response = await fetch(process.env.REACT_APP_SERVER_URL + path, {
        ...fetchParamaters,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      });

      
  
      if (response.status === 401) {
          localStorage.removeItem("user_logged_in_before");
          
          if(localStorage.getItem("impersonating")){
            localStorage.removeItem("impersonating");
            window.location = "/adminPortal";
            return;
          }

          window.location = "/welcomeBack";
      }

      const outcome = { status: response.status, data: await response.json() };

      console.log(outcome);
  
      return outcome;
    }
};



export default userAPI;