import React, { useEffect, useState, useCallback } from "react";
import API from "../../services/api";
import TopSheetTable from "./topSheetTable";
import { InputText } from "primereact/inputtext";
import adminDashboardStyles from "../../style/admin_dashboard.module.css";
import { InputNumber } from 'primereact/inputnumber';
import { Calendar } from "primereact/calendar";
import { Button } from "primereact/button";
import LoadingSpinner from "./customLoader";
import moment from "moment";
import { RadioButton } from 'primereact/radiobutton';
import utils from '../../utils/utils';
import { SelectButton } from 'primereact/selectbutton';


function TopSheet({setTitle, showToast}) {
  const start = moment().utc().startOf('month').subtract(3, 'months');
  const [topSheetData, setTopSheetData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [search, setSearch] = useState("");
  const [startDate, setStartDate] = useState(start.toDate());
  const [range, setRange] = useState(12);
  const categories = [{name: 'all', key: 'all'}, {name: 'active', key: 'onlyActive'}, {name: 'finished', key: 'finished'}];
  const [selectedCategory, setSelectedCategory] = useState(categories[0].name);

  useEffect(() => {
    setTitle("Top Sheet")
    getCircles();
  }, []);
  
  const getCircles = async () => {
    try {
      const monthDiff = moment().diff(moment(startDate), 'months');
      setLoading(true)
      //gets all circles
      const circles = await API.call({
        url: `/circles/topsheet?range=${range}&start=${monthDiff}`,
        method: "GET",
      });
      if(circles.error || !Array.isArray(circles)) {
        throw new Error(circles.error);
      }  
      setTopSheetData(circles);
    } catch (error) {
      showToast(error.message || JSON.stringify(error), "error");
    } finally {
      setLoading(false)
    }
  }

  const filterCircles = useCallback((circles) => {
    // console.log(circles)
    return circles ? circles.sort((a, b) => b.excelID - a.excelID)
      .filter((c, index) => {
        if(index === 0){
          return true
        }

        if(selectedCategory === "onlyActive") {
          return utils.isActiveCircle(c);
        }

        if(selectedCategory === "finished") {
          return utils.isCircleFinished(c);
        }

        return true
      }) : null;
  }, [selectedCategory])

  const searchForUser = (value) => {
    setSearch(value)
  }

  return (
    <React.Fragment>
      <div style={{height: '91%'}} >
        <div className="p-fluid p-formgrid p-grid p-mr-0 p-ml-0">
          <div className="p-field p-col-12 p-md-2 p-mb-0 p-mt-1 p-mr-4 p-p-0">
              <div className="p-inputgroup">
                <InputText
                  autoFocus
                  className={adminDashboardStyles.adminSearchBar}
                  value={search}
                  onChange={(e) => searchForUser(e.target.value)}
                  placeholder="Search"
                />
                <span className="p-inputgroup-addon">
                    <i className="pi pi-search"></i>
                </span>
              </div>
              
          </div>
          <div className="p-field p-col-12 p-md-5 p-mb-0 p-grid">
          <div className="p-col-fixed label-inline centerMiddle">Circles:</div>
              <div className="max-width-300 p-col center-el-vertically">
              <SelectButton className="select-buttons-sm height-35px" optionLabel="name" optionValue="key" value={selectedCategory} options={categories} onChange={(e) => {
                setSelectedCategory(e.value)
              }}></SelectButton>
                
              {/* {
                  categories.map((category) => {
                      return (
                          <div key={category.key} className="p-field-radiobutton p-mb-0 p-mr-3">
                              <RadioButton
                                inputId={category.key}
                                name="category"
                                value={category}
                                onChange={(e) => setSelectedCategory(e.value)}
                                checked={selectedCategory.key === category.key} />
                              <label htmlFor={category.key}>{category.name}</label>
                          </div>
                      )
                  })
              } */}
              </div>
              
          </div>
          <div className="p-field p-col-12 p-md-1 p-mb-0 p-mt-1 p-grid p-mr-0">
            {/* <div className="p-col-fixed label-inline centerMiddle p-offset-2">From:</div>
            <div className="p-col">
              <Calendar
                value={startDate}
                onChange={(e) => setStartDate(e.value)}
                view="month"
                disabled={true}
                dateFormat="mm/yy"
                yearNavigator
                maxDate={moment().toDate()}
                minDate={moment().subtract(12, 'months').toDate()}
                yearRange="2019:2030"
              />
            </div> */}
            
          </div>
          {/* p-col-align-end */}
          <div className="p-col-12 p-md-4 p-mb-0 p-mt-1 p-grid p-mr-0 p-ml-0">
            <div className="p-col-fixed label-inline centerMiddle">Range:</div>
            <div className="p-col centerMiddle ">
              <InputNumber 
                className="small-buttons"
                value={range}
                onValueChange={(e) => setRange(e.value)}
                suffix={` month${range < 2 ? "" : "s"}`}
                min={1}
                max={24}
                showButtons 
                mode="decimal" />
            </div>
            <div className="p-col">
            <Button
              icon="pi pi-filter"
              className="p-button-outlined p-button-sm border-2px"
              label="Apply"
              onClick={getCircles}
            ></Button>
            </div>
          </div>
        </div>
        
        <div className="ag-theme-alpine p-mt-3 topsheet-table">
          {loading && 
              <LoadingSpinner></LoadingSpinner> }
              {<TopSheetTable loading={loading} search={search} topSheetData={filterCircles(topSheetData)}></TopSheetTable>}
        </div>
      </div>
    </React.Fragment>
  
    );
}

export default TopSheet;
