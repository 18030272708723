import EveryDayAndHighNetWorthDetails from "./common/everyDayAndHighNetWorthDetails";

const title = "🌱 Every Day Investor";
const type = "Restricted Investor";
const trackBackId = "BackRestricted";
const QuickFacts = () => {
  return (
    <ul>
        <li>
          <b>89% of our customers</b> are Every Day Investors
        </li>
        <br />
        <li>
          Most Every Day Investors{" "}
          <b>haven’t used a peer to peer financial product before</b>. If you
          have, to qualify you must have not invested more than 10% of your
          net assets and don’t intend to this year.
        </li>
        <br />
        <li>Legally, this is referred to as a “Restricted Investor”</li>
      </ul>
  );
}

const Acceptance = () => {
  return (
    <>
      <div className="informationLabel">
      Putting all your money into a single business or type of investment is risky. Spreading your money across different investments makes you less dependent on any one to do well.
      </div>
       <div className="informationLabel">
        You should not invest more than 10% of your net assets in high-risk investments. Doing so could expose you to significant losses. For the purposes of this statement, net assets do NOT include: your home (primary residence), your pension (or any pension withdrawals) or any rights under qualifying contracts of insurance. For the purposes of this statement high-risk investments are: peer-to-peer (P2P) loans; investment based crowdfunding; and unlisted debt and equity (such as in companies not listed on an exchange like the London Stock Exchange).
      </div>
      <div className="informationLabel">
      I accept that being a restricted investor will expose me to promotions for investment where there is a risk of losing all the money I invest. I am aware that it is open to me seek professional advice before making any investment in a high-risk investment.
      </div>
    </>
  );
}

const Details = (props) => 
  <EveryDayAndHighNetWorthDetails
    {...props}
    trackBackId={trackBackId}
    title={title}
  />

const everyDayInvestor = {
  title,
  type,
  QuickFacts,
  Acceptance,
  Details
};

export default everyDayInvestor;
