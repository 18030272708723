import { Button } from "primereact/button";
import React, { useEffect, useState } from "react";
import { createPartnerActivity } from "../../../users/services/partnerActivity";
import LoadingSpinner from "../../components/loadingSpinner";
import { getPartnerActivityForUser } from "../../services/partnerActivity";

function PartnerReferrals({ userData }) {
  const [isLoading, setIsLoading] = useState(true);
  const [partnerData, setPartnerData] = useState([]);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refer = async (partnerKey) => {
    setIsLoading(true);
    const { data, status } = await createPartnerActivity(
      partnerKey,
      "membershipTeamReferral",
      userData.email
      
    );
    setIsLoading(false);

    if (status !== 200) {
      alert("There was an error: " + (data.error || data.message || data));
      return;
    }

    window.location.href = window.location.href + "&tabIndex=3";
  };

  const initialize = async () => {
    if (!userData.email) {
      alert("No email found");
      return;
    }

    const { data, status } = await getPartnerActivityForUser(userData.email);

    if (status !== 200) {
      alert("There was an error" + (data.error || data.message || data));
      return;
    }

    setPartnerData(data);
    setIsLoading(false);
  };

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
      {partnerData.map((d) => {
        return (
          <div key={d.partnerKey}>
            {d.title}:{" "}
            {d.alreadyReferred ? (
             <b style={{color:"green"}}>Already Referred</b>
            ) : (
              <Button
                style={{verticalAlign:"middle", marginLeft:"10px"}}
                label="Refer Now"
                onClick={() => refer(d.partnerKey)}
              />
            )}
            <hr style={{ marginTop: "20px", marginBottom: "20px" }}></hr>
          </div>
        );
      })}
    </div>
  );
}

export default PartnerReferrals;
