import React, { useState } from "react";
import CircleChatsMain from "./circleChats.js/circleChatsMain";
import CircleInfoMain from "./circleInfo/CircleInfoMain";
import CirclePaymentsMain from "./circlePayments.js/CirclePaymentsMain";
import CircleSummaryMain from "./circleSummary.js/CircleSummaryMain";

function CircleViewTabs(props) {
  const [tabIndex, setTabIndex] = useState(0);

  const tabs = [
    {
      icon: "fas fa-star",
      component: CircleSummaryMain,
    },
    {
      icon: "fas fa-credit-card",
      component: CirclePaymentsMain,
    },
    {
      icon: "fas fa-comments",
      component: CircleChatsMain,
    },
    {
      icon: "fas fa-info-circle",
      component: CircleInfoMain,
    },
  ];

  const Component = tabs[tabIndex].component;

  return (
    <React.Fragment>
      <div className="circleViewTabs">
        {tabs.map((itm, i) => (
          <div
            key={i}
            className={"transition " + (tabIndex === i ? "active" : "")}
            onClick={() => setTabIndex(i)}
          >
            <i className={itm.icon}></i>
          </div>
        ))}
      </div>
      <div className="circleViewTabContent">
        <Component circle={props.data}></Component>
      </div>
    </React.Fragment>
  );
}

export default CircleViewTabs;
