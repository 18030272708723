import React, {useEffect} from "react";
import { useHistory } from "react-router";
import { useUI } from "../../../contexts/UIContext";
import { useUser } from "../../../contexts/UserContext";
import { getNextRoute } from "../../../utils/userRoutes";
import Form from "../../generic/forms/Form";

function PersonalFinance({setTitle, setSubTitle}) {
  const UI = useUI();
  const history = useHistory();
  const userContext = useUser();
  useEffect(() => {
    setTitle("Your Details");
    setSubTitle(
    <>
      Personal {">"} Goals {">"} <b>Finance</b> {">"} Experience
    </>);
  }, []);

  const submitValues = async (values) => {
    for (var key in values) {
      if (values[key] === "") {
        values[key] = 0;
      }
    }

    const updateObject = {
      applicant_salary: values.applicantSalary,
      rent_per_month: values.monthlyRent,
      other_p_m: values.householdExpenses,
      how_much_debt_do_you_have_: values.debt_question,
      savings_and_assets: values.savingsAndAssets,
    };

    UI.showLoading();
    const { status } = await userContext.updateUser(updateObject);
    UI.hideLoading();

    if (status !== 200) {
      UI.showToast(
        "There was an error saving your details! Contact us to fix it"
      );
      return;
    }

    history.push(getNextRoute(userContext.user));
  };

  return (
    <>
      {/* <h2 className="onboarding-header">Your Details</h2>
      <div className="headerDescription">
        Personal {">"} Goals {">"} <b>Finance</b> {">"} Experience
      </div> */}
      <div style={{ width: "100%", marginTop: "20px", fontSize: "14px" }}>
      Use the tooltips to help you answer the questions.
    </div>

      <Form
        inputs={[
          { key: "applicantSalary", value: '' },
          { key: "monthlyRent", value: '' },
          { key: "householdExpenses", value: '' },
          { key: "savingsAndAssets", value: '' },
          { key: "debt_question", value: '' },
        ]}
        onSubmit={(values) => {
          submitValues(values);
        }}
      />
    </>
  );
}


export default PersonalFinance;
