import moment from "moment";
import { Checkbox } from "primereact/checkbox";
import { TabPanel, TabView } from "primereact/tabview";
import React, { useEffect, useState } from "react";
import InlineLoading from "../../../../users/components/generic/inlineLoading";
import CircleService from "../../../services/circleService";
import riskPolicyConfig from "./RiskPolicyConfig";

import RiskPolicySummary from "./RiskPolicySummary";
import RiskPolicyTable from "./RiskPolicyTable";

function RiskPolicyMain({ setTitle, showToast }) {
  const [data, setData] = useState([]);
  const [totals, setTotals] = useState({ active: [], all: [] });
  const [activeOnly, setActiveOnly] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const { circles: riskData, totals } = await CircleService.getRiskPolicy();

      if (riskData.error) {
        throw riskData;
      }

      setData(riskData.sort((a, b) => b.excelID - a.excelID));
      setTotals(totals);
      setIsLoading(false);
    } catch (err) {
      showToast("There was an error in loading the Risk Information. Please contact your development team.", "error", 6000)
      
    }
  };

  const getTotalsConfig = () => {
    let excludeFields = [
      "excelID",
      "startingDate",
      "endingDate",
      "monthly_investment",
      "period",
      "Active_Risk_Class",
      "Active_Net_Risk",
    ];

    let newConfig = JSON.parse(JSON.stringify(riskPolicyConfig));

    for (var key of excludeFields) {
      delete newConfig[key];
    }

    const appendConfig = {
      Risk_Class: { ...riskPolicyConfig.Risk_Class, pinned: "left" },
    };

    return { ...appendConfig, ...newConfig };
  };

  if (isLoading || !data.length) {
    return <InlineLoading />;
  }

  const sortDataByClass = (dataToSort) => {
    return dataToSort.sort((a, b) => {
      return a.Risk_Class.localeCompare(b.Risk_Class)
    })
  }

  const dataToProvide = !activeOnly
    ? data
    : data.filter((d) => {
        const today = moment.utc();
        const startDate = moment.utc(d.startingDate);
        const endDate = moment.utc(d.endingDate);

        return (
          today.isAfter(startDate) &&
          today.isBefore(endDate) &&
          d.participants.some((d) => !d.dropout_date)
        );
      });

  return (
    <React.Fragment>
      <div className="flex">
        <div>
          <h2 style={{ marginBottom: "10px" }}>Risk Policy</h2>
        </div>
        <div style={{ marginLeft: "300px" }}>
          <div
            style={{
              border: "1px solid grey",
              backgroundColor: "#ecece",
              padding: "8px",
              borderRadius: "10px",
            }}
          >
            Filter by Active Circles only?{" "}
            <Checkbox
              checked={activeOnly}
              onChange={(e) => setActiveOnly(e.checked)}
            />{" "}
          </div>
        </div>
      </div>
      <div style={{ marginBottom: "30px" }}>
        Click a row to reveal individual participant calculations, e.g bad rates
        and payment information. Click a header to sort.
      </div>

      <TabView>
        <TabPanel header="Summary">
          {/* commented as 'Need to revisit how these calculated and right now they distract fo Matt' */}
          {/* <RiskPolicySummary data={dataToProvide} /> */}

          <RiskPolicyTable
            noSelect={true}
            data={sortDataByClass(activeOnly ? totals.active : totals.all)}
            config={getTotalsConfig()}
            sortable={false}
          />
        </TabPanel>
        <TabPanel header="Circle Data">
          <RiskPolicyTable config={riskPolicyConfig} data={dataToProvide} />
        </TabPanel>
      </TabView>
    </React.Fragment>
  );
}

export default RiskPolicyMain;
