import React, {useState} from "react";
import { Dialog } from "primereact/dialog";
import { InputTextarea } from 'primereact/inputtextarea';
import { Button } from 'primereact/button';
import CircleService from "../../services/circleService";

function NotesEditorPopup({onHide, showToast, onUpdateNotes, showPopup, savedNotes, circleID}) {
  const [notes, setNotes] = useState("");

  const save = async () => {
    const result = await CircleService.saveNotes(notes, circleID);
    if (result.notesUpdated) {
      showToast("Notes updated!", "success");
      onUpdateNotes(notes);
        onHide(false)
      } else {
        showToast("Notes not updated!", "error");
      }
  }
    const footer = (
        <div>
            <Button label="Cancel" className="p-button-text p-button-plain" onClick={() => onHide(false)} />
            <Button label="Save" className="p-button-info" onClick={save} />
        </div>
    );
    return (
      <Dialog
        header="Edit notes"
        footer={footer}
        visible={showPopup}
        style={{ width: document.body.offsetWidth < 600 ? "95vw" : "50vw" }}
        onShow={() => setNotes(savedNotes)}
        onHide={() => onHide(false)}>
            <InputTextarea rows={5} className="notes-editor" value={notes} onChange={(e) => setNotes(e.target.value)} />
      </Dialog>
    );
}


export default NotesEditorPopup;