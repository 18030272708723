import { Button } from "primereact/button";
import { useState } from "react";

function DrawDayInstructions(props) {

  const [isGenerated, setIsGenerated] = useState(false);

  const options = [
    {
      title: "Generate",
      description: "Generate the draw, then check it before you submit.",
      isActive: function(){
        return props.status === "GENERATE" && !isGenerated
      },
      isDone: function(){
        return !this.isActive();
      }
    },
    {
      title: "Save",
      description: "Send the results to Slack, not visible in the platform.",
      isActive: function(){
        return props.status === "GENERATE" && isGenerated
      },
      isDone: function(){
        return props.status !== "GENERATE";
      }
    },
    {
      title: "Publish",
      description:
        "Publish the results so members can view the results in the platform.",
      isActive: function(){
        return props.status === "SAVED"
      },
      isDone: function(){
        return props.status === "PUBLISHED";
      }
    },
  ];

  return (
    <div className="drawDayButtons" style={{marginTop:"40px", marginBottom:"40px"}}>
      {options.map((itm, i) => {
        return (
          <div key={i} style={{padding:"20px", borderRadius:"10px"}} className={itm.isActive() ? "active":""}>
            <h2>{itm.title}</h2>
            <div>{itm.description}</div>
            <Button
            style={{marginTop:"30px"}}
              onClick={(e) => {
                if(props.status === "GENERATE"){
                  setIsGenerated(true);
                }
                props.onClick()}}
              label={!itm.isDone() ? itm.title : "DONE"}
            ></Button>{" "} {itm.isDone() && <i className="pi pi-check" style={{marginLeft:"15px", color:"green", fontSize:"25px"}}></i>}
          </div>
        );
      })}
    </div>
  );
}

export default DrawDayInstructions;
