import moment from "moment";
import { getNameOfMember, getPictureURL } from "../../../../../utils/users";

function ChatMessage ({message, user}) {
    const getDateString = (theDate) => {
        const momentDate = theDate ? moment(theDate) : moment();
    
        if(momentDate.isSame(moment(), "day")){
          return momentDate.format("HH:mm");
        }
    
        return momentDate.format("Do MMM");
      }

      const pictureSrc = message.isAdmin || message.user.firstname === "StepLadder" ? "./images/stepLadderLogo.png" : getPictureURL(message.user);

    return (<div
          className={
            "individualChatMessage" +
            (message.user && user._id === message.user._id ? " me":"")
          }
        >
          <div className="chatMessage">
            <div>
                <div className="coverimage" style={{backgroundImage:"url("+pictureSrc+")"}} />
            </div>
            <div>
              <div className="name">
                <div>{getNameOfMember(message.user, message.isAdmin)}</div>
                <div className="date">{getDateString(message.showFromDate || message.dateSubmitted)}</div>
              </div>
              <div className="message">{message.message}</div>
            </div>
          </div>
        </div>)
  }


export default ChatMessage;
