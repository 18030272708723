import { InputText } from "primereact/inputtext";
import { useState } from "react";
import { uploadImage } from "../../services/articlesService";
import { toBase64 } from "./ImageHandler";

function UploadPic({ onChange }) {
  const [isPicLoading, setIsPicLoading] = useState(false);

  const uploadPic = async (event) => {
    try {
      setIsPicLoading(true);
      const file = event.target.files[0];

      const base64 = await toBase64(file);

      if (base64 === "") {
        throw new Error("file is empty");
      }

      const response = await uploadImage(base64);

      if (!response.status) {
        throw new Error(response.error);
      }

      onChange(response.url);
    } catch (err) {
      setIsPicLoading(false);
      alert(err.message);
    }
  };

  return (
    <div
      style={{
        display: "inline-block",
        backgroundColor: "#ececec",
        borderRadius: "10px",
        padding: "10px",
        marginBottom: "15px",
        cursor:"pointer"
      }}
    >
      <InputText
        style={{
            cursor:"pointer",
          opacity: 0,
          width: "100%",
          height: "100%",
          position: "absolute",
          top: 0,
          left: 0,
        }}
        type="file"
        onChange={(e) => uploadPic(e)}
      />
      {isPicLoading ? <span>Loading....</span> : <span>Upload File</span>}
    </div>
  );
}

export default UploadPic;
