import React from "react";
import PlatformContentContainer from "../_generic/platformContentContainer";
import { Button } from "primereact/button";
import { useHistory } from "react-router";
import { addMeta } from "../../../services/meta";
import { useUser } from "../../../contexts/UserContext";

function ContinueBuilding() {
  const history = useHistory();
  const userContext = useUser();


  function goNext() {
    addMeta({ drawDayContinueBuildingSeen: true })
    const path = userContext.isAnyHomeCircle() ? "/dashboard/drawday/partnersViewFairfax" : "/dashboard/drawday/partnersViewHargreaves";
    history.push(path);
  }

  return (
    <div
      className={"drawDayStoryContainer drawday"}
      style={{
        backgroundImage: "url(./images/drawday/drawday-bg.png)",
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        zIndex: "55",
      }} >
      <PlatformContentContainer
        noChildrenDiv={true}
        className="platform_small p-mt-5 p-pt-6 p-pr-5 p-pl-5 height-100">
        <div className="height-100 p-pb-0 p-grid p-m-0 center"
          style={{ paddingTop: '20vmin', }}>
          <div className="p-mt-1 p-text-center p-align-end show-desktop width-100">
            <h2 className="center p-text-center show-desktop p-col-12 p-pt-3">
              Great work on continuing to build your financial future!
            </h2>
            <h3 className="center-el p-mt-3 p-st-white show-desktop p-col-12 p-p-0 f-weight400">
              The membership team will be in touch to confirm your additional Circle spot.
            </h3>
          </div>
          <Button
            label="Continue"
            className="p-button-raised width-100 bg-stepladder-green main-color p-m-0 p-p-0"
            style={{ height: '3em' }}
            onClick={() => goNext()} />
          <div className="p-grid" style={{ flexDirection: "row-reverse", }}>
            <img
              className="p-pt-3"
              style={{ width: '70%', height: '90%' }}
              alt="logo"
              src="./images/drawday/builder-on-ladder-1.png"
            />
          </div>
        </div>
      </PlatformContentContainer >
    </div >
  );
}

export default ContinueBuilding;
