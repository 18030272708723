import {useEffect} from "react";
import stepLadderInfo from "./templates/stepLadderInfo";
import { useState } from "react";
import QuizPageLayout from "./templates/quizPage";
import {getABVariant} from "../../../../../../../services/meta";
import { isMobile } from "../../../../../../../utils/generic";
import { useUser } from "../../../../../../../contexts/UserContext";
import InfoSlab from "./../../../../../../generic/InfoSlab";

function BeforeYouStart({ nextPage, setTitle, setIsFull }) {
  const [variant, setVariant] = useState(0);
  const userContext = useUser();

  useEffect(() => {
    setTitle("Here's the small print (but we want to make sure it's clear).");
    // if(isMobile && !userContext.isHideABTest()) {
    //   setIsFull(true)
    //   startTest();
    // }
  }, [setTitle])

  // async function startTest() {
  //   const abVariant = await getABVariant(userContext.user, "onboardingMobileVersion");
  //   setVariant(abVariant);
  // }

  const btnClass = `${variant ? "p-button-stepladder" : ""}`; // "p-mt-6 " +

  return (
    <QuizPageLayout btnClass={btnClass} buttonLabel={"Wish to continue?"}  onSubmit={() => nextPage("questions")}>

      {stepLadderInfo.map((itm, key) => (
        <InfoSlab key={key} data={itm} variant={variant}/>
      ))}
    </QuizPageLayout>
  );
}

export default BeforeYouStart;
