import React from "react";
import PlatformContentContainer from "../../_generic/platformContentContainer";
import FeedReferrals from "../../circles/circleFeed/components/referrals";
import AddonsNavigation from "./common/navigation";


function ReferFriend() {
    return (
        <PlatformContentContainer className="platform_small p-mt-lg-4">
            <AddonsNavigation title="Refer a Friend" />
            <div className="blueBox investor p-mb-3">
                <b>It’s Better Together!</b>
                <p>
                    Share the love and reach your goals with your friends.  Invite them to join StepLadder we’ll reward you both with a £25 Amazon voucher*.  There’s no limit to how many people you can invite and get rewarded for, so start sharing today...
                </p>
                <p>
                    <i>
                        * You will be rewarded when somebody who signs up using your link joins and makes their first 3 payments.
                    </i>
                </p>
            </div>
            <div className="p-grid">
                <FeedReferrals className="center-img width-100 p-m-2 p-pb-6"/>
            </div>
        </PlatformContentContainer>
    );
}

export default ReferFriend;