import InsuranceQuizQuestion from "../components/InsuranceQuizQuestion";

function InsuranceBuyHome({ onNext, onChange }) {
  return (
    <InsuranceQuizQuestion
      title="Are you either looking to buy a house or own a home already?"
      onChange={(newval) => onChange({ lookingToBuy: newval })}
      description="Question 2 of 3"
      onNext={onNext}
    />
  );
}

export default InsuranceBuyHome;
