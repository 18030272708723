import { Button } from "primereact/button";
import { useEffect } from "react";
import PlatformContentContainer from "../../platform/_generic/platformContentContainer";
// import { useHistory } from "react-router";
import { addMeta } from "../../../services/meta";

function ApplicationCompleteBigAmount() {
    // const history = useHistory();
    const text = "Based on our affordability calculations for you, we’ve worked out that you are eligible for more than one StepLadder Savings Circle.";
    const text2 = "The button below shows the additional  Circle we think you can afford to join. More Circles means you will reach your savings goals faster.";
   

    useEffect(() => {
        addMeta({bundleTestShown: true})
    }, []);

    const goTo = (path) => {
        if(path === "/circleInvite") {
            addMeta({bundleTestClick: true})
        }
        window.location = path;
        // history.push(path)
    }
    return (
        <div className={"drawDayStoryContainer drawday"} 
                style={{backgroundImage: "url(./images/drawday/application-complete-big-amount.png)",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: "55"
        }} >
            <PlatformContentContainer className="platform_small p-mt-6 p-pb-6 ">
                <div style={{width: "300px"}}>
                    <h1 className="p-mt-6">Congratulations! Your application is complete</h1>
                </div>
                <h3 className="p-pt-3 p-mt-0 p-pb-4" style={{color: "var(--stepladder-green)"}}>{"But before you go..."}</h3>
                <p className=" p-mb-3" style={{width: "300px"}}>
                    {text}
                </p>
                <p className=" p-mb-3" style={{width: "300px"}}>
                    {text2}
                </p>
                <div>
                    <Button
                        label={`Save an extra £1,000
                        over the next 10 months`}
                        className="p-mt-5 p-button-raised p-button-stepladder width-100"
                        onClick={() => {goTo("/circleInvite")}} />
                    <Button
                        className="p-button-link width-100"
                        label={`No thanks`}
                        style={{color: "white"}}
                        onClick={() => {goTo("/")}} />
                </div>
            </PlatformContentContainer>
        </div>
    );
}

export default ApplicationCompleteBigAmount;
