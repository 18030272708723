import { useUser } from "../../../../../contexts/UserContext";
import FeedComponentContainer from "./feedComponentContainer";

function FeedReferrals(props) {
  const userContext = useUser();
  const className = (props.className || "");

  const learnMoreLink = "https://www.joinstepladder.com/risk";


 

  return (
    <FeedComponentContainer className={className} title="">
      Don’t invest unless you’re prepared to lose money.
      This is a high-risk investment. You may not be able to access your money easily and are unlikely to be protected if something goes wrong. Take two minutes to{" "} 
      <a target="_blank" rel="noopener noreferrer" href={learnMoreLink}>
        learn more
      </a>.
      
      
    </FeedComponentContainer>
  );
}

export default FeedReferrals;
