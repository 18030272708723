import React, { useState, useEffect } from "react";
import { useHistory } from "react-router";

import { useUI } from "../../../../contexts/UIContext";
import { useUser } from "../../../../contexts/UserContext";
import {
  getAvailableCircles,
} from "../../../../services/circles";

import { getNextRoute } from "../../../../utils/userRoutes";
import CenterMiddle from "../../../generic/CenterMiddle";
import LoadingOverlay from "../../../generic/LoadingOverlay";
import getNumberOfActiveCircleSpots from "../terms/logic/getNumberOfActiveCircleSpots";
import TermsMaxCircleError from "../terms/termsMaxCircleError";

import CircleInviteView from "./circleInviteView";
import CircleInviteError from "./errorMessage";
import { isHouseRelated } from "../../../../utils/users";

function CircleInviteMain({setTitle, setSubTitle}) {
  
  const [isLoading, setIsLoading] = useState(true);
  const [circleData, setCircleData] = useState([]);
  const [numberOfActiveSpots, setNumberOfActiveSpots] = useState(0)

  const [selectedCircle, setSelectedCircle] = useState(null);
  const userContext = useUser();
  const user = userContext.user;

  const UI = useUI();
  const history = useHistory();

  const maxSpots = 3;

  useEffect(() => {
    if(userContext.user.stage === "Dashboard"){
      const numberOfActiveSpots = getNumberOfActiveCircleSpots(
        userContext.user, userContext.user.circles?.approved
      );

      setNumberOfActiveSpots(numberOfActiveSpots);

      if(numberOfActiveSpots >= maxSpots){
        return;
      }
    } else {
      setTitle()
    }

    loadCircles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const loadCircles = async () => {
    try {
      const ambassador_id = userContext.user.ambassador_id;
      let {data, status} = await getAvailableCircles(ambassador_id);

      if((!isHouseRelated(user.reason_for_saving) || user.do_you_have_a_referral_code_ === "paunamadalin1997@gmail.com") && !user.ambassador_id){
        data = data.filter(circ => circ.monthly_investment <= 100);
      }

      if(status !== 200 || (data && data instanceof Array && data.length === 0)){
        showGenericError();
        return;
      }

      const circleData = data.sort(
        (a, b) =>
          parseFloat(b.period * b.monthly_investment) -
          parseFloat(a.period * a.monthly_investment)
      );

      setCircleData(circleData);
      setSelectedCircle(circleData[0]);

      if (userContext.user.stage === "CircleInvite" || sessionStorage.getItem("smallCircle")) {
        let circleToSelect = circleData[0];

        if(localStorage.getItem("smallCircle")){
          circleToSelect = circleData.find(c => c.monthly_investment === 25) || circleData[0];
        }

        sessionStorage.removeItem("smallCircle");

        submitSelection(circleToSelect);
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      showGenericError();
    }
  };

  const showGenericError = () => {
    UI.showToast(
      "We had some trouble with your application, please contact us to fix it"
    );
    setCircleData([]);
    setIsLoading(false);
  };

  const submitSelection = async (circle) => {

    setIsLoading(true);

    try {
        const { status } = await userContext.updateUser({requestCircle: circle ? circle : selectedCircle});

        if(status !== 200){
            throw new Error("request circle failed");
        }

        if (userContext.user.stage !== "CircleInvite") {
            history.push("/terms");
            return;
        }

        const {status: updateStageStatus} = await userContext.updateUser({ nextStage: true });

        if (updateStageStatus === 200) {
            history.push(getNextRoute(userContext.user));
        } else {
            throw new Error("update stage failed");
        }

    } catch (err){
      console.log(err);
        showGenericError();
    }
  };

  if (numberOfActiveSpots >= maxSpots) {
    return <TermsMaxCircleError />;
  }

  if (isLoading) {
    return <LoadingOverlay></LoadingOverlay>;
  }

  return (
    <CenterMiddle>
      {circleData.length === 0 ? (
        <CircleInviteError></CircleInviteError>
      ) : (
        <CircleInviteView
          setTitle={setTitle}
          setSubTitle={setSubTitle}
          circleData={circleData}
          selectedCircle={selectedCircle}
          onSubmit={() => submitSelection()}
          onChange={(circle) => setSelectedCircle(circle)}
        ></CircleInviteView>
      )}
    </CenterMiddle>
  );
}

export default CircleInviteMain;
