import React, { useEffect } from "react";
import StickyButtons from "../../generic/stickyButtons";
import { addLeadMeta } from "../../../services/meta";

function GetStartedRiskDisclaimer({ setPage, userInfo, setTitle, setSubTitle }) {
  const learnMoreLink = "https://www.joinstepladder.com/risk";
  const leaveLink = "https://www.joinstepladder.com/faqs";

  useEffect(() => {
    setTitle("Mandatory FCA risk disclaimer");
    setSubTitle('');
    addLeadMeta({ getStartedDisclaimerSeen: true });
  }, [setTitle, setSubTitle]);

  const submit = () => {
    addLeadMeta({ getStartedDisclaimerNext: true });
    setPage(2);
  };

  const leave = () => {
    addLeadMeta({ getStartedDisclaimerLeave: true });
    window.location.href = leaveLink;
  }

  return (
    <>
      <div className="disclaimer_text blueBox">
        <h3>
          {userInfo["firstname"]}, this is a high-risk investment. How would you feel if you lost the money you're about to invest?
        </h3>
        <a target="_blank" rel="noopener noreferrer" href={learnMoreLink}>
          Take 2 minutes to learn more
        </a>.
      </div>
      <StickyButtons
        cancelText="Leave"
        nextText="Continue"
        onCancel={leave}
        nextClick={submit}
      />
    </>
  );
}

export default GetStartedRiskDisclaimer;
