import {useEffect, useState} from "react";
import QuizPageLayout from "./templates/quizPage";
import QuizTitle from "./templates/quizTitle";
import {getABVariant} from "../../../../../../../services/meta";
import { isMobile } from "../../../../../../../utils/generic";
import { useUser } from "../../../../../../../contexts/UserContext";

function FrontPage({ nextPage, setIsFull }) {
  const userContext = useUser();
  const [variant, setVariant] = useState(0);

  // //kept the code in case they want to change the variant and re-try the test
  // useEffect(() => {
  //   if(isMobile && !userContext.isHideABTest()) {
  //     setIsFull(true)
  //     startTest();
  //   }
  // }, [])

  //kept the code in case they want to change the variant and re-try the test
  // async function startTest() {
  //   const abVariant = await getABVariant(userContext.user, "onboardingMobileVersion");

  //   setVariant(abVariant);
  // }
  const textColor = `${variant ? "white" : ""}`;
  const imgSrc = `${variant ? "./images/new-circle.png" : "./images/circle.png"}`;
  const imgWidth = `${variant ? "250px" : ""}`;
  const imgMargin = `${variant ? "0em auto" : ""}`;
  const btnClass = `${variant ? "p-button-stepladder" : ""}`;
  const btnText = `${variant ? "Start quiz" : "Next"}`;


  
  return (
    <QuizPageLayout btnClass={btnClass} buttonLabel={btnText} onSubmit={() => nextPage("beforeYouStart")}>
      <img src={imgSrc} alt="quizImage" style={{width: imgWidth, margin: imgMargin}}  className="circleImage" />
      <QuizTitle color={textColor}>You're almost there!</QuizTitle>
      <p style={{color: textColor, fontWeight: "bold", fontSize: "125%"}} className="mobile-text-center">
        It's quiz time...
      </p>
      <p style={{color: textColor, fontSize: "125%"}} className="p-pb-6 mobile-text-center">
        We operate under Financial Conduct Authority regulation and it's important to us that you know how StepLadder works, so we've put together this short quiz to check your understanding.
      </p>
    </QuizPageLayout>
  );
}

export default FrontPage;
