import { useEffect, useState } from "react";
import moment from "moment";
import { useUser } from "../../../../contexts/UserContext";
import CountdownTimer from "../../../generic/CountdownTimer";
import { Button } from "primereact/button";
import { getCookie } from "../../../../utils/users";

const env = process.env.REACT_APP_ENV;
const testTimerSeconds = 180;


function CoolOffCountdown({ setPage }) {
  const userContext = useUser();
  const allowNewMembers = (moment().isBefore(moment('2023-06-01'))) || ['KogoPay', 'Fusion_Bridge_Capital'].includes(userContext.user.ambassador_id);
  const collOffHours = allowNewMembers ? 24 : 24 * 10000;

  const timerEndDT = moment(userContext.user.coolOffStarted).utc().add(collOffHours, "hour");
  const secondsLeft = timerEndDT.diff(moment().utc(), "second");
  
  const [isTimerFinished, setIsTimerFinished] = useState(secondsLeft < 0);
  useEffect(() => {
    if (allowNewMembers && isTimerFinished) {
      setPage(1);
    }
  }, [isTimerFinished]);

  return (
    <>
      {allowNewMembers &&
        <div className="p-pt-6">
          <h2 className="page-title flex-center-bold p-pt-4">
            Congratulations!
            <br />You've qualified for a Circle, {userContext.user.firstname}!
          </h2>
          <h3 className="flex-center-bold p-mt-5 p-mb-2"
            style={{ color: "var(--main-color)", fontSize: "1.16rem", fontWeight: "bold" }}>
            You've told us everything we need so our team can match you to your perfect Circle
          </h3>

          <h5 className="subTitle flex-center-bold p-pt-1 p-mt-0 p-pb-2">
            This processing takes 24 hours and the countdown to your financially fit future starts now!
            Leave this tab open and check back tomorrow. We will also email you when your place in your Circle is ready.
          </h5>
          <CountdownTimer
            Hours={0}
            Minutes={0}
            Seconds={env.includes('test') ? testTimerSeconds : secondsLeft || 0}
            setIsTimerFinished={setIsTimerFinished}
          />
        </div>
      }
      {!allowNewMembers &&
        <div className="p-pt-4">
          <h2 className="page-title flex-center-bold p-pt-4">
            Thank you for your details {userContext.user.firstname}!
          </h2>
          <h3 className="flex-center-bold p-mt-5 p-mb-2"
            style={{ color: "var(--main-color)", fontSize: "1.16rem", fontWeight: "bold" }}>
            We're sorry, we don't currently have any available Circles that match your requirements.
          </h3>

          <h4 className="subTitle flex-center-bold p-pt-1 p-mt-0 p-pb-2">
            We will be in touch as soon as we have a Circle ready for you.
          </h4>
          <div className="p-grid center" style={{ paddingTop: '2vh' }}>
            <Button
              label={"Close screen"}
              className={`p-button-text font-sm main-color`}
              style={{ textDecoration: 'underline', width: '90%', }}
              onClick={() => {
                window.open("about:blank", "_self");
                window.close();
              }} />
            <div className="p-grid center p-pt-4">
              <img
                src="images/intuituve portal 1.png"
                style={{ width: "80%" }}
              ></img>
            </div>
          </div>

        </div>
      }
    </>
  );
}

export default CoolOffCountdown;
