
import React, { useState, useRef } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import moment from "moment";
import utils from "../../utils/utils";

function MetaTable({metas, loading, metaName}){

    const metaTemp = ({meta}) => {
        return JSON.stringify(meta)
    }
    const dateFormat = ({dateSubmitted}) => {
        return dateSubmitted ? moment.utc(dateSubmitted).format("DD-MM-YYYY") : "no date";
    };
    const userIdTemp = ({userID}) => {
        const userHref = "/adminPortal/user?id=" + userID;
        return <a href={userHref} target="_blank" className={`p-tag p-tag-rounded ${userID.startsWith("lead") ? "p-tag-warning": ""}`}>{userID}</a>
    }
    const userEmail = ({email}) => {
        return email;
    }
    const userFN = ({firstname}) => {
        return firstname;
    }
    const userLN = ({lastname}) => {
        return lastname;
    }
    const userName = () => {
        return userFN()+' '+userLN();
    }
    const userPhone = ({mobilephone}) => {
        return mobilephone;
    }
    const exportCSV = () => {
        utils.downloadCSVFromJson(metas.map(({meta, __v, ...restMeta})=> ({...restMeta, meta: JSON.stringify(meta)})), `meta-${metaName}-list`);
    }
    const header = <div className="p-grid p-m-0">
    <div className="p-col">
        Count: {metas?.length}
    </div>
    <div style={{textAlign:'right'}}>
        <Button
            disabled={!metas.length}
            type="button"
            icon="pi pi-upload"
            label="Export"
            className="p-button p-button-sm p-button-info"
            onClick={exportCSV}></Button>
    </div>
    
    </div>;

    return (<>
        <div>
            <div className="card blue-table p-mt-3">
                <DataTable
                    className="table-rounded p-datatable-sm"
                    paginator
                    header={header}
                    scrollable 
                    scrollHeight="62vh" 
                    value={metas}
                    loading={loading}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={50} rowsPerPageOptions={[30, 50, 100, 200]}>
                        <Column field="dateSubmitted" sortable style={{width: "10%"}} header="Date" body={dateFormat} />
                        <Column field="userID" style={{width: "200px"}} header="UserId" body={userIdTemp}/>
                        <Column field="firstname" style={{width: "200px"}} header="Firstname" body={userFN}/>
                        <Column field="lastname"  style={{width: "200px"}} header="Lastname" body={userLN}/>
                        <Column field="mobilephone"  style={{width: "120px"}} header="Phone" body={userPhone}/>                     
                        <Column field="email" style={{width: "auto"}} header="Email" body={userEmail}/>
                        <Column field="meta" style={{width: "auto"}} sortable header="Meta" body={metaTemp} />
                </DataTable>
            </div>
        </div>
      </>
    )
    
}

export default MetaTable;