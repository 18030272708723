import userAPI from "./userAPI";

async function postChat(circleID, message){
    return userAPI.call("/chats", {
        method: "POST", body: {
            circleID,
            message
        }
    });
}

async function getChats(_id, limit){
    return userAPI.call("/chats?circle="+_id+(limit ? "&limit="+limit : ""), {
        method: "GET",
    });
}


export {getChats, postChat};