
import FeedComponentContainer from "./feedComponentContainer";

function DrawDayCountDown(props) {

  return (
    <FeedComponentContainer title="When is Draw Day">
      Draw Day is the most exciting day of the month. On the 7th of every month at around 10am, one person from your Circle is randomly drawn!  We'll email to remind you, but you can check back here to see who has been drawn.
      <br /><br />If you have just started your Circle, there is a reserve period of 1 or 2 months before the first person draws. Check your Circle for the specific details.
    </FeedComponentContainer>
  );
}

export default DrawDayCountDown;
