import React,{useState, useEffect} from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Dropdown } from 'primereact/dropdown';

function AdminUserForm({adminToEdit, setAdminToEdit, saveRoleChange, setDeletionConfirmationVisible, addAdmin}) {
    const roles = [
        { role: 1 },
        { role: 2 },
        { role: 3 }
    ];
    const [editUser, setEdituser] = useState(adminToEdit);
    useEffect(() => {
        setEdituser(adminToEdit)
      },[adminToEdit])

    return (
        <div>
        <h4 className="p-mt-0">Role permissions:</h4>
        <ul className="p-mb-3">
          <li>1: Admin (all permissions)</li>
          <li>2: Moderators (no payments access, but can edit users)</li>
          <li>3: View Permissions (read only access for customers)</li>
        </ul>
        <h4>
            {`${!adminToEdit.isNew ? "Change Role" : "Add admin email and with role"}`}
        </h4>
        <div className="p-formgroup-inline">
        {adminToEdit.isNew && <div className="p-field">
                <label htmlFor="email" className="p-sr-only">email</label>
                <InputText
                    id="email"
                    type="email"
                    placeholder="email"
                    onChange={(e) => setAdminToEdit({...editUser, email: e.target.value})} />
            </div>}
            <div className="p-field">
                <Dropdown
                    value={{role: editUser.admin_role}}
                    options={roles}
                    onChange={(e) => { 
                        if(adminToEdit.isNew) {
                            setAdminToEdit({...editUser, admin_role: parseInt(e.value.role)})
                        } else{
                            setAdminToEdit({...editUser, admin_role: parseInt(e.value.role)})
                        }
                    }
                        }
                    optionLabel="role"
                    placeholder="Select a role" />
            </div>
        </div>
        <div className="p-mb-5">
            Changes to a role can take up to 2 hours due to authentication being
            cached. If you want to speed this up, ask the user to re-login.
        </div>
        {!adminToEdit.isNew ? <>
            <Button
                label="Save"
                icon="pi pi-check"
                onClick={saveRoleChange}
            />
            <Button
                style={{ marginLeft: "1em" }}
                label="Remove User From Portal"
                className="p-button-danger p-button-outlined"
                icon="pi pi-trash"
                onClick={() => {
                    setDeletionConfirmationVisible(true)
                }}
            />
            
        </>: <Button
        label="Add new admin"
        icon="pi pi-check"
        onClick={addAdmin}
      />}
        
      </div>
    )
}

export default AdminUserForm;