import { useRef } from "react";
import { useEffect, useState } from "react";

function ToastMessage(props) {
  const [show, setShow] = useState(false);
  const [animation, setAnimation] = useState("fadeInUp");
  const timer = useRef(null);
  const [message, setMessage] = useState();

  useEffect(() => {
      if(!props.message){
          return;
      }
      showToast();  
      // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.message]);

  const showToast = () => {
    setMessage(props.message.message);
    setAnimation("fadeInUp");
    setShow(true);
    clearInterval(timer.current);
    timer.current = setInterval(() => {hideToast();}, 4000);
  };

  const hideToast = () => {
    setAnimation("fadeOutDown");
    clearInterval(timer.current);
    timer.current = window.setTimeout(() => {
        setShow(false);
        clearInterval(timer.current);
    }, 500);
  };

  return (
    <div
      style={{ display: show ? "block" : "none" }}
      className={
        "globalToast animate__animated animate__" +
        animation +
        " animate__faster"
      }
    >
      <div>{message}</div>
      
    </div>
  );
}

export default ToastMessage;
