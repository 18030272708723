import React, {useEffect} from "react";
import { useHistory } from "react-router";
import { isMobile, phoneMask2Number } from "../../../utils/generic";
import { getCookie } from "../../../utils/users";
import Form from "../../generic/forms/Form";
import PrivacyPolicy from "./privacyPolicy";

function GetStartedApply({setPage, setUserInfo, userInfo, setTitle, setSubTitle}) {
  const referralCodeCookie = getCookie("referralCode");
  const history = useHistory();

  useEffect(() => {
    setSubTitle(isMobile ? <PrivacyPolicy /> : "");
    setTitle("Apply Now");
  }, [setSubTitle, setTitle]);


  const submit = async (values) => {

    if (values.referralCode && values.referralCode !== "") {
      values.do_you_have_a_referral_code_ = values.referralCode;
    }
    values.hutk = getCookie("hubspotutk");
    delete values.referralCode;
    delete values.referralToggle;

    if (getCookie("ambassador") || userInfo.ambassador_id) {
      values.ambassador_id = getCookie("ambassador") || userInfo.ambassador_id;
    }

    if (localStorage.getItem("abLeadID")) {
      values.abLeadID = localStorage.getItem("abLeadID");
    }
    //remove any non-digit characters before saving
    values.mobilephone = phoneMask2Number(values.mobilephone);

    setUserInfo(values);
    setPage(1);
  };

  function getSavedUserInfo(key) {
    return userInfo[key] || null;
  }

  const getOriginalValue = (key) => {
    return getSavedUserInfo(key) || getCookie(key) || sessionStorage.getItem(key) || "";
  }


  return (
    <>
        <div className={`headerDescription desktop_only`}>Already have an account? Login <b style={{cursor:"pointer"}} onClick={() => history.push("/welcomeBack")}>here</b>.</div>

        
        <Form
          className="getStartedForm"
          inputs={[
            { key: "firstname", value: getOriginalValue("firstname") },
            { key: "lastname", value: getOriginalValue("lastname")},
            { key: "email", value: getOriginalValue("email") },
            { key: "mobilephone", value: getOriginalValue("mobilephone") },
            {
              key: "referralToggle",
              value: !!referralCodeCookie,
              showCondition: function (allValues) {
                return !allValues.find((itm) => itm.key === "referralToggle")
                  .value;
              },
            },
            {
              key: "referralCode",
              value: referralCodeCookie,
              showCondition: function (allValues) {
                return allValues.find((itm) => itm.key === "referralToggle")
                  .value;
              },
            },
            { key: "joint_application", value: false,  },
            !isMobile ? {key: "customComponent", component: PrivacyPolicy} : null,
          ].filter(itm => itm)}
          onSubmit={(values) => {
            submit(values);
          }}
        />
    </>
  );
}

export default GetStartedApply;
