
function SideDoor(){
    var loc = window.location.pathname;
    

    let style = {width:"100%", height:"450px"};

    if(loc === "/sidedoor"){
        style = {width:"100%", height:"100vh", position:window.innerWidth < 600 ? "relative" : "absolute", top:0,left:0};
    }

    return <iframe style={style} srcDoc={'<div id="sidedoor-widget" data-affiliate="stepladder"></div><link href="https://app.sidedoor.co.uk/css/widget.css" rel="stylesheet"><script src="https://app.sidedoor.co.uk/js/widget.js"></script>'} title="SideDoor" />
}

export default SideDoor;