import React, {useState} from "react";
import { useUser } from "../../../contexts/UserContext";

import { isMobile } from "../../../utils/generic";
import PlatformContentContainer from "../_generic/platformContentContainer";
import {options} from "./config";
import MoneyBack from "../vouchers/moneyBack";
import ThanksSubmit from "./thanksSubmit";
import { currencyFormat } from '../../../utils/generic';

function CardDetails({submittedDetails}) {
  const userContext = useUser();
  const [submitted, setSubmitted] = useState(false); 

  const drawnSpots = userContext.drawnSpots();
  const hasWon = drawnSpots.length > 0;
  const option = options[hasWon ? "drawn" : "notdrawn"];
  const drawnSpot = drawnSpots.length ? drawnSpots[0] : null;
  
 const InnerComponent = () => {
  return submitted ? <ThanksSubmit submitted={submittedDetails} userName={userContext.user.firstname}/> :
  <MoneyBack isWinner={hasWon} isNew={true} selected={{key: "moneyBack"}} onFinish={() => setSubmitted(true)} signupToNewCircle={false}></MoneyBack>

 }

  return (
    <div className={"drawDayContainer bg-white " + (isMobile ? "mobile" : "desktop")} >
    <div style={{backgroundImage: "url(" + option.header + ")",
        backgroundPosition: 'top center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        zIndex: "55",
        maxHeight: "250px"
    }}>
        <PlatformContentContainer className="platform_small p-mt-4 p-pt-6 p-pb-6 center-el">
          <h1 className="center txt-rem-xxxl p-mt-0" style={{color: "white"}}>{currencyFormat(drawnSpot ? drawnSpot.awarded : 0)}</h1>
          <p className="center p-mt-0 p-pb-4" style={{color: "white"}}>{option.text}</p>
          <div className="p-mt-6 p-pr-0 p-pl-0">
            <InnerComponent />
              
          </div>
        </PlatformContentContainer>
    </div>
</div>
  );
}

export default CardDetails;
