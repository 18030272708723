import articleConfig from "../../users/utils/articles";
import API from "./api";

async function getAllArticles(){
    return API.call({url: "/articles/admin", method: "GET"})
}

async function getArticleContent(id){
    return API.call({url: "/articles/contentAdmin?id="+id, method: "GET"});
}

async function createOrUpdateArticle(updateObject){
    return API.call({url: "/articles/", method: "POST", body: updateObject});
}

async function uploadImage(base64){
    return API.call({url: "/articles/image", method: "POST", body: {image: base64}});
}

async function deleteArticle(id){
    return API.call({url:"/articles", method: "DELETE", body: {articleID: id}});
}

const articleCategories = {
    education: articleConfig.education.categories.map(c => c.key),
    marketplace: articleConfig.marketplace.categories.map(c => c.key)
}

export {getAllArticles, deleteArticle, articleCategories, createOrUpdateArticle, getArticleContent, uploadImage}