import React from "react";
import { useState, useEffect } from "react";
import { getQueryParams } from "../../../utils/generic";
import ArticleView from "./View";

function ArticleList(props) {
  const list = props.data;
  const [articleSelected, setArticleSelected] = useState();
  const [showArticle, setShowArticle] = useState(false);

  useEffect(() => {
    const params = getQueryParams();

    if(params && params.loadArticle){
      const articleFound = list.find(itm => String(params.loadArticle) === String(itm._id));

      if(articleFound){
        setArticleSelected(articleFound);
        setShowArticle(true);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <div className="articleListClient">
        {list.map((itm, i) => (
          <div key={i} onClick={() => {setArticleSelected(itm); setShowArticle(true)}}>
            <div
              className="coverimage"
              style={{ backgroundImage: "url(" + itm.picture + ")" }}
            ></div>
            <div>
              <div className="title">{itm.title}</div>
              <div className="description">{itm.previewText}</div>
              <div className="description">{itm.subHeading2 || "Click to view."}</div>
            </div>
          </div>
        ))}
      </div>
      {showArticle && <ArticleView article={articleSelected} onHide={() => setShowArticle(false)} />}
    </React.Fragment>
  );
}

export default ArticleList;
