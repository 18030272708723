import React, { useEffect, useState } from "react";

import moment from "moment";
import LoadingSpinner from "../../components/loadingSpinner";

import DrawDayGeneratorTable from "./generatorTable/main";
// import DrawDaySavedTable from "./savedTable/main";
import DrawDayInstructions from "./drawDayInstructions";
import platformEventsService from "../../services/platformEventsService";
import drawDayGeneratorUtils from "./drawDayGeneratorUtils";

function AdminDrawDayGeneratorMain({setTitle, setSubTitle, showToast}) {
  const monthSelected = moment.utc().startOf("month").toDate();
  const [buttonClick, setButtonClick] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [drawDayStatus, setDrawDayStatus] = useState();
  const [lastDrawDay, setLastDrawDay] = useState([]);
  const getDrawDaySituation = async () => {
    const lastDrawDayEvent = await platformEventsService.get({ type: "drawDayGenerated" }, 1);

    //if last draw day event wasnt this month, its time to generate the draw
    if (
      !lastDrawDayEvent ||
      !lastDrawDayEvent.length ||
      !moment
        .utc(lastDrawDayEvent[0].dateSubmitted)
        .isSame(monthSelected, "month")
    ) {
      setDrawDayStatus("GENERATE");
    } else {
      setLastDrawDay(lastDrawDayEvent[0]);
      setDrawDayStatus(lastDrawDayEvent[0].status);
    }

    setIsLoading(false);
  };

  useEffect(() => {
    setTitle("Draw Day Generator");
    getDrawDaySituation();

    if(drawDayStatus === "PUBLISHED") {
      setSubTitle("The draw is done for this month, come back next month to generate it again.")
    };
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const publishData = async () => {
    try {
      await drawDayGeneratorUtils.publishDrawnParticipant(lastDrawDay._id);
      window.location.reload();
    } catch (err) {
      showToast(err, "error");
    }
  };

  const next = () => {
    setButtonClick(new Date());
    if (drawDayStatus === "SAVED") {
      publishData();
    }
  }

  return (
    <React.Fragment>

      {isLoading && <LoadingSpinner></LoadingSpinner>}
      {!isLoading && drawDayStatus && (
        <React.Fragment>
          <DrawDayInstructions
            onClick={next}
            status={drawDayStatus}
          ></DrawDayInstructions>

          {drawDayStatus === "GENERATE" && (
            <DrawDayGeneratorTable
              month={monthSelected}
              buttonClick={buttonClick}
              status={drawDayStatus}
              onComplete={() => window.location.reload()}
            ></DrawDayGeneratorTable>
          )}
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default AdminDrawDayGeneratorMain;
