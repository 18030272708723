import moment from "moment";
import React from "react";
import { useUser } from "../../../../contexts/UserContext";
import { createPartnerActivity } from "../../../../services/partnerActivity";
import { isMobile } from "../../../../utils/generic";

function AddonsMain({circles}) {
    const userContext = useUser();
    const tilesToShow = [
        {
            img: "./images/addons/hl-dashboard.png",
            route: "https://www.members.joinstepladder.com/urlRedirect?url=https://hl.7eer.net/0JKe0V&key=hargreavesLansdowne&email=",
            trackName: "hl"
        },
        {
            img: "./images/addons/snoop-dashboard.png",
            route: "https://www.members.joinstepladder.com/urlRedirect?url=https://snoop.sjv.io/b31QJB&key=Snoop&email=",
            trackName: "snoop"
        }
    ]

    function goTo(link, trackName) {
        createPartnerActivity(trackName, "platformClick", userContext.user.email);
        window.open(link + userContext.user.email, '_blank')
    }

    const header = (
        <div style={{textAlign: !isMobile ? "center" : "left"}}>
            Let us help you reach your financial goals today.
        </div>
    );

    const isEaster2023 = moment(new Date()).utc() < moment('2023-04-11').utc();
    const showEasterBanner2023 = (
        <div className="p-pt-3 p-pl-2">
            <div className={"font-sm " + !isMobile ? "center" : "left"}>
                Due to the Easter Bank Holidays, we have moved April’s Draw Day to April  11th in order to process payments without a delay.
            </div>
            <div className="p-pt-3 p-pb-3 center">
                <img
                    className="round-img"
                    style={{ borderRadius: "10px", width: "17em" }}
                    alt="Easter 2023"
                    src={`images/easter2023.png`}>
                </img>
            </div>
            <div className="divider"></div>
        </div>
    );

    return (
        <div>
            {header}
            {isEaster2023 && showEasterBanner2023}
            <div className="p-grid p-mt-3">
                {tilesToShow && tilesToShow.map((tile, i) => (
                    <div key={i} className="p-col-6 p-mt-3"  onClick={() => goTo(tile.route, tile.trackName)}>
                        <div className={`tile square ${i === 0 ? "first" : ""}`} style={{backgroundImage: "url("+tile.img+")"}}>
                        </div>
                    </div>
                
                ))}
            </div>
        </div>
    );
}

export default AddonsMain;