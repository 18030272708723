import React, { useEffect, useState } from "react";
import {getSignups} from "../../../services/metas";
import SignupsTable from "./signupsTable";


function Signups({setTitle, showToast}) {
    const [loading, setLoading] = useState(false);
    const [signups, setSignups] = useState(null);

  useEffect(() => {
    setTitle("Signup Count")
    getSignup();
  }, []);
  
  const getSignup = async () => {
    try {
      setLoading(true)
      const {data} = await getSignups();
      setSignups(data);
    } catch (error) {
      showToast(error.message || JSON.stringify(error), "error");
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <div style={{height: '94%'}} >
        <div className="p-mt-3 topsheet-table">
            <SignupsTable loading={loading} signups={signups}></SignupsTable>
        </div>
      </div>
    </>
  
    );
}

export default Signups;
