import React from 'react';
import utils from "../../utils/utils";

export default class CellRenderer extends React.Component {
    currencyFormat(num) {
        return utils.isNumeric(num) ? '£' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : num;
     }
    render() {
        return (
            <div>
                {
                    this.props.value === '-' || this.props.value === '' ? <span >{this.props.value}</span> :
                    this.props.value < 0 ? <span className="failed">{this.currencyFormat(this.props.value)}</span> :
                     <span>{this.currencyFormat(this.props.value)}</span>
                }
            </div>
        );
    }
}