import React, { useState, useRef } from "react";
import { useHistory } from "react-router";
import "primeflex/primeflex.css";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from "primereact/inputtext";
import utils from "../../../utils/utils";
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';

function PaygateTable({paygatePayments, loading, dateFormat}) {
    const [globalFilter, setGlobalFilter] = useState(null);
    const [statusFilter, setStatusFilter] = useState(null);
    const [dateFilter, setDateFilter] = useState(null);
    const [periodPaygates, setPeriodPaygates] = useState([]);
    const history = useHistory();
    const statuses = {
        SUCCEEDED: "success",
        FAILED: "danger",
        "NOT CREATED": "warning",
        CREATED: "info",
    };

    const statusesDropDown = ["SUCCEEDED", "FAILED", "NOT CREATED", "CREATED"];
    const dt = useRef(null);

    const status = (rowData) => {
        const classStatus = `p-tag p-tag-rounded text-xxs p-tag-${
          statuses[rowData.Status] || "warning"
        }`;
        return (<span className={classStatus}>{rowData.Status}</span>);
    };

    const datesDropDown = [...new Set(paygatePayments ? paygatePayments.map(pg => dateFormat(pg.CreateDate, true)) : [])].sort();

    const balance = (balance) => {
        return balance ? utils.currencyFormat(balance / 100) : "-";
    };

    const amount = (rowData) => {
        return balance(rowData.Funds);
    };

    const name = (rowData) => {
        return <div className="p-text-truncate">{rowData.Name}</div>;
    };

    const onStatusChange = (value) => {
        dt.current.filter(value, 'Status', 'equals');
        setStatusFilter(value);
    }

    const onDateChange = (value) => {
        dt.current.filter(value, 'CreateDate', 'endsWith');
        setDateFilter(value)
        const periodP = paygatePayments.filter(pg=> 
            `${pg.CreateDate}`.endsWith(value)
          );
        setPeriodPaygates(periodP)
    }

    const filterAndCountByStatus = (perPaygates, status) => {
        return perPaygates.filter(pg => pg.Status === status).length
    }

    const exportCSV = () => {
        dt.current.exportCSV();
    }

    const viewUser = (e) => {
        const id = e.value.UserId;
        if(id) {
            history.push("/adminPortal/user?id=" + id);
        }
    }

    const downloadCSV = () => {
        utils.downloadCSVFromJson(paygatePayments, "paygate-list");
    };


    const header = (
        <div className="table-header">
            <div className="p-grid p-m-0">
                <div className="p-col p-p-0">
                    <span className="p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Global Search" />
                    </span>
                </div>
                <div className="p-col-fixed p-p-0">
                    <div className="p-grid p-m-0">
                        {/* <div className="center-el">
                            <span>Export Filtered:</span>
                        </div>
                        <div className="p-pr-4">
                            <Button label="CSV" disabled={!statusFilter && !dateFilter} className="p-button-raised p-button-info p-button-sm" icon="pi pi-download" onClick={exportCSV} />
                        </div> */}

                        {/* { */}
                          <div className="center-el p-pr-4">
                              <span>Period({`${dateFilter || "-"}`}): <b>{periodPaygates.length}</b></span>

                          </div>
                          <div className="center-el p-pr-4">
                          {statusesDropDown.map(
                            status => (
                                <span className={`p-tag p-mr-1 p-tag-rounded text-xxs p-tag-${statuses[status] || "warning" }`}> 
                                {`${status}: `}<b>{filterAndCountByStatus(periodPaygates, status)}</b></span>))}

                          
                      </div>

                        <div className="center-el p-pr-4">
                            <span>Total: <b>{paygatePayments.length}</b></span>
                        </div>
                        <div className="center-el p-pr-1">
                            <span>Export: </span>
                        </div>
                        <div>
                            <Button label="CSV" className="p-button-raised p-button-info p-button-sm" icon="pi pi-download" onClick={downloadCSV} />
                        </div>
                    </div>
                    
                </div>
            </div>
        </div>
    );

    const statusItemTemplate = (option) => {
        const classStatus = `p-tag p-tag-rounded text-xxs p-tag-${
            statuses[option] || "info"
          }`;
          return (<span className={classStatus}>{option}</span>);
    }

    const statusFilterTemp = (
        <Dropdown 
            value={statusFilter}
            options={statusesDropDown}
            onChange={(e) => (onStatusChange(e.value))}
            itemTemplate={statusItemTemplate}
            placeholder="Select a Status"
            className="p-column-filter"
            showClear />);
             
    const dateFilterTemp = (
        <Dropdown 
            value={dateFilter}
            options={datesDropDown}
            onChange={(e) => (onDateChange(e.value))}
            placeholder="Select a Status"
            className="p-column-filter"
            showClear />);

    // 
    return (
    <React.Fragment>
        <div className="p-mt-3">
            <div className="card blue-table">
                <DataTable
                    onSelectionChange={viewUser}
                    selectionMode="single"
                    ref={dt}
                    className="table-rounded paygate-table"
                    paginator
                    value={paygatePayments}
                    globalFilter={globalFilter}
                    loading={loading}
                    header={header}
                    emptyMessage="No paygates found"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={20} rowsPerPageOptions={[20,50,100]}
                >
                    <Column sortable field="Name" header="Name" body={name}></Column>
                    <Column sortable field="Email" style={{width:'15%'}} header="Email" ></Column>
                    <Column field="PaymentID" header="PaymentID" ></Column>
                    <Column field="AuthorId" header="MangopayId" ></Column>
                    <Column field="Funds" style={{width:'60px'}} header="Funds" body={amount}></Column>
                    <Column field="WalletId" header="WalletId" ></Column>
                    <Column sortable field="CreateDate" header="Create Date" filter filterElement={dateFilterTemp}></Column>
                    <Column sortable field="Status" filter filterElement={statusFilterTemp} header="Status" body={status} ></Column>
                </DataTable>
            </div>
        </div>
    </React.Fragment>
    );      
}

export default PaygateTable;
