import { Button } from "primereact/button";

function ColumnHeader(props) {
  const tooltip = props?.column?.userProvidedColDef?.tooltip;
  const sortable = props?.column?.userProvidedColDef?.sortable;
  return (
    <div style={{width:"100%"}} onClick={() => {
        if(sortable){
            props.progressSort();
        }
        
    }}>
      {props.displayName}
      {!!tooltip && (
        <Button
          tooltip={tooltip}
          style={{ padding: 0,  backgroundColor: "transparent", border:0,  color: "initial" }}
        >
          <i className="pi pi-info-circle" style={{fontSize:"12px", verticalAlign:'middle'}}></i>
        </Button>
      )}
    </div>
  );
}

export default ColumnHeader;
