import React, { useEffect, useState } from "react";
import { getPlatformSettings, updatePlatformSettings} from "../../services/platformSettings";
import { InputText } from 'primereact/inputtext';
import moment from "moment";
import { Toolbar } from 'primereact/toolbar';
import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { Dialog } from 'primereact/dialog';
import {Checkbox} from 'primereact/checkbox';
import AddLookUpMeta from "./addLookUpMeta";
import MetaTable from "./metaTable";
import { Calendar } from 'primereact/calendar';
import { getMetas } from "../../services/metas";

function Metas({setTitle, showToast}) {
    const [metaNames, setMetaNames] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showMetaTypeEditor, setShowMetaTypeEditor] = useState(false);
    const [metas, setMetas] = useState([]);
    const [metaFilter, setMetaFilter] = useState({
        name: "",
        from: null,
        to: null,
        groupedByUserId: true
    });
    const groups = ["draw day", "onboarding", "common"];
    const plarformSettingType = "metaNames";

    useEffect(() => {
        setTitle("Meta Management");
        getMetaTypes()
    }, []);


    async function getMetaTypes() {
        setLoading(true);
        try {
            const {data} = await getPlatformSettings(plarformSettingType);
            setMetaNames(data.metaNames)
            
        } catch(error) {
            showToast(error.message || JSON.stringify(error), "error");
        }
        setLoading(false);
    }

    async function applyFilter() {
        setLoading(true);
        try {
            const {data} = await getMetas({
                ...metaFilter,
                name: metaFilter.name.metaName,
                from: metaFilter.from ? moment(metaFilter.from).valueOf() : "",
                to: metaFilter.to ? moment(metaFilter.to).valueOf() : "",
            });
            setMetas(data.metas)
            
        } catch(error) {
            showToast(error.message || JSON.stringify(error), "error");
        }
        setLoading(false);
    }

    const saveMetaName = async (metaName, group) => {
        setLoading(true);
        try {
            const addMetaValue = {metaName: metaName, group: group};
            const {data: {contentUpdated}} = await updatePlatformSettings({
                type: plarformSettingType,
                add: addMetaValue,
                contentType: plarformSettingType
            });

            if(contentUpdated) {
                setMetaNames([...metaNames, addMetaValue]);
                showToast(`Meta added: ${metaName}`, "success");
            }
            setShowMetaTypeEditor(false)
            
        } catch(error) {
            showToast(error.message || JSON.stringify(error), "error");
        }
        setLoading(false);
    }

    const selectedMetaTypeTemplate = (option, props) => {
        if (option) {
            return (
                <div>
                    <div>{option.metaName}</div>
                </div>
            );
        }

        return (
            <span>
                {props.placeholder}
            </span>
        );
    }

    const metaNameOptionTemplate = (option) => {
        const tagClass = option.group === "draw day" ? "info" : option.group === "onboarding" ? "success" : "warning";
        return (
            <div>
                <div>{option.metaName} <span className={`p-tag p-tag-rounded group-tag p-tag-${tagClass}`}>{option.group}</span></div>
            </div>
        );
    }

    const leftContents = (
        <>
        <div className="p-formgroup-inline">
            <div className="p-field p-mb-0">
                <label htmlFor="metaName">Meta</label>
                <Dropdown
                    className="b-radius-10 width-10em"
                    value={metaFilter.name}
                    options={metaNames}
                    onChange={(e) => setMetaFilter({...metaFilter, name: e.value})}
                    optionLabel="metaName"
                    filter
                    filterBy="metaName"
                    placeholder="Select a meta type"
                    valueTemplate={selectedMetaTypeTemplate}
                    itemTemplate={metaNameOptionTemplate} />
            </div>
            <div className="p-field p-mb-0">
                <label htmlFor="fromCalendar">From</label>
                <Calendar
                    id="fromCalendar"
                    className="b-radius-10-calendar"
                    dateFormat="dd/mm/yy"
                    value={metaFilter.from}
                    onChange={(e) => setMetaFilter({...metaFilter, from: e.value})}
                    showButtonBar />
            </div>
            <div className="p-field p-mb-0">
                <label htmlFor="toCalendar">To</label>
                <Calendar
                    id="toCalendar"
                    className="b-radius-10-calendar"
                    dateFormat="dd/mm/yy"
                    value={metaFilter.to}
                    onChange={(e) => setMetaFilter({...metaFilter, to: e.value})}
                    showButtonBar />
            </div>
            <div className="p-field p-mb-0 p-pt-2">
                <label htmlFor="toCalendar">Distinct UserId</label>
                <Checkbox
                    onChange={e => setMetaFilter({...metaFilter, groupedByUserId: e.checked})}
                    checked={metaFilter.groupedByUserId}></Checkbox>
            </div>
            
            <Button
                disabled={!metaFilter.name}
                className="p-button p-button-sm p-button-info p-button-raised"
                type="button"
                onClick={applyFilter}
                label="Apply"/>
        </div>
        </>
    );
    
    const rightContents = (
        <>
            <Button
                label="Add Meta Type"
                icon="pi pi-plus"
                onClick={() => setShowMetaTypeEditor(true)}
                className="p-mr-2 p-button-outlined p-button-sm" />
        </>
    );

    return (
        <>
            <Dialog
                header="Add new meta type" visible={showMetaTypeEditor}
                style={{ width: '40vw' }}
                contentStyle={{ minHeight: "200px" }}
                onHide={() => setShowMetaTypeEditor(false)}>
                    <AddLookUpMeta
                        groups={groups}
                        saveMetaName={saveMetaName}
                        metaNames={metaNames}
                    />
            </Dialog>
            <Toolbar
                className="b-radius-10 p-card no-border p-mt-2 p-text-bold p-shadow-4 bg-white"
                left={leftContents}
                right={rightContents} />
            <MetaTable
                metas={metas}
                loading={loading}
                metaName={metaFilter?.name?.metaName}
                />    
         </>
    );

}

export default Metas;
