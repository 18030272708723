import React, { useState, useRef, useEffect } from "react";
import "primeflex/primeflex.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";

import utils from "../../../../utils/utils";
import ChangeArrearsRow from "./columnComponents/ChangeRow";
import moment from "moment";
import { currencyFormat } from "../../../../../users/utils/generic";
import { Dropdown } from "primereact/dropdown";
import { RadioButton } from 'primereact/radiobutton';

function Arrears({ arrears, loading, showActive, getArrears }) {
  const [globalFilter, setGlobalFilter] = useState(null);
  const [filteredArrears, setFilteredArrears] = useState(arrears);
  const [filterSelection, setFilterSelection] = useState("All");

  const [showArrearPopup, setShowArrearPopup] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});

  useEffect(() => {
    if (!arrears) {
      getArrears();
    }
    setFilteredArrears(arrears);
  }, [showActive, arrears]);

  const downloadCSV = () => {
    utils.downloadCSVFromJson(arrears.map(({arrearsInfo, ...restArr})=> ({...restArr, ...arrearsInfo})), "arrears");
  };

  const dt = useRef(null);

  const balance = (money) => {
    return money ? utils.currencyFormat(money) : "-";
  };

  const moneyMissing = (rowData) => {
    return balance(rowData.moneyMissing);
  };

  const getNODTotals = () => {
    if (!filteredArrears) {
      return "0";
    }

    const NODs = filteredArrears.filter(
      (fa) => fa?.arrearsInfo?.NOD && fa?.arrearsInfo?.NOD === "Yes"
    );

    const amount = NODs.reduce((accumulator, current) => {
      return (accumulator += current.moneyMissing);
    }, 0);

    return currencyFormat(amount) + " (" + NODs.length + " members)";
  };

  const getArrearsTotal = () => {
    if (!filteredArrears) {
      return "0";
    }

    const amount = filteredArrears.reduce((accumulator, current) => {
      return (accumulator += current.moneyMissing);
    }, 0);

    return currencyFormat(amount) + " (" + filteredArrears.length + " members)";
  };

  const filterOpts = [
    {
      name: "All",
      filter: () => {return true}
    },{
      name: "Archived",
      filter: (row) => {
        return row?.arrearsInfo?.exclude;
      }
    },
    {
      name:"Active",
      filter: (row) => {
        return !row?.arrearsInfo?.exclude;
      }
    }
  ];

  const changeFilter = (val) => {
    const filterVal = filterOpts.find(fo => fo.name === val);
    setFilteredArrears(arrears.filter(filterVal.filter));
    setFilterSelection(val);
  }

  const categories = [{name: 'all', key: 'all'}, {name: 'active', key: 'onlyActive'}, {name: 'finished', key: 'finished'}];
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  const header = (
    <div>
      <div style={{borderBottom:"1px solid #bebebe", marginBottom:"10px", paddingBottom:"10px"}}>
      <div className="p-inputgroup  p-align-stretch height-100 p-col">
          {
              filterOpts.map((category) => {
                  return (
                      <div key={filterOpts.name} className="p-field-radiobutton p-mb-0 p-mr-3">
                          <RadioButton
                            inputId={filterOpts.name}
                            name="name"
                            value={category.name}
                            onChange={(e) => {
                              console.log(e.value)
                              changeFilter(e.value)
                            }}
                            checked={filterSelection === category.name} />
                          <label htmlFor={filterOpts.name}>{category.name}</label>
                      </div>
                  )
              })
          }
          </div>
    </div>
    <div style={{ display: "flex" }}>
      <div style={{ flexGrow: 1, display: "flex" }}>
        <div>
          Arrears: <b>{getArrearsTotal()}</b>
        </div>
        <div style={{ marginLeft: "20px" }}>
          NODs: <b>{getNODTotals()}</b>
        </div>
      </div>
      <div>
        <Button
          label="CSV"
          className="p-button-raised p-button-info p-button-sm"
          icon="pi pi-download"
          onClick={downloadCSV}
        />
      </div>
    </div>
    
    </div>
  );

  const circleNum = (rowData) => {
    return (
      <span className={"p-badge p-badge-secondary badge-border"}>
        {rowData.excelID}
      </span>
    );
  };

  const manageArrear = (rowData) => {
    setSelectedRow(rowData.data);
    setShowArrearPopup(true);
  };

  return (
    <React.Fragment>
      {showArrearPopup && (
        <ChangeArrearsRow
          rowData={selectedRow}
          onHide={() => setShowArrearPopup(false)}
        />
      )}

      <div>
        <div className="card blue-table">
          <DataTable
            selectionMode="single"
            ref={dt}
            className="table-rounded arrearsTable"
            paginator
            onRowClick={manageArrear}
            value={filteredArrears}
            globalFilter={globalFilter}
            loading={loading}
            header={header}
            emptyMessage="No arrears found"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
            rows={20}
            rowsPerPageOptions={[20, 50, 100]}
          >
            <Column
              field="name"
              header="Name"
              filter
              filterPlaceholder="Search"
            ></Column>
            <Column
              sortable
              field="excelID"
              header="Circle"
              body={circleNum}
              filter
              filterPlaceholder="Search"
            ></Column>
            <Column
              field="moneyMissing"
              header="Arrears"
              body={moneyMissing}
              sortable
            ></Column>
            <Column
              sortable
              header="Pre-NOSIA?"
              filter
              filterPlaceholder="Search"
              field="preNOSIA"
            ></Column>
            <Column
              field={"arrearsInfo.NOSIA"}
              header="NOSIA?"
              sortable
              filterPlaceholder="Search"
              filter
            ></Column>
            <Column
              sortable
              field={"arrearsInfo.dateIssued"}
              body={(rowData) => {
                const dateString = rowData?.arrearsInfo?.dateIssued;
                return dateString
                  ? moment.utc(dateString).format("Do MMM")
                  : null;
              }}
              header="NOSIA Issued"
            ></Column>
            <Column sortable field={"arrearsInfo.NOD"} filter filterPlaceholder="Search" header="NOD?"></Column>
            <Column
              sortable
              field={"arrearsInfo.NODDateIssued"}
              body={(rowData) => {
                const dateString = rowData?.arrearsInfo?.NODDateIssued;
                return dateString
                  ? moment.utc(dateString).format("Do MMM")
                  : null;
              }}
              header="NOD Issued"
            ></Column>
            <Column
              sortable
              field={"arrearsInfo.addedToInsights"}
              header="Added to Insights"
              filter
              filterPlaceholder="Search"
            ></Column>
            <Column
              field={"arrearsInfo.latestUpdates"}
              header="Latest Update"
              filter
              filterPlaceholder="Search"
            ></Column>
          </DataTable>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Arrears;
