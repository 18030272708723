import userAPI from "./userAPI";

async function createMangoPayAccount(){
    return userAPI.call("/users/createUserInMangoPay", {
        method: "POST",
    });
}

async function submitBankDetails(bankDetailsObject){
    return userAPI.call("/users/bankDetails", {
        method: "POST",
        body: bankDetailsObject,
    });
}

async function submitBankDetailsWithUserCheck(user, bankDetailsObject){
    let userID = user.userID;

    if(!user.userID){
        const { status: accountStatus, data: response } = await createMangoPayAccount();

        if (accountStatus !== 200 || !response.naturalUserCreatedStatus) {
            return {error: true, reason: "failed_user_creation"};
        }

        userID = response.mangoUserID;
    }


    const {status: bankDetailsStatus, data: bankDetailsResponse } = await submitBankDetails(bankDetailsObject);

    if (bankDetailsStatus !== 200 || !bankDetailsResponse.bankDetailsSubmitted) {
        return {error: true, reason: "failed_bank_details", userID: userID};
    }

    return {error: false, userID: userID, url: bankDetailsResponse.mangopay_url};
}

export {submitBankDetailsWithUserCheck};