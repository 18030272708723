import { useState } from "react";
import { isMobile } from "../../../../utils/generic";
import OnboardingContainer from "../../onboardingContainer";
import InsuranceBuyHome from "./pages/BuyHome";
import InsuranceFrontPage from "./pages/FrontPage";
import InsuranceOutcome from "./pages/Outcome";
import OwnValuableItems from "./pages/OwnValuableItems";
import RelyIncomeInsurance from "./pages/RelyIncome";

function InsuranceQuiz() {
  const [pageIndex, setPageIndex] = useState(0);
  const [answers, setAnswers] = useState({});

  const pages = [
    InsuranceFrontPage,
    RelyIncomeInsurance,
    InsuranceBuyHome,
    OwnValuableItems,
    InsuranceOutcome
  ];

  const Component = pages[pageIndex];

  return (
    <OnboardingContainer>
      <div style={{ width: isMobile ? "100%" : "500px" }}>
        <h2 style={{ marginBottom: "30px", fontWeight: "normal" }}>
          Protect the people you <b>love</b> and protect the <b>things</b> you
          love.
        </h2>
        <Component
          onChange={(newAnswers) => {
            setAnswers({ ...answers, ...newAnswers });
          }}
          answers={answers}
          onNext={() => setPageIndex(pageIndex + 1)}
        />
      </div>
    </OnboardingContainer>
  );
}

export default InsuranceQuiz;
