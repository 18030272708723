import React, { useState, useEffect } from "react";
import { useUser } from "../../../contexts/UserContext";
import {useHistory} from "react-router";
import IncreaseSavings from "./increaseSavings";
import CardDetails from "./cardDetails";
import SpinningWheel from "./spinningWheel";
import BookCallHC from "./bookCallHC";

function DrawDay() {
    const userContext = useUser();
    const history = useHistory();
    const [showDrawDay] = useState(userContext.user.drawDayReady);
    const [itsDrawDay, setItsDrawDay] = useState(true);
    const [notSubmittedCardDetails, setNotSubmittedCardDetails] = useState(true);
    const drawnSpots = userContext.drawnSpots();
    const hasWon = drawnSpots.length > 0;
    const onlyFirstStepWinner = !drawnSpots.length ? false : drawnSpots.every(ds => ds.awarded <= 1000)

    useEffect(() => {
        if(!showDrawDay) {
            history.push("/dashboard");
        }
    }, [history, showDrawDay]);


    function seenResult() {
        setItsDrawDay(false);
    }

    if(itsDrawDay){
        return <SpinningWheel hasWon={hasWon} seenResult={seenResult} />
    }

    if(hasWon && notSubmittedCardDetails){
        if(onlyFirstStepWinner) {
            return <CardDetails submittedDetails={() => setNotSubmittedCardDetails(false)} />
        } else {
            return <BookCallHC submittedDetails={() => setNotSubmittedCardDetails(false)} />
        }
    }

    return (
        <IncreaseSavings hasWon={hasWon} onlyFirstStepWinner={onlyFirstStepWinner} />
  );
}

export default DrawDay;
