import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import React, { useState, useEffect } from "react";
import Papa from "papaparse";

import LoadingSpinner from "../../components/loadingSpinner";
import LifecycleCalculations from "./LifecycleCalculations";
import moment from "moment-timezone";
import KeySelection from "./KeySelection";
import API from "../../services/api";

function AdminLifecycleTrackerMain({setTitle, setSubTitle}) {
  const [dates, setDates] = useState([moment.utc().startOf("month").toDate(), moment.utc().endOf("month").toDate()]);
 
  const [isLoading, setIsLoading] = useState(false);
  const [csvData, setCSVData] = useState([]);
  const [lifecycleData, setLifecycleData] = useState([]);
  const [keySelectionVisible, setKeySelectionVisible] = useState(false);

  useEffect(() => {
    setTitle("Lifecycle Tracker");
    setSubTitle("Upload a document of emails, and select the dates you are interested in tracking lifecycle changes.")
  }, []);

  const uploadFile = (event) => {
    const csvfile = event.target.files[0];
    const filename = csvfile.name;

    if (filename.indexOf(".csv") === -1) {
      return alert("Please upload a valid csv file");
    }

    Papa.parse(csvfile, {
      complete: async (json_data) => {
        const data = json_data.data;

        setCSVData(data);
        setKeySelectionVisible(true);
      },
      header: true,
    });
  };

  const getLifecycleData = async (key) => {
    setKeySelectionVisible(false);
    setIsLoading(true);
    const data = await API.call({
      url: "/users/getLifecycleChanges",
      method: "POST",
      body: {emails: csvData.map((itm) => itm[key])},
    });

    if (data.error) {
      return alert(
        "There was an error with checking lifecycle stages: " + data.error
      );
    }

    console.log(data);

    setLifecycleData(data);
    setIsLoading(false);
  };

  return (
    <React.Fragment>
      {isLoading && <LoadingSpinner></LoadingSpinner>}
      <KeySelection onCancel={() => setKeySelectionVisible(false)} keys={csvData.length === 0 ? [] : Object.entries(csvData[0]).map((itm) => itm[0])} onKeySelected={(key) => getLifecycleData(key)} visible={keySelectionVisible}></KeySelection>
      {!isLoading && (
        <React.Fragment>
          <div style={{ marginTop: "50px", marginBottom: "50px" }}>
            <input
              type="file"
              style={{
                height: "100%",
                top: 0,
                left: 0,
                opacity: 0,
                position: "absolute",
                zIndex: 5,
                cursor: "pointer",
              }}
              onChange={(e) => uploadFile(e)}
              onClick={(event) => {
                event.target.value = null;
              }}
            ></input>
            <Button label="Upload CSV"></Button>
            
          </div>

          <div style={{ marginTop: "25px" }}>
            What dates are you looking for?
          </div>
          <Calendar
            value={dates}
            onChange={(e) => setDates(e.value)}
            selectionMode="range"
            readOnlyInput
            dateFormat="dd/mm/yy" 
          />
          <hr style={{marginBottom:"50px", marginTop:"50px"}}></hr>
          <LifecycleCalculations data={lifecycleData} dateRange={dates}></LifecycleCalculations>
          
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default AdminLifecycleTrackerMain;
