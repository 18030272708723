import moment from "moment";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Button } from 'primereact/button';
import UtilsService from "../../../../utils/utils";
const dropsColumns = [ 
    "_id",
    "firstname",
    "lastname",
    "email",
    "excellID",
    "type",
    "ambassador",
    "reserve_period",
    "monthly_investment",
    "period",
    "dropoutReason",
    "notes",
    "dropout_date",
    "endingDate",
    "drawn_date",
    "lastPlatformVisit",
    "startingDate",
    "join_date",
    "opportunityDate",
    "paygate",
    "first_batch",
    "second_batch",
    "pastSuccessfulPayment",
    "anyPayment",
    "mangoId",
    "walletID",
    "balance",
    "FMC",
    "credit_builder",
    "equifax",
    "IVA_CCJ",
    "AML",
    "creditCheck",
    "creditCheck2",
    "referral_code",
    "reason_for_saving",
    "household_income",
    "debt",
    "education",
    "englishFirstLanguage",
    "PostCode",
    "dob",
    "ghost",
    "availability",
    "hidden_failed_payments"]

function DropoutsSummary({month, actualDropouts, potentials, isLoading}){
    const keys = {
        total: "total",
        dropouts: "dropouts",
        potentials: "potentials",
        twoMissed: "twoMissed",
        ghost: "ghost",
    }
    const data = [
        {
            title: "Total (Potential + Dropped Out So Far)",
            value: getTotals(actualDropouts, potentials),
            key: keys.total
        },
        {
            title: "Dropouts to Date",
            value: actualDropouts.length,
            key: keys.dropouts
        },
        {
            title: "Potential Remaining",
            value: potentials.length,
            key: keys.potentials
        },
        {
            title: "Potential 2x Missed Payments",
            value: UtilsService.count(getTwoMissedPayments(potentials)),
            key: keys.twoMissed
        },
        {
            title: "Potential Ghosts",
            value: UtilsService.count(getGhost(potentials)),
            key: keys.ghost
        }
        
    ];

    const downloadData = (data, name, columns) => {
        const finaliseData = data.map(d => {
            return {...d.user, ...d};
        });
    
      UtilsService.downloadCSVFromJson(finaliseData, name, columns)
    }

    const downloadGhosts = () => {
        downloadData(getGhost(potentials), "Ghosts");
    }

    const downloadMissedTwo = () => {
        downloadData(getTwoMissedPayments(potentials), "missed");
    }

    const downloadTotalDropouts = () => {
        downloadData(actualDropouts, "dropouts", dropsColumns);
    }

    const downloadPotentials = () => {
        downloadData(potentials, "potentials");
    }

    const getActionButton = (tooltip, actionBtn, amount) => {
        return <Button
            label="Download"
            disabled={!amount}
            tooltip={tooltip}
            tooltipOptions={{position: 'left'}}
            className="p-button-outlined"
            onClick={actionBtn}
        />;
    }

   function actionBodyTemplate(rowData) {
        switch (rowData.key) {
            case keys.ghost:
                return getActionButton("Download potential ghost", downloadGhosts, rowData.value)
            case keys.twoMissed:
                return getActionButton("Download Potential 2x Missed Payments", downloadMissedTwo, rowData.value)
            case keys.dropouts:
                return getActionButton("Download dropouts", downloadTotalDropouts, rowData.value)
            case keys.potentials:
                return getActionButton("Download potentials remaining", downloadPotentials, rowData.value)
            default:
                return "";
        }
    }

    return <div>
        
        
        <DataTable value={data} className="dropoutsTable" loading={isLoading}>
            <Column field="title" header="Category" />
            <Column field="value" header="Total" />
            <Column field="value" header="Action" body={actionBodyTemplate} />
        </DataTable>
    </div>
}

function getTotals(actualDropouts, potentials){
    return actualDropouts.length + potentials.length;
}

function getGhost(potentials){
    return potentials.filter(p => p.potentialDropoutReason === "first_month");
}

function getTwoMissedPayments(potentials){
    return potentials.filter(p => p.potentialDropoutReason === "two_missed_payments");
}
export default DropoutsSummary;