function currencyFormat(inputNum) {
    let num = numberWithCommas(parseFloat(inputNum).toFixed(2));
    if(num.slice(-2) === "00") {
      num = num.slice(0, -3);
    }
  
    return "£" + num;
  }
 
 function numberWithCommas(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
 }
 

 function requiredValue(value) {
    return (typeof value === 'string' && value.trim() !== '') || (typeof value === 'number' && !isNaN(value));
 }
 
 
 export { currencyFormat, requiredValue };