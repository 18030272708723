import React from "react";

function AmbassadorWarning({ circle }) {
  if (circle.ambassador) {
    const ambassadorName = circle.ambassador.ambassadorName ||  "an Ambassador";

    const AmbassadorImage = ({ambassadorImage}) => {
      return ambassadorImage ? 
      <img className="width-4em p-p-0 b-radius-10 p-col-fixed" src={ambassadorImage} alt="ambassador"/> :
      <i className="fa fa-user-o fa-2x center-el p-col-fixed"></i>
    }

    return (
      <div className="ambassadorWarning p-grid p-mr-0 p-ml-0 p-shadow-1">
        <AmbassadorImage ambassadorImage={circle.ambassador.circleImage} />
        <div className="p-col center-el font-lg">
          This is the {ambassadorName} Circle.
        </div>
      </div>
    );
  }

  return <React.Fragment></React.Fragment>;
}

export default AmbassadorWarning;
