import { useEffect, useState } from "react";
import Form from "../../../../generic/forms/Form";
import GoBack from "./common/goBack";
import CertifiedReasons from "./common/certifiedReasons";

const title = "Certified Sophisticated Investor";
const trackBackId = "BackCertified";

const QuickFacts = () => {
  return (
    <ul>
      <li>
        <span>
          I have been certified as a sophisticated investor by a suitably
          qualified and regulated individual within the last 36 months and
          holds a signed written certificate to state this.
        </span>
      </li>
    </ul>
  );
}

const Acceptance = () => {
  return (
    <>
      <p className="informationLabel">
      I accept that being a sophisticated investor will expose me to promotions for investment where there is a significant risk of losing all the money I invest. I am aware that it is open to me seek professional advice before making any investment in a high-risk investment.
      </p>
    </>
  );
}

const Details = ({back, isDisableNext, setFormlist}) => {
  const reasons = [{
    selected: false,
    type: "monthYearCertified",
    text: `Do you have a written certificate of signed within the last 36 months by an authorised firm engaging in Peer-to-Peer investment activity, 
    clearly stating that you understand the risks associated with this type of investment?`,
    question: "What is the month and year when your certification was issued?",
    answer: ""

  }];
  return (
    <>
      <h3 className="investorTitle">
          Your reason for selecting <br/> {title}
      </h3>
      
      <div>
        {/* <Form
          inputs={[ { key: "yes", value: "" }]}
          hideNextButton={true}
          formList={(formList) => setConfirm(formList[0]?.value === "Yes")}
          onSubmit={(values) => setConfirm(values[0])}
        /> */}

    <CertifiedReasons
        data={reasons}
        setFormlist={setFormlist}
        isValid={isDisableNext} />
      </div>
      <GoBack
        back={back}
        trackBackId={trackBackId} />
    </>
  );
}

const certifiedSophisticatedInvestor = {
  title,
  type: title,
  QuickFacts,
  Acceptance,
  Details
};

export default certifiedSophisticatedInvestor;
