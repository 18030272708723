import CircleInviteMain from "../components/onboarding/authenticated/circleInvite/circleInviteMain";
import InvestorClassificationMain from "../components/onboarding/authenticated/investorClassification/InvestorClassificationMain";
import PaymentsMain from "../components/onboarding/authenticated/payments/paymentsMain";
import PersonalDetails from "../components/onboarding/authenticated/personalDetails";
import PersonalExperience from "../components/onboarding/authenticated/personalExperience";
import PersonalFinance from "../components/onboarding/authenticated/personalFinance";
import DecideNextAction from "../components/onboarding/unauthenticated/decideNextAction";
import ErrorPage from "../components/onboarding/unauthenticated/errorPage";
import GetStarted from "../components/onboarding/unauthenticated/getStarted";
import Logout from "../components/onboarding/unauthenticated/logout";
import MagicToken from "../components/onboarding/unauthenticated/magicToken";
import WelcomeBack from "../components/onboarding/unauthenticated/welcomeBack";
import DashboardMain from "../components/platform/DashboardMain";
import ApplicationCompleteBigAmount from "../components/onboarding/authenticated/applicationCompleteBigAmount";
import ApplicationCompletePartners from "../components/onboarding/authenticated/applicationCompletePartners";
import HelperInfo from "../components/onboarding/authenticated/helpers/helperInfo";
import MangoPayReturn from "../components/onboarding/authenticated/payments/mangoPayReturn";
import JointApplication from "../components/onboarding/authenticated/jointApplication";

import { isAmbassador } from "../services/ambassadors";
import SideDoor from "../components/platform/articles/custom/sideDoor/sideDoor";
import ThreeQuestionQuizMain from "../components/onboarding/authenticated/quiz/threeQuestionQuiz/main";
import TermsMain from "../components/onboarding/authenticated/terms/termsMain";
import CoolOffMain from "../components/onboarding/authenticated/collOff/main";

//The order of these routes is important, see getNextRoutes below for why
const userRoutes = [
    {
        path:"/dashboard/ambassadors",
        authenticated: true,
        criteria: function(user){
            return isAmbassador(user);
        },
        component: DashboardMain
    },
    {
        path: "/personal",
        authenticated: true,
        criteria: function(user){
            return user.stage === "Affordability" && !user.dob;
        },
        component: PersonalDetails
    },
    {
        path: "/finance",
        authenticated: true,
        criteria: function(user){
            return user.stage === "Affordability" && !user.applicant_salary;
        },
        component: PersonalFinance
    },
    {
        path: "/experience",
        authenticated: true,
        criteria: function(user){
            return user.stage === "Affordability" && (!user.education_type || !user.joint_application);
        },
        component: PersonalExperience
    },
    {
        path: "/jointApplication",
        authenticated: true,
        criteria: function(user){
            return user.stage === "Affordability" && user.joint_application && !user.applicant_salary_applicant_2;
        },
        component: JointApplication
    },
    {
        path: "/circleInvite",
        authenticated: true,
        criteria: function(user, routerIsChecking){
            if(!routerIsChecking){
                return user.stage === "CircleInvite";
            } else {
                return ["Affordability", "CoolOff", "Quiz", "InvestorClassification"].indexOf(user.stage) === -1;
            }
            
        },
        component: CircleInviteMain
    },
    {
        path: "/quiz",
        authenticated: true,
        criteria: function(user){
            return user.stage === "Quiz";
        },
        component: ThreeQuestionQuizMain
    },
    {
        path: "/cooloff",
        authenticated: true,
        criteria: function(user){
            return user?.stage === "CoolOff";
        },
        component: CoolOffMain
    },
    {
        path: "/investorClassification",
        authenticated: true,
        criteria: function(user){
            return user.stage === "InvestorClassification";
        },
        component: InvestorClassificationMain
    },
    {
        path: "/terms",
        authenticated: true,
        criteria: function(user, routerIsChecking){
            if(!routerIsChecking){
                return user.stage === "Terms";
            } else {
                return ["Affordability", "CoolOff", "Quiz", "InvestorClassification"].indexOf(user.stage) === -1;
            }
        },
        component: TermsMain
    },
    {
        path: "/payments",
        authenticated: true,
        criteria: function(user){
            return user.stage === "Payments";
        },
        component: PaymentsMain
    },
    {
        path: "/mangoPayReturn",
        authenticated: true,
        criteria: function(user){
            return user.stage === "Payments"
        },
        component: MangoPayReturn
    },
    {
        path: "/dashboard",
        authenticated: true,
        criteria: function(user){
            return user.stage === "Dashboard"
        },
        component: DashboardMain
    },
    {
        path: "/ApplicationCompleteBigAmount",
        authenticated: true,
        criteria: function(user){
            return user.stage === "Dashboard";
        },
        component: ApplicationCompleteBigAmount
    },
    {
        path: "/ApplicationCompletePartners",
        authenticated: true,
        criteria: function(user){
            return user.stage === "Dashboard";
        },
        component: ApplicationCompletePartners
    },
    {
        path: "/HelperInfo",
        authenticated: true,
        criteria: function(user){
            return user.stage === "Dashboard";
        },
        component: HelperInfo
    },
    {
        path: "/error",
        component: ErrorPage,
    },
    {
        path: "/logout",
        component: Logout
    },
    {
        path: "/welcomeBack",
        authenticated: false,
        component: WelcomeBack,
    },
    {
        path: "/getStartedApply",
        authenticated: false,
        component: GetStarted, //GetStartedApply,
    },
    {
        path: "/magicToken",
        authenticated: false,
        component: MagicToken
    },
    {
        path: "/sidedoor",
        authenticated: false,
        component: SideDoor
    },
    {
        path: "/",
        component: DecideNextAction,
    },
    
    
];

const getNextRoute = function(user, routerIsChecking=false){
    if(!user){
        if(!!localStorage.getItem("user_logged_in_before")){
            return "/welcomeBack";
        } else {
            return "/getStartedApply";
        }
    }

    for(const route of userRoutes.filter((routePart) => routePart.authenticated)){
        if(route.criteria(user, routerIsChecking)){
            return route.path;
        }
    }

    return "/error";
};
   

export {userRoutes, getNextRoute};