const API = {
  call: async function (data, callback = function () {}) {
    //console.log("sending data:", JSON.parse(JSON.stringify(data)));

    if (data.body) {
      data.body = JSON.stringify(data.body);
    }

    data.headers = {
      "Content-Type": "application/json",
      // 'Content-Type': 'application/x-www-form-urlencoded',
    };

    data.credentials = "include";

    const url = data.url;
    delete data.url;

    // console.log("calling:", process.env.REACT_APP_SERVER_URL + url);
    const response = await fetch(process.env.REACT_APP_SERVER_URL + url, data)
      .then((response) => {
        if (response.status === 401) {
          if (localStorage.getItem("impersonation")) {
            localStorage.removeItem("impersonation");
            window.location = "/adminPortal";
          } else if (localStorage.getItem("adminLoggedIn")) {
            localStorage.removeItem("adminLoggedIn");
            window.location = "/adminPortal/login";
          } else {
            window.location = "/welcomeBack";
          }
        } else {
          //chengad method .json() to .text() as it could failed if body not passed correctly
          return response.text();
        }
      })
      .then((data) => {
        return data ? JSON.parse(data) : {}
      })

    return response;
  },
  call2: async function (path, params) {

    if (params) {
      params.body = JSON.stringify(params.body);
    }
    const response = await fetch(
      process.env.REACT_APP_SERVER_URL + path, 
      {
        ...params,
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    
    if (response.status === 401) {
      if (localStorage.getItem("impersonation")) {
        localStorage.removeItem("impersonation");
      } else if (localStorage.getItem("adminLoggedIn")) {
        localStorage.removeItem("adminLoggedIn");
      } 

      window.location = "/adminPortal/login";
    }

    const outcome = { status: response.status, data: await response.json() };
    if(!response.ok) {
      throw new Error(
        outcome?.data?.error?.type ||
        outcome?.data?.error?.message ||
        outcome?.data?.error ||
        outcome?.data)
    }

    return outcome;
  }
};

export default API;
