
import { Dropdown } from 'primereact/dropdown';

function CircleSelector({circle, circles, selectCircle}){
    return (
        <Dropdown
            className="b-radius-10"  
            value={circle ? circles.find((c) => circle && `${c._id}` === `${circle._id}`) : circles[0]} 
            options={circles} 
            onChange={(e) => selectCircle(e.value)} 
            optionLabel="excelID" 
            filter 
            filterBy="excelID"
            placeholder="Num" />
    )
}

export default CircleSelector;