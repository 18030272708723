
function InfoTooltip(props) {
  
  let position = {
    top:"10px",
    right:"10px"
  };

  if(props.position === "right"){
    position.left = "10px";
    delete position.right;
  }

  if(props.position && typeof props.position === "object"){
    position = props.position;
  }

  return (
    <div className={"tooltip tooltipContainer"+(props.blueTooltip ? " blueTooltip" : "")}>
      {props.children ? props.children : props.blueTooltip ? <i className="pi pi-fw pi-info"></i> : <i className="pi pi-fw pi-info-circle"></i>}
      <span className="tooltiptext"  style={position} dangerouslySetInnerHTML={{__html: props.text}}></span>
    </div>
  );
}

export default InfoTooltip;
