import moment from "moment";

function isMemberDropout(user, circle) {
  return getSpecificParticipant(user, circle).some((part) => part.dropout_date);
}

function hasMissedPayments(user, circle) {
  if(user.disableAutomaticPayments){
    return false;
  }

  const approved = getSpecificParticipant(user, circle);

  const expectedPayIns = getExpectedPayIns(circle);

  return approved.some(
    (part) => {
      const totalPayments = getTotalFromArray(!part.payments ? [] : part.payments.filter(paym => (paym.Status === "SUCCEEDED" || paym.Status === "CREATED") && paym.Type === "PAYIN"), "Funds");
      return totalPayments < expectedPayIns;
    }
    
  );
}

function getTotalFromArray(array, field) {
  return array.reduce(
    (accumulator, current) => accumulator + current[field],
    0
  );
}

function getExpectedPayIns(circle) {
  const start = moment(circle.startingDate);
  const end = moment(circle.endingDate);
  const today = moment();

  if(start.isAfter(today)){
    return 0;
  }

  if(end.isBefore(today)){
    return circle.monthly_investment * circle.period * 100;
  }

  const difference = Math.abs(today.diff(start, "months")) + 1;
  return difference * circle.monthly_investment * 100;
}

function getSpecificParticipant(user, circle) {
  return circle.participants.filter((part) => part.user._id === user._id);
}

function hasDrawn(user, circle) {
  return getSpecificParticipant(user, circle).some((part) => part.drawn_date);
}

const circleProfiles = [
  {
    text: "Your Circle",
    img: "tree.jpg",
    short: "Your Circle",
  },
  {
    text: "Raising a Deposit",
    img: "house.jpg",
    short: "Raising Deposit",
  },
  {
    text: "Building Credit Score",
    img: "credit.jpg",
    short: "Credit Building",
  },
  {
    text: "Clearing Your Debt",
    img: "debt.jpg",
    short: "Debt Clearing",
  },
  {
    text: "Buying Furniture",
    img: "furniture.jpg",
    short: "Furniture",
  },
];


const drawDayTiming = { D: 7, hour: 12, minutes: 0 };
const getNextDrawDay = () => {
  const today = moment();
  const drawDay = drawDayTiming;
  const thisMonthsDrawDay = moment().set(drawDay);

  if (thisMonthsDrawDay.isBefore(today)) {
    return today.add(1, "month").set(drawDay);
  } else {
    return thisMonthsDrawDay;
  }
};

const getCircleNextDrawDay = (circle) => {
  const today = moment();
  const firstDraw = moment(circle.startingDate).add(circle.reserve_period, "months");
  const circleEnd = moment(circle.endingDate);

  if(today.isAfter(circleEnd)){
    return null;
  }

  const nextDraw = getNextDrawDay();

  if(firstDraw.isAfter(nextDraw, "days")){
    return firstDraw.set(drawDayTiming);
  }

  return nextDraw;
}

const getCirclePeriodMonthNum = (startingDate, secondDate) => {
  const startDate = moment(startingDate);
  const diffYears = secondDate.year() - startDate.year();
  return secondDate.month() + diffYears * 12 - startDate.month() + 1;
}

const isReservedPeriod = (circle) => {
  const months = getCirclePeriodMonthNum(circle.startingDate, moment());
  // const startDate = moment(startingDate);
  // const diffYears = secondDate.year() - startDate.year();
  console.log(months, circle.reserved_period);
  return months - circle.reserved_period > 0;
}



export {
  getNextDrawDay,
  hasMissedPayments,
  isMemberDropout,
  hasDrawn,
  getSpecificParticipant,
  circleProfiles,
  getTotalFromArray,
  getCircleNextDrawDay,
  isReservedPeriod,
  getCirclePeriodMonthNum
};
