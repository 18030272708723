import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { useEffect, useState } from "react";

function CountdownTimer({ Hours, Minutes, Seconds, setIsTimerFinished }) {
  const [counter, setCounter] = useState(Hours * 3600 + Minutes * 60 + Seconds);

  useEffect(() => {
    const interval = setInterval(() => {
      setCounter((prevCounter) => prevCounter - 1);
    }, 1000);
    return () => {
      setIsTimerFinished(counter <= 1);
      clearInterval(interval);
    }
  }, [counter]);

  const getTimerHours = () => {
    return Math.trunc(counter / 3600)
  }
  const getTimerMinutes = () => {
    return Math.trunc((counter - getTimerHours() * 3600) / 60)
  }
  const getTimerSeconds = () => {
    return counter - getTimerHours() * 3600 - getTimerMinutes() * 60
  }

  return (
      <div className="flex-center-bold">
        <div className="p-ml-2 p-mr-2 min-width-60">
          <div className="p-badge p-badge-xl"
            style={{ background: "white", color: "#9C27B0", boxShadow: "0 0 0 2px #9C27B0" }}
          >{getTimerHours().toString()}</div>
          <p>Hours</p>
        </div>
        <div className="p-ml-2 p-mr-2 min-width-60">
          <div className="p-badge p-badge-xl"
            style={{ background: "white", color: "var(--stepladder-green)", boxShadow: "0 0 0 2px var(--stepladder-green)"}}
          >{getTimerMinutes().toString()}</div>
          <p>Minutes</p>
          <img
            alt="sandWatch"
            src="images/sand-timer.png"
            style={{ width: "3rem" }}
          ></img>
        </div>
        <div className="p-ml-2 p-mr-2 min-width-60">
          <div className="p-badge p-badge-xl"
            style={{ background: "white", color: "#FBC02D", boxShadow: "0 0 0 2px #FBC02D"}}
          >{getTimerSeconds().toString()}</div>
          <p>Seconds</p>
        </div>
      </div>
  );
}

export default CountdownTimer;
