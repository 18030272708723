const menuOptions = [
    {
      text: "Circles",
      icon: "fas fa-home",
      path: "/dashboard",
    },
    {
      text: "Knowledge",
      icon: "fas fa-lightbulb",
      path: "/dashboard/education"
    },
    {
      text: "Offers",
      icon: "fas fa-shopping-basket",
      path: "/dashboard/marketplace",
    },
    {
      text: "Help",
      icon: "fas fa-question-circle",
      path:"/dashboard/help"
    },
  ];

  export default menuOptions;