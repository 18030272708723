import { Button } from "primereact/button";
import { useRef, useEffect } from "react";
import { useUser } from "../../contexts/UserContext";

function StickyButtons({
  onCancel,
  cancelText,
  nextText,
  nextClick,
  nextClassName = "",
  cancelClassName = "",
  getBtnHeightFn,
  isDisabled=false,
  unstick = false
}) {
  const ref = useRef(null);
  const userContext = useUser();

  useEffect(() => {
    
    if(getBtnHeightFn) {
      getBtnHeightFn((ref.current.clientHeight + 40));
    }
    
  }, [getBtnHeightFn, userContext])


  return (
    <div className={`formButtonContainer ${unstick ? "old" : ""}`} ref={ref}>
        {onCancel && (
          <Button
            style={{ marginRight: "15px" }}
            label={cancelText || "Back"}
            onClick={() => onCancel()}
            className={"p-button-outlined " + cancelClassName}
          ></Button>
        )}
        <Button
          disabled={isDisabled}
          className={`${nextClassName}`}
          label={nextText ? nextText : "Next"}
          onClick={() => nextClick()}
        ></Button>
      </div>
  );
}

export default StickyButtons;
