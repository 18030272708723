import React, {useEffect, useState} from "react";
import DesktopMenu from "../generic/menus/desktopMenu";
import PageWrapper from "../generic/pageWraper";
// import {getABVariant} from "../../services/meta";
// import { isMobile } from "../../utils/generic";
import { useUser } from "../../contexts/UserContext";
import moment from "moment-timezone";
import { getCookie } from "../../utils/users";

function OnboardingContainer({title, subTitle, children, isFull}) {
  const userContext = useUser();
  const isStageCoolOff = userContext.user?.stage === "CoolOff";
  const [variant, setVariant] = useState(isStageCoolOff ? 1 : 0);

  //kept the code in case they want to change the variant and re-try the test
  // useEffect(() => {
  //   if(isMobile && !userContext.isHideABTest()) {
  //     startTest();
  //   }
  // }, []);

  // async function startTest() {
  //   const abVariant = await getABVariant(userContext.user, "onboardingMobileVersion");
  //   setVariant(abVariant);
  // }

  // if (isLoading) {
  //   return <LoadingOverlay />;
  // }
  const ambassadorCookie = getCookie("ambassador");
  const allowNewMembers = (moment().isBefore(moment('2023-06-01'))) || ['KogoPay', 'Fusion_Bridge_Capital'].includes(ambassadorCookie);
  const bg = !allowNewMembers ? "" : isStageCoolOff ? "url(./images/header-confetti-bg-white.png)" 
    : `${variant ?  "url(" + `${ isFull ? "./images/mobile-bg.png" : "./images/header1.png"}` + ")" : ""}`;
  const bgColor = variant && isFull ? "var(--primary-color)" : "var(--main-color)";

  return (
    <>
    <div className={"animate__animated layoutContainer onboardingContainer flex width-100"}  style={{backgroundImage: bg, backgroundColor: bgColor}}>
      <DesktopMenu variant={variant} />
      {/* className="innerLayoutContainer" */}
      <div className="p-pt-0">
        <PageWrapper>
          {(title || subTitle) &&<div className="page-wrapper-header" style={{width: `${variant ? "80%" : ""}`, minHeight: `${variant && !isFull ? "" : "100px"}` }}>
            <h2 className={`onboarding-header ${variant ? "" : "main-color"}`}>
              {title}
            </h2>
            <div className={`onboarding-subtitle ${variant ? "" : "grey-color"}`}>{subTitle}</div>
          </div>}
          {children}
        </PageWrapper>
      </div>
    </div>
    </>
  );
}

export default OnboardingContainer;
