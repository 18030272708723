import React, { useEffect, useState } from "react";
import moment from "moment";
import DrawDaySavedTable from "../adminDrawDayGenerator/savedTable/main";
import platformEventsService from "../../services/platformEventsService";
import { Dropdown } from "primereact/dropdown";
import circleService from "../../services/circleService";

function DrawDayHistory({setTitle, setSubTitle, showToast}) {
  const [isLoading, setIsLoading] = useState(true);
  const [selectedDrawDay, setSelectedDrawDay] = useState([]);
  const [drawDays, setDrawDays] = useState([]);
  const [circleData, setCircleData] = useState([]);

  useEffect(() => {
    setTitle("Draw Day Info");
    getDrawDaySituation();
  }, [setTitle]);


  const getDrawDaySituation = async () => {
    const drawDayEvents = await platformEventsService.get({ type: "drawDayGenerated" }, 1000);
    const mappedDrawDayEvents = drawDayEvents.map(dd => ({label: getLabelName(dd.dateSubmitted), value: dd}));
    setDrawDays(mappedDrawDayEvents)
    setSelectedDrawDay(mappedDrawDayEvents[0].value);
    getSavedData(mappedDrawDayEvents[0].value)
  };

  const getLabelName = (value) => {
    return value ? moment.utc(value).format("MMM-YYYY") : "-";
  }

  const getSavedData = async (platformEvent) => {
    const participantIDs = platformEvent.extraContent.participants;

    if (participantIDs) {
      const data = await circleService.getParticipantsByIDs(participantIDs);
      setCircleData(data);
    } else {
      showToast("Couldn't find any platform event", "error");
    }
    setIsLoading(false);
  };


  return (
    <React.Fragment>
        <Dropdown
            className="p-mb-2"
            style={{"padding": "3px", borderRadius: "10px"}}
            value={selectedDrawDay}
            options={drawDays}
            placeholder="Select Draw Day period"
            onChange={(e) => {
                setSelectedDrawDay(e.value);
                getSavedData(e.value)
            }}/>
            
        <DrawDaySavedTable
            circleData={circleData}
            showToast={showToast}
            isLoading={isLoading}
            drawDayEvent={selectedDrawDay}
            buttonClick={false}>
        </DrawDaySavedTable>
    </React.Fragment>
  );
}
export default DrawDayHistory;