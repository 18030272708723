import React, { useEffect, useState } from "react";
import paymentsService from "../../../services/payments";
import PaygateTable from "./paygateTable";
import AdminPageWrapper from "../../../components/pageWrapper";
import moment from "moment";

function Paygate({setTitle}) {
    const [paygatePayments, setPaygatePayments] = useState([]);
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setTitle("Paygate");
        getPayGates()
    }, []);

    const dateFormat = (date, isShort) => {
        const format = isShort ? "MMM-YYYY" : "DD-MMM-YYYY";
        return date && date !== "no date" ? moment.utc(date).format(format) : "no date";
    };


    async function getPayGates() {
        setLoading(true);
        try {
            const {paygates} = await paymentsService.getPayGates();
            setPaygatePayments(paygates)
            
        } finally {
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <PaygateTable 
                paygatePayments={paygatePayments.map(pg => ({...pg, CreateDate: dateFormat(pg.CreationDate)}))}
                loading={loading}
                dateFormat={dateFormat}
                >
            </PaygateTable>
         </React.Fragment>
    );

}

export default Paygate;
