import React from "react";
import PlatformContentContainer from "../_generic/platformContentContainer";
import {options} from "./config";
import { Button } from "primereact/button";

function HelpTodoWithMoney({isHelp}) {
    const option = options["notdrawn"];

  return (
    <div 
        className={"drawDayStoryContainer scrollable drawday" } 
        style={{backgroundImage: "url(" + option.bg + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: "55"
        }} >
        <PlatformContentContainer className="platform_small p-mt-lg-4 p-pt-6 p-pb-6 p-pr-5 p-pl-5">
            <div className="p-pt-6">
                <h1 className="center p-pt-5 p-mt-1 p-text-center txt-rem-l">Would you like our help to plan what to do with your money?</h1>
                <p className="p-text-center p-mt-5">
                    This won’t affect how quickly it will arrive in your account
                </p>
                <div>
                    <Button 
                        label="Yes"
                        className="p-mt-5 p-button-raised p-button-info width-100 button-purple"
                        onClick={() => isHelp(true)} />

                    <Button 
                        label="No"
                        className="p-mt-5 p-button-raised p-button-info width-100 button-purple"
                        onClick={() => isHelp(false)} />
                </div>
            </div>
        </PlatformContentContainer>
    </div>
  );
}

export default HelpTodoWithMoney;
