import React, { useState } from "react";
import { getQueryParams, isMobile } from "../../../../utils/generic";
import OnboardingContainer from "../../onboardingContainer";
import MMOBError from "./MMOBError";
import MMOBLoading from "./MMOBLoading";

function MMOB() {
  const url = window.location.href.split("/");
  let id = url.pop();

  const MMOB_ID = id !== "mmob" ? id : null;

  if(!isMobile){
    return <OnboardingContainer>
      <MMOBLoading id={MMOB_ID} />
    </OnboardingContainer>
  }

  return <MMOBLoading id={MMOB_ID} />

}

export default MMOB;
