
import moment from "moment";
import API from "../../services/api";
import platformEventsService from "../../services/platformEventsService";

const drawDayGeneratorUtils = {
  getDrawCircleData: async function (monthSelected) {
    let response = await API.call({
      method: "GET",
      url:
        "/circles/drawDayCircles?monthRequested=" +
        moment.utc(monthSelected).toISOString(),
    });

    return response;
  },
  getCirclesEnding: function (circleData, monthSelected) {
    return circleData
      .filter((circle) => this.isCircleEnding(circle, monthSelected))
      .sort((a, b) => b.excelID - a.excelID);
  },
  isCircleEnding: function (circle, monthSelected){
    return moment.utc(circle.endingDate).isSame(moment.utc(monthSelected), "month");
  },
  getPotentialDrawnParticipants: function (circle, strict = false) {
    if(strict){
      return circle.participants.filter((part) => part.drawEligibility === "eligible");
    }

    return circle.participants.filter(
      (part) => ["drawn", "dropout"].indexOf(part.drawEligibility) === -1
    );
  },
  generateDrawMember: function (circle) {
    const participants = this.getPotentialDrawnParticipants(circle, true);

    const max = participants.length - 1;
    const min = 0;
    const random = (Math.floor(Math.random() * (max - min + 1)) + min);

    return [participants[
      random
    ]];
  },
  generateDrawnMemberList: function(circleData, monthSelected){
    return circleData.map((circle) => {
      return this.isCircleEnding(circle, monthSelected) ? this.getPotentialDrawnParticipants(circle, true) : this.generateDrawMember(circle);
    }).flat().filter((itm) => itm);
  },
  saveDrawnParticipants: async function(drawnIDs){
    const response = await platformEventsService.createOrUpdate({type: "drawDayGenerated", status: "SAVED", extraContent: {participants: drawnIDs}});

    if(response.error){
      throw response.error;
    }

    return response;
  },
  publishDrawnParticipant: async function(_id){
    const response = await platformEventsService.createOrUpdate({_id: _id, status: "PUBLISHED"});

    if(response.error){
      throw response.error;
    }

    return response;
  }
};

export default drawDayGeneratorUtils;
