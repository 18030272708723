import API from "./api";

function getPartnerActivityForUser(email) {
  return API.call2("/partnerActivity/user?email=" + email, { method: "GET" });
}

function getPartnerActivitySummary(){
    return API.call2("/partnerActivity/summary", {method: "GET"});
}

export { getPartnerActivityForUser, getPartnerActivitySummary };
