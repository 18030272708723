import moment from "moment";

import { useUser } from "../../../../../contexts/UserContext";
import WaitQuizMain from "../waitQuiz/WaitQuizMain";
import { nextChanceToTakeQuiz } from "./logic/nextChanceTakeQuiz";

import TakeQuizMain from "./pages/takeQuiz/takeQuizMain";



function ThreeQuestionQuizMain({setTitle, setIsFull}){

    const userContext = useUser();
    const user = userContext.user;
    
    const nextTakeQuizDate = nextChanceToTakeQuiz(user.quiz);
    const canTakeQuizNow = nextTakeQuizDate.isSame(moment.utc(), "day");

    // console.log(nextTakeQuizDate);

    if(!canTakeQuizNow){
        return <WaitQuizMain nextTake={nextTakeQuizDate} />
    }

    const lastOutcome = user.quiz?.length > 0 ? user.quiz[user.quiz.length - 1].outcome : undefined

    return <TakeQuizMain setTitle={setTitle} setIsFull={setIsFull} lastOutcome={lastOutcome}/>
}

export default ThreeQuestionQuizMain;