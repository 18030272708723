import moment from "moment";
import { Calendar } from "primereact/calendar";

function ArrearsCalendar({ id, value, label, onChange }) {
  const makeChange = (val) => {
    onChange(id, val);
  };
  return (
    <div>
      <b>{label}</b><br />
      <Calendar value={value} onChange={(e) => makeChange(moment(e.value).format("YYYY-MM-DDTHH:mm:ss") + "Z")}></Calendar>
    </div>
  );
}

export default ArrearsCalendar;
