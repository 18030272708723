import React from 'react';
import Moment from 'moment';

export default class FixedCellRenderer extends React.Component {
    render() {
        return (
            <div>
                {
                    this.props.value === true ? <span><i className={ "boldRow pi pi-check active"} /></span>  :
                    this.props.value  ? <span>{Moment(this.props.value).format("MMM-YY")}</span> :
                    ""
                }
            </div>
        );
    }
}
// 🍩