import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import EditAmbassador from "./editAmbassador/editAmbassador";
import ViewAmbassadors from "./viewAmbassadors";
import CircleService from "../../services/circleService";
import AmbassadorService from "../../services/ambassadorService";

function AdminAmbassadors({ setTitle, showLoading, hideLoading, showToast }) {
  const history = useHistory();
  const [ambassadorsList, setAmbassadorsList] = useState([]);
  const [circleList, setCircleList] = useState([]);
  const [showEditAmbassador, setShowEditAmbassador] = useState(false);
  const [isNewAmbassador, setIsNewAmbassador] = useState(false);
  const [currentAmbassador, setCurrentAmbassador] = useState({});

  useEffect(() => {
    setTitle("Ambassadors");
    loadData();
  }, []);

  const loadData = async () => {
    try {
      const { data: foundAmbassadors } = await AmbassadorService.getAmbassadorsList2();
      setAmbassadorsList(foundAmbassadors);

      const circles = await CircleService.getCircleList(0, false, true);
      setCircleList(circles);

    } catch (error) {
      showToast(error.message || JSON.stringify(error), "error");
    } finally {
    }
  }

  return (
    <>
      <div>
        <Button
          onClick={(e) => {
            setIsNewAmbassador(true);
            setShowEditAmbassador(true);
          }}
          className="p-button-outlined p-button-info p-button-raised"
          icon="pi pi-plus"
          label="Add Ambassador"
          style={{ marginTop: "1em" }}
        ></Button>
      </div>

      <ViewAmbassadors
        ambassadorsList={ambassadorsList}
        onSelectionChange={(row) => {
          setCurrentAmbassador(row);
          setIsNewAmbassador(false);
          setShowEditAmbassador(true);
        }}
      ></ViewAmbassadors>

      <Dialog
        className="editambassadordialog"
        header={isNewAmbassador ? "Add Ambassador" : "Edit Ambassador"}
        visible={showEditAmbassador}
        onHide={() => {
          setShowEditAmbassador(false);
          setCurrentAmbassador({});
        }}
      >
        <EditAmbassador
          history={history}
          isNewAmbassador={isNewAmbassador}
          ambassador={currentAmbassador}
          showLoading={showLoading}
          hideLoading={hideLoading}
          circleList={circleList.filter(c => c.ambassador?._id === currentAmbassador?._id)}
          showToast={showToast}
        ></EditAmbassador>
      </Dialog>

    </>
  )
}

export default AdminAmbassadors;
