import API from "./api";

const platformEventsService = {
  path: "/platformEvents",
  createOrUpdate: async function (event) {
    //{_id: "", type: "", status: "", extraContent: {}

    return this.callAPI({
      method: "POST",
      body: {
        event: event
      },
    });
  },
  get: async function (filter = {}, limit = 25, sort = null) {
    return this.callAPI({
      method: "GET",
      url:
        this.path +
        "?filter=" +
        JSON.stringify(filter) +
        (sort ? "&sort=" + JSON.stringify(sort) : "") + "&limit=" + limit,
    });
  },
  callAPI: async function (callObject) {
    if (!callObject.url) {
      callObject.url = this.path;
    }
    return API.call(callObject);
  },
};

export default platformEventsService;
