import userAPI from "./userAPI";

function getUser(){
    return userAPI.call("/users", { method: "GET" });
}

function logout(){
    return userAPI.call("/users/logout", {method: "POST"});
}

function requestMagicToken(email){
    return userAPI.call("/users/requestMagicLink", {method: "POST", body: {email: email, callback_url: window.location.origin + "/magicToken"}});
}

function submitMagicToken(token){
    return userAPI.call("/users/login", {method: "POST", body: { token: token }});
}

function createUser(userObject){
    return userAPI.call("/users", {method: "POST", body: {user: userObject}});
}

function createUserThroughMMOB(userID){
  return userAPI.call("/users/mmob", {method: "POST", body: {id: userID}});
}

function submitInsuranceDetails({email, answers}){
  return userAPI.call("/users/insuranceQuiz", {
    method: "POST",
    body: {
      email,
      answers
    }
  })
}

function nextStage(){
    return userAPI.call("/users/requestNextStage", {method: "PUT"});
}

function updateUserDetails(userObject){
    return userAPI.call("/users", {method: "PUT", body: {user: userObject}});
}

function checkDuplicate(postcode, dob){
    return userAPI.call("/users/checkDuplicateUser?zip="+postcode+"&dob="+dob, {method: "GET"});
}

function updateUserInHubspot(fields){
  return userAPI.call("/users/updateUserInHubspot", {method: "POST", body: fields});
}

  function requestCallback(requestType, requestMessage){
    return userAPI.call("/users/requestCallback", {method: "POST", body: {
      requestType,
      requestMessage
    }})
  }

function findAddress(value){
  return userAPI.call("/users/findAddress?postCode=" + value, { method: "GET" });
}

export {
  requestCallback,
  getUser,
  logout,
  requestMagicToken,
  submitMagicToken,
  createUser,
  nextStage,
  updateUserDetails,
  checkDuplicate,
  createUserThroughMMOB,
  submitInsuranceDetails,
  findAddress,
  updateUserInHubspot
};