import AdminLoading from "./pages/adminLoading";
import AdminLogin from "./pages/adminLogin";
import AdminMagicToken from "./pages/adminMagicToken";
import AdminHome from "./pages/adminHome";
import AdminManageUser from "./pages/adminUser/main";
import AdminCircles from "./pages/adminCircles/main";
import AdminAccessManagement from "./pages/settings/adminAccessManagement";

import AdminAmbassadors from "./pages/adminAmbassadors/main";
import AdminPaymentGenerator from "./pages/payments/paymentGenerator/adminPaymentGenerator";
import AdminPaymentDisabled from "./pages/payments/adminPaymentAutomaticPaymentsDisabled";
import AdminCreditCheck from "./pages/adminCreditCheck/adminCreditCheck";
import CircleTracker from "./pages/circleTracker/circleTracker";

import TopSheet from "./pages/topSheet/topSheet";
import FailedPayment from "./pages/payments/failedPayments/failedPayment";
import AdminLifecycleTrackerMain from "./pages/adminLifecycleChanges/main";
import TransfersToPayins from "./pages/payments/transfersToPayins";
import Transfers from "./pages/payments/transfers/transfers";
import AdminDrawDayGeneratorMain from "./pages/adminDrawDayGenerator/main";
import DrawDayHistory from "./pages/drawDayHistory/drawDayHistory";
import AdminArticlesMain from "./pages/adminArticles.js/AdminArticlesMain";
import FeesMain from "./pages/adminMI/fees/FeesMain";
import RiskPolicyMain from "./pages/adminMI/riskPolicy/RiskPolicyMain";
import VouchersView from "./pages/adminMI/vouchers/vouchersView";
import PartnerAnalytics from "./pages/partnerAnalytics/PartnerAnalytics";
import Paygate from "./pages/payments/paygate/paygate";
import Signups from "./pages/adminMI/signups/signups";
import Conversions from "./pages/adminMI/conversions/conversions";
import Chats from "./pages/chats/chats";
import Metas from "./pages/metas/metas";

 const AdminRoutes = [
    {
      path: "/adminPortal",
      exact: true,
      children: AdminLoading,
    },
    {
      path: "/adminPortal/login",
      exact: true,
      children: AdminLogin,
    },
    {
      path: "/adminPortal/magicToken",
      exact: true,
      children: AdminMagicToken,
    },
    {
      path: "/adminPortal/home",
      exact: true,
      children: AdminHome,
    },
    {
      path: "/adminPortal/tracker",
      exact: true,
      children: CircleTracker,
    },
    {
      path: "/adminPortal/topsheet",
      exact: true,
      children: TopSheet,
    },
    {
      path: "/adminPortal/user",
      exact: true,
      children: AdminManageUser,
    },
    {
      path: "/adminPortal/circles",
      exact: true,
      children: AdminCircles,
    },
    {
      path: "/adminPortal/manageAccess",
      exact: true,
      children: AdminAccessManagement,
    },
    {
      path: "/adminPortal/ambassadors",
      exact: true,
      children: AdminAmbassadors,
    },
    {
      path: "/adminPortal/paymentGenerator",
      exact: true,
      children: AdminPaymentGenerator,
    },
    {
      path: "/adminPortal/disablePayments",
      exact: true,
      children: AdminPaymentDisabled,
    },
    {
      path: "/adminPortal/failedPayments",
      exact: true,
      children: FailedPayment,
    },
    {
      path: "/adminPortal/transfers",
      exact: true,
      children: Transfers,
    },
    {
      path: "/adminPortal/TransfersToPayins",
      exact: true,
      children: TransfersToPayins,
    },
    {
      path: "/adminPortal/paygate",
      exact: true,
      children: Paygate,
    },
    {
      path: "/adminPortal/creditCheck",
      exact: true,
      children: AdminCreditCheck,
    },
    {
      path: "/adminPortal/drawDayGenerator",
      exact: true,
      children: AdminDrawDayGeneratorMain,
    },
    {
      path: "/adminPortal/drawDayInfo",
      exact: true,
      children: DrawDayHistory,
    },
    {
      path: "/adminPortal/lifecycleChanges",
      exact: true,
      children: AdminLifecycleTrackerMain
    },
    {
      path: "/adminPortal/articles",
      exact: true,
      children: AdminArticlesMain
    },
    {
      path: "/adminPortal/fees",
      exact: true,
      children: FeesMain
    },
    {
      path: "/adminPortal/riskPolicy",
      exact: true,
      children: RiskPolicyMain
    },
    {
      path: "/adminPortal/vouchers",
      exact: true,
      children: VouchersView
    },
    {
      path: "/adminPortal/partnerAnalytics",
      exact: true,
      children: PartnerAnalytics
    },
    {
      path: "/adminPortal/signups",
      exact: true,
      children: Signups,
    },
    {
      path: "/adminPortal/conversions",
      exact: true,
      children: Conversions,
    },
    {
      path: "/adminPortal/chats",
      exact: true,
      children: Chats,
    },
    {
      path: "/adminPortal/metas",
      exact: true,
      children: Metas,
    },
  ];

  export default AdminRoutes;