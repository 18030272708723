import { useEffect } from "react";

import { useState } from "react";
import { createPartnerActivity } from "../../../services/partnerActivity";
import LoadingOverlay from "../../generic/LoadingOverlay";

function URLRedirect() {
  const [isError, setIsError] = useState(false);

  const urlParams = new URLSearchParams(window.location.search);

  const key = urlParams.get("key");
  const url = urlParams.get("url");
  const email = urlParams.get("email");

  useEffect(() => {
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initialize = async () => {

    if(!url){
        setIsError(true);
        return;
    }

    if(email && key && url){
        await createPartnerActivity(key, "emailClick", email);
        window.location.href= url;
    } else {
        setIsError(true);
    }
    

   
  };

  if (isError) {
    return <RedirectError />;
  }

  return <LoadingOverlay />;
}

function RedirectError() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <div style={{ padding: "40px", textAlign: "center" }}>
        <div>
          <img alt="logo"  style={{width: "110px", marginBottom:"15px"}} src="./images/stepLadderLogo.png" />
        </div>
        Uh-oh, we ran into an error. We'd still love to connect you,{" "}
        <a href="https://www.joinstepladder.com/contact">please contact us</a>{" "}
        to discuss this.
        <br /> <br />
        If you are a member, <a href="/dashboard/marketplace">click here</a> to
        see our current offers which may include the link you were linking for.
      </div>
    </div>
  );
}

export default URLRedirect;
