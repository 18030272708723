import moment from "moment-timezone";

function randomNumber(min, max) {
  // min and max included
  return Math.floor(Math.random() * (max - min + 1) + min);
}

function getCircleDurationRemaining(circle){
    const endDate = moment(circle.endingDate);

    const monthsRemaining = moment(endDate).diff(moment(), 'months', true);
    const percentage =  monthsRemaining / circle.period;

    return {
        percentage: percentage > 1 ? 1 : percentage < 0 ? 0 : percentage,
        number: monthsRemaining
    };

}

export { randomNumber, getCircleDurationRemaining };
