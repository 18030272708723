import { Dialog } from "primereact/dialog";
import React from "react";

function KeySelection(props) {
  return (
    <Dialog header="Which field is the email field?" onHide={(e) => props.onCancel()} visible={props.visible}>
      <React.Fragment>
        {props.keys.map((itm) => {
          return <div style={{border:"1px solid #bebebe", padding:"5px", marginBottom:"10px", cursor:"pointer"}} onClick={() => props.onKeySelected(itm)}>{itm}</div>;
        })}
      </React.Fragment>
    </Dialog>
  );
}

export default KeySelection;
