import React from "react";
import { withRouter } from "react-router";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";

import moment from "moment";


import AddCircle from "./addCircle";
import UpdateCircle from "./updateCircle";

import ViewCircles from "./viewCircles";
import CircleService from "../../services/circleService";

class page extends React.Component {
  constructor(props) {
    super(props);
    this.props.setTitle("View Circles")
    this.updateCircleRef = React.createRef();

    this.state = {
      showAddCircle: false,
      showUpdateCircle: false,
      circleList: [],
      loading: false
    };
  }

  async componentDidMount() {
    this.setState({
      loading: true,
    });
    await this.getCircles();

    //get query params
    var queryURL = new URLSearchParams(window.location.search);
    var showCircleID = queryURL.get("showCircleID");


    if(showCircleID){
      //find the circle
      var circleRow = this.state.circleList.filter(function(itm){
        return itm._id === showCircleID;
      });

      if(!circleRow.length){
        return this.props.showToast("No circle could be found with that ID", "error");
      } else {
        circleRow = circleRow[0];
        this.showUpdateCircle(circleRow);
      }
    }
  }

  showUpdateCircle(circleObject){
    this.updateCircleRef.current.updateCircleObject(circleObject);
    this.setState({
      showUpdateCircle: true,
    });
  }

  async getCircles() {
    try {
      //gets all circles
      const newCircleData = await CircleService.getCircleList();

      this.setState({
        circleList: newCircleData,
      });
        
    } finally {
      this.setState({
        loading: false,
      });
    }
    
  }

  formatStartingDate(circleRow) {
    return moment(circleRow.startingDate).format("MMM YYYY");
  }

  formatAvailability(circleRow) {
    return circleRow.availability ? "Visible" : "Hidden";
  }

  formatMonthlyInvestment(circleRow) {
    return "£" + circleRow.monthly_investment;
  }

  formatTotal(circleRow) {
    return (
      "£" + (circleRow.period * circleRow.monthly_investment).toLocaleString()
    );
  }

  render() {

    return (
      <div>
        <AddCircle
          showLoading={this.props.showLoading}
          hideLoading={this.props.hideLoading}
          showToast={this.props.showToast}
          visible={this.state.showAddCircle}
          onHide={(e) => {
            this.setState({ showAddCircle: false });
          }}
        ></AddCircle>
        <UpdateCircle
          showLoading={this.props.showLoading}
          hideLoading={this.props.hideLoading}
          showToast={this.props.showToast}
          visible={this.state.showUpdateCircle}
          history={this.props.history}
          onHide={(e) => {
            this.setState({ showUpdateCircle: false });
          }}
          ref={this.updateCircleRef}
        ></UpdateCircle>
        {/* <h1 className="p-m-0">View Circles</h1> */}

        <Button
          label="Add Circle"
          className="p-button-outlined p-button-info p-button-raised"
          icon="pi pi-plus"
          onClick={(e) => this.setState({ showAddCircle: true })}
          style={{ marginTop: "2em" }}
        ></Button>
        <ViewCircles
          loading={this.state.loading}
          onRowClick={(row) => {
            this.showUpdateCircle(row);
          }}
          circles={this.state.circleList}
        ></ViewCircles>
      </div>
    );
  }
}

var AdminCircles = withRouter(page);

export default AdminCircles;
