import { useState } from "react";
import StickyButtons from "../../../../../../../generic/stickyButtons";

function QuizPageLayout({
  style,
  children,
  buttonLabel,
  buttonDisabled,
  onSubmit,
  btnClass,
  className
}) {

  const [btnsHeight, setBtnsHeight] = useState(0);

  return (
    <div style={style} className={`quizPageLayout ${className}`}>
      <div style={{paddingBottom: btnsHeight}}>{children}</div>
      <StickyButtons
        isDisabled={buttonDisabled}
          nextText={buttonLabel || "Next"}
          nextClick={onSubmit}
          getBtnHeightFn={setBtnsHeight}
           />
    </div>
  );
}

export default QuizPageLayout;
