import React from "react";
import { useHistory } from "react-router";
import { getNextRoute } from "../../../../utils/userRoutes";
import { useUI } from "../../../../contexts/UIContext";
import { useUser } from "../../../../contexts/UserContext";
import { Button } from "primereact/button";

function ShowMeCircle() {
  const UI = useUI();
  const history = useHistory();
  const userContext = useUser();

  const submit = async () => {
    const { status: updateStageStatus } = await userContext.updateUser({ nextStage: true });

    if (updateStageStatus !== 200) {
      UI.showToast("There was an error, please try refreshing the page or contact us to fix it");
    }
    UI.hideLoading();

    history.push(getNextRoute(userContext.user));
  };

  return (
    <>
      <div>
        <p className="flex-center-bold p-mt-5"></p>
        <h1 className="page-title flex-center-bold">
          Your Circle is ready,
          <br />{userContext.user.firstname}!
        </h1>
        <div className="flex-center-bold p-mt-1 p-mb-1"
          style={{ color: "var(--main-color)", fontSize: "1.16rem", fontWeight: "bold" }}>
          <h3>Click to reveal how much money <br />you will raise in your new Circle...</h3>
        </div>
      </div>
      <div className="flex-center-bold">
        <Button
          label="Show me my Circle"
          className="p-button p-button-raised p-button-rounded button-purple"
          onClick={submit}
        />
      </div>
      <img
        alt="circles-in-projectors"
        src="images/circles-in-projectors.png"
        style={{ width: "100%", marginLeft: "0em", marginTop: "0em" }}
      ></img>
    </>
  );
}

export default ShowMeCircle;
