import React, { useEffect, useState } from "react";
import RowExpansion from "./rowExpansion";import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import drawDayGeneratorUtils from "../drawDayGeneratorUtils";
import { MultiSelect } from 'primereact/multiselect';

import DrawDayTotal from "../drawDayTotal";
import LoadingSpinner from "../../../components/loadingSpinner";


function DrawDayGeneratorTable(props) {
  const [circleData, setCircleData] = useState([]);
  const [circlesEnding, setCirclesEnding] = useState([]);
  const [expandedRows, setExpandedRows] = useState([]);
  const [drawnParticipants, setDrawnParticipants] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isGenerated, setIsGenerated] = useState(false);

  useEffect(() => {
    const fetchData = async () => {

      await loadCircleData();
    }
    fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const loadCircleData = async () => {
    try{
      let drawDayData = await drawDayGeneratorUtils.getDrawCircleData(props.month);

      drawDayData = drawDayData.map((circle) => {
        const participants = circle.participants;

        return {...circle, eligibileCount: participants.filter((part) => part.drawEligibility === "eligible").length + "/" + participants.length};
      });
      setCircleData(drawDayData);
      setCirclesEnding(drawDayGeneratorUtils.getCirclesEnding(drawDayData, props.month));
    }finally{
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if(isLoading){
      return;
    }

    if(!isGenerated){
      generateDrawForAll();
      setIsGenerated(true);
    } else {
      saveDrawnParticipants();
    }

 // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.buttonClick, circleData]);


  const getDrawnParticipants = (circle) => {
    return circle.participants.filter((part) => {
      return drawnParticipants.findIndex((itm) => itm._id === part._id) > -1;
    });
  }
  
  const changeDrawnParticipants = (value, circle) => {
    //Remove them from the list
    let participants = drawnParticipants.filter((part) => circle.participants.findIndex((itm) => itm._id === part._id) === -1);

    //re-add them because we are being sent all participants in that circle who are drawing
    setDrawnParticipants(participants.concat(value));
  }

  const saveDrawnParticipants = async () => {
    try {
      await drawDayGeneratorUtils.saveDrawnParticipants(drawnParticipants.map((part) => part._id));
      props.onComplete();
    } catch(err){
      alert("There was an error in saving the result.");
    }
  };

  const generateDrawForAll = () => {
    console.log("generateDrawForAll")
    console.log("circleData",circleData)
    try {
      const drawnMembers = drawDayGeneratorUtils.generateDrawnMemberList(circleData);
      console.log(drawnMembers)
      setDrawnParticipants(drawnMembers);
      
    } catch (error) {
      console.log(error)
    }
  }
  
  const generateDrawnMemberForCircle = (circle) => {
    const drawnMember = drawDayGeneratorUtils.generateDrawMember(circle);

    if(!drawnMember[0]){
      alert("We can't generate a random draw for a Circle with no-one eligible to draw, please select them manually.")
      return;
    }
  
    if(drawnMember){
      changeDrawnParticipants(drawnMember, circle);
    }
  };

  const usersNotEligibleButHaveDrawn = () => {
    return drawnParticipants.filter((part) => part.drawEligibility !== "eligible");
  }

  const sameUsersDrawn = () => {
    return drawnParticipants.filter((part, index, participants) => participants.findIndex((itm) => {
      return itm.user === part.user
    }) !== index);
  }

  const sumDrawn = () => {

    return circleData.reduce((accumulator, circle) => {
      const drawnCount = circle.participants.filter((part) => drawnParticipants.findIndex((drawnPart) => drawnPart._id === part._id) > -1).length;

      return accumulator + (drawnCount * (circle.period * circle.monthly_investment));
    }, 0);
    
  }


  if(isLoading){
    return <LoadingSpinner />
  }

  return (
    <React.Fragment>
      {sameUsersDrawn().length > 0 && <div className="platformWarning">The following members have been marked to draw but have been drawn more than once: <ul>{sameUsersDrawn().map((itm, i) => <li key={i}>{itm.name}</li>)}</ul></div>}
      {usersNotEligibleButHaveDrawn().length > 0 && <div className="platformWarning">The following members have been marked to draw, but are not eligible:<ul>{usersNotEligibleButHaveDrawn().map((itm, i) => <li key={i}>{itm.name}</li>)}</ul></div>}
      {circlesEnding.length > 0 && <div className="platformWarning">The following Circles are ending this month and all eligible members have been marked to draw: <ul>{circlesEnding.map((itm, i) => <li key={i}>{"Circle "+ itm.excelID}</li>)}</ul></div>}
      
      <DrawDayTotal total={sumDrawn()}></DrawDayTotal>
      <DataTable
        sortField="excelID" sortOrder={-1}
        value={circleData}
        onRowToggle={(e) => setExpandedRows(e.data)}
        expandedRows={expandedRows}
        rowExpansionTemplate={RowExpansion}
      >
      <Column expander style={{ width: '4em' }} />
      <Column field="excelID" sortable header="Circle ID"></Column>
      <Column header="Draw Amount" body={(row) => "£"+(row.period * row.monthly_investment).toLocaleString()} />
      <Column header="Eligible To Draw" field="eligibileCount"></Column>
      <Column style={{overflow: "visible"}} header="Drawn Member(s)" body={(row) => <React.Fragment><MultiSelect options={drawDayGeneratorUtils.getPotentialDrawnParticipants(row)} optionLabel="name" value={getDrawnParticipants(row)}  display="chip" onChange={(e) => changeDrawnParticipants(e.value, row)} /> <i className="pi pi-refresh" style={{cursor:"pointer"}} onClick={() => generateDrawnMemberForCircle(row)}></i></React.Fragment>}></Column>
    </DataTable>
    </React.Fragment>
    
  );
}

export default DrawDayGeneratorTable;
