import React from "react";

function MMOBInfo() {
  return (
    <React.Fragment>
      <div
        style={{
          border: "1px solid grey",
          borderRadius: "10px",
          padding: "15px",
          marginTop: "50px",
        }}
      >
        <h4 style={{ margin: 0 }}>1. How It Works</h4>
        <div>
          We help people like you save towards a deposit for a home, pay off
          debts, improve their credit* and raise money towards your financial
          goals. <br />
          <br />
          We are a digital version of the traditional Savings Circles you may
          already know as Committee, Gameya, Chit Fund, Susu and more.
          <br />
          <br />
          Everyone in the Circle will contribute the same amount each month and
          for the same period of time. Each month one member of the Circle will
          recieve the total and this will repeat every month until everyone has
          achieved their saving’s goal.
          <div style={{ marginTop: "30px" }}>
            <b style={{ fontSize: "18px" }}>Joining StepLadder can help you:</b>
            <br />
            <ul>
              <li>Commit to your own savings goal</li>
              <li>Raise your savings faster and better</li>
              <li>Receive support from experts we introduce</li>
              <li>Enjoy discounts and offers from our partners</li>
              <li>Potentially Improve your credit score</li>
              <li>Pay no interest</li>
            </ul>
          </div>
          <div className="video-container" style={{ marginTop: "30px" }}>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/v1Ud8BJ2YLk"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
      <div
        style={{
          border: "1px solid grey",
          borderRadius: "10px",
          padding: "15px",
          marginTop: "50px",
        }}
      >
        <div style={{ marginTop: "20px" }}>
          <b>Traditional community concept</b><br />
          Now on a simple, secure and regulated online platform.
          <br />
          <hr />
          <br />
          <b>Save for your life goals</b>`<br />
          Used by 1,000s to save for their homes, pay off debt and towards other
          goals.
          <br />
          <hr />
          <br />
          <b>Start from </b>`<br />
          As little as £25 per month - up to £1,000 per month
          <br />
          <hr />
          <br />
          <b>Potential to</b>`<br />
          Build your credit score while you work toward you savings goal
          <br />
          <hr />
          <br />
        </div>
      </div>

      <div style={{ marginTop: "40px", paddingBottom: "250px" }}>
        *Missed or late payments may have an adverse effect on your credit
        score. Using StepLadder’s P2P product you will lend to and borrow from
        other Members in your Circle. Capital at Risk, Not FSCS Eligible. Some
        of our trusted partners pay us a commission should you choose to use
        them.
      </div>
    </React.Fragment>
  );
}

export default MMOBInfo;
