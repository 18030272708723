import { Button } from "primereact/button";
import UtilsService from "../../../../utils/utils";

function DownloadDropoutData({ potentials, actualDropouts, month, masterListFailedPayments, showToast, isLoading }) {
  const masterListFailedPaymentsLength = masterListFailedPayments && !masterListFailedPayments.error ? masterListFailedPayments.length: 0;
  const downloadData = (data, name) => {
      const finaliseData = data.map(d => {
          return {...d.user, ...d};
      });
  
    UtilsService.downloadCSVFromJson(finaliseData, name)
  }

  const downloadMissedPaymentMasterList = () => {
    if(masterListFailedPayments.error) {
      showToast(`Get payment master list error: ${masterListFailedPayments.error}`, "error");
    } else {
      downloadData(masterListFailedPayments, "masterListFailedPayments");
    }
  }

  return (
    <div className={"p-mt-4 flex"}>
      <div className="p-text-bold">Total missed payment master list: {masterListFailedPaymentsLength}</div> 
      <Button
        disabled={isLoading || !masterListFailedPayments.length}
        onClick={downloadMissedPaymentMasterList}
        style={{ marginLeft: "15px" }}
        label={`Download`}
        className="p-button-outlined" />
    </div>
  );
}

export default DownloadDropoutData;
