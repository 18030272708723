import React from "react";
import PlatformContentContainer from "../_generic/platformContentContainer";
import {options} from "./config";
import { Button } from "primereact/button";

function ThanksSubmit({userName, submitted}) {

  return (
    <div 
        className={"drawDayStoryContainer scrollable drawday" } 
        style={{backgroundImage: "url(" + options["drawn"].bg + ")",
                backgroundPosition: 'center',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                zIndex: "55"
        }} >
        <PlatformContentContainer className="platform_small p-mt-lg-4 p-pt-6 p-pb-6 p-pr-5 p-pl-5">
            <div className="p-pt-6">
                <h1 className="center p-pt-5 p-mt-1 txt-rem-xxxl p-text-center txt-rem-xxl">Thanks for submitting your details,</h1>
                <h1 className="p-st-purple p-text-center">{userName}</h1>
                <p className="p-text-center p-p-3">
                    Your savings will be in your bank account in 3-5 business days.
                </p>
                <div>
                    <Button 
                        label="Ok"
                        className="p-mt-5 p-button-raised p-button-info width-100 button-purple"
                        onClick={submitted} />

                   
                </div>
            </div>
        </PlatformContentContainer>
    </div>
  );
}

export default ThanksSubmit;
