import { useState, useRef, useEffect } from "react";
import FormConfig from "./components/formConfig";
import { Button } from "primereact/button";
import TextInput from "./components/textInput";
import ToggleInput from "./components/toggleInput";
import { useUI } from "./../../../contexts/UIContext";
import VisibleSelect from "./components/visibleSelect";
import FillAddress from "./components/fillAddress/fillAddress";
import StickyButtons from "../stickyButtons";

function Form(props) {
  const [formList, setFormlist] = useState(props.inputs);
  const [buttonClick, setButtonClick] = useState(false);
  const [enterManually, setEnterManually] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);
  const [btnsHeight, setBtnsHeight] = useState(0);
  const [anyFocused, setAnyFocused] = useState(false);
  const UIContext = useUI();

  useEffect(() => {
    isValid();
  }, [])

  const updateValue = (key, value, validate) => {
    let list = formList;
    let index = formList.findIndex((itm) => itm.key === key);
    list[index].value = value;
    if(validate && (key === "postcode")) {
      list[index].valid = validate(value);
    }
    setFormlist([...list]);
    if(props.list) {
      props.list([...list])
    }
    isValid()
  };

  const nextClick = () => {
    setButtonClick(true);
    if(props.setNextClick) {
      props.setNextClick(false)
    }

    if (isFormValid) {
      let newObject = {};
      formList.forEach((itm) => {
        if (
          itm.value !== null &&
          itm.value !== undefined &&
          (!itm.showCondition || itm.showCondition(formList))
        ) {
          newObject[itm.key] =
            typeof itm.value === "string" ? itm.value.trim() : itm.value;
        }
      });

      props.onSubmit(newObject);
    } else{
      UIContext.showToast("Please enter all the required fields correctly");
    }
  };

  const isValid = () => {
    const valid = formList.every((itm) => {
      const formObject = FormConfig[itm.key];
      if (
        formObject &&
        !formObject.optional &&
        formObject.validate &&
        !formObject.validate(itm.value) &&
        (!itm.showCondition || itm.showCondition(formList))
      ) {
        return false;
      }
      
      return true;
    });
    if(props.formList) {
      props.formList(formList)

    }
    setIsFormValid(valid)
  }

  const components = {
    text: TextInput,
    toggle: ToggleInput,
    visibleSelect: VisibleSelect,
    fillAddress: FillAddress
  };

  const onChangeCustom = (events) => {
    if(events.enterManually != null) {
      setEnterManually(events.enterManually);
    }

    if(events.address) {
      updateValue("firstLineOfAddress", events.address.address);
      updateValue("town", events.address.town_or_city);
      if(events.address.postcode) {
        updateValue("postcode", events.address.postcode);
      }
      setSelectedAddress(true)
    }

  }

  const filterAddressInputs = ({key}) => {
    return (key !== "firstLineOfAddress" && key !== "town") || (enterManually || selectedAddress);
  }

  return (
    <div className={"form" + (props.className ? " " + props.className : "")} style={{paddingBottom: btnsHeight}}>
      {formList
        .filter((itm) => !itm.showCondition || itm.showCondition(formList))
        .filter(filterAddressInputs)
        .map((itm, i) => {

          if (itm.key === "customComponent") {
            const CustomComponent = itm.component;
            return <CustomComponent key={i} list={formList} onChange={onChangeCustom} showValidation={buttonClick} value={itm.value || null}></CustomComponent>;
          }
          const formObject = FormConfig[itm.key];

          if (itm.label) {
            formObject.label = itm.label;
          }

          if (itm.max) {
            formObject.max = itm.max;
          }

          if (!formObject) {
            return <div>MISSING COMPONENT CODE: {itm.key}</div>;
          }

          const Component =
            components[formObject.type ? formObject.type : "text"];
            

          return (
            <div
              className={
                "formComponent " +
                itm.key +
                (formObject.type === "visibleSelect" ? " visibleSelect" : "") +
                (itm.showCondition
                  ? " animate__faster animate__animated animate__fadeInDown"
                  : "")
              }
              key={i.toString()}
            >

              <Component
                key={i.toString()}
                title={formObject.label}
                value={itm.value}
                img={itm.img}
                onFocus={() => setAnyFocused(true)} 
                onBlur={() => setAnyFocused(false)} 
                onChange={(value) => updateValue(itm.key, value, formObject.validate)}
                showValidation={buttonClick}
                formObject={formObject}
              />
            </div>
          );
        })}
        {!props.hideNextButton && <StickyButtons
          unstick={anyFocused}
          cancelText={props.cancelText}
          onCancel={props.onCancel}
          isDisabled={!isFormValid}
          nextText={props.nextText}
          nextClick={nextClick}
          getBtnHeightFn={setBtnsHeight}
           />}
    </div>
  );
}

export default Form;
