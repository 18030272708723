import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useUI } from "../../../../contexts/UIContext";
import { useUser } from "../../../../contexts/UserContext";
import CenterMiddle from "../../../generic/CenterMiddle";
import TermsBox from "./termsBox";
import HomeCircleWarning from "../circleInvite/homeCircleWarning";
import AmbassadorWarning from "../circleInvite/ambassadorWarning";
import Agreement from "./circlesOption/agreement";
import { getCorrectCircleDates } from "../payments/logic/circleTranslation";

function TermsMain({setTitle, setSubTitle}) {
  const userContext = useUser();

  const UI = useUI();
  const history = useHistory();


  useEffect(() => {
    const title = userContext.user.stage === "Terms" ? "Here's a Circle to help you reach your goal!" : "Here's your Circle Terms!";
    const subtitle =( <div>
      Need a different Circle? <span 
        style={{cursor: "pointer"}}
        onClick={() => history.push("/circleInvite")}><b>Click here</b>.</span>
    </div>)
    setTitle(title);
    setSubTitle(subtitle);
  }, [userContext.user.stage, setTitle, setSubTitle, history])

  const requestedCircle = getCorrectCircleDates(
    userContext.user.circles.requested
  );

  if (!requestedCircle) {
    history.push("/circleInvite");
    UI.showToast("Please select a new Circle!");
    return <></>;
  }

  return (
    <React.Fragment>
      <CenterMiddle>
        <TermsBox circleData={requestedCircle} />
        <HomeCircleWarning circle={requestedCircle} />
        <AmbassadorWarning circle={requestedCircle} />
        <Agreement hideTitle={true} />
      </CenterMiddle>
    </React.Fragment>
  );
}

export default TermsMain;
