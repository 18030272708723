const agreementsConfig = [
    {
      key: "membershipAgreement",
      title: "Membership Agreement",
      url:
        "https://f.hubspotusercontent20.net/hubfs/2442073/Agreements/January%202021/StepLadder%20Membership%20Agreement%20November%202020.pdf",
      agreedTo: false,

      compressed: true
    },
    {
      key: "loanAgreement",
      title: "Loan Agreement",
      url:
        "https://f.hubspotusercontent20.net/hubfs/2442073/Agreements/January%202021/StepLadder%20Loan%20Agreement%20November%202020.pdf",
      agreedTo: false,

      compressed: true
    },
    {
      key: "creditReferenceAgreement",
      title: "Credit Reference Agreement",
      url:
        "https://f.hubspotusercontent20.net/hubfs/2442073/Agreements/January%202021/StepLadder%20Credit%20Reference%20Agreement%20November%202020.pdf",
      agreedTo: false,
    },
    {
      key: "forumRulesAgreement",
      title: "Forum Rules Agreement",
      url:
        "https://f.hubspotusercontent20.net/hubfs/2442073/Agreements/January%202021/StepLadder%20Forum%20Rules%20Agreement%20November%202020.pdf",
      agreedTo: false,
    },
];

export default agreementsConfig;