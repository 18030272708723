import { Button } from "primereact/button";
import {useState, useEffect} from "react";
import { Dialog } from "primereact/dialog";
import { Card } from 'primereact/card';
import GeneratedPaymentsView from "./generatedPaymentsView";

function PerformChecks({getStagedFile, stagedPayments, downloadStagedCSV, uploadStagedFile, period}) {
    const [showGeneratedPayments, setShowGeneratedPayments] = useState(false);

    return (
        <Card
            className="sl-card"
            title="2. Perform your checks">
        <Button
          onClick={downloadStagedCSV}
          className="p-button-info p-button-raised p-mr-3"
          label="Download CSV"
        ></Button>
        <Button
          onClick={() => {
            setShowGeneratedPayments(true);
            getStagedFile()}}
          className="p-button-info p-button-raised"
          label="View Generated Payments"
        ></Button>
        <p>
            If you want to make changes, make changes in the excel file and then
            re-upload. Make sure the file stays as a CSV file. The key fields that
            the payment script looks at is:
        </p>
       
        <ul>
          <li>
            <b>Wallet ID</b>: The mangopay wallet ID for that Circle
          </li>
          <li>
            <b>Mango Pay User ID</b>: The mangopay user ID for the user
          </li>
          <li>
            <b>Monthly Investment</b>: The monthly amount excluding admin fees
          </li>
          <li>
            <b>Admin Fee</b>: The monthly admin fee for that individual
          </li>
          <li>
            <b>Disable Automatic Payments</b>: If set to 1, then we won't run
            the payment cycle
          </li>
        </ul>
        <div>
          <input
            type="file"
            className="upload-input"
            onChange={uploadStagedFile}
            onClick={(event)=> { 
              event.target.value = null
        }}
          ></input>
          <Button
            className="p-button-outlined p-button-info"
            icon="pi pi-upload"
            label="Reupload"></Button>
        </div>

      <Dialog
          header={
            `Generated Payments for period: ${period}`
          }
          visible={showGeneratedPayments}
          style={{ width: "90vw" }}
          onHide={() => setShowGeneratedPayments(false)}
        >
          <GeneratedPaymentsView
            stagedPayments={stagedPayments}
          />
        </Dialog>
      </Card>
    );
}

export default PerformChecks;