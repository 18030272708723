import React, {useState} from "react";
import { useUser } from "../../../contexts/UserContext";

import { isMobile } from "../../../utils/generic";
import PlatformContentContainer from "../_generic/platformContentContainer";
import {options} from "./config";
import { currencyFormat } from '../../../utils/generic';
import { Button } from "primereact/button";

function BookCallHC({submittedDetails}) {
  const userContext = useUser();
  const drawnSpots = userContext.drawnSpots();
  const hasWon = drawnSpots.length > 0;
  const option = options[hasWon ? "drawn" : "notdrawn"];
  const drawnSpot = drawnSpots.length ? drawnSpots.find(ds => ds.awarded > 1000) : null;

  // const emansIDs = [67, 75, 76, 77, 78, 79, 80, 125, 126, 133, 137];
  // let emanDrawn = null;
  // if (drawnSpots.map(c => c.circle.excelID).some(cId => emansIDs.indexOf(cId) > -1)) {
  //   emanDrawn = true;
  // }

  function goTo() {
    // const url = emanDrawn ? "https://calendly.com/emaneffectuk/30min" : "https://calendly.com/stepladderteam/drawcall"
    // window.open(url, '_blank').focus();
    submittedDetails();
 }

 const InnerComponent = () => {
    return(<div>
        <h2 className="p-text-center show-desktop p-pt-5">Your money is being stored safely</h2>
      
        <h3 className="p-text-normal p-text-center p-pt-3"> 
          Our membership team will be reaching out to discuss next steps.
        </h3>
        <Button 
                label="Continue"
                className="p-mt-7 p-button-raised p-button-info width-100 button-purple"
                onClick={() => goTo()} />
      </div>)
  
   }

  return (
    <div className={"drawDayContainer bg-white " + (isMobile ? "mobile" : "desktop")} >
    <div style={{backgroundImage: "url(" + option.header + ")",
        backgroundPosition: 'top center',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
        zIndex: "55",
        maxHeight: "250px"
    }}>
        <PlatformContentContainer className="platform_small p-mt-4 p-pt-6 p-pb-6 center-el">
          <h1 className="center txt-rem-xxxl p-mt-0" style={{color: "white"}}>{currencyFormat(drawnSpot ? drawnSpot.awarded : 0)}</h1>
          <p className="center p-mt-0 p-pb-4" style={{color: "white"}}>{option.text}</p>
          <div className="p-mt-6 p-pr-0 p-pl-0">
            <InnerComponent />
              
          </div>
        </PlatformContentContainer>
    </div>
</div>
  );
}

export default BookCallHC;
