import React, { useEffect, useState } from "react";
import "primeflex/primeflex.css";

import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";

function ViewAmbassadors({ ambassadorsList, onSelectionChange }) {
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(!ambassadorsList?.length > 0)
      }, [ambassadorsList]);

    return (<>
        <div className="blue-table">
            <DataTable
                className="table-rounded p-datatable-sm"
                loading={loading}
                scrollable scrollHeight="75vh"
                sortField="_id"
                sortOrder={-1}
                onSelectionChange={(row) => {
                    return onSelectionChange
                        ? onSelectionChange(row.value)
                        : false;
                }}
                selectionMode="single"
                dataKey="_id"
                value={ambassadorsList}
                style={{ marginTop: "1em" }}
            >
                <Column field="_id" sortable header="ID"></Column>
                <Column field="ambassadorName" sortable header="Name"></Column>
            </DataTable>
        </div>
    </>
    )
}

export default ViewAmbassadors;
