import { InputText } from "primereact/inputtext";

function ArrearsFreeText({ value, id, label, onChange }) {
  const makeChange = (val) => {
    onChange(id, val);
  };

  return (
    <div>
      <b>{label}</b>
      <InputText
        style={{ width: "100%" }}
        value={value}
        onChange={(e) => makeChange(e.target.value)}
      />
    </div>
  );
}

export default ArrearsFreeText;
