import {useState, useEffect} from "react";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import Papa from "papaparse";
import moment from "moment";
import { Dialog } from "primereact/dialog";

import API from "../../../services/api";
import SubmitPaymentCycle from "./submitPaymentCycle";
import GeneratePayments from "./generatePayments";
import PerformChecks from "./performChecks";
import utils from "../../../utils/utils";
import paymentService from "../../../services/payments";
function AdminPaymentGenerator ({setTitle, showLoading, hideLoading, showToast}) {
  const dt = moment().date() > 7 ? moment().add(2, "M") : moment().add(1, "M");
  const months = [
    { label: "January", value: 1 },
    { label: "February", value: 2 },
    { label: "March", value: 3 },
    { label: "April", value: 4 },
    { label: "May", value: 5 },
    { label: "June", value: 6 },
    { label: "July", value: 7 },
    { label: "August", value: 8 },
    { label: "September", value: 9 },
    { label: "October", value: 10 },
    { label: "November", value: 11 },
    { label: "December", value: 12 },
  ];

  const years = [
    { label: "2021", value: 2021 },
    { label: "2022", value: 2022 },
    { label: "2023", value: 2023 },
    { label: "2024", value: 2024 },
    { label: "2025", value: 2025 },
    { label: "2026", value: 2026 },
    { label: "2027", value: 2027 },
  ];
  const [runPaymentsConfirmation, setRunPaymentsConfirmation] = useState(false);
  const [stagedPayments, setStagedPayments] = useState(null);
  const [monthSelected, setMonthSelected] = useState(dt.month()+1);
  const [yearSelected, setYearSelected] = useState(dt.year());

  useEffect(() => {
    setTitle("Payments Generator")
  }, []);

  useEffect(() => {
    setStagedPayments(null);
  }, [monthSelected, yearSelected]);


  async function generatePaymentsStaging(isSecondBatch = false) {
    let data = null;
    setStagedPayments(null);
    const body = {
      month: monthSelected,
      year: yearSelected,
    };
    showLoading();
    if(!isSecondBatch) {
      data = await paymentService.generatePayments(body);
    } else{
      data = await paymentService.generateSecondBatchPayments(body);
    }
    hideLoading();

    if (data.paymentsSaved) {
      showToast(`Payments ${isSecondBatch ? " second batch" : ""} staged!`, "success");
    } else {
      showToast("Payments not staged, there was an error", "error");
    }
  }

  function showNoFile() {
    showToast(
      "No staged file was found, check you've submitted a staged file",
      "error"
    );
  }

  function showPaymentCycleFailed() {
    showToast(
      "Payments cycle failed",
      "error"
    );
  }

  function showPaymentCycleSuccess() {
    showToast(
      "Payments cycle scheduled successful! ",
      "success"
    );
  }

  async function schedulePayments() {
    showLoading();
    let response = null;
    try {
      response = await paymentService.schedulePayments(monthSelected, yearSelected);
      
    if (!response.queueItems) {
      if (response.reason === "no_file") {
        showNoFile();
      } else {
        setRunPaymentsConfirmation(false);
        showPaymentCycleFailed();
      }
      hideLoading();
      return;
    } else {
      setRunPaymentsConfirmation(false);
      showPaymentCycleSuccess();
    }
    
    } catch (error) {
      setRunPaymentsConfirmation(false);
      showToast(error.message, "error");
    }

    hideLoading();

  }

  async function getStagedFile() {
    try {
      let data = null;
      showLoading();
      data = await paymentService.getStagedFile(monthSelected, yearSelected);
      if(!data || data.error === "no_file"){
        showToast(
          `No generated payments for: ${monthSelected}-${yearSelected}, please generate it`,
          "warn"
        );
        return
      }

      setStagedPayments(data.paymentData)
    
      return data.paymentData;
    } catch (error) {
      showToast(`Error: ${JSON.stringify(error.message || error.data || error)}`, "error" );
      return;
    } finally{
      hideLoading();
    }
  }

  async function downloadStagedCSV() {
    let data = null;
    if(!stagedPayments) {
      data = await getStagedFile()
    }else {
      data = stagedPayments;
    }

    if(data) {
      const csv = utils.generateCsv(data);
      utils.downloadfile(csv, yearSelected, monthSelected);
    }
  }

  async function getFinal() {
    let finalPayments = null;
    try {
      showLoading();
      finalPayments = await paymentService.cycleResult(monthSelected, yearSelected); 
    } catch (error) {
        showToast(error.message || error, "error");
        return;
    }
    hideLoading();
    if(finalPayments.paymentData.length) {
      downloadFileFromJson(finalPayments);
    } else {
      showToast("no result found", "error");
    }
  }

  async function getIgnoredPayments() {
    let JSONdata = null;
    try {
      showLoading();
      JSONdata = await paymentService.getIgnoredPayments(monthSelected, yearSelected);
    } catch (error) {
        showToast(error.message || error, "error");
        return;
    }
    downloadFileFromJson(JSONdata);
    hideLoading();
  }

  function downloadFileFromJson(JSONdata) {
    if(!JSONdata || JSONdata.error === "no_file" || !JSONdata.paymentData.length){
      showToast(`No json data provided for ${monthSelected}-${yearSelected} period`, "error");
    } else {
        const data = JSONdata.paymentData;
        const csv = utils.generateCsv(data);
        
        utils.downloadfile(csv, yearSelected, monthSelected);
    }
    
  }

  async function uploadStagedFile(event) {
    try {
      setStagedPayments(null);
      showLoading();
      const csvfile = event.target.files[0];

      if(csvfile) {
        Papa.parse(csvfile, {
          complete: async (json_data) => {
            if (json_data.data.length === 0) {
              hideLoading();
              showToast(
                "There was an error, no rows were found",
                "error"
              );
              return;
            }
            const data = await API.call({
              url: "/payments/stagedFile",
              method: "PUT",
              body: {
                month: monthSelected,
                year: yearSelected,
                stagingData: json_data.data,
              },
            });
    
            hideLoading();
            if (data.stageFileUpdated && data.duplicates === 0) {
              showToast("File updated. No Duplicates", "success");
            } else if(data.duplicates > 0) {
              showToast(`**** File updated **** Duplicates: ${data.duplicates}`, "warn");
            } else {
              showToast("Error in updating file", "error");
            }
          },
          header: true,
        });
      }
    } catch (error) {
      hideLoading();
      showToast(error.message, "error");
    }
    
  }


    return (
      <>
        <Dialog
          header={
            "Are you sure you want to run the payment cycle? Once started it cannot be stopped."
          }
          visible={runPaymentsConfirmation}
          style={{ width: "50vw" }}
          onHide={() => setRunPaymentsConfirmation(false)}
        >
          You will be running the payment cycle for:{" "}
          <b>
            {months.find((itm) => {
                  return itm.value === monthSelected;
                }).label +
                  " " +
                  yearSelected}
          </b>
          <div style={{ marginTop: "2em" }}>
            <Button
              onClick={(e) => {
                schedulePayments();
              }}
              icon="pi pi-check"
              label="Yes"
            ></Button>
            <Button
              onClick={(e) => {
                setRunPaymentsConfirmation(false);
              }}
              style={{ marginLeft: "1em" }}
              icon="pi pi-times"
              className="p-button-outlined"
              label="No"
            ></Button>
          </div>
        </Dialog>
        <div style={{ marginTop: "2em" }}>
          <div>
            <b>This process happens in 3 stages:</b>
            <ul className="stages">
              <li><b>1.</b> Generate the payments to review</li>
              <li><b>2.</b> You perform any checks/changes</li>
              <li><b>3.</b> Submit to run the payment cycle</li>
            </ul>
        </div>
          <p>
            Start off by selecting the month you would like to generate. For
            example, if you are in May and want to generate payments for all
            circles running in June, select June.
          </p>
          
          <GeneratePayments
            monthSelected={monthSelected} 
            setMonthSelected={setMonthSelected} 
            months={months} 
            generatePaymentsStaging={generatePaymentsStaging}
            yearSelected={yearSelected} 
            setYearSelected={setYearSelected} 
            years={years}
          />
          <br></br>
         
        <PerformChecks
          getStagedFile={getStagedFile}
          stagedPayments={stagedPayments}
          period={
            months.find((itm) => {
            return itm.value === monthSelected;
          }).label +
            " " +
            yearSelected}
          downloadStagedCSV={downloadStagedCSV}
          uploadStagedFile={uploadStagedFile} />
          <br></br>
         
        <SubmitPaymentCycle 
          months={months}
          showToast={showToast}
          period={{
            month: monthSelected,
            year: yearSelected
          }}
          onSubmit={() => setRunPaymentsConfirmation(true) }
          getFinal={getFinal}
          getIgnoredPayments={getIgnoredPayments}/>
        </div>
      </>
    );

}

export default AdminPaymentGenerator;
