import React, { useState } from "react";
import { Button } from "primereact/button";
import { createPartnerActivity } from "../../../services/partnerActivity";
import { useUser } from "../../../contexts/UserContext";


function CheckMyfileView({hide}) {
  const userContext = useUser();
  const user = userContext.user;
  const startTrial = async () => {
    try{
      await createPartnerActivity("checkMyFile", "platformClick", user.email);
      const link = "https://www.checkmyfile.com/?ref=stepladder&cbap=1&tap_s=2531037-f156f6";
      window.open(link, '_blank')
    } finally {
      hide();
    }
  }
  return (
    <React.Fragment>
     <div className="p-mt-3">
        <img
          className="center-img p-mt-5 p-mb-5"
          style={{borderRadius: "4px", height: "40px"}}
          alt="logo"
          src="./images/addons/logos/checkmyfile-long.png">
        </img>
        <h1 className="p-text-center p-mb-5 ">
          Do you know your credit history?
        </h1>
        <p className="p-text-center p-mt-6 p-mr-3 p-ml-3">
          CheckMyFile shows you what a lender can see about your credit history.
        </p>
        <p className="p-text-center p-mb-6 p-mr-3 p-ml-3">
          This means you can get prepared and make positive changes to start improving your credit score.
        </p>

        <p className="p-text-center p-mr-3 p-ml-3">
          <b>As a StepLadder Member you can access a 30-day free trial. The service then costs £14.99 a month and can be cancelled at any time.</b>
        </p>
        <div className="p-mt-5">
        </div>
        <Button label="Start my free trial" className="p-mt-5 p-button-raised p-button-info width-100" onClick={() => startTrial()} />
        <Button label="Cancel" className="p-mt-3 p-button-raised p-button-outlined width-100" onClick={() => hide()} />

     </div>
    </React.Fragment>
  );
}

export default CheckMyfileView;