import { ProgressSpinner } from "primereact/progressspinner";

function LoadingSpinner(){
    return (
        <div className="animate__animated animate__fadeIn" style={{ textAlign: "center", marginTop: "4em" }}>
          <ProgressSpinner
            style={{ width: "50px", height: "50px" }}
            strokeWidth="8"
            fill="#EEEEEE"
            animationDuration=".5s"
          />
        </div>
    )
}

export default LoadingSpinner;