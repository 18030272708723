import React from "react";
import { withRouter } from "react-router";
import "primeflex/primeflex.css";

import { ProgressSpinner } from "primereact/progressspinner";

class page extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  componentDidMount() {
    localStorage.removeItem("impersonation");

    if (!this.isLoggedIn()) {
      this.props.history.push("/adminPortal/login");
    } else {
      this.props.history.push("/adminPortal/home");
    }
  }

  isLoggedIn() {
    if (!localStorage.getItem("adminLoggedIn")) {
      return false;
    } else {
      return true;
    }
  }

  render() {
    return (
      <ProgressSpinner
        className="initial_loader"
        style={{
          overflowX: "hidden",
          overflowY: "hidden",
          width: "50px",
          height: "50px",
        }}
        strokeWidth="8"
        fill="#EEEEEE"
        animationDuration=".5s"
      />
    );
  }
}

var AdminLoading = withRouter(page);

export default AdminLoading;
