import { submitBankDetailsWithUserCheck } from "../../../../../services/payments";

const submitPaymentValues = async (base64File, values, userContext) => {
    if (base64File === "") {
      throw new Error("Please upload your I.D");
    }

    const bankDetailsObject = {
      sortCode: values.sortCode,
      accountNumber: values.accountNumber,
      addressLine1: values.billing_address
        ? userContext.user.address_line_1
        : values.firstLineOfAddress,
      postalCode: values.billing_address ? userContext.user.zip : values.zip,
      kycDocBase64: base64File,
      returnURL: window.location.origin + "/mangoPayReturn",
    };

    const response = await submitBankDetailsWithUserCheck(
      userContext.user,
      bankDetailsObject
    );

    if (response.userID) {
      userContext.updateUser({ userID: response.userID }, true);
    }

    if (response.error) {
      const errorMessages = {
        failed_bank_details:
          "Please check your bank details are correct. If they are, try uploading a different identity document",
        failed_user_creation:
          "We couldn't create you a Mangopay account. Please contact our membership team to fix this",
      };

      throw new Error(errorMessages[response.reason]);
    }

    return response;
  };

  export default submitPaymentValues;