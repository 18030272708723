import React,{useState, useEffect} from "react";
import "primeflex/primeflex.css";
import { Button } from "primereact/button";
import API from "../../services/api";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Dialog } from "primereact/dialog";
import AdminUserForm from "./adminUserForm";

function AdminAccessManagement({setTitle, setSubTitle, showLoading, hideLoading, showToast}) {
  const tempUser = {admin_role: 3, email: "", isNew: true};
  const [adminData, setAdminData] = useState([]);
  const [showEditUser, setShowEditUser] = useState(false);
  const [adminToEdit, setAdminToEdit] = useState(tempUser);
  const [deletionConfirmationVisible, setDeletionConfirmationVisible] = useState(false);
  // const [findUserData, setFindUserData] = useState([]);
  // const [findUserSearch, setFindUserSearch] = useState("");


  useEffect(() => {
    setSubTitle(`On this screen, you can add or remove users to the admin portal. Note
    that you cannot be an admin and a user due to security constraints.`);
    getadmins()
    setTitle("Manage Access");
  },[setSubTitle, setTitle])


  async function getadmins () {
    const data = await API.call({
      url: "/users/search?adminUsers=true",
      method: "GET",
    });
    setAdminData(data)
  }

  async function saveRoleChange() {
    showLoading();
    const data = await API.call({
      url: "/users/adminRole",
      method: "PUT",
      body: {
        userID: adminToEdit._id,
        roleID: adminToEdit.admin_role,
      },
    });

    if (data.updatedAdminRole) {
      showToast("Admin role updated!", "success");
      await getadmins();
      setShowEditUser(false)
      setDeletionConfirmationVisible(false)
    } else {
      showToast("Failed upadate admin role", "error");
    }

    hideLoading();
  }

  async function addAdmin() {
    showLoading();
    try {
      const data = await API.call({
        url: "/users/addAdmin",
        method: "POST",
        body: {
          email: adminToEdit.email,
          roleID: adminToEdit.admin_role,
        },
      });
      if(data.error) {
        showToast(`Failed add admin: ${data.error}`, "error");
      } else {
        showToast("Admin added!", "success");
        await getadmins();
        setShowEditUser(false)
      }
    } catch (error) {
      showToast(`Failed add admin ${error}`, "error");
    }

    hideLoading();
  }

  // async function findUser() {
  //   const user_data = await API.call({
  //     url: "/users/search?name=" + findUserSearch,
  //     method: "GET",
  //   });
  //   setFindUserData(user_data)
  // }
  function showAdminUserForm(editAdmin) {
    if(!editAdmin) {
      setAdminToEdit(tempUser);
    } else {
      setAdminToEdit(editAdmin);
    }

    setShowEditUser(true)
    
  }
    return (
      <React.Fragment>
        {!adminToEdit.isNew && <Dialog
          header={"Remove " + adminToEdit.email}
          visible={deletionConfirmationVisible}
          style={{ width: "50vw" }}
          onHide={() => setDeletionConfirmationVisible(false)}
        >
          <p>
            <b>
              Are you sure you want to remove this user from the admin portal?
            </b>
            <br></br>
            This will not remove the user from the database, it will just take
            away their admin rights.<br></br>
            <br></br>
            <div style={{ textAlign: "center" }}>
              <Button
                icon="pi pi-check"
                className="p-button-success"
                label="Yes"
                style={{ marginRight: "1em" }}
                onClick={(e) => {
                  const adminedit = adminToEdit;
                  adminedit.admin_role = 0;
                  setAdminToEdit(adminedit)
                  saveRoleChange();
                }}
              ></Button>
              <Button
                icon="pi pi-times"
                className="p-button-outlined"
                label="No"
                onClick={(e) => {
                  setDeletionConfirmationVisible(false)
                }}
              ></Button>
            </div>
          </p>
        </Dialog>}
        <Dialog
          header={`${!adminToEdit.isNew ? "Edit " + adminToEdit.email : "Add new admin"}`}
          visible={showEditUser}
          style={{ width: "50vw" }}
          onHide={() => setShowEditUser(false)}
        >
         <AdminUserForm
          adminToEdit={adminToEdit}
          setAdminToEdit={setAdminToEdit}
          saveRoleChange={saveRoleChange}
          addAdmin={addAdmin}
          setDeletionConfirmationVisible={setDeletionConfirmationVisible}
         />
        </Dialog>
        <div>
          <b>Role permissions:</b>
          <br></br>
          <ul>
            <li>1: Admin (all permissions)</li>
            <li>2: Moderators (no payments access, but can edit users)</li>
            <li>3: View Permissions (read only access for customers)</li>
          </ul>
        </div>
        
        <hr className="p-mb-6 p-mt-6"></hr>
        <Button
          className="p-button-outlined p-button-info p-button-raised"
          icon="pi pi-plus"
          label="Add Admin"
          onClick={() => {
            showAdminUserForm()
          }}
        >
        </Button>
        <DataTable
          onSelectionChange={(e) => {
            showAdminUserForm(JSON.parse(JSON.stringify(e.value)))
            
          }}
          selectionMode="single"
          dataKey="id"
          value={adminData}
          style={{ marginTop: "2em" }}
        >
          <Column field="_id" header="User ID"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="admin_role" header="Role"></Column>
        </DataTable>
      </React.Fragment>
    );
}

export default AdminAccessManagement;
