import React, { useState } from "react";
import PlatformContentContainer from "../../_generic/platformContentContainer";
import { Calendar } from 'primereact/calendar';
import { Button } from "primereact/button";
import {useHistory} from "react-router";
import moment from "moment";
import AddonsNavigation from "./common/navigation";
import { useUser } from "../../../../contexts/UserContext";


function HomeBuying() {
    const [buyingDate, setBuyingDate] = useState(null);
    const history = useHistory();
    const userContext = useUser();
    
    async function goTo() {
        const home_buying_goal_date = moment(buyingDate).valueOf();
        await userContext.updateUser({home_buying_goal_date});
        let option = 1;
        if(buyingDate) {
            if(moment.duration(moment(buyingDate).diff(moment())).asYears() > 1) {
                option = 2;
            }
            history.push(`/dashboard/homehelpoptions?option=${option}`)
        }
    }

    return (
        <PlatformContentContainer className="platform_small p-mt-lg-4">
            <AddonsNavigation title="Lifetime ISA" />
            <div className="blueBox investor p-mb-3">
                <p>
                    Amazing! We’re so excited to help you on the journey.
                </p>
                <p>
                    So that we can give you the best possible help, let us know roughly when you think you might be ready to buy...
                </p>
            </div>
            <div className="p-grid">
                <div className="center p-col-12 p-md-9">
                    <Calendar
                        className="calendar max-width-600"
                        showIcon={true}
                        value={buyingDate}
                        onChange={(e) => setBuyingDate(e.value)}
                        yearNavigator
                        yearRange="2022:2030"
                    />
                </div>
                <Button label="Next" disabled={!buyingDate} className="p-col-12 p-md-2 p-pr-lg-5 p-pl-lg-5 p-button p-button-info p-button-raised center-el" onClick={() => goTo()}/>

            </div>
        </PlatformContentContainer>
    );
}

export default HomeBuying;