
const HLTableData = [
    {
        deposit: "£500",
        bonus: "£125",
        newTotal: "£625",
    },
    {
        deposit: "£1,000",
        bonus: "£250",
        newTotal: "£1,225",
    },
    {
        deposit: "£4,000",
        bonus: "£1,000(max)",
        newTotal: "£5,000",
    }
];

const options = {
    checkMyFile: {
        logo: "./images/addons/logos/check-my-file.png",
        logoTitle: "Start your free trial with Check My File",
        texts: [
            {
                type: "",
                text: "CheckMyFile shows you what a lender can see about your credit history. This means you can get prepared and make positive changes to start improving your credit score."
            },
            { 
                type: "b",
                text: "As a StepLadder Member you can access a 30-day free trial. The service then costs £14.99 a month and can be cancelled at any time."
            }
        ],
        button: {
            link: "https://www.checkmyfile.com/?ref=stepladder&cbap=1&tap_s=2531037-f156f6",
            name: "Start Free Trial",
            trackName: "checkMyFile"
        }
    },
    hargreavesL: {
        logo: "./images/addons/logos/hargreaves-lansdown.png",
        logoTitle: "Start today with Hargreaves Lansdown",
        texts: [
            {text:"The Lifetime ISA is a savings scheme that gives you an extra 25% boost to everything you save each year.", type: ""},
            {text:"The maximum top up you could get is £1,000 if you deposit £4,000.", type: ""},
            {text:"Your Lifetime ISA can only be used to purchase your first house or for your retirement at 60. If you use it for any other purpose you will lose your bonus.", type: "i"}
        ],
        table: HLTableData,
        button: {
            link: "https://hl.7eer.net/rnye55",
            name: "Open a LifeTime ISA",
            trackName: "hargreavesLansdowne"
        }
    },
    muve: {
        logo: "./images/addons/logos/muve.png",
        logoTitle: "Speak to a Solicitor",
        texts: [
            {
                type: "",
                text: "Solicitors are an essential part of the home buying journey. They complete the legal work to exchange contracts and ensure that the house is legally in your name."
            },
            { 
                type: "b",
                text: "As a StepLadder Member you get access to your own dedicated contact, Frances, who can show you how to confidently navigate this process."
            }
        ],
        button: {
            link: "",
            name: "Connect Me",
            trackName: "muve"
        }
    },
    fairfax: {
        logo: "./images/addons/logos/fairfax.png",
        logoTitle: "Start today with Fairfax Financial",
        texts: [
            {
                type: "",
                text: "Mortgage brokers can help save you time and get the best deal on your mortgage."
            },
            { 
                type: "b",
                text: "Andrew at Fairfax has helped many StepLadder Members get their mortagage and achieve the dream of owning their own home. Have a chat with him today..."
            }
        ],
        button: {
            link: "",
            name: "Connect Me",
            trackName: "fairfaxFinancial"
        }
    },
}

const helpOptions = {
    earn50: {
        logo: "./images/addons/friends.jpg",
        logoTitle: "Earn £50, Invite Your Friends",
        texts: [
            {
                text: "Why don’t you refer your friends to join you and we’ll reward you with £50 every time somebody signs up."
            },
            {
                text: "You will be rewarded when somebody who signs up using your link joins and makes their first 3 payments."
            }
        ],
        button: {
            link: "/dashboard/referfriend",
            name: "Invite My Friends"
        }
    }, 
    toptips: {
        logo: "./images/addons/debt.jpg",
        logoTitle: "Read Our Top Tips in Paying Off Debt",
        texts: [
            {
                text: "Debt can be hard to address, but it helps to have a strategy, read some of our tips on how to tackle your debt."
            }
        ],
        button: {
            link: "https://blog.joinstepladder.com/time-to-tackle-some-debt",
            name: "Read Top Tips"
        }
    },
}

const addonsConfig = {
    options,
    helpOptions
};

export default addonsConfig;