import React, {useEffect} from "react";
import { Switch, Route, useHistory} from "react-router-dom";
import { useState } from "react";


import ArticlesMain from "./articles/articlesMain";
import CirclesMain from "./circles/CirclesMain";
import HelpMain from "./help/helpMain";
import MobileNav from "./_generic/menus/mobileNav";
import DashboardDesktopMenu from "./_generic/menus/topMenu";
import { useUser } from "../../contexts/UserContext";
import AmbassadorMain from "./ambassador/ambassadorMain";
import ReferFriend from "./circles/addons/referFriend";
import HomeBuying from "./circles/addons/homebuying";
import HomeBuyHelpOptions from "./circles/addons/common/homeBuyHelpOptions";
import ISA from "./circles/addons/ISA";

import DrawDay from "./drawDay/index";
import WhatUserStage from "./drawDay/whatUserStage";
import CardDetails from "./drawDay/cardDetails";
import LastSreensWrapper from "./drawDay/lastSreensWrapper";
import PartnersViewChip from "./drawDay/partnersViewChip";
import PartnersView from "./drawDay/partnersView";
import PartnersViewFairfax from "./drawDay/partnersViewFairfax";
import PartnersViewHargreaves from "./drawDay/partnersViewHargreaves";
import ContinueBuilding from "./drawDay/continueBuilding";
import { addMeta } from "../../services/meta";

function DashboardMain() {
  const userContext = useUser();
  const user = userContext.user;
  const history = useHistory();

  const [showDrawDay] = useState(user.drawDayReady);

  useEffect(() => {
    if(showDrawDay) {
      history.push("/dashboard/drawday");
    }
  }, [history, showDrawDay, userContext]);
  
  useEffect(() => {
    addMeta({ DashboardSeen: true });
  });


  return (
    <React.Fragment>
      <DashboardDesktopMenu></DashboardDesktopMenu>
      <Switch>
        <Route path="/dashboard/help" component={HelpMain} />
        <Route path="/dashboard/education" component={ArticlesMain} />
        <Route path="/dashboard/marketplace/:group?" component={ArticlesMain} />
        <Route path="/dashboard/ambassadors" component={AmbassadorMain} />
        <Route path="/dashboard/referfriend" component={ReferFriend} />
        <Route path="/dashboard/homehelpoptions" component={HomeBuyHelpOptions} />
        <Route path="/dashboard/homebuying" component={HomeBuying} />
        <Route path="/dashboard/isa" component={ISA} />
        {/* <Route path="/dashboard/debthelp" component={DebtHelp} /> */}
        <Route path="/dashboard/drawday/cardinfo/:id?" component={CardDetails} />
        <Route path="/dashboard/drawday/whatuserstage" component={WhatUserStage} />
        <Route path="/dashboard/drawday/carddetails" component={WhatUserStage} />
        <Route path="/dashboard/drawday/lastSreens" component={LastSreensWrapper} />
        <Route path="/dashboard/drawday/partners" component={PartnersView} />
        <Route path="/dashboard/drawday/chip" component={PartnersViewChip} />
        <Route path="/dashboard/drawday/partnersViewFairfax" component={PartnersViewFairfax} />
        <Route path="/dashboard/drawday/partnersViewHargreaves" component={PartnersViewHargreaves} />
        <Route path="/dashboard/drawday/continueBuilding" component={ContinueBuilding} />


        <Route path="/dashboard/drawday" component={DrawDay} />

        {/* very important to have dashoboard at the bottom as platform redirect firstly here /dashboard */}
        <Route path="/dashboard" component={CirclesMain} />
        
      </Switch>
      <MobileNav />
    </React.Fragment>
  );
}

export default DashboardMain;
