import { useHistory } from "react-router";
import menuOptions from "./menuOptions";


function MobileNav() {
  const history = useHistory();
  const pathname = history.location.pathname;

  const routes = menuOptions;

  return (
    <div className="platformMobileMenu">
      {routes.map((itm, i) => {
        return (
          <div
            onClick={() => history.push(itm.path)}
            key={i}
            className={"transition" + (pathname === itm.path ? " active" : "")}
          >
            <i className={itm.icon}></i>
            <div>{itm.text}</div>
          </div>
        );
      })}
    </div>
  );
}

export default MobileNav;
