import { Button } from "primereact/button";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import React, { useEffect } from "react";
import { useState } from "react";
import AmbassadorService from "../../../services/ambassadorService";
import adminDashboardStyles from "../../../style/admin_dashboard.module.css";

function UpdateAmbassadorSettings(props) {
  const [ambassador, setAmbassador] = useState({});

  useEffect(() => {
    setAmbassador(props.ambassadorObject);
  }, [props.ambassadorObject]);

  const newAmbassador = props.newAmbassador;

  const updateAmbassador = async () => {
    props.showLoading();

    const response = await AmbassadorService.updateAmbassador(ambassador);

    if (response.ambassadorUpdated) {
      props.showToast("Ambassador updated!", "success");
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      props.showToast(
        "Failed to update ambassador: " + response.error
          ? response.error
          : "generic error"
      );
    }
  };

  const createNewAmbassador = async () => {
    if (ambassador.ambassadorName.trim() === "") {
      return props.showToast("Please enter a valid ambassador name", "error");
    }

    props.showLoading();

    const response = await AmbassadorService.createAmbassador({ ...ambassador, ambassadorName: ambassador.ambassadorName.trim() });

    if (response.ambassadorCreated) {
      props.showToast("Ambassador created!", "success");
      window.setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      props.showToast(
        "Failed to create ambassador: " + response.error
          ? response.error
          : "generic error",
        "error"
      );
    }
  };

  const updateLocalAmbassadorObject = (key, value) => {
    let ambObject = ambassador;

    ambObject[key] = value;

    setAmbassador({...ambObject});
  };

  return (
    <React.Fragment>
      <div className={adminDashboardStyles.modalInputBox}>
        <div>Ambassador Name</div>
        <InputText
          tooltip="This is a unique code used to show ambassador information in the platform"
          onChange={(e) => {
            updateLocalAmbassadorObject("ambassadorName", e.target.value);
          }}
          disabled={!newAmbassador}
          value={ambassador.ambassadorName || ''}
        ></InputText>
      </div>

      <div className={adminDashboardStyles.modalInputBox}>
        <div>Circle Image URL</div>
        <InputText
          onChange={(e) => {
            updateLocalAmbassadorObject("circleImage", e.target.value);
          }}
          tooltip="The image in the middle of a Circle, usually a house"
          value={ambassador.circleImage || ''}
        ></InputText>
      </div>

      <div className={adminDashboardStyles.modalInputBox}>
        <div>Ignore FMC Check When Users Join The Circle?</div>
        <div className="p-field-checkbox">
          <Checkbox
            inputId="binary"
            checked={ambassador.ignoreFMCForAvailability}
            onChange={(e) =>
              updateLocalAmbassadorObject("ignoreFMCForAvailability", e.checked)
            }
          />
          <label htmlFor="binary">
            {ambassador.ignoreFMCForAvailability ? "Yes" : "No"}
          </label>
        </div>
      </div>
      <div style={{ marginTop: "15px" }}>
        <Button
          label="Save Changes"
          onClick={(e) => {
            return newAmbassador ? createNewAmbassador() : updateAmbassador();
          }}
        ></Button>
      </div>
      <hr></hr>
      <br></br>
      <br></br>
      <b>Code To Put In Website Landing Pages</b>
      <div>
        This code will set a cookie in the .com landing page, which tells the
        application to show Ambassador Circles only. Only available on
        joinstepladder domains.
      </div>
      <textarea
        value={
          "<script>var myDate = new Date(); myDate.setDate(myDate.getDate() + 45); document.cookie = 'ambassador=" +
          ambassador.ambassadorName +
          ";expires=' + myDate + ';domain=.joinstepladder.com;path=/';</script>"
        }
        onChange={(e) => { }}
        style={{ width: "100%", height: "9em", resize: "none" }}
      ></textarea>
    </React.Fragment>
  );
}

export default UpdateAmbassadorSettings;
