import React from "react";
import "primeflex/primeflex.css";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import utils from "../../../utils/utils";
import moment from "moment";

function TransfersList(props) {
  const statuses = {
    SUCCEEDED: "success",
    FAILED: "danger",
    "FAILED VALIDATION": "danger",
  };

  const date = (date, isShort) => {
    const format = isShort ? "MM-YYYY" : "DD-MM-YYYY";
    return date ? moment.utc(date).format(format) : "-";
  };

  const balance = (balance) => {
    return utils.currencyFormat(balance / 100);
  };

  const amount = (rowData) => {
    return balance(rowData.Funds);
  };

  const creationDate = (rowData) => {
    return date(rowData.CreationDate);
  };

  const executionDate = (rowData) => {
    return date(rowData.ExecutionDate);
  };

  const errorMessage = (rowData) => {
    return rowData.error || "-";
  };

  const status = (rowData) => {
    const classStatus = `p-tag p-tag-rounded text-xxs p-tag-${
      statuses[rowData.Status] || "info"
    }`;
    return (<span className={classStatus}>{rowData.Status}</span>);
  };

  const isLOCFromWallet = (rowData) => {
    return isLOCWallet(rowData.WalletId);
  };

  const isLOCToWallet = (rowData) => {
    return isLOCWallet(rowData.TransferTo);
  };

  const isLOCWallet = (wallet) => {
    return (
      <span
        className={
          wallet === "LOCWALLET" ? "p-tag p-tag-rounded p-tag-info" : ""
        }
      >
        {wallet}
      </span>
    );
  };

  return (
    <DataTable value={props.transfers}>
        <Column field="AuthorId" header="AuthorId"></Column>
        <Column field="CreationDate" header="CreationDate" body={creationDate} ></Column>
        <Column field="ExecutionDate" header="ExecutionDate" body={executionDate} ></Column>
        <Column field="PaymentID" header="PaymentID"></Column>
        <Column field="error" header="Error" body={errorMessage}></Column>
        <Column field="Status" header="Status" body={status}></Column>
        <Column field="Funds" header="Amount" body={amount}></Column>
        <Column field="WalletId" header="Transfer From" body={isLOCFromWallet}></Column>
        <Column field="TransferTo" header="Transfer To" body={isLOCToWallet}></Column>
    </DataTable>
  );
}

export default TransfersList;
