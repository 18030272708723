import React from "react";
import "primeflex/primeflex.css";
import { useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";

function AdminCreditCheckTable(props) {
    const [globalFilter, setGlobalFilter] = useState(null);

  const isWaitingForCreditCheck = (userObject) => {
    return !userObject.creditCheckScore;
  };

  const rowSelected = (userObject) => {
    props.showCreditCheckScreen(
      JSON.parse(JSON.stringify(userObject)),
      isWaitingForCreditCheck(userObject)
    );
  };

  const creditCheckData = () => {
    const isWaiting = props.filter === "waiting";

    return props.data.filter((itm) => {
      return isWaitingForCreditCheck(itm) === isWaiting;
    });
  };

  const header = (
    <div className="table-header">
         <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search for anything" />
    </div>
);

  return (
    <DataTable
      value={creditCheckData()}
      onSelectionChange={(e) => {
        rowSelected(e.value);
      }}
      header={header}
      selectionMode="single"
      dataKey="_id"
      globalFilter={globalFilter}
    >
      <Column
        sortable
        header="Name"
        body={(row) => {
          return row.firstname + " " + row.lastname;
        }}
      ></Column>
      <Column sortable field="email" header="Email"></Column>
      <Column
        sortable
        header="Status"
        body={(row) => {
          const configOptions = [
            {
              status: "waiting",
              label: "Waiting",
              color: "#e8e8e8",
            },
            {
              label: "Credit Check 2",
              status: "2ndReview",
              color: "#ffcccc",
            },
          ];

          const status = isWaitingForCreditCheck(row) ? "waiting" : "2ndReview";
          const foundConfigOption = configOptions.find((itm) => {
            return itm.status === status;
          });
          return (
            <span
              style={{
                padding: "0.5em",
                borderRadius: "0.5em",
                fontSize: "12px",
                backgroundColor: foundConfigOption.color,
              }}
            >
              {foundConfigOption.label}
            </span>
          );
        }}
      ></Column>
      <Column sortable field="creditCheckScore.equifax" header="Equifax"></Column>
      <Column
        sortable
        header="AML"
        field="creditCheckScore.AML"
        body={(row) => {
          if (!row.creditCheckScore) {
            return "";
          }
          return (
            row.creditCheckScore.AMLAddress +
            "-" +
            row.creditCheckScore.AMLIdentity +
            "-" +
            row.creditCheckScore.AMLRisk
          );
        }}
      ></Column>
      <Column
        sortable
        field="f_have_you_ever_experienced_iva_ccj_repossession_or_bankruptcy_"
        header="IVA"
      ></Column>
    </DataTable>
  );
}

export default AdminCreditCheckTable;
