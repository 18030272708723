import { InputText } from "primereact/inputtext";
import { InputMask } from "primereact/inputmask";
import React from "react";
import { useState } from "react";
import SelectInput from "./selectInput";
import SliderInput from "./sliderInput";
import InfoTooltip from "../../InfoTooltip";
import InputError from "./inputError";

function TextInput(props) {
  const id = Math.random();
  const [isFocussed, setIsFocussed] = useState(false);
  const formObject = props.formObject;

  const inputType = !formObject.type
    ? "text"
    : ["email", "text", "number"].indexOf(formObject.type) === -1
    ? "text"
    : formObject.type;

  const onFocus = () => {
    if(props.onFocus) {
      props.onFocus()
    }
    setIsFocussed(true)
  }
  const onBlur = () => {
    if(props.onBlur) {
      props.onBlur()
    }
    setIsFocussed(false)
  }

  return (
    <React.Fragment>
      <div className={"textInput" + (isFocussed ? " focus" : "")}>
        {formObject.tooltip && <InfoTooltip text={formObject.tooltip} />}
        <span className="p-float-label width-100">
        { props.img && <img src={props.img} alt="directdebit" className="input-img mobile_only"/>}
          {!formObject.mask ? (
            <InputText
              value={formObject.slider && !isFocussed ? "£"+props.value.toLocaleString() : props.value}
              id={id}
              onFocus={onFocus}
              onBlur={onBlur}
              onChange={(e) => props.onChange(e.target.value)}
              type={inputType}
              readOnly={formObject.inputType === "select" || props.readOnly}
            />
          ) : (
            <InputMask
              value={props.value}
              id={id.toString()}
              onChange={(e) => props.onChange(e.target.value)}
              type={inputType}
              slotChar={formObject.label.indexOf("Phone") > -1 ? "_" : formObject.mask}
              mask={formObject.mask.split("").map((letter) => letter.match(/[a-z]/i) ? "9" : letter).join("")}
              onFocus={onFocus}
              onBlur={onBlur}
            />
          )}

          <label htmlFor={id} style={{marginRight: "1.5em"}}>{formObject.label}</label>
        </span>
        {formObject.inputType === "select" && (
          <SelectInput
            onChange={(theValue) => props.onChange(theValue)}
            value={props.value}
            options={formObject.options}
          ></SelectInput>
        )}
        {formObject.slider && <SliderInput formObject={formObject} onChange={(theValue) => props.onChange(theValue)} max={formObject.max} value={props.value}></SliderInput>}
      </div>
      <InputError showValidation={props.showValidation} value={props.value} formObject={formObject} />
      
    </React.Fragment>
  );
}

export default TextInput;
