import { Button } from "primereact/button";
import React from "react";
import { Dropdown } from "primereact/dropdown";
import { Card } from 'primereact/card';

function GeneratePayments({monthSelected, setMonthSelected, months, generatePaymentsStaging, yearSelected, setYearSelected, years}) {

    return (
        <Card 
            className="sl-card"  
            title="1. Generate payments to review">
            <Dropdown
                value={monthSelected}
                options={months}
                onChange={(e) => {
                    setMonthSelected(e.value);
                }}
                placeholder="Select a Month"
              />
            <Dropdown
                className="p-ml-4"
                value={yearSelected}
                options={years}
                onChange={(e) => {
                    setYearSelected(e.value);
                }}
                placeholder="Select a Year"
            />
            <Button
                label="Generate Payment Review"
                className="p-button-info p-button-raised p-ml-4"
                onClick={(e) => generatePaymentsStaging()}>
            </Button>
            <Button
                label="Generate Payment Second Review"
                className="p-button-info p-button-raised p-ml-4"
                onClick={(e) => generatePaymentsStaging(true)}>
            </Button>
          </Card>
    );
}

export default GeneratePayments;