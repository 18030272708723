import React, { useState, useEffect } from "react";
import { Sidebar } from 'primereact/sidebar';

import { Button } from 'primereact/button';
import {InputSwitch} from 'primereact/inputswitch';

function AdminConfig({initialize, adminConfig, setAdminConfig, user}) {
    const [showAdminSideBar, setShowAdminSideBar] = useState(false);
  
    const isImpersonating = !!localStorage.getItem("impersonation");


    const getUserName = () => {
        const userName = `${user?.firstname} ${user?.lastname}`;
        return userName.substring(0, 12);
    }
    const hideShowDrawDay = (showDrawday) => {
        if(!showDrawday) {
        setAdminConfig({...adminConfig, showDrawday, fsDrawn: false, hcDrawn: false})
        } else {
        setAdminConfig({...adminConfig, showDrawday})
        }
    }

    function returnToAdminPortal() {
        localStorage.removeItem("impersonation");
        localStorage.removeItem("user_logged_in_before");
        window.location = "/adminPortal/user?id=" + user._id;
    }

    const header = (
        <>
           <h4 className="flex-wide">
              Admin Settings
            </h4>
        </>
    );

  return (
    <>
    {isImpersonating && user && (
            <>
            <div
              className="p-shadow-1 admin-view-header"
            >
              <div className="p-grid p-m-0">
                <div className="p-col-4" style={{maxWidth: "150px"}}>
                  <Button
                    label="Go to admin"
                    className="p-badge p-button-outlined p-button-help"
                    onClick={() => returnToAdminPortal()} />
                </div>
                <div className="p-col-7">
                  Logged in as:{" "}
                  <span
                    className="p-tag p-tag-rounded p-tag-success">
                      {getUserName()}
                  </span> user.
                </div>
              </div>
            </div>
            <Button
              icon="pi pi-bars"
              className="p-button-rounded p-button-text admin-settings-btn"
              onClick={() => {setShowAdminSideBar(true); console.log(showAdminSideBar)}} />
            <Sidebar icons={header} visible={showAdminSideBar} position="right" onHide={() => setShowAdminSideBar(false)}>

              <div className="p-grid p-m-0">
                  <div className="p-col-9 center-el">Show draw day</div>
                  <div className="p-col">
                  <InputSwitch
                    checked={adminConfig.showDrawday}
                    onChange={(e) => hideShowDrawDay(e.value)} />
                  </div>
              </div>
              <div className="p-grid p-m-0">
                  <div className="p-col-9 center-el">Show first step drawn</div>
                  <div className="p-col">
                  <InputSwitch
                    disabled={!adminConfig.showDrawday}
                    checked={adminConfig.fsDrawn}
                    onChange={(e) => setAdminConfig({...adminConfig, fsDrawn: e.value})} />
                  </div>
              </div>
              <div className="p-grid p-m-0">
                  <div className="p-col-9 center-el">Show home circle drawn</div>
                  <div className="p-col">
                  <InputSwitch
                    disabled={!adminConfig.showDrawday}
                    checked={adminConfig.hcDrawn}
                    onChange={(e) => setAdminConfig({...adminConfig, hcDrawn: e.value})} />
                  </div>
              </div>
              <div className="p-grid p-m-0">
                  {/* <div className="p-col-9 center-el">Show home circle drawn</div> */}
                  <div className="p-col">
                  <Button
                    label="Apply changes"
                    className="p-button p-button-info p-button-rounded p-mt-4 center-el"
                    onClick={() => {initialize(); setShowAdminSideBar(false)}} />
                  </div>
              </div>
                
                
            </Sidebar>
            </>
            
          )}
    </>
  );
}

export default AdminConfig;
