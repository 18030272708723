import moment from "moment-timezone";
import React from "react";
import { useUser } from "../../../../../contexts/UserContext";
import { currencyFormat } from "../../../../../utils/generic";
import PlatformTitle from "../../../_generic/platformTitle";

function CircleInfoMain(props) {
  const userContext = useUser();

  const circle = props.circle;

  const summaryBoxes = [
    {
      text: "Circle ID",
      number: circle.excelID,
    },
    {
      text: "Reserve Period",
      number:
        circle.reserve_period +
        " month" +
        (circle.reserve_period.length > 1 ? "s" : ""),
    },
    {
      text: "Monthly Amount",
      number: currencyFormat(circle.monthly_investment),
    },
    {
      text: "Circle Length",
      number: circle.period + " months",
    },
    {
      text: "Total Amount",
      number: currencyFormat(circle.monthly_investment * circle.period),
    },
    {
      text: "Members",
      number: circle.participants.length,
    },
    {
      text: "Start Date",
      number: moment(circle.startingDate).format("MMM YYYY"),
    },
    {
      text: "End Date",
      number: moment(circle.endingDate).format("MMM YYYY"),
    },
    {
      text: "Circle Loan",
      number: "Unsecured",
    },
    {
      text: "Interest Rate",
      number: "0%",
    },
    {
      text: "Circle Defaults",
      number: 0,
    },
    {
      text: "Max Loss From Defaults",
      number: "£0",
    },
  ];

  const circleTerms = [
    {
      text: "Download",
      number: "Loan Agreement",
      link: "https://f.hubspotusercontent20.net/hubfs/2442073/Agreements/January%202021/StepLadder%20Loan%20Agreement%20November%202020.pdf",
    },
    {
      text: "Download",
      number: "Member Agreement",
      link: "https://f.hubspotusercontent20.net/hubfs/2442073/Agreements/January%202021/StepLadder%20Membership%20Agreement%20November%202020.pdf",
    },
    {
      text: "Download",
      number: "Credit Reference Agreement",
      link: "https://f.hubspotusercontent20.net/hubfs/2442073/Agreements/January%202021/StepLadder%20Credit%20Reference%20Agreement%20November%202020.pdf",
    },
    {
      text: "Download",
      number: "Forum Rules Agreement",
      link: "https://f.hubspotusercontent20.net/hubfs/2442073/Agreements/January%202021/StepLadder%20Forum%20Rules%20Agreement%20November%202020.pdf",
    },
    {
      text: "Download",
      number: "Terms",
      link:
        process.env.REACT_APP_SERVER_URL + "/circles/termsDocument?id=" +
        circle.excelID + (userContext?.user?.email ? "&email=" + userContext.user.email : "") ,
    },
  ];

  return (
    <React.Fragment>
      <PlatformTitle title="Circle Information" />
      <SummaryBoxTwoPerLine data={summaryBoxes} />

      <PlatformTitle title="Your Circle Documents" />
      <SummaryBoxTwoPerLine data={circleTerms} />
    </React.Fragment>
  );
}

function SummaryBoxTwoPerLine(props) {
  const data = props.data;

  return (
    <div className="summaryBoxes twoPerLine">
      {data.map((itm, i) => {
        const element = (
          <div key={i}>
            <div className="number">{itm.number}</div>
            <div className="description">{itm.text}</div>
          </div>
        );

        if (!itm.link) {
          return element;
        }

        return <a href={itm.link} target="_blank" rel="noopener noreferrer">{element}</a>;
      })}
    </div>
  );
}

export default CircleInfoMain;
