import { useState } from "react";
import CenterMiddle from "../../../generic/CenterMiddle";
import InfoTooltip from "../../../generic/InfoTooltip";

import everyDayInvestor from "./InvestorSelection/everyDay";
import highNetWorth from "./InvestorSelection/highNetWorth";
import selfCertifiedInvestor from "./InvestorSelection/selfCertified";
import certifiedSophisticated from "./InvestorSelection/certifiedSophisticated";
import StickyButtons from "../../../generic/stickyButtons";
import { useUser } from "../../../../contexts/UserContext";
import { useUI } from "../../../../contexts/UIContext";
import { useHistory } from "react-router";
import { getNextRoute } from "../../../../utils/userRoutes";

function InvestorClassificationDetails({selectedOption, back}) {
    const {showLoading, hideLoading, showToast} = useUI();
    const history = useHistory();
    const [btnsHeight, setBtnsHeight] = useState(0);
    const [formList, setFormlist] = useState([]);
    const [isDisabled, setIsDisabled] = useState(false);
    const userContext = useUser();
    const user = userContext.user;
    const {Details} = selectedOption;
    const {Acceptance} = selectedOption

    const submit = async () => {
        try{
            showLoading()
            const status = await updateUserDetails();
            if (status !== 200) {
                showError()
            } else {
                const { status: updateStageStatus } = await userContext.updateUser({
                    nextStage: true,
                });
                if (updateStageStatus !== 200) {
                    showError()
                } else {
                    history.push(getNextRoute(userContext.user))
                }
            }

        } finally {
            hideLoading()
        }
      };

      const showError = () => {
        showToast(
          "There was an error in submitting the form, please refresh or contact us to fix it"
        );
      };

      const updateUserDetails = async () => {
        const updateObject = {
            TypeOfInvestor: selectedOption.type
        }

        if(selfCertifiedInvestor.type === selectedOption.type || certifiedSophisticated.type === selectedOption.type) {
            updateObject.sertifiedInvestorExperience = formList.map(({type, answer}) => ({type, answer}));
        }

        if(highNetWorth.type === selectedOption.type || everyDayInvestor.type === selectedOption.type) {
            const newFormList = {};
            for (const {key, value} of formList) {

                if (value === "") {
                    newFormList[key] = 0;
                } else {
                    newFormList[key] = value
                }
            }

            if(newFormList.applicantSalary !== user.applicant_salary) {
                updateObject.applicant_salary = newFormList.applicantSalary
            }
            if(newFormList.debt_question !== user.how_much_debt_do_you_have_) {
                updateObject.how_much_debt_do_you_have_ = newFormList.debt_question
            }
            if(newFormList.savingsAndAssets !== user.savings_and_assets) {
                updateObject.savings_and_assets = newFormList.savingsAndAssets
            }

        }
        const { status } = await userContext.updateUser(updateObject);

      return status;
    }
            


  return (
    <CenterMiddle>
        <Details
            back={back}
            user={user}
            setFormlist={setFormlist}
            isDisableNext={setIsDisabled} />
    
        <div className="investorSubTitle">
            Acceptance Statement{" "}
            <InfoTooltip
            text={
                "We ask you to accept this statement as requested by the FCA. Contact us if you have any questions."
            }
            position={window.innerWidth > 600 ? "right" : "left"}
            blueTooltip={true}
            />
        </div>

        <div
            className="blueBox investor"
            style={{ maxHeight: "340px", overflowY: "auto", overflowX: "visible", paddingBottom: btnsHeight }}>
            <Acceptance />
        </div>
            
        <StickyButtons
            isDisabled={isDisabled}
            nextText={"Next"}
            nextClick={submit}
            getBtnHeightFn={setBtnsHeight}
            />
    </CenterMiddle>
  );
}

export default InvestorClassificationDetails;
