import React from "react";
import PlatformContentContainer from "../../../_generic/platformContentContainer";
import OptionItem from "./optionItem";
import addonsConfig from "./addonsConfig";
import AddonsNavigation from "./navigation";

function HomeBuyHelpOptions({location}) {
    const {checkMyFile, hargreavesL, muve, fairfax } = addonsConfig.options
    const options = [checkMyFile];
    let title = "Excellent, so there’s a few people you’ll need to help you in the process..."
    const option = +location.search.replace("?option=", "");

    if(option === 1) {
        options.push(muve);
        options.push(fairfax);
    }

    if(option === 2) {
        title = "Great! We’ve got some things that may be able to help you!";
        options.push(hargreavesL);
    }
    return (
        <PlatformContentContainer className="platform_small p-mt-lg-4">
            <AddonsNavigation title="Buying a Home" />
            <h3>{title}</h3>
            {options.map((itm) => (
                <OptionItem option={itm}/>
            ))}
        </PlatformContentContainer>
    );
}

export default HomeBuyHelpOptions;