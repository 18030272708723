import React from "react";
import { withRouter } from "react-router";
import "primeflex/primeflex.css";
import API from "../../../services/api";
import { TabView, TabPanel } from 'primereact/tabview';
import FailedPaymentList from "./failedPaymentList";
import FailedPaymentSpotsList from "./failedPaymentSpotsList";

import utils from "../../../utils/utils";
import {Checkbox} from 'primereact/checkbox';
import Arrears from "./arrears/arrears";
import Dropouts from "./dropouts/Dropouts";
import PreNosia from "./arrears/columnComponents/PreNosia";

class page extends React.Component {
  constructor(props) {
    super(props);
    this.props.setTitle("Failed payments")
    this.state = {
        activeIndex: 0,
        payments: [],
        filteredPayments: [],
        arrears: null,
        checked: false,
        loading: false,
        loadingArrears: false
    };
    this.showActive = this.showActive.bind(this);
  }

  // async componentDidMount() {
  //   this.getFailedPaymentsList();
  // }

  async getArrears() {
    if(!this.state.arrears) {
      this.setState({ loadingArrears: true });
      const arrears = await API.call({
        url: "/payments/arrears",
        method: "GET",
      });

      console.log(arrears);

      const mappedArrears = arrears.map(ar => {
        return {...ar, preNOSIA: PreNosia(ar)};
      })

      console.log("mapped", mappedArrears);
  
      this.setState({ arrears: mappedArrears, loadingArrears: false });
    }
  }

  // async getFailedPaymentsList() {
  //   this.setState({ loading: true });
  //   const data = await API.call({
  //     url: "/payments/getFailedPaymentsList",
  //     method: "GET",
  //   });

  //   this.setState({ payments: data.payments, filteredPayments: data.payments, loading: false });
  // }

  async updateParticipant(isHidden, participantId) {
      let res = null;
      try {
        const response = await API.call({
            url: "/circles/updateCircleParticipant",
            method: "PUT",
            body: {
              participant: {
                id: participantId,
                hidden_failed_payments: isHidden,
              },
            },
          });
          if(response.updatedParticipant.hidden_failed_payments === isHidden) {
            res = response.updatedParticipant;
          } else {
              throw Error("Participant update fail");
          }
        this.props.showToast("Participant updated", "success");
      } catch (error) {
        this.props.showToast("Participant update fail", "error");
      }

      return res;
  }

  showActive(event) {
    this.setState({checked: event.checked, loading: true});
    const filteredPayments = [...this.state.payments].filter((c) => !utils.isFinished(c.startingDate, c.period) && utils.isStarted(c.startingDate));
      if(event.checked) {
        this.setState({ filteredPayments });
      } else {
        this.setState({ filteredPayments: [...this.state.payments] });
      }

      this.setState({ loading: false });
  }

  render() {
    return (
      <React.Fragment>
        
        <TabView activeIndex={this.state.activeIndex} onTabChange={(e) => this.setState({activeIndex: e.index})}>
        
          
          <TabPanel header="Arrears">
              <Arrears arrears={this.state.arrears} showActive={this.state.checked} loading={this.state.loadingArrears} getArrears={()=> this.getArrears()}></Arrears>
          </TabPanel>

          <TabPanel header="Dropouts">
            <Dropouts showActive={this.state.checked} showToast={this.props.showToast} />
          </TabPanel>
          
          {/* <TabPanel header="Failed Payments Spot">
              <FailedPaymentSpotsList updateParticipant={this.updateParticipant} loading={this.state.loading} payments={this.state.filteredPayments}></FailedPaymentSpotsList>
          </TabPanel>
          <TabPanel header="Failed Payments">
              <FailedPaymentList payments={this.state.filteredPayments}></FailedPaymentList>
          </TabPanel> */}
        </TabView>
      </React.Fragment>
    );
  }
}

const FailedPayment = withRouter(page);

export default FailedPayment;
