import moment from "moment";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import API from "../../../../../services/api";

import ArrearsCalendar from "./CalendarSelection";
import ArrearsCheckbox from "./Checkbox";
import ArrearsFreeText from "./FreeText";
import ArrearsYesNoOption from "./YesNoOption";

function ChangeArrearsRow({ rowData, onHide }) {
    console.log(rowData);
  const [newArrears, setArrears] = useState(rowData.arrearsInfo || {});
  const [isLoading, setIsLoading] = useState(false);

  console.log(rowData);
  console.log(newArrears);

  const onChange = (key, value) => {
    let changeObj = newArrears;
    changeObj[key] = value;
    console.log("CH", changeObj);
    setArrears({ ...changeObj });
  };

  const onSubmit = async () => {
    const response = await API.call({
      url: "/circles/updateCircleParticipant",
      method: "PUT",
      body: {
          participant: {
              id: rowData._id,
              arrearsInfo: newArrears
          },
      },
    });


    if (response?.updatedParticipant) {
        alert("Update successful!");
        window.location.reload();
    } else {
        alert("Update wasn't successful");
    }
  };

  return (
    <Dialog
      onHide={onHide}
      visible={true}
      header={"Arrears for " + rowData.name}
    >
      {!isLoading ? (
        <Button label="Save Changes" onClick={onSubmit} />
      ) : (
        "Saving..."
      )}

      <ArrearsSection>
        <div style={{ marginRight: "35px" }}>
          <b>Circle ID</b>:<br /> {rowData.excelID}
        </div>
        <div>
          <b>Arrears</b>:<br /> {rowData.moneyMissing}
        </div>
      </ArrearsSection>
      <ArrearsSection>
        <ArrearsYesNoOption
          label="NOSIA?"
          value={newArrears?.NOSIA || ""}
          onChange={onChange}
          id={"NOSIA"}
        />
        <ArrearsCalendar
          label="Date Issued"
          value={
            newArrears?.dateIssued
              ? moment.utc(newArrears.dateIssued).toDate()
              : ""
          }
          onChange={onChange}
          id={"dateIssued"}
        />
      </ArrearsSection>
      <ArrearsSection>
        <ArrearsYesNoOption
          label="NOD?"
          value={newArrears?.NOD || ""}
          onChange={onChange}
          id={"NOD"}
        />
        <ArrearsYesNoOption
          label="Added to Insights?"
          value={newArrears?.addedToInsights || ""}
          onChange={onChange}
          id={"addedToInsights"}
        />
      </ArrearsSection>
      <ArrearsSection>
      <ArrearsCalendar
          label="Date Issued"
          value={
            newArrears?.NODDateIssued
              ? moment.utc(newArrears.NODDateIssued).toDate()
              : ""
          }
          onChange={onChange}
          id={"NODDateIssued"}
        />
        </ArrearsSection>
      <ArrearsSection>
        <ArrearsFreeText
          value={newArrears?.latestUpdates || ""}
          label="Latest Updates"
          id="latestUpdates"
          onChange={onChange}
        />
      </ArrearsSection>
      <ArrearsSection>
        <ArrearsCheckbox 
          value={newArrears?.exclude || false}
          label="Exclude?"
          id="exclude"
          onChange={onChange}
        />
      </ArrearsSection>
    </Dialog>
  );
}

function ArrearsSection({ children }) {
  return (
    <div
      className="flexeven"
      style={{
        borderBottom: "1px solid grey",
        marginBottom: "10px",
        padding: "20px 0px",
        alignItems: "center",
      }}
    >
      {children}
    </div>
  );
}

export default ChangeArrearsRow;
