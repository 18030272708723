import moment from "moment";

const numberOfDaysToWait = 5;
const numberOfAttemptsBeforeWaiting = 2;

function nextChanceToTakeQuiz(quiz){
    const quizData = quiz || [];

    if(numberOfAttemptsInPeriod(quizData) >= numberOfAttemptsBeforeWaiting){
        const lastTaken = quizData[quizData.length - 1].dateTaken;
        return moment.utc(lastTaken).add(numberOfDaysToWait, "days").startOf("day");
    }

    return moment.utc();
}

function numberOfAttemptsInPeriod(quizData){
    const datePeriodToCheck = moment.utc().subtract(numberOfDaysToWait, "days").startOf("day");

    return quizData.filter(itm => {
        return moment.utc(itm.dateTaken).startOf("day").isAfter(datePeriodToCheck);
    }).length;
}




export {nextChanceToTakeQuiz, numberOfAttemptsInPeriod, numberOfAttemptsBeforeWaiting, numberOfDaysToWait};