import React, { useState } from "react";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useUI } from "../../../contexts/UIContext";
import { getArticleList } from "../../../services/articles";
import articleConfig from "../../../utils/articles";
import InlineLoading from "../../generic/inlineLoading";
import ArticleList from "./List";
import GenericCategories from "./categories";
import PlatformContentContainer from "../_generic/platformContentContainer";

function ArticlesMain({match}) {
  const history = useHistory();
  const UI = useUI();

  const articleType =
    history.location.pathname.indexOf("marketplace") > -1
      ? "marketplace"
      : "education";
  const articleInfo = articleConfig[articleType];
  const selectedGroup = match.params && match.params.group ? articleInfo.categories.find(ct => ct.key === match.params.group): articleInfo.categories[0]
  const [selected, setSelected] = useState(selectedGroup);
  const [isLoading, setIsLoading] = useState(true);
  const [articles, setArticles] = useState([]);

  useEffect(() => {
    getArticleData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getArticleData = async () => {
    setIsLoading(true);
    const { data, status } = await getArticleList();

    if (status !== 200) {
      UI.showToast("We couldn't load all the articles, contact us to fix it");
    } else {
      setArticles(data);
    }

    setIsLoading(false);
  };

  return (
    <PlatformContentContainer style={{ marginTop: "-85px" }}>
      
      <h2>{articleInfo.title}</h2>
      <div className="headerDescription" style={{ marginBottom: "65px" }}>
        {articleInfo.description}
      </div>

      {isLoading && <InlineLoading />}

      {!isLoading && (
        <React.Fragment>
          <GenericCategories
            onChange={(itm) => setSelected(itm)}
            selected={selected}
            data={articleInfo.categories}
          />
          <ArticleList
            data={articles
              .filter(
                (art) =>
                  art.type === articleType &&
                  ["all", ...art.category].indexOf(selected.key) > -1
              )
              .sort((a, b) =>
                a.title && b.title ? a.title.localeCompare(b.title) : 0
              )}
          />
        </React.Fragment>
      )}
    </PlatformContentContainer>
  );
}

export default ArticlesMain;
