import { AgGridColumn, AgGridReact } from "ag-grid-react";
import moment from "moment";
import { Dialog } from "primereact/dialog";
import { currencyFormat } from "../../../../users/utils/generic";

function RiskPopup(props) {
  const value = props.value;
  console.log(value);

  const participants = value.participants;
  console.log(participants);

  const getRowStyle = (params) => {
    if(params.data.dropout_date){
        return {backgroundColor: "#ececec"};
    } else {
        return {backgroundColor: "white"}
    }
  }

  return (
    <Dialog header={"Circle " + value.excelID + " (" + participants.length + " participants)"} style={{width:"90%", height:"90%"}} onHide={() => props.onHide()} visible={true}>
      
      <div style={{marginBottom:"20px"}}>A grey row represents a dropout.</div>


      <div style={{marginBottom:"15px"}}>
        <b>Start</b>: {moment.utc(value.startingDate).format("MMM YYYY")} |{" "}
        <b>End</b>: {moment.utc(value.endingDate).format("MMM YYYY")} | {" "}
        <b>FMC</b>: {currencyFormat(value.monthly_investment)} | {" "}
        <b>Length</b>: {value.period} months
      </div>
      <div className="ag-theme-alpine" style={{ height: 500, width: '100%' }}>
      <AgGridReact getRowStyle={getRowStyle} rowSelection={"single"} rowData={participants}>
        <AgGridColumn
          headerName={"Name"}
          cellRendererFramework={name}
          field={"user"}
        />
        
        <AgGridColumn
          headerName={"Equifax Score"}
          field={"user.creditCheckScore.equifax"}
        />
        <AgGridColumn headerName={"Credit Builder"} field={"credit_builder"} />
        <AgGridColumn
          headerName={"Individual Risk"}
          field={"individualRisk"}
          cellRendererFramework={currency}
        />
        <AgGridColumn headerName={"Bad Rate"} cellRendererFramework={percentage} field={"badRate"} />
        <AgGridColumn headerName={"Pay Ins"} cellRendererFramework={currency} field={"payIns"} />
        <AgGridColumn headerName={"Pay Outs"} cellRendererFramework={currency} field={"payOuts"} />
      </AgGridReact>
      </div>
    </Dialog>
  );
}

function name(props) {
  const value = props.value;
  return value.firstname + " " + value.lastname;
}

function currency(props){
    return currencyFormat(props.value);
}

function percentage(props){
    return parseFloat(props.value*100).toFixed(2)+"%";
}


export default RiskPopup;
