import { InputSwitch } from "primereact/inputswitch";
import React from "react";

function ToggleInput(props) {
  return (
    <React.Fragment>
      <label>{props.title}</label><br></br>
      <InputSwitch
        className="inputSwitch"
        checked={props.value}
        onChange={(e) => props.onChange(e.value)}
    />
    </React.Fragment>
    
  );
}

export default ToggleInput;
