import React, { useEffect, useState} from "react";
import { useHistory } from "react-router";
import { useUI } from "../../../../contexts/UIContext";
import { useUser } from "../../../../contexts/UserContext";

import CenterMiddle from "../../../generic/CenterMiddle";
import Form from "../../../generic/forms/Form";
import LoadingOverlay from "../../../generic/LoadingOverlay";
import PaymentsWelcome from "../payments/paymentsWelcome";

import { getCorrectCircleDates } from "./logic/circleTranslation";
import paymentInputs from "./logic/paymentInputs";
import submitPaymentValues from "./logic/submitPayments";
import PaymentsCircleMissing from "./PaymentCirclesMissing";
import PaymentInfo from "./paymentInfo";
import PaymentRedirectMessage from "./paymentRedirectMessage";

import PaymentsUploadID from "./paymentUploadID";

function PaymentsMain({setTitle, setSubTitle}) {

  const UI = useUI();
  const history = useHistory();
  const userContext = useUser();
  const user = userContext.user;

  const requestedCircle = getCorrectCircleDates(user.circles.requested);
  const paymentInfoComponent = PaymentInfo(requestedCircle);
  const inputsFields = paymentInputs(() => paymentInfoComponent, userContext);

  const [base64File, setBase64File] = useState();
  const [showRedirectMessage, setShowRedirectMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [seenFinalStepWelcome, setSeenFinalStepWelcome] = useState(localStorage.getItem("seenFinalStepWelcome"));

  useEffect(() => {
    localStorage.setItem("seenFinalStepWelcome", seenFinalStepWelcome);
    if (seenFinalStepWelcome) {
      const subTitle = (
        <>Let’s set-up your auto-payments. Need to raise a different amount?
          <b onClick={() => history.push("/circleInvite")}> Click Here</b></>);

      setTitle("You’re all set!");

      setSubTitle(subTitle);
    }
  }, [setTitle, setSubTitle, history, seenFinalStepWelcome]);

  if (!seenFinalStepWelcome) {
    return <PaymentsWelcome setSeenFinalStepWelcome={setSeenFinalStepWelcome} />
  }

  const submitValues = async (values) => {
    try {

      if(!base64File){
        throw new Error("Please upload your ID")
      }


      if(values.accountNumber === "" || values.sortCode === ""){
        throw new Error("Please enter your account number and sort code");
      }

      UI.showLoading();
      const response = await submitPaymentValues(
        base64File,
        values,
        userContext
      );
      UI.hideLoading();

      setShowRedirectMessage(true);

      window.setTimeout(function () {
        window.location.href = response.url;
      }, 2500);
      
      localStorage.removeItem("seenFinalStepWelcome");
    } catch (err) {
      UI.hideLoading();
      UI.showToast(err?.message || err?.error || err);
    }
  };


  if(isLoading){
    return <LoadingOverlay />
  }

  if (!requestedCircle) {
    return <PaymentsCircleMissing />;
  }

  return (
    <React.Fragment>

      {seenFinalStepWelcome  &&
        <CenterMiddle>
          {showRedirectMessage && <PaymentRedirectMessage />}

          {/* <div className={"headerDescription"}>
        Let’s set-up your auto-payments. Need to raise a different amount?
        <b onClick={() => history.push("/circleInvite")}> Click Here</b>
      </div> */}


          <PaymentsUploadID onChange={(base64) => setBase64File(base64)} />


          {inputsFields.length && (
            <Form
              className="paymentsForm"
              inputs={inputsFields}
              onSubmit={(values) => {
                submitValues(values);
              }}
            />
          )}

        </CenterMiddle>
      }
    </React.Fragment>
  );
}

export default PaymentsMain;
