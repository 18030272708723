import React, { useRef, useEffect }  from 'react';
import moment from "moment";
import Papa from "papaparse";

const UtilsService = {
  getYearAndMonth(date) {
    if (!date) return "-";
    const ndate = moment(date);
    return { month: ndate.format("M"), year: ndate.format("YYYY") };
  },
  getYearAndMonthPeriod(date, period) {
    if (!date || !period) return "-";
    const ndate = moment(date);
    const fdate = moment(ndate).add(period, "M");
    return { month: fdate.format("M"), year: fdate.format("YYYY") };
  },
  shortYearAndMonth(date) {
    if (!date) return "-";
    return date.add(1, "M").format("MMMYY");
  },

  isNumeric(num) {
    return !isNaN(num);
  },

  isFuturePeriod(periodDate) {
    return periodDate.isAfter(moment(), "month");
  },

  getDateFromString(date) {
    return date !== null && date !== undefined
    ? new Date(date)
    : null
  },

  getName(user) {
    return user && (user.firstname || user.lastname) ? `${user.firstname ? user.firstname : "-"} ${user.lastname ? user.lastname : "-"}` : <span className="gray-text">no name</span>;
  },

  convertPeriodToDate(period) {
    const num = period.match(/\d+/g) ? period.match(/\d+/g)[0] : null;
    const letr = period.match(/[a-zA-Z]+/g)
      ? period.match(/[a-zA-Z]+/g)[0]
      : null;

    return num && letr ? moment(`${letr} 20${num}`) : null;
  },

  isFinished(startDate, period) {
    return moment().isAfter(moment(startDate).add(period - 1, 'months'), 'month');
  },

  isCircleFinished({endingDate}) {
    return endingDate ? moment().isAfter(moment(endingDate), 'month') : false;
  },
  
  isStarted(startDate) {
    return moment().isAfter(moment(startDate).subtract(1, 'month'), 'month');
  },

  // min and max included
  randomIntFromInterval(min, max) {
    return Math.random() * (max - min + 1) + min;
  },

  getCirclePeriodMonthNum(startingDate, secondDate) {
    const startDate = moment(startingDate);
    const diffYears = secondDate.year() - startDate.year();
    return secondDate.month() + diffYears * 12 - startDate.month() + 1;
  },

  isReservedPeriod(circle) {
    const months = this.getCirclePeriodMonthNum(circle.startingDate, moment());
    // const startDate = moment(startingDate);
    // const diffYears = secondDate.year() - startDate.year();
    console.log(months, circle.reserved_period);
    return months - circle.reserved_period > 0;
  },

  //sees if theres a more suitable circle in the future and returns it
  //for the case that someone is in a old requested circle
  getFutureCircleDate(circleObject) {
    try {
      if (circleObject.ambassador) {
        return circleObject;
      }

      const futureThreshold = moment.utc().add(1, "M").startOf("month");
      const circleStartDate = moment.utc(circleObject.startingDate);

      if (circleStartDate.isSameOrAfter(futureThreshold)) {
        return circleObject;
      }

      circleObject.startingDate = futureThreshold.format();

      return circleObject;
    } catch (err) {
      return circleObject;
    }
  },

  columns(data){
    const cols = data.reduce(
      (keys, obj) =>
        keys.concat(
          Object.keys(obj).filter((key) => keys.indexOf(key) === -1)
        ),
      []
    )
    return cols
  },

  isActiveCircle(circle) {
    return moment.utc().isBefore(moment.utc(circle.endingDate)) &&
           moment.utc().isAfter(moment.utc(circle.startingDate))
  },

  generateCsv(data, columns) {
    let columnsObj = null;
    try {
      columnsObj = !columns ? null : columns.reduce((acc, item, index) => {
        acc[index]=item;
        return acc;
      }, {});
    } catch (error) {
      console.log(`failed parse csv columns ${error}`)
    }
    return Papa.unparse(data, {
      columns: columnsObj || this.columns(data),
    });
  },

  downloadCSVFromJson(JSONdata, name, columns) {
    const csv = this.generateCsv(JSONdata, columns);
    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csv);
    const link = document.createElement("a");
    link.href = encodedUri;
    link.download = name + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  downloadfile(csv, year, month) {
    // now download csv file HERE.. this doesnt seem to work
    const encodedUri = encodeURI("data:text/csv;charset=utf-8," + csv);

    const link = document.createElement("a");
    link.href = encodedUri;
    link.download = year + "-" + month + ".csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  },

  currencyFormat(num) {
    return this.isNumeric(num) ? '£' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,') : '£' + num;
  },

  getFiscalYearOfDate(date){
    const dateAsMoment = moment.utc(date);

    let start = moment.utc(dateAsMoment).set({month: "April"}).startOf("month");

    if(dateAsMoment.format("M") < 4){
      start = start.subtract(1, "year");
    }

    return {start: start, end: moment.utc(start).add(1, "year").subtract(1, "second")};
  },
  count(array) {
    return array && array.length != null ? array.length : 0;
  },

  getCookie(cname) {
    var name = cname + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  },
  usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
},

getPictureURL(user) {
  const pictures = new Array(9)
    .fill(undefined)
    .map((itm, i) => "./images/userPictures/" + i + ".png");
  try {
      if (user && user.picture) {
        const userChosenPicture = pictures[user.picture];

        if (userChosenPicture) {
          return userChosenPicture;
        }
      }
      return pictures[0];
  } catch (err) {
    return pictures[0];
  }
  }
};

export default UtilsService;
