import React, { useState } from "react";
import ShowMeCircle from "./showMeCircle";
import CoolOffCountdown from "./countdown";

function CoolOffMain({ setTitle, setSubTitle }) {

  const [tabIndex, setTabIndex] = useState(0);
  const tabs = [
    {
      component: CoolOffCountdown
    },
    {
      component: ShowMeCircle
    },
  ];

  const Component = tabs[tabIndex].component;

  const setPage = (index) => {
    setTabIndex(index);
  };

  return (
    <>
      <Component
        setPage={(index) => setPage(index)}
      />
    </>
  );
}


export default CoolOffMain;
