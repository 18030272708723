
import React, { useState } from "react";
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import moment from "moment";
import { Dialog } from 'primereact/dialog';
import { Dropdown } from "primereact/dropdown";
import {userPicture, userName} from "./chatHelper";


function ChatTable({chats, loading, showCircleChat, deleteChat, editChat}){
    const [deleteChatDialog, setDeleteChatDialog] = useState(null);
    const [nameFilter, setNameFilter] = useState("All");

    const actions = (chat) => {
        const {circles, circle} = chat;
        return <>
        <Button  icon="pi pi-reply" tooltip="Reply" className="p-button-rounded p-button-text p-button-outlined"
            disabled={circles?.length > 1 || circles?.length === 0}
            onClick={()=> {showCircleChat(circle || circles[0])}}
        />
        <Button icon="pi pi-pencil" tooltip="Edit" className="p-button-rounded p-button-text p-button-outlined" 
            disabled={!chat.isAdmin}
            onClick={() => editChat(chat)}
        />
        <Button icon="pi pi-trash" tooltip="Delete" className="p-button-rounded p-button-text p-button-outlined" 
            onClick={() => setDeleteChatDialog(chat)} 
        />
        </>
    }

    const deleteChatDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={() => setDeleteChatDialog(null)} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={() => {deleteChat(deleteChatDialog);setDeleteChatDialog(null)} } />
        </>
    );

    const circleNum = ({excelID, circles}) => {
        const num = excelID ? excelID :
        circles?.length === 1 ? circles[0].excelID :
        circles?.length === 0 ? "all" :
        <i style={{lineHeight: "1.5"}} className="pi pi-ellipsis-h"></i>;
        return <div className="p-badge p-badge-secondary badge-border">{num}</div>
    }

    const messageTemp = ({message}) => {
        return <div className="p-text-truncate">{message}</div>
    }


    const getDateString = ({dateSubmitted}) => {
        const chatDate = dateSubmitted ? moment(dateSubmitted) : moment();
    
        // if(chatDate.isSame(moment(), "day")){
        //   return chatDate.format("HH:mm");
        // }
    
        return chatDate.format("DD/MM/YY");
    }

    const getDateShowFrom = ({showFromDate}) => {
        if(!showFromDate) {
            return ""
        }
    
        return `until ${moment(showFromDate).format("DD/MM/YY")}`;
    }

    const FILTER_MAP = {
        All: () => true,
        StepLadder: (chat) => chat.isAdmin,
        Members: (chat) => !chat.isAdmin
    }
    const nameFilterItems = [...new Set(Object.keys(FILTER_MAP))];
    const nameFilterTemp = (
      <Dropdown
        className="p-column-filter"
        value={nameFilter}
        options={nameFilterItems}
        onChange={(e) => (setNameFilter(e.value))}
        placeholder="Select an option"
        appendTo={document.body}  //to show DropDown over the scrollable table
    />);

    const getChats = () => {
        return chats?.filter(FILTER_MAP[nameFilter]);
    }
  
    return (<>
        <div>
            <div className="card blue-table">
                <DataTable 
                    className="table-rounded p-datatable-sm"
                    paginator
                    scrollable scrollHeight="55vh" 
                    value={getChats()}
                    loading={loading}
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" rows={50} rowsPerPageOptions={[30, 50, 100, 200]}>
                        <Column field="circle" style={{width: "70px"}} header="Circle" body={circleNum}/>
                        <Column field="user" style={{width: "50px"}} header="Pic" body={userPicture}/>
                        <Column field="user" header="Name" body={userName} filter filterElement={nameFilterTemp}/>
                        <Column field="message" header="Message" body={messageTemp} filter filterMatchMode="contains" filterPlaceholder="contains text"/>
                        <Column field="dateSubmitted" header="Date" body={getDateString}/>
                        <Column field="showFromDate" header="Hidden" body={getDateShowFrom}/>
                        <Column field="circle" style={{width: "170px"}} header="Actions" body={actions}/>
                </DataTable>
            </div>
        </div>
        <Dialog
            visible={deleteChatDialog ? true : false}
            style={{ width: '450px' }}
            header="Confirm"
            className="with-footer"
            modal
            footer={deleteChatDialogFooter}
            onHide={() => setDeleteChatDialog(null)}>
            <div className="p-p-3 p-d-flex p-p-0 p-pb-lg-0 ">
                <i className="pi pi-exclamation-triangle p-mr-3 warning" style={{ fontSize: '2rem'}} />
                {deleteChatDialog && <span>Are you sure you want to delete chat from <b>{userName(deleteChatDialog)}</b>?</span>}
            </div>
        </Dialog>
      </>
    )
    
}

export default ChatTable;