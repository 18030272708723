import {useEffect} from "react";
import moment from "moment-timezone";
import { Button } from "primereact/button";
import { Card } from "primereact/card";
import React from "react";
import { useHistory } from "react-router";

import { currencyFormat } from "../../../../utils/generic";
import HomeCircleWarning from "./homeCircleWarning";

function CircleInviteView(props) {
  const circleData = props.circleData || [];
  const selected = props.selectedCircle;
  const history = useHistory();
  useEffect(() => {
    const subTitle = (<div>
    If you need a more tailored Circle,{" "}
    <a href="https://www.joinstepladder.com/contact/">contact us</a> to
    discuss your options.
  </div>)
    props.setTitle("Select A New Circle");
    props.setSubTitle(subTitle);
  }, []);

  return (
    <React.Fragment>
      {/* <h2>Select A New Circle</h2> */}
      {/* <div className="headerDescription">
        If you need a more tailored Circle,{" "}
        <a href="https://www.joinstepladder.com/contact/">contact us</a> to
        discuss your options.
      </div> */}

      <SelectCircles
        selected={selected}
        circles={circleData}
        onChange={(circle) => {
          props.onChange(circle);
        }}
      ></SelectCircles>

      <CirclePreview circle={selected}></CirclePreview>

      <HomeCircleWarning circle={selected} />

      <div className="circleInviteButton">
        <Button
          className="p-button-outlined"
          onClick={() => history.push("/")}
          label="Back"
        ></Button>

        <Button onClick={() => props.onSubmit(selected)} label="Next"></Button>
      </div>
    </React.Fragment>
  );
}

function SelectCircles(props) {
  const selected = props.selected;
  const circles = props.circles;

  return (
    <div className="pick_other_circles">
      {circles.map((circle, i) => {
        return (
          <div
            key={circle.excelID}
            onClick={(e) => props.onChange(circle)}
            className={
              "transition pointer boxShadow " +
              (selected._id === circle._id ? "selected" : "")
            }
          >
            <div>
              {currencyFormat(circle.period * circle.monthly_investment)}
            </div>
            <div>{circle.period}mo</div>
          </div>
        );
      })}
    </div>
  );
}

function CirclePreview(props) {
  const circle = props.circle;

  const data = [
    {
      key: "MONTHLY",
      value: currencyFormat(circle.monthly_investment),
    },
    {
      key: "DURATION",
      value: circle.period + "mo",
    },
    {
      key: "MEMBERS",
      value: circle.period,
    },
    {
      key: "1ST PAY",
      value: moment(circle.startingDate).format("Do MMM"),
    },
    {
      key: "FEES P.M",
      value: currencyFormat(props.circle.admin_fee),
    },
  ];

  return (
    <Card
      className="circlePicker boxShadow"
      title={
        "Raise " + currencyFormat(circle.period * circle.monthly_investment)
      }
    >
      <div className="inner_content">
        {data.map((itm, i) => {
          return (
            <div key={i}>
              <div className="title">{itm.key}</div>
              <div className="amount">{itm.value}</div>
            </div>
          );
        })}
      </div>

      <img src="./images/Circle-First-Step.png" alt="firstSteps"></img>
    </Card>
  );
}

export default CircleInviteView;
